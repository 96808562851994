
.sidebar
  .sidebar-section.sidebar-header
      router-link.logotype.light(:to="{ name: 'Root' }")
  .sidebar-section.sidebar-body
    .sidebar-body-container
      .sidebar-nav.pt-3
        sidebar-nav-item(
          v-for="item in navItems" 
          :key="item.id"
          :to="item.url" 
          :icon="item.icon"
        ) {{ item.label }}
