<template lang="pug">
div
  .card-body
    stack
      stack-item(fill)
        .h3.mb-0 {{ $t("admin_tools.tabs.digia") }}
      stack-item 
        stack(spacing="xl")
          stack-item
            strong {{ $t("labels.filter_by") }}
          stack-item
            stack
              stack-item
                label.m-0(label-for="start-date-input") {{ $t('labels.company') }}
              stack-item
                company-select(v-model="selectedCompany")
          stack-item
            stack
              stack-item
                label.m-0(label-for="start-date-input") {{ $t('companies.automation.start_date') }}
              stack-item
                b-form-input(
                  :value="filters.date_start"
                  @input="updateQueryParams({date_start: $event || undefined})"
                  type="date"
                  id="start-date-input"
                )
          stack-item
            stack
              stack-item
                label.m-0(label-for="end-date-input") {{ $t('companies.automation.end_date') }}
              stack-item
                b-form-input(
                  :value="filters.date_end"
                  @input="updateQueryParams({date_end: $event || undefined})"
                  type="date"
                  id="end-date-input"
                )
  b-tabs(v-if="filters.company_id" card :value="digiaTab" @input="onTabChange" lazy)
    b-tab(v-for="tab in tabs" :key="tab.id" :title="tab.label") 
      component(
        :is="tab.component"
        :globalFilters="filters"
        :globalRequestFilters="requestFilters"
      )
</template>

<script>
import FilesTab from "@/views/Digia/Tabs/files.vue";
import InvoicesTab from "@/views/Digia/Tabs/invoices.vue";
import CustomersTab from "@/views/Digia/Tabs/customers.vue";
import CompanySelect from "@/components/CompanySelect.vue";
import ApiCompanies from "@/api/companies.js";
import { dateToDateString } from "@/common/date_to_date_string.js";
import { addDays } from "@/common/date_utils.js";

export default {
  components: {
    FilesTab,
    InvoicesTab,
    CustomersTab,
    CompanySelect,
  },

  computed: {
    digiaTab() {
      const index = this.tabs.findIndex(
        (tab) => tab.id === this.$route.query.digia_tab
      );
      return index >= 0 ? index : 0;
    },

    tabs() {
      return [
        {
          id: "files",
          label: this.$t("labels.files"),
          component: FilesTab,
        },
        {
          id: "invoices",
          label: this.$t("labels.invoices"),
          component: InvoicesTab,
        },
        {
          id: "customers",
          label: this.$t("labels.customers"),
          component: CustomersTab,
        },
      ];
    },

    filters() {
      return {
        company_id: this.$route.query.company_id
          ? +this.$route.query.company_id
          : null,
        company_name: this.$route.query.company_name ?? null,
        date_start:
          this.$route.query.date_start ??
          dateToDateString(addDays(new Date(), -30)),
        date_end: this.$route.query.date_end ?? dateToDateString(new Date()),
        files: this.$route.query.files
          ? JSON.parse(this.$route.query.files)
          : [],
      };
    },

    requestFilters() {
      return {
        company_id: this.filters.company_id,
        date_start: this.filters.date_start,
        date_end: this.filters.date_end,
        file_ids: this.filters.files.map((f) => f.value),
      };
    },

    selectedCompany: {
      get() {
        if (!this.filters.company_id) {
          return null;
        }
        return {
          label: this.filters.company_name ?? "",
          value: this.filters.company_id,
        };
      },
      set(value) {
        this.updateQueryParams({
          company_id: value?.value,
          company_name: value?.label,
        });
      },
    },
  },

  methods: {
    buildQueryParams(params) {
      return {
        ...this.$route,
        query: {
          ...this.$route.query,
          ...params,
        },
      };
    },

    updateQueryParams(params) {
      this.$router.push(this.buildQueryParams(params));
    },

    replaceQueryParams(params) {
      this.$router.replace(this.buildQueryParams(params));
    },

    onTabChange(index) {
      this.updateQueryParams({ digia_tab: this.tabs[index].id });
    },

    clearQueryParams() {
      this.replaceQueryParams({
        digia_tab: undefined,
        company_id: undefined,
        company_name: undefined,
        date_start: undefined,
        date_end: undefined,
        files: undefined,
      });
    },

    async initCompany() {
      if (!this.filters.company_id) {
        const response = await ApiCompanies.fetchCompanies({
          page: 1,
          per_page: 1,
        });
        const company = response.data.companies[0] ?? null;
        if (company) {
          this.replaceQueryParams({
            company_id: company.id,
            company_name: company.company_name,
          });
        }
      }
    },
  },

  mounted() {
    this.initCompany();
  },

  beforeDestroy() {
    this.clearQueryParams();
  },
};
</script>
