<template lang="pug">
  .container
    stack
      stack-item(fill)
        .h3.mb-0 {{ $t('companies.tabs.approvers') }}
      stack-item
        b-btn(
          variant="primary",
          squared,
          @click="exportApprovalLogs()"
        )
          .mdi.mdi-download
          | {{ $t('companies.export_to_excel') }}
    .pt-3.px-3
      stack
        stack-item
          b-form-group(
            :label="$t('companies.basic.vendor')"
            label-for="name-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.name"
              type="search"
              id = "name-input"
              @change="reload"
            )
      rest-table(
        namespace="approvers_log",
        :headers="headers",
        ref="ApproversLogTable",
        :fetch-handler="getData",
        embed
      )
</template>

<script>

import ApiApprovalLogs from "@/api/approval_logs.js";
import ApiCompanies from "@/api/companies";

export default {
  data() {
    return {
      search_params: {},
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "vendor",
          label: "Vendor",
          sortable: true,
          squeeze: true,
        },
        {
          key: "invoice_number",
          label: "Purchase Invoice Number",
          sortable: true,
          squeeze: true,
        },
        {
          key: "invoice_date",
          label: "Invoice Date",
          sortable: true,
          squeeze: true,
        },
        {
          key: "approver_set",
          label: "Approver Set",
          sortable: true,
          squeeze: true,
        },
        {
          key: "factual_verificator_set",
          label: "Factual Verificator Set",
          sortable: true,
          squeeze: true,
        },
        {
          key: "created_at",
          label: "Timestamps",
          sortable: true,
          squeeze: true,
        },
      ]
    }
  },

  methods: {
    reload() {
      this.$refs.ApproversLogTable.fetchData();
    },

    exportApprovalLogs() {
      ApiCompanies.exportApprovalLogs(this.$route.params.id)
          .then((resp) => {
            let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", `approval_logs.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((e) => {});
    },

    getData(pagination) {
      this.inProgress = true;
      const search_params = this.search_params;
      return new Promise((res) => {
        ApiApprovalLogs.FetchLogs(this.$route.params.id, search_params, pagination).then(
            (resp) => {
              res({
                items: resp.data.approval_logs,
                meta: resp.data.meta,
              });
            }
        ).catch((e) => {
          this.inProgress = false;
        });
      });
    },
  }

}
</script>
