<template lang="pug">
div
  .mb-3
    stack
      stack-item
        stack
          stack-item
            label.m-0(label-for="search") {{ $t('labels.search') }}
          stack-item
            b-form-input(
              :value="filters.search"
              @input="updateQueryParams({search: $event || undefined})"
              :placeholder="$t('placeholders.type')"
              id="search"
            )
      stack-item
        stack
          stack-item
            label.m-0(label-for="status") {{ $t('labels.status') }}
          stack-item
            ui-select(
              :searchable="false"
              :options="statusOptions",
              :value="filters.file_status"
              @input="updateQueryParams({file_status: $event || undefined})"
              id="status"
            )
      stack-item(fill)
      stack-item
        b-btn(
          variant="primary"
          squared
          @click="callRestartDialog"
        )
          .mdi.mdi-refresh
          | {{ $t('actions.restart_all_failed') }}
  rest-table(
    ref="table"
    namespace="files"
    :headers="headers"
    :fetch-handler="getData"
  )
    template(v-slot:cel_status="{item}")
      file-status-badge(:status="item.status")
    template(v-slot:cel_invoices_count="{item}")
      router-link(:to="getInvoicesLink(item)") {{ item.invoices_count }}
    template(v-slot:cel_customers_count="{item}")
      router-link(:to="getCustomerLink(item)") {{ item.customers_count }}
</template>

<script>
import ApiDigiaFiles from "@/api/digia_files.js";
import FileStatusBadge from "./components/file_status_badge.vue";
import ApiDigiaCustomers from "@/api/digia_customers.js";

export default {
  components: {
    FileStatusBadge,
  },

  props: {
    globalFilters: {
      type: Object,
      required: true,
    },
    globalRequestFilters: {
      type: Object,
      required: true,
    },
  },

  watch: {
    requestFilters(oldValue, newValue) {
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        this.refetchTable();
      }
    },
  },

  computed: {
    filters() {
      return {
        search: this.$route.query.search ?? "",
        file_status: this.$route.query.file_status ?? null,
      };
    },

    requestFilters() {
      return {
        ...this.globalRequestFilters,
        filename: this.filters.search,
        status: this.filters.file_status,
      };
    },

    headers() {
      return [
        {
          key: "id",
          label: this.$t("labels.file_id"),
          sortable: true,
        },
        {
          key: "filename",
          label: this.$t("labels.file_name"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("labels.date"),
          sortable: true,
        },
        {
          key: "invoices_count",
          label: this.$t("labels.invoices_count"),
          sortable: true,
        },
        {
          key: "customers_count",
          label: this.$t("labels.customers_count"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("labels.status"),
          sortable: true,
        },
        {
          key: "status_reason",
          label: this.$t("labels.status_reason"),
          width: "35%",
        },
      ];
    },

    statusOptions() {
      return [
        {
          value: null,
          label: this.$t("labels.any_status"),
        },
        {
          value: "failed",
          label: this.$t("labels.failed"),
        },
        {
          value: "processed",
          label: this.$t("labels.processed"),
        },
        {
          value: "processing",
          label: this.$t("labels.processing"),
        },
      ];
    },
  },

  methods: {
    refetchTable() {
      this.$refs.table.updateOptions({ page: 1 });
    },

    getInvoicesLink(item) {
      return {
        ...this.$route,
        query: {
          ...this.$route.query,
          digia_tab: "invoices",
          files: JSON.stringify([{ value: item.id, label: item.filename }]),
        },
      };
    },

    getCustomerLink(item) {
      return {
        ...this.$route,
        query: {
          ...this.$route.query,
          digia_tab: "customers",
          files: JSON.stringify([{ value: item.id, label: item.filename }]),
        },
      };
    },

    getData(pagination) {
      return new Promise((res) => {
        ApiDigiaFiles.fetchAll({
          ...pagination,
          ...this.requestFilters,
        }).then((response) => {
          res({
            items: response.data.digia_files,
            meta: response.data.meta,
          });
        });
      });
    },

    buildQueryParams(params) {
      return {
        ...this.$route,
        query: {
          ...this.$route.query,
          ...params,
        },
      };
    },

    updateQueryParams(params) {
      this.$router.push(this.buildQueryParams(params));
    },

    replaceQueryParams(params) {
      this.$router.replace(this.buildQueryParams(params));
    },

    clearQueryParams() {
      this.replaceQueryParams({
        search: undefined,
        file_status: undefined,
      });
    },

    callRestartDialog() {
      this.$dialogConfirm({
        title: "Restart all failed",
        description: "Do you really want to restart all failed invoices?",
        variant: "danger",
        okLabel: this.$t("actions.restart_all_failed"),
      }).then((result) => {
        if (!result) return;
        ApiDigiaCustomers.restart({
          ...this.globalRequestFilters,
        }).then(() => {
          this.refetchTable();
        });
      });
    },
  },

  beforeDestroy() {
    this.clearQueryParams();
  },
};
</script>
