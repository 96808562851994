
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('admin_tools.tabs.failed_vendors') }}
      stack
        stack-item()
          b-form-group(
            :label="$t('admin_tools.failed_vendors.name')"
            label-for="model-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.name"
              type="search"
              id = "model-input"
              @change="reload"
            )
        stack-item()
          b-form-group(
            :label="$t('admin_tools.failed_vendors.company_name')"
            label-for="model-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.company_name"
              type="search"
              id = "model-input"
              @change="reload"
            )
        stack-item
          b-btn(
            variant="primary"
            squared
            @click="exportToExcel"
          )
            .mdi.mdi-download
            | {{ $t('resellers.export_to_excel') }}
      rest-table(
        namespace="logs",
        :headers="headers",
        ref="failedVendorsTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_actions" slot-scope="{ item }")
          b-button(
            size="xs",
            variant="flat-danger",
            squared,
            v-b-tooltip.focus,
            @click="deleteVendor(item)"
          )
            .mdi.mdi-trash-can-outline
            | {{ $t("actions.delete") }}
