
b-modal(resizable,
  v-model="show",
  dialog-class="table-modal"
)
  template(slot="modal-title")
    .modal-title {{ $t('companies.approvers.add_title') }}
    .modal-subtitle {{ $t('companies.approvers.add_description') }}
  stack
    stack-item
      b-form-group(
        :label="$t('companies.approvers.name')"
        label-for="name-input"
        label-cols="4"
      )
        b-form-input(
          v-model="search_params.name"
          type="search"
          id = "name-input"
          @change="reload"
        )
  rest-table(
    namespace="assign_approvers",
    :headers="headers",
    ref="AssignApproversTable",
    :fetch-handler="getData",
    embed
  )
    template(slot="cel_actions", slot-scope="{ item }")
      b-btn(
        variant="primary"
        squared
        v-if="!item.is_assigned"
        @click="assign(item)"
      )
        .mdi.mdi-plus-box-outline
        | {{ $t('companies.approvers.assign') }}
      b-btn(
        variant="flat-danger",
        squared,
        v-if="item.is_assigned",
        @click="deassign(item)"
      )
        .mdi.mdi-minus-box-outline
        | {{ $t('companies.approvers.deassign') }}
  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="close"
    )
      | {{ $t('actions.ok') }}
