<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('resellers.tabs.groups') }}
        stack-item
          b-btn(
            variant="primary"
            squared
            @click="addCompanyGroup"
          )
            .mdi.mdi-briefcase-plus-outline
            | {{ $t('resellers.add_group') }}
      rest-table(
        namespace="reseller_company_groups"
        :headers="headers"
        ref="resellersCompanyGroupsTable"
        :fetch-handler="getData"
        embed
      )
        template(slot="cel_actions" slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs"
                variant="flat-primary"
                squared
                @click="editCompanyGroup(item)"
              ) 
                .mdi.mdi-pencil-outline
                | {{ $t('actions.edit') }}
            stack-item
              b-btn(
                size="xs"
                variant="flat-danger"
                squared
                @click="deleteCompanyGroup(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
    edit-company-group-modal(ref="EditCompanyGroupModal" @done="reloadList")
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiResellers from "@/api/resellers.js";
import EditCompanyGroupModal from "@/modals/Resellers/editCompanyGroup.vue";

export default {
  components: {
    EditCompanyGroupModal
  },

  data() {
    return {
      pagination: {
        sortBy: null
      },
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true
        },
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "actions",
          label: "",
          squeeze: true
        }
      ]
    };
  },

  methods: {
    reloadList() {
      this.$refs.resellersCompanyGroupsTable.fetchData();
    },
    getData(pagination) {
      return new Promise(res => {
        ApiResellers.fetchResellerCompanyGroups(
          this.$route.params.id,
          pagination
        ).then(resp => {
          res({
            items: resp.data.reseller_company_groups,
            meta: resp.data.meta
          });
        });
      });
    },

    editCompanyGroup(item) {
      this.$refs.EditCompanyGroupModal.open(this.$route.params.id, item);
    },

    deleteCompanyGroup(item) {
      ApiResellers.deleteResellerCompanyGroup(
        this.$route.params.id,
        item.id
      ).then(resp => {
        this.reloadList();
      });
    },

    addCompanyGroup() {
      this.$refs.EditCompanyGroupModal.open(this.$route.params.id, null);
    },

    goBack() {
      router.push("/resellers");
    }
  }
};
</script>
