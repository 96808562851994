import axios from 'axios';

const fetchResellers = (pagination) => {
  return axios.get('/api/v1/resellers', { params: { ...pagination }});
};

const getReseller = id => {
  return axios.get(`/api/v1/resellers/${id}`);
};

const createReseller = (reseller) => {
  return axios.post('/api/v1/resellers', reseller);
};

const updateReseller = (id, reseller) => {
  return axios.patch(`/api/v1/resellers/${id}`, reseller);
};

const deleteReseller = id => {
  return axios.delete(`/api/v1/resellers/${id}`);
};

const inviteResellerUser = (resellerId, resellerUser) => {
  return axios.post(`/api/v1/resellers/${resellerId}/invite`, resellerUser);
};

const fetchResellerUsers = (resellerId, pagination) => {
  return axios.get(`/api/v1/resellers/${resellerId}/users`, { params: { ...pagination }});
};

const updateResellerUser = (resellerId, resellerUserId, resellerUser) => {
  return axios.patch(`/api/v1/resellers/${resellerId}/users/${resellerUserId}`, resellerUser);
};

const deleteResellerUser = (resellerId, resellerUserId) => {
  return axios.delete(`/api/v1/resellers/${resellerId}/users/${resellerUserId}`);
};

const fetchResellerCompanies = (resellerId,query_params, pagination) => {
  return axios.get(`/api/v1/resellers/${resellerId}/companies`, { params: { ...query_params, ...pagination }});
};

const exportToExcel = (resellerId) => {
  return axios({
    url:`/api/v1/resellers/${resellerId}/export_companies_to_excel`,
    method: 'GET',
    responseType: 'blob'
  });
};

const fetchResellerErrors = (resellerId) => {
  return axios.get(`/api/v1/resellers/${resellerId}/errors`);
};

const fetchResellerStatistics = (resellerId) => {
  return axios.get(`/api/v1/resellers/${resellerId}/statistics`);
};

const fetchResellerCompanyGroups = (resellerId, pagination) => {
  return axios.get(`/api/v1/resellers/${resellerId}/company_groups`, { params: { ...pagination }});
};

const addResellerCompanyGroup = (resellerId, resellerCompanyGroup) => {
  return axios.post(`/api/v1/resellers/${resellerId}/add_company_group`, resellerCompanyGroup);
};

const updateResellerCompanyGroup = (resellerId, resellerCompanyGroupId, resellerCompanyGroup) => {
  return axios.patch(`/api/v1/resellers/${resellerId}/company_groups/${resellerCompanyGroupId}`, resellerCompanyGroup);
};

const deleteResellerCompanyGroup = (resellerId, resellerUserId) => {
  return axios.delete(`/api/v1/resellers/${resellerId}/company_groups/${resellerUserId}`);
};

const ApiResellers = {
  fetchResellers,
  getReseller,
  createReseller,
  updateReseller,
  deleteReseller,
  fetchResellerUsers,
  inviteResellerUser,
  updateResellerUser,
  deleteResellerUser,
  fetchResellerCompanies,
  fetchResellerErrors,
  fetchResellerStatistics,
  fetchResellerCompanyGroups,
  addResellerCompanyGroup,
  updateResellerCompanyGroup,
  deleteResellerCompanyGroup,
  exportToExcel
};

export default ApiResellers;