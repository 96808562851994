<template lang="pug">
  .container
    stack
      stack-item
        b-button(
          class="uppercase"
          :variant="'flat-primary'"
          v-b-tooltip.focus
          @click="close()"
        )
          .mdi.mdi-backspace-outline
          | Back
    Approvers(:target="'account'", :target_id="this.$route.query['account_id']")

</template>

<script>
import Approvers from "@/views/Approvers/index.vue";

export default {
  components: {
    Approvers
  },

  methods: {
    close() {
      const {vendor_id, invoice_id, account_id, ...rest} = this.$route.query;

      this.$router.push({...this.$route, query: rest})
    },
  }
}
</script>