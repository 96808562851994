import axios from 'axios';

const fetchInvoices = (company_id, query_params, pagination) => {
    return axios.get(`/api/v1/companies/${company_id}/scope_sales_invoices`, { params: { ...query_params, ...pagination }});
};

const fetchInvoice = (company_id, invoice_id) => {
    return axios.get(`/api/v1/companies/${company_id}/scope_sales_invoices/${invoice_id}`);
};

const fetchStates = (company_id) => {
    return axios.get(`/api/v1/companies/${company_id}/scope_sales_invoices/states`);
};

const restartInvoices = (company_id) => {
    return axios.get(`/api/v1/companies/${company_id}/scope_sales_invoices/restart`);
};

const deleteFailedInvoices = (company_id) => {
    return axios.get(`/api/v1/companies/${company_id}/scope_sales_invoices/delete_failed`);
}

const deleteProcessedInvoices = (company_id) => {
    return axios.get(`/api/v1/companies/${company_id}/scope_sales_invoices/delete_processed`);
}

const deleteInvoice = (company_id, invoice_id) => {
    return axios.delete(`/api/v1/companies/${company_id}/scope_sales_invoices/${invoice_id}`)
}

const exportFailed = (company_id) => {
    return axios({
        url: `/api/v1/companies/${company_id}/scope_sales_invoices/export_failed`,
        method: 'GET',
        responseType: 'blob'
    })
}

const ApiScopeSalesInvoices = {
    fetchInvoices,
    fetchStates,
    fetchInvoice,
    restartInvoices,
    deleteFailedInvoices,
    deleteProcessedInvoices,
    deleteInvoice,
    exportFailed
};

export default ApiScopeSalesInvoices;