
div
  .pb-5
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{ $t('companies.reports.scheduled_reports') }}
        rest-table(
          namespace="report_types",
          :headers="type_headers",
          ref="scheduledReportTypesTable",
          :fetch-handler="getScheduledReportTypes",
          :disablePagination="total_types <= 10"
          embed
        )
          template(slot="cel_schedule", slot-scope="{ item }")
            p {{ item.schedule }}
          template(slot="cel_actions", slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-danger",
                  squared,
                  @click="deleteReport(item)",
                  v-if="item.status != 'started'"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{ $t('companies.reports.scheduled_reports_runned') }}
        rest-table(
          namespace="reports_scheduled",
          :headers="headers",
          ref="reportsTable",
          :fetch-handler="getData",
          embed
        )
          template(slot="cel_status", slot-scope="{ item }")
            p {{ item.status }}
              b-badge(
                pill
                variant="danger"
                v-if="item.status === 'error'"
                :id="`error-tooltip-target-${item.id}`"
                @click="showReport(item)"
              ) !
              b-tooltip(
                :target="`error-tooltip-target-${item.id}`"
                triggers="hover"
                v-if="item.status === 'error'"
              ) {{ item.message[0] }} <br /> {{ $t('companies.reports.show_details') }}
            p {{ item.sftp_status == 'uploaded' ? 'sftp' :  item.sftp_status == 'error' ? 'sftp' : '' }}
              b-badge(
                pill
                :variant="item.sftp_status === 'uploaded' ? 'primary' : 'danger'"
                v-if="item.sftp_status === 'error' || item.sftp_status === 'uploaded'"
                :id="`error-tooltip-target-sftp-${item.id}`"
              ) !
              b-tooltip(
                :target="`error-tooltip-target-sftp-${item.id}`"
                triggers="hover"
                v-if="item.sftp_status === 'error' || item.sftp_status === 'uploaded'"
              ) {{ item.sftp_message }}
          template(slot="cel_name", slot-scope="{ item }")
            task-name(:item="item")
          template(slot="cel_actions", slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-danger",
                  squared,
                  @click="deleteReport(item)",
                  v-if="item.status != 'started'"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-warning",
                  squared,
                  @click="restartJob(item)",
                  v-if="item.status == 'error'"
                )
                  .mdi.mdi-restart
                  | {{ $t('companies.reports.restart') }}
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-warning",
                  squared,
                  @click="editReport(item)",
                  v-if="item.status == 'error' & ['twin_treasury_file', 'netvisor_opus_voucher', 'netvisor_importer_payment'].includes(item.report_type)"
                )
                  .mdi.mdi-file-edit
                  | {{ $t('actions.edit') }}
  create-report-modal(ref="CreateReportModal", @done="reloadList")
  show-report-modal(ref="ShowReportModal")
