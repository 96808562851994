import axios from 'axios';

const fetchAll = (company_id, params, config) => {
  return axios.get(`/api/v1/companies/${company_id}/dimension_names`, { params, ...config });
};

const ApiCompanyDimensionNames = {
  fetchAll,
};

export default ApiCompanyDimensionNames;
