
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('resellers.tabs.statistics') }}

      rest-table(
        namespace="resellerStatistics"
        :headers="headers"
        ref="resellerStatisticsTable"
        :fetch-handler="getData"
        embed
      )
