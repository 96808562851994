
.h-100(v-if="hasCompanies")
  header-dropdown(
    ref="dropdown"
    v-if="companiesList.length"
    :label="currentCompany.name"
  )
    span(slot="subtitle")
      | {{ $t(`company_switcher.role`) }} 
      b-badge.text-uppercase(
        variant="primary" pill
      ) {{ $t(`company.role.${currentCompany.role}`) }}
    div(slot="dropdown")
      .pb-3.pt-2
        h6.dropdown-header {{ $t('company_switcher.switch_company') }}
        button.dropdown-item.d-flex.justify-content-between.align-items-center(
          v-for="company in companies"
          :key="company.id"
          :class="{ active: company.id === currentCompany.id }"
          @click="switchCompany(company.id)"
        ) 
          .mr-4 {{ company.name }}
          small 
            b-badge.text-uppercase(
              v-if="company.role === 'admin'"
              variant="primary" pill
            ) {{ $t(`company.role.${company.role}`) }}
  header-button(
    v-else
    :label="currentCompany.name"
  )
    span(slot="subtitle")
      | {{ $t(`company_switcher.role`) }} 
      b-badge.text-uppercase(
        variant="primary" pill
      ) {{ $t(`company.role.${currentCompany.role}`) }}
