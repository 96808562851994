import axios from 'axios';

const fetchUsers = (query_params, pagination) => {
    return axios.get('/api/v1/admin_company_users', { params: { ...query_params, ...pagination }});
};

const updateUser = (id, params) => {
    return axios.put(`/api/v1/admin_company_users/${id}`, { params: { ...params } })
};

const createUser = (id, params) => {
    return axios.post(`/api/v1/admin_company_users/${id}`, { params: { ...params } })
};

const deleteUser = (id) => {
    return axios.delete(`/api/v1/admin_company_users/${id}`)
}

const importUsers = (file) => {
    return axios.post(`/api/v1/admin_company_users/import`, file, { headers: { 'Content-Type': 'multipart/form-data'} })
}
const exportUsers = () => {
    return axios({
        url:`/api/v1/admin_company_users/export`,
        method: 'GET',
        responseType: 'blob'
    });
}

const getCompanies = () => {
    return axios.get(`/api/v1/admin_company_users/companies`)
}

const ApiAdminCompanyUsers = {
    fetchUsers,
    createUser,
    updateUser,
    deleteUser,
    importUsers,
    exportUsers,
    getCompanies
};

export default ApiAdminCompanyUsers;