import axios from 'axios';

const fetchVendors = (company_id, query_params, pagination) => {
  let res = axios.get(`/api/v1/companies/${company_id}/vendors`, { params: { ...query_params, ...pagination }});
  return res
};

const updateVendor = (company_id, vendor_id, vendor) => {
  return axios.patch(`/api/v1/companies/${company_id}/vendors/${vendor_id}`, vendor);
};

const switchCombineLines = (company_id, state) => {
  return axios.patch(`/api/v1/companies/${company_id}/vendors/company_switch_combine_lines`, state);
};

const mostCommonVatCodes = (company_id) => {
  return axios.get(`/api/v1/companies/${company_id}/vendors/most_common_vat_codes`);
};

const vendorDimensions = (company_id, vendor_id, pagination) => {
 return axios.get(`/api/v1/companies/${company_id}/vendors/${vendor_id}/vendor_dimensions`, { params: { ...pagination }});
};

const showVendor = (company_id, vendor_id) => {
  return axios.get(`/api/v1/companies/${company_id}/vendors/${vendor_id}`);
}

const fetchPurchaseInvoices = (company_id, vendor_id, query_params, pagination) => {
 return axios.get(`/api/v1/companies/${company_id}/vendors/purchase_invoices`, { params: { ...pagination, ...query_params, vendor_id: vendor_id } })
}

const ApiCompanyVendors = {
  fetchVendors,
  updateVendor,
  switchCombineLines,
  mostCommonVatCodes,
  vendorDimensions,
  showVendor,
  fetchPurchaseInvoices
};

export default ApiCompanyVendors;
