<template lang="pug">
b-modal(centered,
v-model="show",
hide-footer=true,
:title = "error_message"
)
  stack
    stack-item
      .container
        p(
          v-html="backtrace"
        )
</template>

<script>

export default {
  data(){
    return{
      show: false,
      backtrace: '',
      error_message: ''
    }
  },
  methods: {
    open(item) {
      this.show = true;
      this.inProgress = true;
      this.error_message = item.message[0];
      this.backtrace = this.parseBacktrace(item.message[1]);
    },

    parseBacktrace(message){
      try {
        return JSON.parse(message).join('<br>')
      } catch {
        if(message) {
          return message
        } else {
          return 'no details'
        }
      }
    }
  },

  close() {
    this.show = false;
  }
}

</script>
