<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item()
          .h3.mb-0 {{ $t('admin_tools.tabs.relearn') }}
        stack-item
          .p#relearn-status
    .container
      stack
        stack-item
          b-form-group(
            :label="$t('admin_tools.relearn.company')"
            label-for="model-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.company"
              type="search"
              id = "model-input"
              @change="reloadList"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.automation.start_date')"
            label-for="start-date-input"
            label-cols="5"
          )
            b-form-input(
              v-model="search_params.start_date"
              type="date"
              id="start-date-input"
              @change="reloadList"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.automation.end_date')"
            label-for="end-date-input"
            label-cols="5"
          )
            b-form-input(
              v-model="search_params.end_date"
              type="date"
              id="end-date-input"
              @change="reloadList"
            )
        stack-item

      rest-table(
        namespace="processed_companies",
        :headers="headers",
        ref="companiesTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_start_date", slot-scope="{ item }")
          b-form-input(
            type="date",
            v-model="item.start_date",
            @input="setEdit(item)",
          )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                :disabled="canBeLearned(item)",
                @click="startLearning(item)"
              )
                .mdi.mdi-refresh
                | {{ $t('admin_tools.relearn.start_learning') }}
      b-button.float-button(
        v-if="edited_items.length > 0"
        variant="primary",
        @click="saveAll"
      )
        .mdi.mdi-content-save-all
        | Save changes
</template>

<script>
import ApiAdminTools from "@/api/admin_tools.js";

export default {

  data() {
    return {
      search_params: {
        company: null,
      },
      pagination: {
        sortBy: null,
      },
      edited_items: [],
      items: [],
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "company_name",
          label: "Company",
          sortable: true,
          squeeze: true,
        },
        {
          key: "invoices_count",
          label: "Invoices",
          sortable: true,
          squeeze: true,
        },
        {
          key: "last_learning",
          label: "Relearned at",
          sortable: true,
          squeeze: true,
        },
        {
          key: "start_date",
          label: "Start Date",
          sortable: true,
          squeeze: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          squeeze: true,
        },
        {
          key: "status_reason",
          label: "Status Reason",
          sortable: true,
          squeeze: true,
        },
        {
          key: "body",
          label: "Body",
          sortable: true,
          squeeze: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        }
      ]
    }
  },

  components: {
  },
  methods: {
    reloadList() {
      this.$refs.companiesTable.fetchData();
    },

    getData(pagination) {
      const search_params = this.search_params;
      return new Promise((res) => {
        ApiAdminTools.processedCompanies(search_params, pagination).then(
            (resp) => {
              this.items = resp.data.companies;
              res({
                items: resp.data.companies,
                meta: resp.data.meta,
              });
            }
        );
      });
    },

    startLearning(item) {
      this.$dialogConfirm({
        title: this.$t('admin_tools.relearn.start_learning') + ' "' + item.company_name + '"?',
        description: this.$t("admin_tools.relearn.confirm_relearn_company"),
        variant: "warning",
        icon: "mdi mdi-alert",
        okLabel: this.$t("admin_tools.relearn.start_learning"),
        okVariant: "primary",
        okIcon: "refresh",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiAdminTools.startLearning(item.id).then(
            (resp) => {
              this.reloadList();
            }
        );
      });
    },

    canBeLearned(item) {
      return item.is_learning
    },

    RelearnDisabled() {
      let worker_running = this.$t("admin_tools.relearn.worker_is_running");
      let free_queue = this.$t("admin_tools.relearn.free_queue");
      ApiAdminTools.checkRelearnQueue().then((resp) => {
        let item = document.getElementById("relearn-status");
        if(resp.data.company_ids.length > 0) {
          item.textContent = worker_running + " " + resp.data.company_ids.join(", ");
          return;
        }
        item.textContent = free_queue;
      });
    },

    setStartDate(item) {
      ApiAdminTools.setStartDate(item.id, item.start_date).then(
          (resp) => {
            this.reloadList();
          }
      );
    },

    setEdit(item) {
      item.is_edited = true
      this.edited_items.push(item.id)
    },

    saveAll() {
      this.items.forEach((item) => {
        if(item.is_edited) {
          this.setStartDate(item)
          item.is_edited = false
        }
      })
      this.edited_items = []
    }
  },

  mounted() {
    this.RelearnDisabled();
  }
};
</script>
