<template lang="pug">
.mini-profile(v-if="user")
  .mini-profile__header
    .mini-profile__avatar
      avatar-box(
        slot="prepend"
        :src="user.avatar"
        :label="user.name || user.email"
      )
    .mini-profile__info
      .mini-profile__name {{ user.name || `User #${user.id}` }}
      .mini-profile__email {{ user.email }}
  .mini-profile__body
    stack(spacing="sm")
      stack-item(fill)
        b-btn(
          variant="link-secondary"
          block
          squared
          disabled
        ) 
          .mdi.mdi-account-edit-outline
          | {{ $t('user.edit_profile') }}
      stack-item(fill)
        b-btn(
          variant="link-secondary"
          block
          squared
          @click="logout"
        ) 
          .mdi.mdi-exit-to-app
          | {{ $t('user.logout') }}
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['user'])
  },

  methods: {
    logout() {
      this.$store.dispatch('logout');
    }
  }
};
</script>