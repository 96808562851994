<template lang="pug">
div
  .pb-5
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{ $t('companies.reports.scheduled_reports') }}
        rest-table(
          namespace="report_types",
          :headers="type_headers",
          ref="scheduledReportTypesTable",
          :fetch-handler="getScheduledReportTypes",
          :disablePagination="total_types <= 10"
          embed
        )
          template(slot="cel_schedule", slot-scope="{ item }")
            p {{ item.schedule }}
          template(slot="cel_actions", slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-danger",
                  squared,
                  @click="deleteReport(item)",
                  v-if="item.status != 'started'"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{ $t('companies.reports.scheduled_reports_runned') }}
        rest-table(
          namespace="reports_scheduled",
          :headers="headers",
          ref="reportsTable",
          :fetch-handler="getData",
          embed
        )
          template(slot="cel_status", slot-scope="{ item }")
            p {{ item.status }}
              b-badge(
                pill
                variant="danger"
                v-if="item.status === 'error'"
                :id="`error-tooltip-target-${item.id}`"
                @click="showReport(item)"
              ) !
              b-tooltip(
                :target="`error-tooltip-target-${item.id}`"
                triggers="hover"
                v-if="item.status === 'error'"
              ) {{ item.message[0] }} <br /> {{ $t('companies.reports.show_details') }}
            p {{ item.sftp_status == 'uploaded' ? 'sftp' :  item.sftp_status == 'error' ? 'sftp' : '' }}
              b-badge(
                pill
                :variant="item.sftp_status === 'uploaded' ? 'primary' : 'danger'"
                v-if="item.sftp_status === 'error' || item.sftp_status === 'uploaded'"
                :id="`error-tooltip-target-sftp-${item.id}`"
              ) !
              b-tooltip(
                :target="`error-tooltip-target-sftp-${item.id}`"
                triggers="hover"
                v-if="item.sftp_status === 'error' || item.sftp_status === 'uploaded'"
              ) {{ item.sftp_message }}
          template(slot="cel_name", slot-scope="{ item }")
            task-name(:item="item")
          template(slot="cel_actions", slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-danger",
                  squared,
                  @click="deleteReport(item)",
                  v-if="item.status != 'started'"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-warning",
                  squared,
                  @click="restartJob(item)",
                  v-if="item.status == 'error'"
                )
                  .mdi.mdi-restart
                  | {{ $t('companies.reports.restart') }}
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-warning",
                  squared,
                  @click="editReport(item)",
                  v-if="item.status == 'error' & ['twin_treasury_file', 'netvisor_opus_voucher', 'netvisor_importer_payment'].includes(item.report_type)"
                )
                  .mdi.mdi-file-edit
                  | {{ $t('actions.edit') }}
  create-report-modal(ref="CreateReportModal", @done="reloadList")
  show-report-modal(ref="ShowReportModal")
</template>
<script>
import router from "@/router";
import axios from "axios";
import { mapGetters } from "vuex";
import ApiCompanyReports from "@/api/companyReports.js";
import ApiCompanies from "@/api/companies.js";
import CreateReportModal from "@/modals/Companies/createReport.vue";
import ShowReportModal from "@/modals/Companies/showReport.vue";
import TaskName from "@/views/Tasks/taskName.vue";

export default {
  components: {
    CreateReportModal,
    ShowReportModal,
    TaskName
  },

  data() {
    return {
      company: {},
      total_types: 0,
      pagination: {
        sortBy: null,
      },
      type_headers: [
        {
          key: "report_type_name",
          label: "Type",
          sortable: true,
        },
        {
          key: "schedule",
          label: "Schedule",
          sortable: false,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        }
      ],
      headers: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "report_type_name",
          label: "Type",
          sortable: true,
        },
        {
          key: "software_name",
          label: "Software",
          sortable: true,
        },
        {
          key: "name",
          label: "File name",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Created at",
          sortable: true,
        },
        {
          key: "end_at",
          label: "End at",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    };
  },
  methods: {
    reloadList() {
      this.$refs.reportsTable.fetchData();
      this.$refs.scheduledReportTypesTable.fetchData();
    },
    getScheduledReportTypes() {
      return new Promise((res) => {
        ApiCompanyReports.fetchScheduledReportTypes(this.$route.params.id).then(
          (resp) => {
            this.total_types = resp.data.meta.total_count
            res({
              items: resp.data.reports,
              meta: resp.data.meta,
            });
          }
        );
      });
    },

    getData(pagination) {
      return new Promise((res) => {
        ApiCompanyReports.fetchScheduledReports(this.$route.params.id, pagination).then(
          (resp) => {
            res({
              items: resp.data.reports,
              meta: resp.data.meta,
            });
          }
        );
      });
    },

    showReport(item) {
      this.$refs.ShowReportModal.open(item)
    },

    addReport() {
      this.$refs.CreateReportModal.open(this.$route.params.id, null);
    },

    editReport(item) {
      this.$refs.CreateReportModal.open(this.$route.params.id, item);
    },

    deleteReport(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "briefcase-remove",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiCompanyReports.deleteReport(this.$route.params.id, item.id).then(
          (resp) => {
            this.reloadList();
          }
        );
      });
    },
    downloadReport(item) {
      ApiCompanyReports.downloadReport(this.$route.params.id, item.id)
        .then((resp) => {
          let filename = resp.headers["content-disposition"]
            .split(";")
            .find((n) => n.includes("filename="))
            .replace("filename=", "")
            .trim();
          let fileURL = window.URL.createObjectURL(
            new Blob([resp.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((e) => {});
    },
    fetchData() {
      ApiCompanies.getCompany(this.$route.params.id).then((resp) => {
        this.company = resp.data.company;
      });
    },
    restartJob(item) {
      ApiCompanyReports.restartReport(this.$route.params.id, item.id).then(
          (resp) => {
            this.reloadList();
          }
      );
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>
