<template lang="pug">
b-modal(
  centered
  v-model="show"
)
  template(slot="modal-title")
    div
      .modal-title {{ $t('companies.create_title') }}
      .modal-subtitle {{ $t('companies.create_description') }}
  b-form-group(
    :label="$t('companies.basic.name')"
    label-for="company-name-input"
    label-cols="4"
    :invalid-feedback="errors.first('company_name')"
    :state="errors.has('company_name') ? false : null"
  )
    b-input(
      v-model="form.company_name"
      id="company-name-input"
      v-validate="'required'"
      data-vv-name="company_name"
      :data-vv-as="$t('companies.basic.name')"
    )
  b-form-group(
    :label="$t('companies.softwares.basware_company_id')"
    label-for="business-id-input"
    label-cols="4"
    :invalid-feedback="errors.first('netvisor_organization_id')"
    :state="errors.has('netvisor_organization_id') ? false : null"
  )
    b-input(
      v-model="form.basware_company_id"
      id="business-id-input"
      v-validate="'required'"
      data-vv-name="basware_company_id"
      :data-vv-as="$t('companies.softwares.basware_company_id')"
    )
  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )  
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
</template>

<script>
import { mapGetters } from "vuex";
import Company from "@/models/Company";
import ApiCompanies from "@/api/companies.js";
import ApiResellers from "@/api/resellers.js";

export default {
  $_veeValidate: {
    validator: "new"
  },

  data() {
    return {
      show: false,
      form: {},
    };
  },

  methods: {
    open(company) {
      this.form = {
        parent_company_id: company.id,
      };
      this.show = true;
    },

    close() {
      this.show = false;
    },

    save() {
      this.$validator.validateAll().then(res => {
        if (!res) return;
        const form = this.form;
        const params = {
          company_name: form.company_name,
          parent_company_id: form.parent_company_id,
          basware_company_id: form.basware_company_id,
        };
        ApiCompanies.cloneCompany(params)
          .then(result => {
            this.$emit("done");
            this.close();
          })
          .catch(error => {

          });
      });
    }
  }
};
</script>
