<template lang="pug">
div
  stack
    stack-item(fill)
      .h3.mb-0 {{ $t('companies.tabs.replacement_account_list') }}
  stack
    stack-item
      .mt-3.mb-3
      b-form-file(
        v-model="replacementFile",
        :state="Boolean(replacementFile)",
        placeholder="Choose a file or drop it here...",
        drop-placeholder="Upload match file",
        accept=".xlsx"
    )
    stack
      stack-item
      stack-item
        b-btn(
          :disabled="!Boolean(replacementFile)",
          variant="primary",
          squared,
          @click="uploadReplacementFile"
        )
          .mdi.mdi-upload
          | {{ $t('actions.upload') }}
    stack
      stack-item
        b-btn(variant="primary", squared, @click="clearReplacementFile")
          .mdi.mdi-delete
          | {{ $t('actions.delete') }}
  rest-table(
    namespace="replacementCodes",
    :embed="true",
    :progress="inProgress",
    :headers="headers",
    :items="items",
    :fetch-handler="getData",
    :sortBy="pagination.sortBy",
    :sortOrder="pagination.sortOrder",
    ref="companyReplacementtListTable"
  )
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiCompanyAccountKeyword from "@/api/companyAccountKeyword.js";
import ApiCompanyFeatures from "@/api/companyFeatures.js";


export default {
  components: {
  },
  data() {
    return {
      replacementFile: null,
      inProgress: false,
      pagination: {
        sortBy: 'old_code',
        sortOrder: "asc",
      },
      headers: [
        {
          key: "old_code",
          label: "Old code",
          squeeze: false,
        },
        {
          key: "new_code",
          label: "New code",
          squeeze: false,
        },
      ],
      items: []
    };
  },

  methods: {
    reloadList() {
      this.$refs.companyReplacementtListTable.fetchData();
    },
    getData(pagination) {
      this.inProgress = true;
      return new Promise((res) => {
        ApiCompanyAccountKeyword.fetchReplacementList(this.$route.params.id, '', pagination)
          .then((resp) => {
            res({
              items: resp.data.replacement_codes,
              meta: resp.data.meta,
            })
          }).catch((e) => {
            this.inProgress = false;
          });
      }); 
    },
    uploadReplacementFile() {
      let formData = new FormData();
      formData.append("file", this.replacementFile);
      formData.append("company_id", this.$route.params.id);
      ApiCompanyFeatures.uploadReplacementFile(formData)
          .then((resp) => {
            this.reloadList();
            this.$emit("done");
            this.close();
          })
          .catch((e) => {});
    },
    clearReplacementFile() {
      let formData = new FormData();
      formData.append("company_id", this.$route.params.id);
      ApiCompanyFeatures.clearReplacementFile(formData)
          .then((resp) => {
            this.reloadList();
            this.$emit("done");
            this.close();
          })
          .catch((e) => {});
    }
  },
  mounted() {
    this.reloadList();
  },
};
</script>
