
.container
  stack
    stack-item
      b-button(
        class="uppercase"
        :variant="'flat-primary'"
        v-b-tooltip.focus
        @click="close()"
      )
        .mdi.mdi-backspace-outline
        | Back
  Approvers(:target="'account'", :target_id="this.$route.query['account_id']")

