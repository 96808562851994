import axios from "axios";
import store from "@/store";
import Company from "@/models/Company";

const fetchCompanies = (query_params, pagination, config) => {
  return axios.get("/api/v1/companies", {
    ...config,
    params: { ...config?.params, ...query_params, ...pagination },
  });
};

const getCompany = (id) => {
  return axios.get(`/api/v1/companies/${id}`);
};

const createCompany = (company) => {
  return axios.post("/api/v1/companies", company);
};

const updateCompany = (id, company) => {
  return axios.patch(`/api/v1/companies/${id}`, company);
};

const deleteCompany = (id) => {
  return axios.delete(`/api/v1/companies/${id}`);
};

const importCompanies = (formData) => {
  return axios.post("/api/v1/companies/import", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const fetchStastistics = (id) => {
  return axios.get(`/api/v1/companies/${id}/statistics`);
};

const fetchAutomation = (id, query_params, pagination) => {
  return axios.get(`/api/v1/companies/${id}/automation`, {
    params: { ...query_params, ...pagination },
  });
};

const fetchBilling = (id) => {
  return axios.get(`/api/v1/companies/${id}/billing`);
};

const downloadServiceAgreementFile = (company_id) => {
  return axios({
    url: `/api/v1/companies/${company_id}/download_service_agreement`,
    method: "GET",
    responseType: "blob",
  });
};

const cloneCompany = (company) => {
  return axios.post("/api/v1/companies/clone", company);
};

const exportApprovalCompanies = (company_id) => {
  return axios({
    url: `/api/v1/companies/${company_id}/export_approval`,
    method: "GET",
    responseType: "blob",
  });
};

const exportApprovalLogs = (company_id) => {
  return axios({
    url:`/api/v1/companies/${company_id}/approval_logs/export`,
    method: 'GET',
    responseType: 'blob'
  });
}

const ApiCompanies = {
  fetchCompanies,
  getCompany,
  updateCompany,
  createCompany,
  deleteCompany,
  importCompanies,
  fetchStastistics,
  fetchAutomation,
  fetchBilling,
  downloadServiceAgreementFile,
  cloneCompany,
  exportApprovalCompanies,
  exportApprovalLogs
};

export default ApiCompanies;
