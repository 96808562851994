import axios from 'axios';

const fetchDimensions = (company_id, pagination, q) => {
  return axios.get(`/api/v1/companies/${company_id}/dimensions`, { params: { q: q, ...pagination }});
};

const updateDimension = (company_id, dimension_item_id, dimension) => {
  return axios.patch(`/api/v1/companies/${company_id}/dimensions/${dimension_item_id}`, dimension);
};

const fetchDimension = (company_id, dimension_item_id) => {
  return axios.get(`/api/v1/companies/${company_id}/dimensions/${dimension_item_id}`);
}

const ApiCompanyDimensions = {
  fetchDimensions,
  updateDimension,
  fetchDimension
};

export default ApiCompanyDimensions;
