import _ from 'lodash';


class Company {
  constructor(p = {}) {
    this.id = _.get(p, 'id', null);
    this.company_name = _.get(p, 'company_name', null);
    this.netvisor_organization_id = _.get(p, 'netvisor_organization_id', null);
    this.reseller_id = _.get(p, 'reseller_id', null);
    this.email = _.get(p, 'email', null);
    this.iban = _.get(p, 'iban', null);
    this.bic = _.get(p, 'bic', null);
  }
}

export default Company;
