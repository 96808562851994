
.status-badge(:class="classes")
  b-badge(
    pill
    :variant="variant"
    :id="`status-badge-${index}`"
  )
    slot {{ label || status }}
  b-tooltip(
    v-if="tooltip"
    :target="`status-badge-${index}`"
    :customClass="isLargeTooltip ? 'status-badge-tooltip-large' : ''"
  ) 
    slot(name="tooltip") 
      template(v-if="!isLargeTooltip")  {{ `Status reason: \"${tooltip}\"` }}
      template(v-else)
        p.text-left(v-for="p in tooltip") {{ p }}
