<template lang="pug">
.ui-select(ref="select")
  .ui-select-input(@click="onInputClick" :class="inputClasses")
    .ui-select-input__value-container
      .ui-select-input__placeholder(v-if="!hasSelected") 
        | {{ $t(multiselect ? multiselectPlaceholder : placeholder) }}
      template(v-else)
        .ui-select-input__selected-tags(v-if="multiselect")
          .ui-select-input__selected-tag(v-for="option in valueObject")
            UiTag(@close="toggleOption(option)") {{ getOptionLabel(option) }}
        .ui-select-input__selected-value(v-else) {{ getOptionLabel(valueObject) }}
    .ui-select-input__actions-container
      //- .ui-select-input__action.ui-select-input__action-clear
      .ui-select-input__action(v-if="serverOptions.loading")
        b-spinner.ui-select-input__spinner(variant="primary" small)
      .ui-select-input__action(v-else)
        .ui-select-input__arrow
  .ui-select-dropdown(v-if="opened")
    .ui-select-dropdown__search(v-if="searchable")
      input.ui-select-dropdown__search-input(
        :value="search"
        @input="inputSearch"
        type="text"
        autofocus
        :placeholder="$t(searchPlaceholder)"
      )
    .ui-select-dropdown__list-selected-tags(v-if="hasSelected && multiselect")
      .ui-select-dropdown__list-selected-tag(v-for="option in valueObject")
        UiTag(@close="toggleOption(option)") {{ getOptionLabel(option) }}
    .ui-select-dropdown__list-container
      .ui-select-dropdown__list-option(
        v-for="item in extendedOptionsList"
        @click="toggleOption(item.option)"
        :class="getOptionClasses(item)"
      )
        .ui-select-dropdown__list-option-checkbox(v-if="multiselect")
          b-checkbox(:checked="item.selected")
        .ui-select-dropdown__list-option-label {{ getOptionLabel(item.option) }}
        .ui-select-dropdown__list-option-checked(v-if="!multiselect && item.selected")
          .mdi.mdi-check
</template>

<script>
import UiTag from "../UiTag/UiTag.vue";

export default {
  components: {
    UiTag,
  },
  props: {
    options: {
      type: [Array, Function],
      default: () => [],
    },
    value: {
      default: null,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "tags",
    },
    labelKey: {
      type: String,
      default: "label",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    object: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "ui-select.placeholder",
    },
    multiselectPlaceholder: {
      type: String,
      default: "ui-select.multiselect_placeholder",
    },
    searchPlaceholder: {
      type: String,
      default: "ui-select.search_placeholder",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    allowSearch: {
      type: Boolean,
      default: true,
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      opened: false,
      search: "",

      // Server options support
      serverOptions: {
        abortController: null,
        items: [],
        page: 1,
        itemsPerPage: 15,
        loading: false,
        reachEnd: false,
      },
    };
  },

  computed: {
    isServerOptions() {
      return this.options && !Array.isArray(this.options);
    },

    inputClasses() {
      return {
        "ui-select-input--focus": this.opened,
        "ui-select-input--disabled": this.disabled,
      };
    },

    valueFromOptions() {
      if (this.multiselect) {
        return this.rawOptionsList.filter((option) =>
          this.value.some((value) => this.compareOptionAndValue(option, value))
        );
      }

      return (
        this.rawOptionsList.find((option) =>
          this.compareOptionAndValue(option, this.value)
        ) ?? null
      );
    },

    valueObject() {
      return this.object ? this.value : this.valueFromOptions;
    },

    hasSelected() {
      return this.multiselect ? !!this.valueObject.length : !!this.valueObject;
    },

    localOptionsList() {
      return this.options;
    },

    serverOptionsList() {
      return this.serverOptions.items;
    },

    rawOptionsList() {
      return this.isServerOptions
        ? this.serverOptionsList
        : this.localOptionsList;
    },

    extendedOptionsList() {
      return this.rawOptionsList.map((option) => ({
        option,
        selected: this.isOptionSelected(option),
      }));
    },
  },

  methods: {
    onInputClick() {
      if (this.opened) {
        this.handleHide();
      } else {
        this.handleShow();
      }
    },

    handleShow() {
      if (this.disabled) {
        return;
      }
      this.opened = true;
      this.$emit("show");
      this.initOptionsFetch();
    },

    initOptionsFetch() {
      if (this.isServerOptions && !this.serverOptions.items.length) {
        this.refetchOptions();
      }
    },

    handleHide() {
      this.opened = false;
      this.$emit("hide");
    },

    clickAway(event) {
      const el = this.$refs.select;
      const path = event.composedPath();
      if (!el || el === event.target || path.includes(el)) {
        return;
      }
      this.$emit("outClick", event);
      this.handleHide();
    },

    resetSearch() {
      this.search = "";
    },

    resetServerOptions() {
      this.serverOptions = {
        abortController: null,
        items: [],
        page: 1,
        itemsPerPage: 15,
        loading: false,
        reachEnd: false,
      };
    },

    abortFetchOptions() {
      if (this.serverOptions.abortController) {
        this.serverOptions.abortController.abort();
        this.serverOptions.abortController = null;
      }
      this.serverOptions.loading = false;
    },

    async fetchOptions() {
      this.abortFetchOptions();
      this.serverOptions.abortController = new AbortController();
      this.serverOptions.loading = true;
      try {
        const params = {
          abortController: this.serverOptions.abortController,
          search: this.search,
          page: this.serverOptions.page,
          itemsPerPage: this.serverOptions.itemsPerPage,
        };
        const result = await this.options(params);
        this.serverOptions.items = [
          ...this.serverOptions.items,
          ...result.items,
        ];
        this.$emit("optionsResult", { params, result });
        this.serverOptions.loading = false;
      } catch (error) {
        if (error?.code !== "ERR_CANCELED") {
          this.serverOptions.loading = false;
        }
      }
    },

    fetchMore() {
      if (this.serverOptions.loading || this.serverOptions.reachEnd) {
        return;
      }
      this.serverOptions.page++;
      this.fetchOptions();
    },

    refetchOptions() {
      this.abortFetchOptions();
      this.resetServerOptions();
      this.fetchOptions();
    },

    inputSearch(event) {
      this.search = event.currentTarget.value;
      if (this.isServerOptions) {
        this.refetchOptions();
      }
    },

    toggleOption(option) {
      if (this.multiselect) {
        const newValue = [...this.valueObject];
        const index = newValue.findIndex((value) =>
          this.compareOptionAndValue(option, value)
        );
        if (index >= 0) {
          newValue.splice(index, 1);
        } else {
          newValue.push(this.object ? option : this.getOptionValue(option));
        }
        this.$emit("input", newValue);
      } else {
        this.$emit("input", this.object ? option : this.getOptionValue(option));
      }
      if (this.closeOnSelect) {
        this.handleHide();
      }
    },

    getOptionValue(option) {
      return option?.[this.valueKey];
    },

    getOptionLabel(option) {
      return option?.[this.labelKey];
    },

    compareOptionAndValue(option, value) {
      if (this.object) {
        return this.getOptionValue(option) === this.getOptionValue(value);
      }
      return this.getOptionValue(option) === value;
    },

    isOptionSelected(option) {
      if (this.multiselect) {
        return this.value?.some((value) =>
          this.compareOptionAndValue(option, value)
        );
      }
      return this.compareOptionAndValue(option, this.value);
    },

    getOptionClasses(option) {
      return {
        "ui-select-dropdown__list-option--active": option.selected,
      };
    },
  },

  mounted() {
    window.addEventListener("mousedown", this.clickAway);
    this.initOptionsFetch();
  },

  beforeDestroy() {
    window.removeEventListener("mousedown", this.clickAway);
    this.abortFetchOptions();
  },
};
</script>

<style lang="scss">
@import "@/assets/stylesheet/_variables.scss";
@import "@/assets/stylesheet/_form.scss";

.ui-select {
  min-width: 14rem;
  position: relative;
}

.ui-select-input {
  display: flex;
  width: 100%;
  height: calc(1.5em + 1.1rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #f1f3f7;
  @include input-color($bb-gray-900);
  border-radius: 0;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &--focus {
    @include input-color($yellow, true);
  }

  &--disabled,
  &--readonly {
    background: $bb-gray-900;
    @include input-color($bb-gray-900, false);
  }

  &__value-container {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__selected-value {
    padding: 0 0.75rem;
  }

  &__selected-tags {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    gap: 3px;
    flex-shrink: 1;
    flex-grow: 1;
    padding: 5px 8px;
  }

  &__actions-container {
    padding-right: 0.75rem;
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow {
    border: 0px solid transparent;
    border-top-width: 6px;
    border-left-width: 5px;
    border-right-width: 5px;
    border-top-color: $bb-gray-600;
  }

  &__placeholder {
    padding: 0 0.75rem;
    color: $bb-gray-700;
  }
}

.ui-select-dropdown {
  z-index: 1100;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-width: 300px;
  border: 1px solid #f1f3f7;
  background-color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  box-shadow: 0 1px 3px rgba(47, 54, 59, 0.08),
    0 5px 10px rgba(47, 54, 59, 0.04);

  &__search-input {
    display: block;
    width: 100%;
    border-radius: 0;
    border: none;
    padding: 0.55rem 0.75rem;

    &:focus {
      outline: none;
    }
  }

  &__search {
    border-bottom: 1px solid $bb-gray-900;
  }

  &__list-container {
    user-select: none;
    max-height: 360px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0.55rem 0;
  }

  &__list-selected-tags {
    padding: 0.55rem 0.75rem;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    border-bottom: 1px solid $bb-gray-900;
  }

  &__list-option {
    border-left: 2px solid transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.55rem 0.75rem;

    &:hover {
      background: rgba($primary, 0.1);
    }

    &--active {
      background: rgba($primary, 0.1);
      border-color: $primary;
    }

    &--active:hover {
      background: rgba($primary, 0.2);
    }
  }

  &__list-option-checkbox {
    pointer-events: none;
    flex-shrink: 0;
  }

  &__list-option-label {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__list-option-checked {
    color: $primary;
    flex-shrink: 0;
    font-size: 1.25rem;
    margin: -0.5rem 0;
    margin-left: 1rem;
  }
}
</style>
