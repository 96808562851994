import _ from 'lodash';

class Pagination {
  constructor(p = {}) {
    this.page = p.page || p.current_page || 1;
    this.per_page = Number(p.perPage || p.per_page || 10);
    this.sort_order = p.sortOrder || p.sort_order || null;
    this.sort_by = p.sortBy || p.sort_by || null;
    this.search = p.search || null;
  }
}

export default Pagination;
