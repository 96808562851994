<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item()
          .h3.mb-0 {{ $t('admin_tools.tabs.machine_learning') }}
    .container
      rest-table(
        namespace="processed_companies",
        :headers="headers",
        ref="machineLearningTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                @click="startLearning(item)"
              )
                .mdi.mdi-refresh
                | {{ $t('admin_tools.relearn.start_learning') }}
</template>

<script>
import ApiMachineLearning from "@/api/machine_learning.js";

export default {
  data() {
    return {
      search_params: {
        company: null,
      },
      pagination: {
        sortBy: null,
      },
      edited_items: [],
      items: [],
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "company_name",
          label: "Company",
          sortable: true,
          squeeze: true,
        },
        {
          key: "ml_method",
          label: "Algorythm",
          sortable: true,
          squeeze: true,
        },
        {
          key: "accuracy",
          label: "Accuracy",
          sortable: true,
          squeeze: true,
        },
        {
          key: "start_date",
          label: "Start Date",
          sortable: true,
          squeeze: true,
        },
        {
          key: "end_date",
          label: "End Date",
          sortable: true,
          squeeze: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    };
  },

  methods: {
    reloadList() {
      this.$refs.machineLearningTable.fetchData();
    },

    getData(pagination) {
      const search_params = this.search_params;
      return new Promise((res) => {
        ApiMachineLearning.fetchCompanies(search_params, pagination).then(
          (resp) => {
            this.items = resp.data.companies;
            res({
              items: resp.data.companies,
              meta: resp.data.meta,
            });
          }
        );
      });
    },

    startLearning(company) {
      ApiMachineLearning.startLearning(company.id).then((resp) => {
        this.reloadList();
      });
    },
  },
};
</script>
