<template lang="pug">
div
  .p-4
    .container
      .pt-3.px-3
        stack
          stack-item(fill)
            .h3.mb-0 {{ $t('admin_tools.tabs.companies_approval') }}
          stack-item
            b-btn(
              variant="primary",
              squared,
              @click="exportApprovalCompanies()"
            )
              .mdi.mdi-download
              | {{ $t('companies.export_to_excel') }}
        stack
          stack-item()
            b-form-group(
              :label="$t('admin_users.company')"
              label-for="company-name-input"
              label-cols="3"
            )
              b-form-input(
                v-model="search_params.company_name"
                type="search"
                id="company-name-input"
                @change="reloadList(item)"
              )

      rest-table(
        namespace="companies_approval",
        :headers="headers",
        ref="companiesApprovalTable",
        :fetch-handler="getData",
        embed
      )
</template>
<script>

import router from "@/router";
import ApiCompanies from "@/api/companies.js";
import ApiApprovers from "@/api/approvers.js";

export default {
  data() {
    return {
      search_params: {
        company_name: null,
      },
      pagination: {
        sortBy: null,
      },
      headers: [
        {
          key: "company_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "netvisor_organization_id",
          label: "Business ID",
          sortable: true,
        },
      ],
    };
  },

  methods: {
    reloadList() {
      this.$refs.companiesApprovalTable.fetchData();
    },
    getData(pagination) {
      return new Promise((res) => {
        ApiCompanies.fetchCompanies({approval_flow_active: true, ...this.search_params}, pagination).then(
            (resp) => {
              res({
                items: resp.data.companies,
                meta: resp.data.meta,
              });
            }
        );
      });
    },
    exportApprovalCompanies() {
      ApiCompanies.exportApprovalCompanies(this.$route.params.id)
          .then((resp) => {
            let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", `approval_companies.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((e) => {});
    },
    goBack() {
      router.push("/companies");
    },
  }
}
</script>