<template lang="pug">
dialog-template(
  :title="title"
  :description="description"
  :variant="variant"
  :icon="icon"
)
  stack.justify-content-center(slot="actions" spacing="sm" wrap)
    stack-item
      b-btn.dialog-action-btn(
        block
        @click="$emit('ok')"
        squared
        :variant="okVariant || `flat-${variant}`"
      ) 
        .mdi(v-if="okIcon" :class="`mdi-${okIcon}`")
        | {{ okLabel }}
</template>

<script>
import DialogTemplate from './DialogTemplate.vue';

export default {
  components: {
    DialogTemplate
  },

  props: {
    title: {
      type: String,
      default: null
    },

    description: {
      type: String,
      default: null
    },

    variant: {
      type: String,
      default: 'primary'
    },

    icon: {
      type: String,
      default: 'check'
    },

    okVariant: {
      type: String,
      default: null
    },

    okLabel: {
      type: String,
      default() {
        return this.$t('actions.ok');
      }
    },

    okIcon: {
      type: String,
      default: null
    }
  }
};
</script>