
b-modal(centered, v-model="show")
  template(slot="modal-title")
    .modal-title {{ $t('companies.users.edit_title') }}
    //- .modal-subtitle {{ $t('companies.users.edit_description') }}
  .alert.alert-danger(v-if="this.error !== null") {{ this.error }}

  b-form-group(
    :label="$t('companies.users.name')",
    label-for="name-input",
    label-cols="2"
    :invalid-feedback="errors.first('name')"
    :state="errors.has('name') ? false : null"
  )
    b-input#name-input(
      v-model="form.name"
      v-validate="'required'"
      data-vv-name="name"
      :data-vv-as="$t('companies.users.name')"
      :disabled="inProgress"
    )
  b-form-group(
    :label="$t('companies.users.email')",
    label-for="email-input",
    label-cols="2"
    :invalid-feedback="errors.first('email')"
    :state="errors.has('email') ? false : null"
  )
    b-input#email-input(
      v-model="form.email",
      v-validate="'required|email'"
      data-vv-name="email"
      :data-vv-as="$t('companies.users.email')"
      type="email"
      :disabled="inProgress"
    )

  b-form-group(
    :label="$t('companies.users.role')",
    label-for="role-input",
    label-cols="2"
    :invalid-feedback="errors.first('role')"
    :state="errors.has('role') ? false : null"
  )
    b-select#role-input(
      v-validate="'required'"
      data-vv-name="role"
      :data-vv-as="$t('companies.users.role')"
      v-model="form.role",
      :options="globalRolesOptions",
      :disabled="inProgress"
    )

  rest-table(
    namespace="user_companies",
    :headers="headers",
    ref="userCompaniesTable",
    :fetch-handler="getData",
    embed
  )
    template(slot="cel_role", slot-scope="{ item }")
      b-form-select(
        v-model="item.role",
        :options="rolesOptions",
        @change="updateCompanyUser(item)"
      )
    template(slot="cel_actions", slot-scope="{ item }")
      b-btn(
        size="xs",
        variant="flat-danger",
        squared,
        @click="deleteCompanyUser(item)"
      )
        .mdi.mdi-trash-can-outline
        | {{ $t('actions.delete') }}

  template(slot="modal-footer")
    b-btn(variant="primary", squared, @click="save" :disabled="inProgress")
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}

