import axios from 'axios';

const fetchUsers = (companyId, pagination) => {
  return axios.get(`/api/v1/companies/${companyId}/users`, { params: { ...pagination }});
};

const updateUser= (companyId, userId, User) => {
  return axios.patch(`/api/v1/companies/${companyId}/users/${userId}`, User);
};

const deleteUser = (companyId, userId) => {
  return axios.delete(`/api/v1/companies/${companyId}/users/${userId}`);
};

const inviteUser = (companyId, user) => {
  return axios.post(`/api/v1/companies/${companyId}/users/invite`, user);
};

const ApiCompanyUsers = {
  fetchUsers,
  updateUser,
  deleteUser,
  inviteUser
};

export default ApiCompanyUsers;
