
b-modal(resizable,
  v-model="show",
  cancel-disabled
  dialog-class="table-modal"
)
  div
  .modal-title {{ $t('companies.vendors.replacement_account_list') }}
  .modal-subtitle {{ $t('companies.vendors.replacement_account_list') }}
  p
    a(href="/replacement_codes.xlsx", target="_blank") {{ $t('companies.file_example') }}
  stack
    stack-item
      .mt-3.mb-3
      b-form-file(
        v-model="replacementFile",
        :state="Boolean(replacementFile)",
        placeholder="Choose a file or drop it here...",
        drop-placeholder="Upload match file",
        accept=".xlsx"
      )
    stack
      stack-item
      stack-item
        b-btn(
          :disabled="!Boolean(replacementFile)",
          variant="primary",
          squared,
          @click="uploadReplacementFile"
        )
          .mdi.mdi-upload
          | {{ $t('actions.upload') }}
    stack
      stack-item
        b-btn(variant="primary", squared, @click="clearReplacementFile")
          .mdi.mdi-delete
          | {{ $t('actions.delete') }}
  rest-table(
    namespace="replacementCodes",
    :embed="true",
    :progress="inProgress",
    :headers="replacement_headers",
    :items="items",
    :fetch-handler="getData",
    :sortBy="pagination.sortBy",
    :sortOrder="pagination.sortOrder",
    ref="vendorReplacementtListTable"
  )
  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="close"
    )
      .mdi.mdi-account-check-outline
      | {{ $t('actions.ok') }}
