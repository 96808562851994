
dialog-template(
  :title="title"
  :description="description"
  :variant="variant"
  :icon="icon"
)
  stack.justify-content-center(slot="actions" spacing="sm" wrap)
    stack-item
      b-btn.dialog-action-btn(
        block
        @click="$emit('ok', true)"
        squared
        :variant="okVariant || `flat-primary`"
      ) 
        .mdi(v-if="okIcon" :class="`mdi-${okIcon}`")
        | {{ okLabel }}
    stack-item
      b-btn.dialog-action-btn(
        block
        @click="$emit('cancel', false)"
        squared
        :variant="cancelVariant || `flat-danger`"
      ) 
        .mdi(v-if="okIcon" :class="`mdi-${cancelIcon}`")
        | {{ cancelLabel }}
