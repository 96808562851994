
.container
  .h3.mb-0 Voucher
  table.table-sm.table-striped.table-bordered
    tbody()
      tr(v-for="(item, field) in data" :key="field")
        td(v-if="field !== 'VoucherLine'") {{field}}
        td(v-if="field !== 'VoucherLine'") {{item}}
  br
  .h3.mb-0 Voucher Lines
  table.table-sm.table-striped.table-bordered(v-if="data !== null")
    thead
      tr
        th(rowspan="2") #
        th(rowspan="2") LineSum
        th(rowspan="2") VatCode
        th(v-bind:colspan="dimensions_count()") Dimensions
        th(rowspan="2") VatPercent
        th(rowspan="2") Description
        th(rowspan="2") NetvisorKey
        th(rowspan="2") AccountNumber
      tr
        th(v-for="(item, i) in dimensions")
          p {{item}}
    tbody
      tr(v-for="(item, index) in [data['VoucherLine']].flat()")
        td {{index}}
        td {{item['LineSum']}}
        td {{item['VatCode']}}
        td(v-for="(dimension) in dimensions")
          p {{dimension_item(item, dimension)}}
        td {{item['VatPercent']}}
        td {{item['Description']}}
        td {{item['NetvisorKey']}}
        td {{item['AccountNumber']}}
  br
  .h3.mb-0 Suggested Lines
  LineItemsTable(:line_items="this.line_items")
