<template lang="pug">
  div.position-relative(v-if="this.vendor !== null")
    .p-4
      .container
        stack
          stack-item
            b-button(
              class="uppercase"
              :variant="'flat-primary'"
              v-b-tooltip.focus
              @click="close()"
            )
              .mdi.mdi-backspace-outline
              | Back
          stack-item
            b-card
              .h4.mb-0 Name: {{ this.vendor.name }}
      .container
        b-tabs(content-class='mt-3' card)
          b-tab(title='Vendor')
            VendorTab(:vendor="this.vendor")
          b-tab(title='Purchase Invoices')
            PurchaseInvoicesTab(:vendor_id="this.vendor.id")
          b-tab(title='Approvers')
            ApproversTab(:target="'vendor'", :target_id="this.vendor.id")

</template>
<script>
import router from "@/router";
import ApiCompanyVendors from "@/api/companyVendors.js";
import PurchaseInvoicesTab from "@/views/Vendor/tabs/purchase_invoices.vue"
import VendorTab from "@/views/Vendor/tabs/vendor_tab.vue"
import ApproversTab from "@/views/Approvers/index.vue"

export default {
  components: {
    VendorTab,
    PurchaseInvoicesTab,
    ApproversTab
  },

  props: ['id'],

  data() {
    return {
      vendor: null,
      selected: VendorTab,
    }
  },

  methods: {
    close() {
      const {vendor_id, invoice_id, ...rest} = this.$route.query;

      this.$router.push({...this.$route, query: rest})
    },

    fetchVendor() {
      ApiCompanyVendors.showVendor(this.$route.params.id, this.id).then(resp => {
        this.vendor = resp.data.vendor
        console.log(this.vendor)
      })
    },
  },

  watch: {
    id: {
      immediate: true,
      handler (_v, _ov) {
        this.fetchVendor()
      }
    }
  },
}
</script>