import axios from 'axios';

const FetchApprovers = (company_id, query_params, pagination) => {
    return axios.get(`/api/v1/companies/${company_id}/approvers`, { params: { ...query_params, ...pagination }});
}

const FetchTargetApprover = (company_id, target, target_id) => {
    return axios.get(`/api/v1/companies/${company_id}/approvers/${target}/${target_id}/approver`);
}

const FetchTargetVerificators = (company_id, target, target_id) => {
    return axios.get(`/api/v1/companies/${company_id}/approvers/${target}/${target_id}/verificators`);
}

const FetchApproversList = (company_id, query_params, pagination) => {
    return axios.get(`/api/v1/companies/${company_id}/approvers/list_to_assign`, { params: { ...query_params, ...pagination }})
}

const AssignApprover = (company_id, approver_id, target_name, target_id, role) => {
    return axios.post(`/api/v1/companies/${company_id}/approvers/${approver_id}/assign/${target_name}/${target_id}/${role}`)
}

const DeassignApprover = (company_id, approver_id, target_name, target_id, role) => {
    return axios.post(`/api/v1/companies/${company_id}/approvers/${approver_id}/deassign/${target_name}/${target_id}/${role}`)
}

const createApprover = (company_id, params) => {
    return axios.post(`/api/v1/companies/${company_id}/approvers`, params)
}

const updateApprover = (company_id, approver_id, params) => {
    return axios.patch(`/api/v1/companies/${company_id}/approvers/${approver_id}`, params)
}

const deleteApprover = (company_id, approver_id) => {
    return axios.delete(`/api/v1/companies/${company_id}/approvers/${approver_id}`)
}

const fetchApprovalFlows = (company_id) => {
    return axios.get(`/api/v1/companies/${company_id}/approval_flows`);
}

const upApprovalFlow = (company_id, approval_flow_id) => {
    return axios.patch(`/api/v1/companies/${company_id}/approval_flows/${approval_flow_id}/up`)
}

const downApprovalFlow = (company_id, approval_flow_id) => {
    return axios.patch(`/api/v1/companies/${company_id}/approval_flows/${approval_flow_id}/down`)
}

const deleteApprovalFlow = (company_id, approval_flow_id) => {
    return axios.delete(`/api/v1/companies/${company_id}/approval_flows/${approval_flow_id}`)
}

const createApprovalFlow = (company_id, params) => {
    return axios.post(`/api/v1/companies/${company_id}/approval_flows`, params)
}

const exportApprovalSettings = (company_id) => {
    return axios({
        url:`/api/v1/companies/${company_id}/approvers/export`,
        method: 'GET',
        responseType: 'blob'
    });
}

const ApiApprovers = {
    FetchApprovers,
    FetchApproversList,
    createApprover,
    updateApprover,
    deleteApprover,
    AssignApprover,
    DeassignApprover,
    fetchApprovalFlows,
    upApprovalFlow,
    downApprovalFlow,
    deleteApprovalFlow,
    createApprovalFlow,
    FetchTargetApprover,
    FetchTargetVerificators,
    exportApprovalSettings
};

export default ApiApprovers;