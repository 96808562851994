<template lang="pug">
  .container
    stack
      stack-item(fill)
        .h3.mb-0 {{ $t('companies.tabs.approvers') }}
      stack-item
        b-btn(
          variant="primary",
          squared,
          @click="addApprover"
        )
          .mdi.mdi-account-plus
          | {{ $t('companies.approvers.add') }}
    .pt-3.px-3
      stack
        stack-item
          b-form-group(
            :label="$t('companies.approvers.name')"
            label-for="name-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.name"
              type="search"
              id = "name-input"
              @change="reload"
            )
      rest-table(
        namespace="approvers",
        :headers="headers",
        ref="ApproversTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_roles", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item(v-if="item.is_approver")
              b-button(
                pill
                class="uppercase"
                :variant="'info'"
                v-b-tooltip.focus
                :title="`Approver`"
              )
                | {{ $t('companies.approvers.role_approver') }}
            stack-item(v-if="item.is_verificator")
              b-button(
                pill
                class="uppercase"
                :variant="'success'"
                v-b-tooltip.focus
                :title="`Factual Verificator`"
              )
                | {{ $t('companies.approvers.role_verificator') }}
        template(slot="cel_assigned_to", slot-scope="{ item }")
          stack(spacing="xs")
            table.table-sm.table-striped.table-bordered.targets-table(v-if="Array.isArray(item.assigned_to) && item.assigned_to.length > 0")
              thead
                tr
                  th(v-for="column in columns(item.assigned_to)") {{ column }}
              tbody
                tr(v-for="row in rows(item.assigned_to)")
                  td(v-for="column in columns(item.assigned_to)") {{ row[column] || '' }}

            //table.table-sm.table-striped.table-bordered(v-if="Array.isArray(item.assigned_to) && item.assigned_to.length > 0")
            //  thead
            //    tr
            //      th Class
            //      th Name
            //  tbody
            //    tr(v-for="(item, index) in item.assigned_to")
            //      td {{ item[0] }}
            //      td {{ item[1] }}
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                @click="editApprover(item)"
              )
                .mdi.mdi-pencil-outline
                | {{ $t('actions.edit') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteApprover(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
    edit-approver-modal(ref="EditApproverModal", @done="reload")
</template>

<script>
import ApiApprovers from "@/api/approvers.js";
import EditApproverModal from "@/modals/Companies/editApprover.vue";

export default {
  components: {
    EditApproverModal
  },

  data() {
    return {
      search_params: {
        name: '',
      },
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "approver_name",
          label: "Name",
          sortable: true,
          squeeze: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          squeeze: true,
        },
        {
          key: "price_limit",
          label: "Max approval limit",
          sortable: true,
          squeeze: true,
        },
        {
          key: "roles",
          label: "Roles",
          sortable: false,
          squeeze: true,
        },
        {
          key: "assigned_to",
          label: "assigned",
          sortable: false,
          squeeze: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    }
  },

  methods: {
    reload() {
      this.$refs.ApproversTable.fetchData();
    },
    getData(pagination) {
      this.inProgress = true;
      const search_params = this.search_params;
      return new Promise((res) => {
        ApiApprovers.FetchApprovers(this.$route.params.id, search_params, pagination).then(
            (resp) => {
              res({
                items: resp.data.approvers,
                meta: resp.data.meta,
              });
            }
        ).catch((e) => {
          this.inProgress = false;
        });
      });
    },

    addApprover(){
      this.$refs.EditApproverModal.open(this.$route.params.id, null);
    },

    editApprover(item) {
      this.$refs.EditApproverModal.open(this.$route.params.id, item);
    },

    deleteApprover(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "account-minus",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiApprovers.deleteApprover(this.$route.params.id, item.id).then(
            (resp) => {
              this.reload();
            }
        );
      });
    },

    rows(data) {
      const rows = [];
      for (const [col, val] of data) {
        const row = rows.find(row => !row[col]);
        if (row) {
          row[col] = val;
        } else {
          const newRow = { [col]: val };
          rows.push(newRow);
        }
      }
      return rows;
    },

    columns(data) {
      return Array.from(new Set(data.map(([item]) => item)))
    }

  }
}
</script>

<style lang="scss" scoped>
.targets-table {
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 10px;
    border: 1px solid #ccc;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: center;
  }

  td {
    text-align: left;
  }
}
</style>