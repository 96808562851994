<template lang="pug">
b-modal(
  centered
  v-model="show"
)
  template(slot="modal-title")
    div
      .modal-title {{ $t('resellers.add_group') }}

  b-form-group(
    :label="$t('resellers.name')"
  )     
    b-input(
      v-model="form.name"
    )
    b-form-group(label="Choose companies:")
      b-form-checkbox-group(
        id="checkbox-company" 
        name="company_ids" 
        v-model="selected"
        switches
        stacked
      )
        b-form-checkbox(:value="company.id" :key="company.id" v-for="company in resellerCompanies")
          | {{ company.company_name }}{{ company.company_group_name != null ? ' (group ' + company.company_group_name + ')' : '' }}

  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )  
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
</template>

<script>
import { mapGetters } from "vuex";
import Reseller from "@/models/Reseller";
import ApiResellers from "@/api/resellers.js";

export default {
  $_veeValidate: {
    validator: "new"
  },

  data() {
    return {
      show: false,
      isAdd: false,
      resellerId: null,
      form: {},
      resellerCompanies: [],
      selected: []
    };
  },

  methods: {
    fetchResellerCompanies() {
      return new Promise(res => {
        ApiResellers.fetchResellerCompanies(this.$route.params.id).then(
          resp => {
            this.resellerCompanies = resp.data.reseller_companies;
            this.selected = resp.data.reseller_companies
              .filter(
                company =>
                  company["company_group_id"] == this.form.id &&
                  company["company_group_id"] !== null
              )
              .map(h => h["id"]);
          }
        );
      });
    },
    open(resellerId, resellerCompanyGroup) {
      this.fetchResellerCompanies();
      if (resellerCompanyGroup) {
        this.isAdd = false;
        this.form = {
          id: resellerCompanyGroup.id,
          name: resellerCompanyGroup.name
        };
      } else {
        this.isAdd = true;
        this.form = {
          name: ""
        };
      }
      this.resellerId = resellerId;
      this.show = true;
    },

    close() {
      this.show = false;
    },

    save() {
      this.$validator.validateAll().then(res => {
        if (!res) return;
        const form = this.form;
        const params = {
          name: form.name,
          company_ids: this.selected
        };
        const resellerId = this.resellerId;
        if (this.isAdd) {
          ApiResellers.addResellerCompanyGroup(resellerId, params)
            .then(res => {

              this.$emit("done");
              this.close();
            })
            .catch(e => {

            });
        } else {
          ApiResellers.updateResellerCompanyGroup(resellerId, form.id, params)
            .then(res => {

              this.$emit("done");
              this.close();
            })
            .catch(e => {

            });
        }
      });
    }
  }
};
</script>