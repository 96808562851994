import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import User from '@/models/User';
import LocalStorage from '@/common/localstorage';

const state = {
  language: LocalStorage.get('language', 'en', String),
  user: window.user ? new User(window.user) : null
};

export default {
  state,
  getters,
  actions,
  mutations
};
