
b-modal(centered,
v-model="show",
hide-footer=true,
:title = "error_message"
)
  stack
    stack-item
      .container
        p(
          v-html="backtrace"
        )
