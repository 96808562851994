
b-modal(resizable,
  v-model="show",
  cancel-disabled
  dialog-class="table-modal"
)
  div
  .modal-title {{ $t('companies.vendors.edit_vendor_dimensions') }}
  .modal-subtitle {{ $t('companies.vendors.edit_vendor_dimensions') }}
  stack
    stack-item
      b-btn(
        variant="primary",
        squared,
        @click="addDimensionItem"
      )
        .mdi.mdi-file-plus-outline
        | {{ $t('actions.add') }}
  rest-table(
    namespace="vendor_dimensions",
    :headers="headers",
    ref="vendorDimensionsTable",
    :fetch-handler="getData",
    embed
  )
    template(slot="cel_not_suggest", slot-scope="{ item }")
      b-form-checkbox(
        v-model="item.not_suggest",
        switch,
        @change="changeNotSuggest(item)"
      )
    template(slot="cel_actions", slot-scope="{ item }")
      stack(spacing="xs")
        stack-item
          b-btn(
            size="xs",
            variant="flat-danger",
            squared,
            @click="deleteDimensionItem(item)"
          )
            .mdi.mdi-trash-can-outline
            | {{ $t('actions.delete') }}
  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="close"
    )
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
  add-vendor-dimension-modal(ref="AddVendorDimensionModal", @done="reloadVendorDimensions()")

