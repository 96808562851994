<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('resellers.tabs.basic') }}
      div.form-container.mt-5
        b-form-group(
          :label="$t('resellers.name')"
          label-for="reseller-name-input"
          label-cols="4"
        )
          b-input(
            v-model="reseller.name"
            id="reseller-name-input"
          )

        b-form-group(
          :label="$t('resellers.business_id')"
          label-for="business-id-input"
          label-cols="4"
        )
          b-input(
            v-model="reseller.company_id"
            id="business-id-input"
          )

        b-form-group(
          :label="$t('resellers.einvoice_address')"
          label-for="einvoice-address-input"
          label-cols="4"
        )
          b-input(
            v-model="reseller.einvoice_address"
            id="einvoice-address-input"
          )

        b-form-group(
          :label="$t('resellers.einvoice_operator_address')"
          label-for="einvoice-operator-address-input"
          label-cols="4"
        )
          b-input(
            v-model="reseller.einvoice_operator_address"
            id="einvoice-operator-address-input"
          )
        b-form-group(
          :label="$t('resellers.rtype')"
          label-for="reseller-type-input"
          label-cols="4"
        )
          b-select(
            v-model="reseller.rtype"
            :options="rtypeOptions"
            id="reseller-type-input"
          )
        b-form-group(
          :label="$t('resellers.automate_accounting')"
          label-for="automate-accounting-input"
          label-cols="4"
        )
          b-form-checkbox(
            v-model="reseller.automate_accounting"
            switch
            id="automate-accounting-input"
          )
        b-form-group(
          :label="$t('resellers.default_dimensions')"
          label-for="default-dimensions-input"
          label-cols="4"
        )
          b-form-checkbox(
            v-model="reseller.default_dimensions"
            switch
            id="default-dimensions-input"
          )
        b-form-group(
          :label="$t('resellers.domain')"
          label-for="domain-input"
          label-cols="4"
        )
          b-input(
            v-model="reseller.domain"
            id="domain-input"
          )
        stack
          stack-item(fill)
          stack-item
            b-btn(
              variant="primary"
              squared
              @click="save"
            )
              .mdi.mdi-account-check-outline
              | {{ $t('actions.save') }}
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiResellers from "@/api/resellers.js";

export default {
  data() {
    return {
      rtypeOptions: [
        {
          value: "Bookkeeping",
          text: "Bookkeeping"
        },
        {
          value: "Others",
          text: "Others"
        }
      ],
      reseller: {}
    };
  },

  methods: {
    getData() {
      ApiResellers.getReseller(this.$route.params.id).then(resp => {
        this.reseller = resp.data.reseller;
      });
    },

    save() {
      ApiResellers.updateReseller(this.reseller.id, this.reseller).then(
        resp => {
          this.reseller = resp.data.reseller;
        }
      );
    },
    goBack() {
      router.push("/resellers");
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

