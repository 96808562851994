import router from '@/router';
import ApiCompany from '@/api/companies';
import Company from '@/models/Company';

const switchCompany = ({ commit, state, dispatch, getters }, company_id) => {
  if (state.currentCompanyId === company_id) return;
  commit('SET_CURRENT_COMPANY', company_id);

  router.push({
    name: getters.currentCompany.role !== Company.role.ADMIN ? 'Analytics' : 'Company'
  });

  dispatch('fetchCompany');
};

const fetchCompany = ({ commit, state, getters }) => {
  const currentCompany = getters.currentCompany;
  if (currentCompany.role !== Company.role.ADMIN) {
    commit('SET_COMPANY', null);
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    commit('SET_PROGRESS', true);
    ApiCompany.fetchCompany(currentCompany.id)
      .then(res => {
        commit('SET_COMPANY', res.data.company);
        commit('SET_PROGRESS', false);
        resolve(res);
      })
      .catch(reject);
  });
};

const updateCompany = ({ commit, state }, company) => {
  return new Promise((resolve, reject) => {
    ApiCompany.updateCompany(company)
      .then(res => {
        commit('UPDATE_COMPANY', company);
        resolve(res);
      })
      .catch(reject);
  });
};

const removeCompany = ({ commit, state, dispatch, getters }, id) => {
  const companies = state.companies.filter(c => c.id !== id);
  commit('UPDATE_COMPANY_LIST', companies);
  commit('SET_COMPANY', null);
  commit('SET_CURRENT_COMPANY', companies.length ? companies[0].id : null);

  if (!getters.currentCompany) {
    router.push({ name: 'NoHaveCompany' });
    return;
  }

  if (getters.currentCompany.role === Company.role.ADMIN) {
    router.push({ name: 'Company' });
    dispatch('fetchCompany');
    return;
  }

  router.push({ name: 'Analytics' });
};

export default {
  fetchCompany,
  switchCompany,
  updateCompany,
  removeCompany
};
