<template lang="pug">
div.position-relative
  div.invoice-popover(v-if="$route.query.invoice_id")
    ShowPurchaseInvoice(:id="$route.query.invoice_id")
  .p-4(v-else)
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.automation') }}
      .pt-3.px-3
        stack
          stack-item(fill)
            b-form-group(
              :label="$t('companies.automation.remote_key')"
              label-for="netvisor-key-input"
              label-cols="4"
            )
              b-form-input(
                v-model="search_params.remote_key"
                type="search"
                id = "netvisor-key-input"
                @change="reload"
              )
          stack-item
            b-badge(
              variant="secondary"
              v-if="search_params.remote_key"
              @click="search_params.remote_key = null; reload();"
              )
              .mdi.mdi-backspace-outline
          stack-item(fill)
            b-form-group(
              :label="$t('companies.automation.start_date')"
              label-for="start-date-input"
              label-cols="5"
            )
              b-form-input(
                v-model="search_params.start_date"
                type="date"
                id="start-date-input"
                @change="reload"
              )
          stack-item(fill)
            b-form-group(
              :label="$t('companies.automation.end_date')"
              label-for="end-date-input"
              label-cols="5"
            )
              b-form-input(
                v-model="search_params.end_date"
                type="date"
                id="end-date-input"
                @change="reload"
              )
        stack
          stack-item
            b-form-checkbox(
              v-model="search_params.suggested",
              switch,
              @change="reload()"
            )
              | {{ $t('companies.automation.suggested') }}
          stack-item
            b-form-checkbox(
              v-model="search_params.with_voucher",
              switch,
              @change="reload()"
            )
              | {{ $t('companies.automation.with_voucher') }}
          stack-item
            b-form-checkbox(
              v-model="search_params.wrong_dimensions",
              switch,
              @change="reload()"
            )
              | {{ $t('automation.wrong_dimensions_invoices') }}
          stack-item
            b-form-checkbox(
              v-model="search_params.wrong_accounts",
              switch,
              @change="reload()"
            )
              | {{ $t('automation.wrong_accounts_invoices') }}
      .container.table-limit
        rest-table(
          namespace="automation_list"
          :headers="headers"
          ref="automationTable"
          :fetch-handler="getData"
          :sortBy="pagination.sortBy"
          :sortOrder="pagination.sortOrder"
          embed
        )
          template(slot="cel_remote_key" slot-scope="{ item }")
            router-link(
              :to="{...$route, query: {...$route.query, invoice_id: item.id.toString()}}"
              label="item.remote_key"
            )
              | {{ item.remote_key }}
          template(slot="cel_status" slot-scope="{ item }")
            ItemStatusBadge(:item="item")
          template(slot="cel_account_suggest_status" slot-scope="{ item }")
            AutomationStatusBadge(:item="item.account_suggest_status")
          template(slot="cel_dimension_suggest_status" slot-scope="{ item }")
            AutomationStatusBadge(:item="item.dimension_suggest_status")
          template(slot="cel_approval_status" slot-scope="{ item }")
            AutomationStatusBadge(:item="item.approval_status")
          template(slot="cel_actions" slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-success",
                  squared,
                  @click="resuggest(item)",
                  v-if="(['not_suggested', 'failed'].includes(item.status) || item.approval_status == 'not_suggested') && item.with_voucher == 'NO'"
                )
                  .mdi.mdi-restart
                  | {{ $t('companies.automation.resuggest') }}
</template>

<script>
import ApiCompanies from "@/api/companies.js";
import ApiPurchaseInvoices from "@/api/purchase_invoices.js";
import ShowPurchaseInvoice from "@/views/PurchaseInvoice/show.vue";
import { DateTime } from "luxon";
import ItemStatusBadge from "./automation/item-status-badge.vue";
import AutomationStatusBadge from "./automation/automation-status-badge.vue";

export default {
  components: {
    ShowPurchaseInvoice,
    ItemStatusBadge,
    AutomationStatusBadge,
  },
  data() {
    const monthBefore = DateTime.local().minus({ month: 1 }).toISODate();
    const today = DateTime.local().toISODate();
    return {
      search_params: {
        remote_key: null,
        start_date: monthBefore,
        end_date: today,
        suggested: false,
        with_voucher: false,
        wrong_dimensions: false,
        wrong_accounts: false,
      },
      pagination: {
        sortBy: "remote_key",
        sortOrder: "desc",
      },
      headers: [
        {
          key: "remote_key",
          label: "Invoice Number",
          sortable: true,
          squeeze: true,
        },
        {
          key: "bill_id",
          label: "Bill ID",
          sortable: true,
          squeeze: true,
        },
        {
          key: "vendor_name",
          label: "Vendor name",
          sortable: true,
          squeeze: true,
        },
        {
          key: "date",
          label: "Date",
          sortable: true,
          squeeze: true,
        },
        {
          key: "total_sum",
          label: "Total sum",
          sortable: true,
          squeeze: true,
        },
        {
          key: "with_voucher",
          label: "With Voucher?",
          sortable: false,
          squeeze: true,
        },
        {
          key: "account_correct",
          label: "Correct #",
          sortable: true,
          squeeze: true,
        },
        {
          key: "correct_percent",
          label: "Correct %",
          sortable: true,
          squeeze: true,
        },
        {
          key: "suggestion_methods",
          label: "Methods",
          sortable: true,
          squeeze: true,
        },
        {
          key: "status",
          label: "Invoice Status",
          sortable: true,
          squeeze: true,
        },
        {
          key: "account_suggest_status",
          label: "Status Account",
          sortable: true,
          squeeze: true,
        },
        {
          key: "dimension_suggest_status",
          label: "Status Dimension",
          sortable: true,
          squeeze: true,
        },
        {
          key: "approval_status",
          label: "Status Circulation",
          sortable: true,
          squeeze: true,
        },
        {
          key: "comment",
          label: "Comment",
          sortable: true,
          squeeze: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    };
  },
  methods: {
    reload() {
      this.$refs.automationTable.fetchData();
    },

    getData(pagination) {
      const search_params = this.search_params;
      return new Promise((res) => {
        ApiCompanies.fetchAutomation(
          this.$route.params.id,
          search_params,
          pagination
        ).then((resp) => {
          res({
            items: resp.data.invoices,
            meta: resp.data.meta,
          });
        });
      });
    },

    resuggest(item) {
      let params = { purchase_invoice: { status: "new" } };
      ApiPurchaseInvoices.updateInvoice(item.id, params).then((resp) => {
        this.reload();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 0px;
}
</style>
