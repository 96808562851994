<template lang="pug">
.pb-5
  .page-hero
    h3.pl-3.pb-0.pt-0 {{ company.company_name }}
  .container
    b-card(no-body)
      tabs(:tabs="tabs", :selected="selected", @select="selectTab")
      transition(name="fade", mode="out-in")
        component(:is="tabContent")
</template>
<script>
import _isEmpty from "lodash/isEmpty";
import BasicTab from "@/views/Companies/Tabs/basic.vue";
import SoftwaresTab from "@/views/Companies/Tabs/softwares.vue";
import UsersTab from "@/views/Companies/Tabs/users.vue";
import FeaturesTab from "@/views/Companies/Tabs/features.vue";
import StatisticsTab from "@/views/Companies/Tabs/statistics.vue";
import VendorsTab from "@/views/Companies/Tabs/vendors.vue";
import AutomationTab from "@/views/Companies/Tabs/automation.vue";
import DimensionsTab from "@/views/Companies/Tabs/dimensions.vue";
import AccountListTab from "@/views/Companies/Tabs/account_list.vue";
import BillingTab from "@/views/Companies/Tabs/billing.vue";
import ScopeTab from "@/views/Companies/Tabs/sales_invoices.vue";
import ReportsTab from "@/views/Companies/Tabs/reports.vue";
import ApproversTab from "@/views/Companies/Tabs/approvers.vue";
import ApiCompanies from "@/api/companies.js";
import ApiCompanyReports from "@/api/companyReports.js";

const BASIC_TAB = "basic";
const SOFTWARES_TAB = "softwares";
const USERS_TAB = "users";
const FEATURES_TAB = "features";
const STATISTICS_TAB = "statistics";
const VENDORS_TAB = "vendors";
const AUTOMATION_TAB = "automation";
const DIMENSIONS_TAB = "dimensions";
const ACCOUNT_LIST_TAB = "account list";
const BILLING_TAB = "billing";
const SCOPE_TAB = "sales_invoices";
const REPORTS_TAB = "reports";
const APPROVERS_TAB = "approvers";

export default {
  data() {
    return {
      selected: this.$route.query.tab || BASIC_TAB,
      company: {},
      showScope: false
    };
  },

  watch: {
    $route(r) {
      const tab = r.query.tab;
      this.selected = tab ? tab : BASIC_TAB;
    },
  },

  computed: {
    tabs() {
      return [
        {
          id: BASIC_TAB,
          label: this.$t("companies.tabs.basic"),
        },
        {
          id: SOFTWARES_TAB,
          label: this.$t("companies.tabs.softwares"),
        },
        {
          id: USERS_TAB,
          label: this.$t("companies.tabs.users"),
        },
        {
          id: FEATURES_TAB,
          label: this.$t("companies.tabs.features"),
        },
        {
          id: STATISTICS_TAB,
          label: this.$t("companies.tabs.statistics"),
        },
        {
          id: VENDORS_TAB,
          label: this.$t("companies.tabs.vendors"),
        },
        {
          id: AUTOMATION_TAB,
          label: this.$t("companies.tabs.automation"),
        },
        {
          id: DIMENSIONS_TAB,
          label: this.$t("companies.tabs.dimensions"),
        },
        {
          id: ACCOUNT_LIST_TAB,
          label: this.$t("companies.tabs.account_list"),
        },
        {
          id: BILLING_TAB,
          label: this.$t("companies.tabs.billing"),
        },
        {
          id: REPORTS_TAB,
          label: this.$t("companies.tabs.reports")
        },
        {
          id: SCOPE_TAB,
          label: this.$t("companies.tabs.scope")
        },
        {
          id: APPROVERS_TAB,
          label: this.$t("companies.tabs.approvers")
        }
      ].filter( tab => {
        if (tab.id === BILLING_TAB && window.user.role === 'user' && window.user.companies[0] && window.user.companies[0].hide_billing) { return false; }
        return true;
      }).filter( tab => {
        if (tab.id === SCOPE_TAB && !this.showScope) { return false; }
        return true;
      });
    },

    tabContent() {
      const tab = this.selected;

      if (tab === BASIC_TAB) return BasicTab;
      if (tab === SOFTWARES_TAB) return SoftwaresTab;
      if (tab === USERS_TAB) return UsersTab;
      if (tab === FEATURES_TAB) return FeaturesTab;
      if (tab === STATISTICS_TAB) return StatisticsTab;
      if (tab === VENDORS_TAB) return VendorsTab;
      if (tab === AUTOMATION_TAB) return AutomationTab;
      if (tab === DIMENSIONS_TAB) return DimensionsTab;
      if (tab === ACCOUNT_LIST_TAB) return AccountListTab;
      if (tab === BILLING_TAB) return BillingTab;
      if (tab === SCOPE_TAB) return ScopeTab;
      if (tab === REPORTS_TAB) return ReportsTab;
      if (tab === APPROVERS_TAB) return ApproversTab;
      return null;
    },
  },

  methods: {
    showScopeReport() {
      ApiCompanyReports.showScopeReport(this.$route.params.id) .then((resp) => {
          this.showScope = resp.data.showScopeReport;
        })
        .catch((e) => {
          this.showScope = false
        });
    },
    selectTab(tab_id) {
      this.selected = tab_id;
      this.$router.push({
        query: {
          ...this.$route.query,
          tab: tab_id,
        },
      });
    },

    fetchData() {
      ApiCompanies.getCompany(this.$route.params.id)
        .then((resp) => {
          this.company = resp.data.company;
        })
        .catch((e) => {
          if (
            !_isEmpty(e.response) &&
            !!e.response.status &&
            e.response.status === 403
          ) {
            this.$router.push({ name: "Companies" });
          }
        });
    },
  },
  mounted() {
    this.fetchData();
    this.showScopeReport();
  },
};
</script>
