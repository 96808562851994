<template lang="pug">
.app
  transition(name="fade" mode="out-in")
    router-view
  overlay-loader(fixed :show="inProgress" :label="$t('rest.fetching_data')")
</template>

<script>
import axios from "axios";

export default {
  name: "App",

  components: {},

  data() {
    return {
      progress: false,
      isLoaded: false,
      activeConnections: 0,
    };
  },
  watch: {
    activeConnections: function (val) {
      this.progress = val > 0;
    },
  },
  computed: {
    inProgress() {
      return this.progress > 0;
    },
  },
  created() {
    axios.interceptors.request.use(
      (config) => {
        if (config.skip_spinner != true) {
          //this.addConnection();
        }
        return config;
      },
      (error) => {
        //this.removeConnection();
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        //this.removeConnection();
        return response;
      },
      (error) => {
        //this.removeConnection();
        let message = "Something went wrong";
        if (error?.response?.data && error?.response?.data?.error) {
          message = error.response.data.error;
        }
        // this.$store.dispatch("global/alert", {
        //   type: "error",
        //   text: message,
        // });

        return Promise.reject(error);
      }
    );
  },
  methods: {
    addConnection() {
      //this.activeConnections++;
    },
    removeConnection() {
      //if (this.activeConnections > 0) this.activeConnections--;
    },
    showAlert() {
      this.$store.dispatch("global/alert", {
        type: "error",
        text: "Something went wrong!",
      });
    },
  },
};
</script>
