import axios from 'axios';

const FetchList = (query_params, pagination) => {
  return axios.get('/api/v1/automation/list', { params: { ...query_params, ...pagination }});
}

const FetchInvoiceLines = (id) => {
  return axios.get(`/api/v1/automation/invoice/${id}/lines`);
}

const FetchInvoiceData = (id) => {
  return axios.get(`/api/v1/automation/invoice/${id}/data`);
}

const ApiAutomation = {
  FetchList,
  FetchInvoiceLines,
  FetchInvoiceData
}

export default ApiAutomation;