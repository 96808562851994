
.container
  stack
    stack-item(fill)
      .h3.mb-0 {{ $t('companies.tabs.approvers') }}
    stack-item
      b-btn(
        variant="primary",
        squared,
        @click="exportApprovalLogs()"
      )
        .mdi.mdi-download
        | {{ $t('companies.export_to_excel') }}
  .pt-3.px-3
    stack
      stack-item
        b-form-group(
          :label="$t('companies.basic.vendor')"
          label-for="name-input"
          label-cols="4"
        )
          b-form-input(
            v-model="search_params.name"
            type="search"
            id = "name-input"
            @change="reload"
          )
    rest-table(
      namespace="approvers_log",
      :headers="headers",
      ref="ApproversLogTable",
      :fetch-handler="getData",
      embed
    )
