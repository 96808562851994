
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.basic') }}
      div.form-container.mt-5
        b-form-group(
          :label="$t('companies.basic.name')"
          label-for="company-name-input"
          label-cols="4"
        )
          b-input(
            v-model="company.company_name"
            id="company-name-input"
          )

        b-form-group(
          :label="$t('companies.basic.netvisor_organization_id')"
          label-for="business-id-input"
          label-cols="4"
        )
          b-input(
            v-model="company.netvisor_organization_id"
            id="business-id-input"
          )
        b-form-group(
          :label="$t('companies.basic.email')"
          label-for="email-input"
          label-cols="4"
          :invalid-feedback="errors.first('email')"
          :state="errors.has('email') ? false : null"
        )
          b-input#email-input(
            v-model="company.email"
            v-validate="'email'"
            data-vv-name="email"
            :data-vv-as="$t('companies.basic.email')"
          )

        b-form-group(
          :label="$t('companies.basic.reseller')"
          label-for="reseller-input"
          label-cols="4"
        )
          v-select(
            v-model="company.reseller_id"
            id="reseller-input"
            :options="resellers"
            :reduce="reseller => reseller.value"
            label="text"
            :clearable="false"
          )
        stack
          stack-item
            b-btn(variant="primary", squared, @click="downloadServiceAgreementFile")
              .mdi.mdi-download
              | {{ $t('actions.download_service_agreement') }}
          stack-item(fill)
          stack-item
            b-btn(
              variant="primary"
              squared
              @click="save"
            )
              .mdi.mdi-account-check-outline
              | {{ $t('actions.save') }}

