
.container
  stack
    stack-item(fill)
      .h3.mb-0 {{ $t('companies.tabs.approvers') }}
    stack-item
      b-btn(
        variant="primary",
        squared,
        @click="addApprover"
      )
        .mdi.mdi-account-plus
        | {{ $t('companies.approvers.add') }}
  .pt-3.px-3
    stack
      stack-item
        b-form-group(
          :label="$t('companies.approvers.name')"
          label-for="name-input"
          label-cols="4"
        )
          b-form-input(
            v-model="search_params.name"
            type="search"
            id = "name-input"
            @change="reload"
          )
    rest-table(
      namespace="approvers",
      :headers="headers",
      ref="ApproversTable",
      :fetch-handler="getData",
      embed
    )
      template(slot="cel_roles", slot-scope="{ item }")
        stack(spacing="xs")
          stack-item(v-if="item.is_approver")
            b-button(
              pill
              class="uppercase"
              :variant="'info'"
              v-b-tooltip.focus
              :title="`Approver`"
            )
              | {{ $t('companies.approvers.role_approver') }}
          stack-item(v-if="item.is_verificator")
            b-button(
              pill
              class="uppercase"
              :variant="'success'"
              v-b-tooltip.focus
              :title="`Factual Verificator`"
            )
              | {{ $t('companies.approvers.role_verificator') }}
      template(slot="cel_assigned_to", slot-scope="{ item }")
        stack(spacing="xs")
          table.table-sm.table-striped.table-bordered.targets-table(v-if="Array.isArray(item.assigned_to) && item.assigned_to.length > 0")
            thead
              tr
                th(v-for="column in columns(item.assigned_to)") {{ column }}
            tbody
              tr(v-for="row in rows(item.assigned_to)")
                td(v-for="column in columns(item.assigned_to)") {{ row[column] || '' }}

          //table.table-sm.table-striped.table-bordered(v-if="Array.isArray(item.assigned_to) && item.assigned_to.length > 0")
          //  thead
          //    tr
          //      th Class
          //      th Name
          //  tbody
          //    tr(v-for="(item, index) in item.assigned_to")
          //      td {{ item[0] }}
          //      td {{ item[1] }}
      template(slot="cel_actions", slot-scope="{ item }")
        stack(spacing="xs")
          stack-item
            b-btn(
              size="xs",
              variant="flat-primary",
              squared,
              @click="editApprover(item)"
            )
              .mdi.mdi-pencil-outline
              | {{ $t('actions.edit') }}
          stack-item
            b-btn(
              size="xs",
              variant="flat-danger",
              squared,
              @click="deleteApprover(item)"
            )
              .mdi.mdi-trash-can-outline
              | {{ $t('actions.delete') }}
  edit-approver-modal(ref="EditApproverModal", @done="reload")
