<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.users') }}
        stack-item
          b-btn(
            variant="primary",
            squared,
            @click="inviteNewUser"
          )
            .mdi.mdi-account-plus
            | {{ $t('companies.users.invite_new_user') }}
      rest-table(
        namespace="company_users",
        :headers="headers",
        ref="companyUsersTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                @click="editUser(item)"
              ) 
                .mdi.mdi-pencil-outline
                | {{ $t('actions.edit') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteUser(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
    edit-user-modal(ref="EditUserModal", @done="reloadList")
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiCompanyUsers from "@/api/companyUsers.js";
import EditUserModal from "@/modals/Companies/editUser.vue";

export default {
  components: {
    EditUserModal,
  },

  data() {
    return {
      pagination: {
        sortBy: null,
      },
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "email",
          label: "email",
          sortable: true,
        },
        {
          key: "role",
          label: "role",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    };
  },

  methods: {
    reloadList() {
      this.$refs.companyUsersTable.fetchData();
    },
    getData(pagination) {
      return new Promise((res) => {
        ApiCompanyUsers.fetchUsers(this.$route.params.id, pagination).then(
          (resp) => {
            res({
              items: resp.data.users,
              meta: resp.data.meta,
            });
          }
        );
      });
    },

    editUser(item) {
      this.$refs.EditUserModal.open(this.$route.params.id, item);
    },

    deleteUser(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "account-minus",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiCompanyUsers.deleteUser(this.$route.params.id, item.id).then(
          (resp) => {
            this.reloadList();
          }
        );
      });
    },

    inviteNewUser() {
      this.$refs.EditUserModal.open(this.$route.params.id, null);
    },

    goBack() {
      router.push("/companies");
    },
  },
};
</script>

