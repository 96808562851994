<template lang="pug">
.ui-tag
  .ui-tag__label 
    slot {{ label }}
  .ui-tag__close(v-if="closeable" @click.stop="$emit('close')")
    .mdi.mdi-close
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: null,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/stylesheet/_variables.scss";
@import "@/assets/stylesheet/_form.scss";

.ui-tag {
  padding: 2px 5px;
  border-radius: 3px;
  background: $bb-green;
  color: white;
  display: flex;
  align-items: center;

  &__close {
    cursor: pointer;
    margin-left: 4px;
  }
}
</style>
