
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div
      .modal-title {{ $t('companies.reports.create_title') }}
      .modal-subtitle {{ $t('companies.reports.create_description') }}

  b-form-group(:label="$t('companies.reports.report_type')")
    b-form-select(
      v-model="selectedReportType",
      :options="reportsOptions",
    )

  b-form-group(:label="$t('companies.reports.schedule_type')")
    b-form-radio-group(v-model="report.schedule", v-if="showSchedule", stacked)
      b-form-radio(value="single") {{ $t('companies.reports.schedule_single') }}
      b-form-radio(value="schedule") {{ $t('companies.reports.schedule_schedule') }}
      b-form-radio(value="every_day", v-if="showEveryDaySchedule") {{ $t('companies.reports.schedule_every_day') }}
      b-form-radio(value="for_every_day", v-if="showForEveryDaySchedule") {{ $t('companies.reports.schedule_for_every_day') }}
      b-form-radio(
        :value="-1 * (new Date().getDay() + 1)",
        v-if="showForEveryWeekSchedule"
      ) {{ `${$t('companies.reports.schedule_for_every_week')} ${['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date().getDay()]}` }}

    b-form-radio-group(v-model="report.schedule", v-if="showHours", stacked)
      b-form-radio(value="specific_time") {{  $t('companies.reports.scheduled_at_time') }}
      b-form-radio(value="period") {{ $t('companies.reports.scheduled_by_period') }}

  b-form-group(
    :label="$t('companies.reports.schedule_time')",
    v-if="report.schedule === 'specific_time'")
    stack
      stack-item
        b-form-timepicker(
          v-model="report.daily_run_at"
          label-no-date-selected="Select daily start time"
          locale="en"
        )

  b-form-group(
    :label="$t('companies.reports.schedule_time_period')",
    v-if="report.schedule === 'period'"
  )
    stack
      stack-item
        b-form-select.mt-3(
          v-model="report.run_period"
          :options="time_periods"
          )

  b-form-group(
    :label="$t('companies.reports.schedule_day')",
    v-if="report.schedule === 'schedule'"
  )
    b-form-spinbutton(v-model="report.schedule_day", min="1", max="15")

  b-form-group(:label="$t('companies.reports.dates_range')", v-if="showDates")
    stack
      stack-item
        b-form-datepicker(
          v-model="report.date_start"
          label-no-date-selected="Start date"
        )
      stack-item(fill)
      stack-item
        b-form-datepicker(
          v-model="report.date_end",
          label-no-date-selected="End date"
        )

  b-form-group(
    :label="$t('companies.reports.account_numbers_range')",
    v-if="showAccountRange"
  )
    stack
      stack-item
        b-form-input(v-model="report.number_from", type="number")
      stack-item(fill)
      stack-item
        b-form-input(v-model="report.number_to", type="number")

  b-form-group(
    :label="$t('companies.reports.default_bank_account')",
    v-if="showDefaultBankAccount"
  )
    b-form-input(v-model="report.default_bank_account")

  b-form-group(
    :label="$t('companies.reports.save_to_google_drive')",
    v-if="google_drive_available && !!selectedReportType"
  )
    b-form-checkbox(v-model="report.google_drive", switch)

  b-form-group(:label="currentReport.file_upload_label" v-if="showUpload")
    b-form-file(
      v-model="file",
      :state="Boolean(file)",
      :placeholder="$t('companies.reports.file_not_selected')",
      :drop-placeholder="$t('companies.reports.file_not_selected')"
    )

  b-form-group(:label="currentReport.second_file_upload_label" v-if="showSecondUpload")
    b-form-file(
      v-model="second_file",
      :state="Boolean(second_file)",
      :placeholder="$t('companies.reports.file_not_selected')",
      :drop-placeholder="$t('companies.reports.file_not_selected')"
    )

  b-form-group(
    :label="$t('companies.reports.download_from_sftp')",
    v-if="showDownloadSftp"
  )
    b-form-checkbox(
      v-model="download_from_sftp",
      switch
      )
  b-form-group(
    :label="$t('companies.reports.download_sftps_items')"
    v-if="showDownloadSftp && download_from_sftp"
  )
    div(
      class="form-container mt-5"
      v-for="(item, index) in currentReport.sftp_download_keys" :key="item.key"
    )
      b-form-group(:label="item.label")
        v-select.mt-3(
          :reduce="download_key => download_key.value"
          v-model="report.sftp_download_keys[index].value"
          v-validate="'required'"
          data-vv-name="report.sftp_download_keys[index].value"
          :data-vv-as="$t('companies.sftp_storages')"
          :options="sftp_storages"
          label="text"
        )

  b-form-group(
    :label="$t('companies.reports.sftp_for_upload')",
    v-if="showUploadSftp"
  )
    b-form-checkbox(
      v-model="upload_to_sftp",
      switch
      )
  b-form-group(
    :label="$t('companies.reports.upload_sftps_items')"
    v-if="showUploadSftp && upload_to_sftp"
  )
    div(
      class="form-container mt-5"
      v-for="(item, index) in currentReport.sftp_upload_keys" :key="item.key"
    )
      b-form-group(:label="item.label")
        v-select.mt-3(
          :reduce="upload_key => upload_key.value"
          v-model="report.sftp_upload_keys[index].value"
          :options="sftp_storages"
           v-validate="'required'"
          data-vv-name="report.sftp_upload_keys[index].value"
          :data-vv-as="$t('companies.sftp_storages')"
          label="text"
        )
  b-form-group(
    :label="$t('companies.reports.new_partner_key')",
    v-if="showPartnerChooser"
  )
    b-form-checkbox(
      v-model="report.new_partner_key",
      switch
      )
  b-form-group(
    v-if="showSourceAccount",
    :label="$t('companies.reports.source_account')",
  )
    b-form-input(
      v-validate="'required'"
      v-model="report.source_account",
      :state="errors.has('source_account') ? false : null",
      data-vv-name="source_account",
      :data-vv-as="$t('companies.reports.source_account')"
      )
  //- b-form-group(:label="currentReport.second_file_upload_label" v-if="showDownloadSftp && download_from_sftp")
  //-   .form-container.mt-5
  //-     b-form-group(:label="$t('companies.storages.sftp.path')")
  //-       stack
  //-         stack-item
  //-           b-btn(
  //-             :disabled="report.sftp_folder === './'",
  //-             variant="primary",
  //-             squared,
  //-             @click="SFTPfolderUp"
  //-           )
  //-             .mdi.mdi-folder-upload-outline
  //-         stack-item(fill)
  //-           b-input(v-model="report.sftp_folder", :readonly="true")
  //-       b-form-select.mt-3(
  //-         v-model="sftpSelectedFolder",
  //-         :disabled="sftpFolders.length === 0",
  //-         :options="sftpFolders",
  //-         :select-size="sftpFolders.length"
  //-       )

  b-link(
    :href="'/' + currentReport.file_format"
    target="_blank"
    v-if="showFileFormat"
  ) {{ currentReport.file_format_text }}

  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
