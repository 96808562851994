// Example:
// new RestClient('/api/v1/trackers/', 'tracker', 'trackers')

import axios from 'axios';
import { isNumber } from 'lodash';

const POST = 'post';
const PATCH = 'patch';

class RestClient {
  /**
   * Generate new Rest API client
   * @param {String} url
   */
  constructor(url, prular = null, singular = null) {
    this._url = url;
    this._prular = prular;
    this._singular = singular;
  }

  get url() {
    return this._url.replace(/\/$/g, '');
  }

  fetchAll(params = {}) {
    return axios.get(this.url, { params });
  }

  fetch(id, params = {}) {
    return axios.get(`${this.url}/${id}`, { params });
  }

  save(data = {}, params = {}) {
    let url = this.url;
    let method = POST;
    if (isNumber(data.id)) {
      url += `/${data.id}`;
      method = PATCH;
    }
    return axios({
      method,
      url,
      data: {
        [this._singular]: data
      },
      params
    });
  }

  destroy(id, params = {}) {
    return axios.delete(`${this.url}/${id}`, params);
  }
}

export default RestClient;
