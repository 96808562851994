<template lang="pug">
div
  .pb-5
    .page-hero
      h3.pl-3.pb-0.pt-0(
        v-if="!!reseller"
      ) {{reseller.name}}
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{$t('billing.title') }}
        .pt-3.px-3
          stack
            stack-item
              b-form-group.mt-3(
                :label="$t('billing.year_and_month')"
                label-for="month-input"
                label-cols="6"
              )
                vue-monthly-picker(
                  v-model="selectedMonth"
                  id="month-input"
                  :placeHolder="`${new Date().getFullYear()}/${new Date().getMonth() + 1}`"
                  alignment="center"
                  @selected="reload"
                )
            stack-item(fill)
            stack-item
              b-btn(
                variant="primary"
                squared
                :href="`/billing/download?reseller_id=${$route.params.id}` + (!!selectedMonth ? `&year=${selectedMonth._i.split('/')[0]}&month=${selectedMonth._i.split('/')[1]}` : '')"
                target="_blank"
                v-if="!!reseller"
              )
                .mdi.mdi-download
                | {{ $t('actions.download') }}
        .container.table-limit
          rest-table(
            namespace="billing"
            :headers="headers"
            ref="billingTable"
            :fetch-handler="getData"
            embed
          )
</template>
<script>
import router from "@/router";
import axios from "axios";
import { mapGetters } from "vuex";
import ApiBilling from "@/api/billing.js";
import ApiResellers from "@/api/resellers.js";

export default {
  data() {
    return {
      reseller: null,
      selectedMonth: null,
      pagination: {
        sortBy: null
      },
      headers: [
        {
          key: "company_name",
          label: "Company Name",
          sortable: true,
          squeeze: false,
          tooltip: "Company Name"
        },
        {
          key: "netvisor_organization_id",
          label: "Business ID",
          sortable: true,
          squeeze: false,
          tooltip: "Business ID"
        },
        {
          key: "invoices_count",
          label: "Total Invoices",
          sortable: false,
          squeeze: false,
          tooltip: "Total Invoices"
        },
        {
          key: "already_suggested_invoices_count",
          label: "Already Suggested Invoices",
          sortable: false,
          squeeze: false,
          tooltip: "Amount of Invoices with correct account suggested by the Accounting software"
        },
        {
          key: "suggested_invoices_count",
          label: "FR Suggested Invoices",
          sortable: false,
          squeeze: false,
          tooltip: "Amount of Invoices suggested by Finance.Rocks"
        },
        {
          key: "automatically_correct_invoices_count",
          label: "Automatically correct invoices",
          sortable: false,
          squeeze: false,
          tooltip: "Total amount of invoices with correctly suggested account"
        },
        {
          key: "automation_perc",
          label: "Automation %",
          sortable: false,
          squeeze: false,
          tooltip: "Automation %"
        },

        {
          key: "correct_invoices_count",
          label: "FR Correct Invoices",
          sortable: false,
          squeeze: false,
          tooltip: "FR Correct Invoices"
        },

        {
          key: "correct_invoices_perc",
          label: "FR Correct % of suggested",
          sortable: false,
          squeeze: false,
          tooltip: "FR Correct % of suggested"
        },

        {
          key: "correct_lines",
          label: "FR Correct Lines",
          sortable: false,
          squeeze: false,
          tooltip: "FR Correct Lines"
        },
        {
          key: "summ",
          label: "Billable €",
          sortable: false,
          squeeze: false,
          tooltip: "Billable €"
        }
      ]
    };
  },
  methods: {
    reload() {
      this.$refs.billingTable.fetchData();
    },

    getData(pagination) {
      let params = {};
      if (!!this.selectedMonth) {
        params.year = this.selectedMonth._i.split("/")[0]
        params.month = this.selectedMonth._i.split("/")[1]
      }
      return new Promise(res => {
        ApiBilling.FetchCompanies(this.$route.params.id, params, pagination).then(resp => {
          res({
            items: [...resp.data.totals, ...resp.data.companies],
            meta: resp.data.meta
          });
        });
      });
    },
    fetchData() {
      ApiResellers.getReseller(this.$route.params.id).then(resp => {
        this.reseller = resp.data.reseller;
      });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

