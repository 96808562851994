<template lang="pug">
UiSelect(
  @input="$emit('input', $event)"
  :value="value"
  :options="getData"
  :object="object"
  :searchable="false"
)
</template>

<script>
import ApiCompanyDimensionNames from "@/api/company_dimension_names.js";

export default {
  props: {
    value: {
      default: null,
    },
    object: {
      default: false,
    },
    companyId: {
      required: true,
    }
  },
  methods: {
    async getData() {
      const response = await ApiCompanyDimensionNames.fetchAll(this.companyId);
      return {
        items: response?.data?.dimension_names?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      };
    },
  },
};
</script>