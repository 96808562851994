
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div
      .modal-title {{ $t('companies.import') }}
      .modal-subtitle {{ $t('companies.import_description') }}
  p 
    a(href="/finance_rocks_new.csv", target="_blank") {{ $t('companies.file_example') }}
  b-form-file(
    v-model="file",
    :state="Boolean(file)",
    placeholder="Choose a file or drop it here...",
    drop-placeholder="Drop file here...",
    accept=".csv"
  )

  template(slot="modal-footer")
    b-btn(
      :disabled="!Boolean(file)",
      variant="primary",
      squared,
      @click="upload"
    ) 
      .mdi.mdi-upload
      | {{ $t('actions.upload') }}
