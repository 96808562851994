<template lang="pug">
svg.no-avatar(
  viewBox="0 0 100 100" 
  style="overflow: visible;" 
  xmlns="http://www.w3.org/2000/svg" 
  version="1.1"
)
  text.no-avatar__text(
    v-if="label"
    text-anchor="middle"  
    x="50" 
    y="64"
  ) {{ abbreviation }}
</template>

<script>
export default {
  name: 'NoAvatar',

  props: {
    label: {
      type: String,
      default: null
    }
  },

  computed: {
    abbreviation() {
      if (!this.label) return '';
      const match = this.label.match(/\b([A-Z])/g);
      if (match) return match.join('');
      return this.label.slice(0, 1).toUpperCase();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/_variables.scss';

.no-avatar {
  background: $bb-gray-900;

  &__text {
    fill: $bb-gray-600;
    user-select: none;
    font-size: 40px;
    line-height: 1;
  }
}
</style>