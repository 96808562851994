import axios from 'axios';

const fetchUsers = (query_params, pagination) => {
    return axios.get('/api/v1/admin_users', { params: { ...query_params, ...pagination }});
};

const updateUser = (id, params) => {
    return axios.put(`/api/v1/admin_users/${id}`, { params: { ...params } })
};

const deleteUser = (id) => {
    return axios.delete(`/api/v1/admin_users/${id}`)
}

const deleteCompanyUser = (user_id, company_user_id) => {
    return axios.delete(`/api/v1/admin_users/${user_id}/delete_company_user/${company_user_id}`)
}

const updateCompanyUser = (id, params) => {
    return axios.put(`/api/v1/admin_users/${id}/update_company_user`, params )
}

const userCompanies = (id, pagination) => {
    return axios.get(`/api/v1/admin_users/${id}/user_companies`, { params: { ...pagination } })
}

const ApiAdminUsers = {
    fetchUsers,
    updateUser,
    deleteUser,
    userCompanies,
    updateCompanyUser,
    deleteCompanyUser
};

export default ApiAdminUsers;
