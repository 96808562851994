
table.table-sm.table-striped.table-bordered.w-100(v-if="Array.isArray(line_items)")
  thead
    tr
      th(rowspan="2") LineSum
      th(rowspan="2") VatCode
      th(rowspan="2") VatPercent
      th(rowspan="2") Description
      th(v-bind:colspan="dimensions_count()") Dimensions
      th(rowspan="2") Account
      th(rowspan="2") Suggestions
    tr
      th(v-for="(item, i) in dimensions")
        p {{item}}
  tbody
    tr(v-for="(item, index) in line_items")
      td {{item['line_sum']}}
      td {{item['suggestion'] !== null ? line_vat_code(item['suggestion']['vat_code']) : ''}}
      td {{item['data'] !== null ? item['data']['VatPercent'] : ''}}
      td 
        div
          p 
            b Netvisor: 
            span {{item['description']}}
        div(v-if="(item['article_name'])")
          p 
            b Finvoice: 
            span {{ item['article_name'] }}
        div(v-if="(item['finvoice_comment'])")
          p 
            b Finvoice comment: 
            span {{ item['finvoice_comment'] }}
      td(v-for="(dimension) in dimensions" v-bind:id="dimensions_correct(item, dimension)" width="20%")
        p {{dimension_item(item, dimension)}}
        UiSpoiler(
          :openLabel="$t('labels.show_suggestion')"
          :closeLabel="$t('labels.hide_suggestion')"
        )
          p.text-muted(v-html="suggestion_description(item['suggestion'])")
      td(v-bind:id="account_correct(item)") {{item['suggestion'] !== null ? item['suggestion']['account_number'] : ''}}
      td
        table
          tr(v-for="(result, i) in item['suggest_results']" v-bind:id="result['result']")
            td {{result['method']}}
            td {{result['result']}}
            td {{result['description']}}
            td {{result['account_number']}}
