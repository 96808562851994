<template lang="pug">
button.header-button(
  @click="click"
)
  .header-button__prepend(v-if="hasPrepend")
    slot(name="prepend")
  .header-button__content
    .header-button__subtitle(v-if="hasSubtitle && subtitleTop")
      slot(name="subtitle") {{ subtitle }}
    .header-button__label
      slot {{ label }}
    .header-button__subtitle(v-if="hasSubtitle && !subtitleTop")
      slot(name="subtitle") {{ subtitle }}
  .header-button__append(v-if="hasAppend")
    slot(name="append")
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      defalt: null
    },
    subtitle: {
      type: String,
      defalt: null
    },
    subtitleTop: {
      type: Boolean,
      defalt: false
    }
  },

  computed: {
    hasSubtitle() {
      return !!this.$slots.subtitle || this.subtitle;
    },

    hasPrepend() {
      return !!this.$slots.prepend;
    },

    hasAppend() {
      return !!this.$slots.append;
    }
  },

  methods: {
    click() {
      this.$emit('click');
    }
  }
};
</script>