<template lang="pug">
  .container
    stack
      stack-item(fill)
        .h3.mb-0 {{ $t('companies.approvers.target_verificators') }}
      stack-item
        b-btn(
          variant="primary",
          squared,
          @click="openAssignApprover"
        )
          .mdi.mdi-account-plus
          | {{ $t('companies.approvers.assign_verificator') }}
    rest-table(
      namespace="target_verificators",
      :headers="headers",
      ref="TargetVerificatorsTable",
      :fetch-handler="getData",
      disablePagination
      embed
    )
      template(slot="cel_actions" slot-scope="{ item }")
        b-button(
          size="xs",
          variant="flat-danger",
          squared,
          v-b-tooltip.focus,
          @click="deassign(item)"
        )
          | remove
    assign-approver-modal(ref="AssignApproverModal", @closed="reload")
</template>
<script>
import ApiApprovers from "@/api/approvers.js";
import AssignApproverModal from "@/modals/Approvers/assignApprovers.vue"

export default {
  props: ['target', 'target_id'],

  components: {
    AssignApproverModal
  },

  data() {
    return {
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "approver_name",
          label: "Name",
          sortable: true,
          squeeze: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          squeeze: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    }
  },

  methods: {
    reload() {
      this.$refs.TargetVerificatorsTable.fetchData();
    },

    getData() {
      this.inProgress = true;

      return new Promise((res) => {
        ApiApprovers.FetchTargetVerificators(this.$route.params.id, this.target, this.target_id).then(
            (resp) => {
              res({
                items: resp.data.verificators,
                meta: resp.data.meta,
              });
            }
        ).catch((e) => {
          this.inProgress = false;
        });;
      });
    },

    openAssignApprover() {
      this.$refs.AssignApproverModal.open(this.target, this.target_id, 'verificator');
    },

    deassign(item) {
      this.inProgress = true;
      return new Promise((res) => {
        ApiApprovers.DeassignApprover(this.$route.params.id, item.id, this.target, this.target_id, 'verificator').then(
            (resp) => {
              this.reload();
            });
      })
    },
  }
}
</script>