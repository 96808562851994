
b-modal(
  centered
  v-model="show"
)
  template(slot="modal-title")
    div
      .modal-title {{ $t('companies.create_title') }}
      .modal-subtitle {{ $t('companies.create_description') }}
  b-form-group(
    :label="$t('companies.basic.name')"
    label-for="company-name-input"
    label-cols="4"
    :invalid-feedback="errors.first('company_name')"
    :state="errors.has('company_name') ? false : null"
  )
    b-input(
      v-model="form.company_name"
      id="company-name-input"
      v-validate="'required'"
      data-vv-name="company_name"
      :data-vv-as="$t('companies.basic.name')"
    )
  b-form-group(
    :label="$t('companies.basic.netvisor_organization_id')"
    label-for="business-id-input"
    label-cols="4"
    :invalid-feedback="errors.first('netvisor_organization_id')"
    :state="errors.has('netvisor_organization_id') ? false : null"
  )
    b-input(
      v-model="form.netvisor_organization_id"
      id="business-id-input"
      v-validate="'required'"
      data-vv-name="netvisor_organization_id"
      :data-vv-as="$t('companies.basic.netvisor_organization_id')"
    )
  b-form-group(
    :label="$t('companies.basic.reseller')"
    label-for="reseller-input"
    label-cols="4"
    :invalid-feedback="errors.first('reseller_id')"
    :state="errors.has('reseller_id') ? false : null"
  )
    v-select(
      v-model="form.reseller_id"
      id="reseller-input"
      :options="resellers"
      :reduce="reseller => reseller.value"
      label="text"
      :clearable="false"
      v-validate="'required'"
      data-vv-name="reseller_id"
      :data-vv-as="$t('companies.basic.reseller')"
    )
  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )  
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
