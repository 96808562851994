
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('admin_tools.tabs.integration_list') }}
        stack-item
          b-btn(
            variant="primary",
            squared,
            @click="exportIntegrations()"
          )
            .mdi.mdi-download
            | {{ $t('companies.export_to_excel') }}
      rest-table(
        namespace="integration_list",
        :headers="headers",
        ref="integrationListTable",
        :fetch-handler="getData",
        embed
      )
