<template lang="pug">
.invoice-uploaded-status(:class="classes")
  .mdi.mdi-check(v-if="success")
  .mdi.mdi-close(v-else)
</template>

<script>
export default {
  props: {
    success: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "invoice-uploaded-status--success": this.success,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheet/_variables.scss";

.invoice-uploaded-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
  font-size: 1.25rem;
  border-radius: 50%;
  background: rgba($danger, 0.1);
  color: $danger;

  &--success {
    background: rgba($primary, 0.1);
    color: $primary;
  }
}
</style>
