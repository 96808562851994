<template lang="pug">
b-modal(
  centered
  :title="title"
  v-model="show"
  cancel-disabled
  :hide-footer="true"
  size="lg"
)
  b-table(:items="userData" :fields="headers" striped small thead-class="d-none")
</template>

<script>
export default {
  data() {
    return {
      show: false,
      inProgress: false,
      customer: {
        customer_number: "",
        customer_hash: {
          additionaladdress: "",
          address: "",
          business_id: "",
          city: "",
          contact_name: "",
          country: "",
          email: "",
          group: "",
          internal_id: "",
          invoicinglanguage: "",
          name: "",
          phone: "",
          postnumber: "",
          streetaddress: "",
          unit_number: "",
          zip: "",
        },
      },
    };
  },

  computed: {
    title() {
      return this.$t("digia.show_customer_info_modal.title", {
        customer_number: this.customer.customer_number,
      });
    },

    headers() {
      return ["label", "value"];
    },

    userData() {
      const result = [];
      for (const key in this.customer.customer_hash) {
        if (Object.hasOwnProperty.call(this.customer.customer_hash, key)) {
          result.push({
            key,
            label: this.$t(`labels.${key}`),
            value: this.customer.customer_hash[key] || "-",
          });
        }
      }
      return result;
    },
  },

  methods: {
    open({ customer }) {
      this.customer = customer;
      this.show = true;
    },

    close() {
      this.show = false;
    },
  },
};
</script>
