import axios from 'axios';

const fetchPurchaseInvoice = (invoice_id) => {
    return axios.get(`/api/v1/purchase_invoices/${invoice_id}`)
}

const fetchSuggestData = (invoice_id) => {
    return axios.get(`/api/v1/purchase_invoices/${invoice_id}/show_suggest_data`)
}

const updateInvoice = (invoice_id, params) => {
 return axios.patch(`/api/v1/purchase_invoices/${invoice_id}`, params)
}

const ApiPurchaseInvoices = {
    fetchPurchaseInvoice,
    fetchSuggestData,
    updateInvoice
}

export default ApiPurchaseInvoices;