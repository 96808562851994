
div
  .mb-3
    stack
      stack-item
        stack
          stack-item
            label.m-0(label-for="status") {{ $t('labels.status') }}
          stack-item
            ui-select(
              :searchable="false"
              :options="statusOptions",
              :value="filters.customer_status"
              @input="updateQueryParams({customer_status: $event || undefined})"
              id="status"
            )
      stack-item
        stack
          stack-item
            label.m-0(label-for="status") {{ $t('labels.files') }}
          stack-item
            digia-file-select(
              multiselect
              :companyId="globalFilters.company_id"
              :value="globalFilters.files"
              @input="updateQueryParams({files: JSON.stringify($event)})"
            )
      stack-item(fill)
      stack-item
        b-btn(
          variant="primary"
          squared
          @click="callRestartDialog"
        )
          .mdi.mdi-refresh
          | {{ $t('actions.restart_all_failed') }}
  rest-table(
    namespace="invoices",
    :headers="headers",
    ref="table",
    :fetch-handler="getData",
    embed
  )
    template(v-slot:cel_customer_number="{item}")
      a(href="#" @click="showCustomerInfo($event, item)") {{ item.customer_number }}
    template(v-slot:cel_status="{item}")
      customer-status-badge(:status="item.status")
    template(v-slot:cel_filename="{item}")
      router-link(:to="getFileFilterLink(item)") {{ item.filename }}
  ShowCustomerInfoModal(ref="showCustomerInfoModal")
