
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.scope_vouchers') }}
        stack-item
          b-form-group(
            :label="$t('companies.sales_invoices.status_filter')"
            label-for="name-input"
            label-cols="4"
          )
            b-select(
              v-model="search_params.status"
              id="status-input"
              :options="statesOptions"
              label="text"
              @change="reload()"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.sales_invoices.search')"
            label-for="keyword-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.keyword"
              type="search"
              id = "keyword-input"
              @change="reload()"
            )
        stack-item
          b-btn(variant="primary", squared, @click="restartAll")
            .mdi.mdi-restart
            | {{ $t('companies.sales_invoices.actions.restart') }}
        stack-item
          b-btn(variant="danger", squared, @click="deleteFailed")
            .mdi.mdi-delete
            | {{ $t('companies.sales_invoices.actions.delete') }}
        stack-item
          b-btn(variant="warning", squared, @click="deleteProcessed")
            .mdi.mdi-delete
            | {{ $t('companies.sales_invoices.actions.delete_processed') }}
      rest-table(
        namespace="scope_vouchers",
        :headers="headers",
        ref="scopeVouchersTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_status" slot-scope="{ item }")
          b-button(
            pill
            class="uppercase"
            :variant="STATUSES[item.status] || 'info'"
            v-b-tooltip.focus
            :title="`Status reason: \"${item.status_reason}\"`"
          )
            | {{ $t(`companies.sales_invoices.statuses.${item.status}`) }}
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteVoucher(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
