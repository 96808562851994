<template lang="pug">
b-badge.badge-status(pill :variant="variant") {{label}}
</template>

<script>
const FileStatusVariant = {
  failed: "danger",
  processed: "info",
  processing: "success",
};

const FileStatusLabelKey = {
  failed: "labels.failed",
  processed: "labels.processed",
  processing: "labels.processing",
};

export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    variant() {
      return FileStatusVariant[this.status] ?? "light";
    },
    label() {
      const labelKey = FileStatusLabelKey[this.status] ?? null;
      return labelKey ? this.$t(labelKey) : this.status;
    },
  },
};
</script>
