// Layouts
import ApplicationLayout from "@/layouts/Application.vue";

// Pages
import Home from "@/views/Home.vue";
import Landing from "@/views/Landing.vue";
import Automation from '@/views/Automation/index.vue';
import Billing from '@/views/Billing/index.vue';
import ShowBilling from '@/views/Billing/show.vue';
import Companies from "@/views/Companies/index.vue";
import EditCompany from "@/views/Companies/edit.vue";
import Resellers from "@/views/Resellers/index.vue";
import EditReseller from "@/views/Resellers/edit.vue";
import SuperAdmins from "@/views/SuperAdmins/index.vue";
import AdminTools from "@/views/AdminTools/index.vue";
import AdminUsers from "@/views/AdminUsers/index.vue"
import NoHaveCompany from "@/views/NoHaveCompany/Index.vue";
import NetvisorContract from '@/views/NetvisorContract/Index.vue';
import NetvisorIntegrationEn from '@/views/NetvisorIntegration/En.vue';
import NetvisorIntegrationFi from '@/views/NetvisorIntegration/Fi.vue';

export const routes = [
  {
    path: "/",
    name: "Root",
    component: ApplicationLayout,
    redirect: { name: "Companies" },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/automation',
        name: 'Automation',
        component: Automation,
        meta: {
          sidebar: true
        }
      },
      {
        path: '/billing',
        name: 'Billing',
        component: Billing,
        meta: {
          sidebar: true
        }
      },
      {
        path: '/billing/:id/show',
        name: 'ShowBilling',
        component: ShowBilling,
        meta: {
          sidebar: true
        }
      },
      {
        path: "/companies",
        name: "Companies",
        component: Companies,
        meta: {
          sidebar: true
        }
      },
      {
        path: "/companies/:id/edit",
        name: "EditCompany",
        component: EditCompany,
        meta: {
          sidebar: true
        }
      },
      {
        path: "/resellers",
        name: "Resellers",
        component: Resellers,
        meta: {
          sidebar: true
        }
      },
      {
        path: "/resellers/:id/edit",
        name: "EditReseller",
        component: EditReseller,
        meta: {
          sidebar: true
        }
      },
      {
        path: "/super_admins",
        name: "SuperAdmins",
        component: SuperAdmins,
        meta: {
          sidebar: true
        }
      },
      {
        path: "/admin_tools",
        name: "AdminTools",
        component: AdminTools,
        meta: {
          sidebar: true
        }
      },
      {
        path: "/admin_users",
        name: "AdminUsers",
        component: AdminUsers,
        meta: {
          sidebar: true
        }
      },
      {
        path: "/no_have_company",
        name: "NoHaveCompany",
        component: NoHaveCompany,
        meta: {
          sidebar: true
        }
      },
      {
        path: '/netvisor_contract',
        name: 'NetvisorContract',
        component: NetvisorContract,
        meta: {
          sidebar: true
        }
      }
    ],
  },
  {
    path: "/",
    name: "Landing",
    component: ApplicationLayout,
    redirect: { name: "AboutService" },
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "/",
        name: "AboutService",
        component: Landing
      },
      {
        path: '/sign-up-via-netvisor/en',
        name: 'NetvisorIntegrationEn',
        component: NetvisorIntegrationEn,
        meta: {
          sidebar: true
        }
      },
      {
        path: '/sign-up-via-netvisor/fi',
        name: 'NetvisorIntegrationFi',
        component: NetvisorIntegrationFi,
        meta: {
          sidebar: true
        }
      }
    ],
  },
];

export default routes;
