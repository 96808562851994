import Vue from "vue";

// import 'bootstrap/dist/css/bootstrap.css';

// Avatar
import AvatarBox from "@/components/Avatar/AvatarBox.vue";
Vue.component("AvatarBox", AvatarBox);

// Stacks
import Stack from "@/components/Stack/Stack.vue";
import StackItem from "@/components/Stack/StackItem.vue";
Vue.component("Stack", Stack);
Vue.component("StackItem", StackItem);

// UiTabs
import UiTabs from "@/components/UiTabs/ui-tabs.vue";
Vue.component("Tabs", UiTabs); // alias
Vue.component("UiTabs", UiTabs);

// DataTable
import DataTable from "@/components/DataTable/DataTable.vue";
Vue.component("DataTable", DataTable);

// RestTable
import RestTable from "@/components/RestTable/RestTable.vue";
Vue.component("RestTable", RestTable);

// Empty states
import EmptyState from "@/components/Empty/EmptyState.vue";
Vue.component("EmptyState", EmptyState);

// OverlayLoader
import OverlayLoader from "@/components/Loader/Overlay.vue";
Vue.component("OverlayLoader", OverlayLoader);

import VueMonthlyPicker from "vue-monthly-picker";
Vue.component("VueMonthlyPicker", VueMonthlyPicker);

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

import CompositionApi from "@vue/composition-api";
Vue.use(CompositionApi);

import UiSelect from "@/components/UiSelect/UiSelect.vue";
Vue.component("UiSelect", UiSelect);

import UiTag from "@/components/UiTag/UiTag.vue";
Vue.component("UiTag", UiTag);

import UiSpoiler from "@/components/UiSpoiler/UiSpoiler.vue";
Vue.component("UiSpoiler", UiSpoiler);
