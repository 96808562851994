<template lang="pug">
status-badge(
  :status="item.status"
  :label="$t(`automation.statuses.${item.status}`)"
  :tooltip="item.status_reason"
  :statusVariants="statusVariants"
  defaultVariant="info"
)
</template>

<script>
import StatusBadge from "./status-badge.vue";

export default {
  components: {
    StatusBadge,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    statusVariants() {
      return {
        not_suggested: "warning",
        processed: "success",
        ready_for_processing: "info",
        suggested: "warning",
        failed: "danger",
      };
    },
  },
};
</script>
