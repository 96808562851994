import _ from 'lodash';

class SuperAdmin {
  constructor(p = {}) {
    this.id = _.get(p, 'id', null);
    this.email = _.get(p, 'email', null);
    this.name = _.get(p, 'name', '');
  }
}

export default SuperAdmin;
