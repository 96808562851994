<template lang="pug">
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          h3.title {{$t('admin_tools.title') }}
        tabs(
          :tabs="tabs"
          :selected="activeTab"
          @select="selectTab"
        )
        transition(name="fade" mode="out-in")
          component(:is="tabContent")
</template>

<script>
import LogsTab from "@/views/AdminTools/Tabs/company_settings_logs.vue";
import RelearnTab from "@/views/AdminTools/Tabs/relearn.vue";
import ActionLogsTab from "@/views/AdminTools/Tabs/action_logs.vue";
import IntegrationListTab from "@/views/AdminTools/Tabs/integration_list.vue";
import FailedVendorsTab from "@/views/AdminTools/Tabs/failed_vendors.vue";
import CompanyUsers from "@/views/AdminTools/Tabs/company_users.vue";
import MachineLearning from "@/views/AdminTools/Tabs/machine_learning.vue";
import CompaniesApproval from "@/views/AdminTools/Tabs/companies_approval.vue";
import DigiaUITab from "@/views/AdminTools/Tabs/digia.vue";

const RELEARN_TAB = "relearn";
const LOGS_TAB = "logs";
const ACTION_LOGS_TAB = "action_logs";
const INTEGRATION_LIST_TAB = "integration_list";
const FAILED_VENDORS_TAB = "failed_vendors";
const COMPANY_USERS_TAB = "company_users";
const MACHINE_LEARNING_TAB = "machine_learning";
const COMPANIES_APPROVAL = "companies_approval";
const DIGIA_UI = "digia";

export default {
  computed: {
    activeTab() {
      return this.$route.query.tab || ACTION_LOGS_TAB;
    },

    tabs() {
      return [
        {
          id: ACTION_LOGS_TAB,
          label: this.$t("admin_tools.tabs.logs"),
        },
        {
          id: RELEARN_TAB,
          label: this.$t("admin_tools.tabs.relearn"),
        },
        {
          id: LOGS_TAB,
          label: this.$t("admin_tools.tabs.company_logs"),
        },
        {
          id: INTEGRATION_LIST_TAB,
          label: this.$t("admin_tools.tabs.integration_list"),
        },
        {
          id: FAILED_VENDORS_TAB,
          label: this.$t("admin_tools.tabs.failed_vendors"),
        },
        {
          id: COMPANY_USERS_TAB,
          label: this.$t("admin_tools.tabs.company_users"),
        },
        {
          id: MACHINE_LEARNING_TAB,
          label: this.$t("admin_tools.tabs.machine_learning"),
        },
        {
          id: COMPANIES_APPROVAL,
          label: this.$t("admin_tools.tabs.companies_approval"),
        },
        {
          id: DIGIA_UI,
          label: this.$t("admin_tools.tabs.digia"),
        },
      ];
    },

    tabContent() {
      const tab = this.activeTab;

      if (tab === RELEARN_TAB) return RelearnTab;
      if (tab === LOGS_TAB) return LogsTab;
      if (tab === ACTION_LOGS_TAB) return ActionLogsTab;
      if (tab === INTEGRATION_LIST_TAB) return IntegrationListTab;
      if (tab === FAILED_VENDORS_TAB) return FailedVendorsTab;
      if (tab === COMPANY_USERS_TAB) return CompanyUsers;
      if (tab === MACHINE_LEARNING_TAB) return MachineLearning;
      if (tab === COMPANIES_APPROVAL) return CompaniesApproval;
      if (tab === DIGIA_UI) return DigiaUITab;

      return null;
    },
  },

  methods: {
    selectTab(tab_id) {
      this.$router.push({
        query: {
          ...this.$route.query,
          tab: tab_id,
        },
      });
    },
  },
};
</script>
