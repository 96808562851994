<template lang="pug">
  .container
    table.table
      thead
        tr
          th Type
          th Heading
          th Description
          th Timestamp
          th Username
      tbody()
        tr(v-for="(item, field) in data" :key="field")
          td() {{item['Type']}}
          td() {{item['Heading']}}
          td() {{item['Description']}}
          td() {{item['Timestamp']['Value']}}
          td() {{item['UserName']}}

</template>
<script>
export default {
  props: ['data'],
}
</script>