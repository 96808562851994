import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

import CompanyModule from './modules/company';
import IndexModule from './modules/index';

export const store = new Vuex.Store({
  modules: {
    index: IndexModule,
    company: CompanyModule
  }
});

if (store.getters.isAuthenticated) {
  const currentCompanyId = store.getters['company/currentCompanyId'];
  axios.defaults.headers.common['BiBook-Company-Id'] = currentCompanyId;
  axios.defaults.headers.common['FinanceRocks-Locale'] = store.getters['language'];
  if (currentCompanyId !== null) {
    store.dispatch('company/fetchCompany');
  }
}

export default store;
