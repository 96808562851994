import locales from '@/localization/locales';
import { mapGetters } from 'vuex';
import eventBus from "@/eventBus";

const LocaleMixin = {
  computed: {
    ...mapGetters(['currentLanguage']),

    languages() {
      return locales;
    }
  },

  methods: {
    switchLanguage(locale) {
      this.$store.dispatch('switchLanguage', locale);
      eventBus.$emit('LocaleChanged', locale);
    }
  }
};

export default LocaleMixin;
