<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.sales_invoices') }}
        stack-item
          b-form-group(
            :label="$t('companies.sales_invoices.status_filter')"
            label-for="name-input"
            label-cols="4"
          )
            b-select(
              v-model="search_params.status"
              id="status-input"
              :options="statesOptions"
              label="text"
              @change="reload()"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.sales_invoices.search')"
            label-for="keyword-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.keyword"
              type="search"
              id = "keyword-input"
              @change="reload()"
            )
        stack-item
          b-btn(variant="primary", squared, @click="restartAll")
            .mdi.mdi-restart
            | {{ $t('companies.sales_invoices.actions.restart') }}
        stack-item
          b-btn(variant="danger", squared, @click="deleteFailed")
            .mdi.mdi-delete
            | {{ $t('companies.sales_invoices.actions.delete') }}
        stack-item
          b-btn(variant="warning", squared, @click="deleteProcessed")
            .mdi.mdi-delete
            | {{ $t('companies.sales_invoices.actions.delete_processed') }}
        stack-item
          b-btn(variant="primary", squared, @click="exportFailed")
            .mdi.mdi-download
            | {{ $t('companies.sales_invoices.actions.export_failed') }}
      rest-table(
        namespace="sales_invoices",
        :headers="headers",
        ref="salesInvoicesTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_invoice_number" slot-scope="{ item }")
          b-link(
            @click="showLines(item)"
          )
            | {{ item.invoice_number }}
        template(slot="cel_status" slot-scope="{ item }")
          b-button(
            pill
            class="uppercase"
            :variant="STATUSES[item.status] || 'info'"
            v-b-tooltip.focus
            :title="`Status reason: \"${item.status_reason}\"`"
          )
            | {{ $t(`companies.sales_invoices.statuses.${item.status}`) }}
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteInvoice(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
  show-sales-invoice-modal(
    ref="ShowSalesInvoiceModal"
  )
  scope-vouchers-tab
</template>

<script>
import ApiScopeSalesInvoices from "@/api/scopeSalesInvoices.js";
import ShowSalesInvoiceModal from "@/modals/Companies/showSalesInvoice.vue";
import ScopeVouchersTab from "@/views/Companies/Tabs/scope_vouchers.vue";

const STATUSES = {
  draft: "info",
  processing: "warning",
  customer_created: "warning",
  processed: "success",
  failed: "danger",
};

export default {
  components: {
    ShowSalesInvoiceModal,
    ScopeVouchersTab,
  },
  data() {
    return {
      inProgress: false,
      search_params: {
        status: "",
        keyword: "",
      },
      pagination: {
        sortBy: null,
        sortOrder: null,
      },
      states: [],
      STATUSES: STATUSES,
      headers: [
        {
          key: "id",
          label: "#",
          squeeze: true,
        },
        {
          key: "job_id",
          label: "Job id",
          squeeze: true,
        },
        {
          key: "invoice_number",
          label: "Invoice number",
          squeeze: false,
        },
        {
          key: "status",
          label: "Status",
          squeeze: false,
          sortable: true,
        },
        {
          key: "status_reason",
          label: "Status Reason",
          squeeze: false,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    };
  },

  methods: {
    reload() {
      this.$refs.salesInvoicesTable.fetchData();
    },

    getData(pagination) {
      this.inProgress = true;
      const search_params = this.search_params;
      return new Promise((res) => {
        ApiScopeSalesInvoices.fetchInvoices(
          this.$route.params.id,
          search_params,
          pagination
        )
          .then((resp) => {
            res({
              items: resp.data.scope_sales_invoices,
              meta: resp.data.meta,
            });
          })
          .catch((e) => {
            this.inProgress = false;
          });

        ApiScopeSalesInvoices.fetchStates(this.$route.params.id).then(
          (resp) => {
            this.states = resp.data.states;
          }
        );
      });
    },

    restartAll() {
      ApiScopeSalesInvoices.restartInvoices(this.$route.params.id).then(
        (resp) => {
          this.reload();
        }
      );
    },

    deleteFailed() {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("companies.sales_invoices.actions.confirm_delete"),
        variant: "danger",
        icon: "lan-disconnect",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiScopeSalesInvoices.deleteFailedInvoices(this.$route.params.id).then(
          (resp) => {
            this.reload();
          }
        );
      });
    },

    deleteProcessed() {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("companies.sales_invoices.actions.confirm_delete"),
        variant: "danger",
        icon: "lan-disconnect",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiScopeSalesInvoices.deleteProcessedInvoices(
          this.$route.params.id
        ).then((resp) => {
          this.reload();
        });
      });
    },

    exportFailed() {
      ApiScopeSalesInvoices.exportFailed(this.$route.params.id)
        .then((resp) => {
          let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", `failed_invoices.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((e) => {});
    },

    showLines(invoice) {
      this.$refs.ShowSalesInvoiceModal.open(invoice.id);
    },

    deleteInvoice(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "lan-disconnect",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiScopeSalesInvoices.deleteInvoice(
          this.$route.params.id,
          item.id
        ).then((resp) => {
          this.reload();
        });
      });
    },
  },

  mounted() {
    this.getData();
  },

  computed: {
    statesOptions() {
      let first_element = { text: "All Invoices", value: null };
      let options = this.states.map((i) => {
        return { text: i[0], value: i[1] };
      });
      options.unshift(first_element);
      return options;
    },
  },
};
</script>
