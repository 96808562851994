<template lang="pug">
.header-void
  .header
    .header__container(
      :class="headerClasses"
    )
      .header__side
        transition(name="fade" mode="out-in")
          router-link.logotype.mr-3(v-if="!$route.meta.sidebar" :to="{ name: 'Root' }")

        language-switcher
        transition(name="fade" mode="out-in")
          template(v-if="isAuthenticated && !$route.meta.sidebar")
            .ml-3.d-flex.h-100
              router-link.header-button.header-nav-item(
                v-for="link in navItems"
                :key="`${link.id}`"
                :label="link.label"
                :to="link.url"
              ) 
                .header-button__prepend(v-if="link.icon")
                  .mdi.header-button__icon(:class="`mdi-${ link.icon }`")
                .header-button__content
                  .header-button__label {{ link.label }}

      .header__side
        template(v-if="isAuthenticated")
          header-dropdown(
            :label="user.name || `User #${user.id}`"
            :subtitle="user.email"
            alignment="right"
          )
            avatar-box(
              slot="prepend"
              :src="user.avatar"
              :label="user.name || user.email"
              size="sm"
            )
            div(slot="dropdown")
              mini-profile
        template(v-else)
          form.auth-bitton-form(
            v-for="link in oauth2Providers"
            :key="`login-link-${link.slug}`"
            :action="link.url"
            method="post"
          )
            input(type="hidden" :value="csrfToken" name="authenticity_token")
            header-button(
              :label="link.label"
              :subtitle="$t('user.login_with')"
              subtitle-top
              type="submit"
            )
              img.header-button__icon(
                slot="prepend"
                :src="link.icon"
              )
</template>

<script>
import oauth2Providers from "@/common/login_providers";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import CompanySwitcher from "@/components/CompanySwitcher.vue";
import MiniProfile from "@/components/MiniProfile.vue";
import HeaderButton from "./HeaderButton.vue";
import HeaderDropdown from "./HeaderDropdown.vue";
import { mapGetters } from "vuex";
import Company from "../../models/Company.js";

export default {
  components: {
    MiniProfile,
    HeaderButton,
    HeaderDropdown,
    CompanySwitcher,
    LanguageSwitcher
  },

  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
    ...mapGetters("company", ["currentCompany", "hasCompanies"]),

    navItems() {
      const menu = [
        {
          id: "companies",
          url: "/companies",
          label: this.$t("menu.companies"),
          icon: "domain",
          allowedRoles: Company.role.ADMIN
        },
        {
          id: "resellers",
          url: "/resellers",
          label: this.$t("menu.resellers"),
          icon: "storefront"
        },
        {
          id: "super_admins",
          url: "/super_admins",
          label: this.$t("menu.super_admins"),
          icon: "account-cog"
        },
        {
          id: 'automation',
          url: '/automation',
          label: this.$t('menu.automation'),
          icon: 'auto-fix'
        },
        {
          id: "admin_tools",
          url: "/admin_tools",
          label: "menu.admin_tools",
          icon: "wrench-outline",
          allowedRoles: ["super_admin"]
        }
      ];

      return menu.filter(i => {
        // if (i.id === 'dataroom' && !this.currentCompany.dataroom_enabled) {
        //   return false;
        // }
        // if (!i.allowedRoles) {
        //   return true;
        // }
        // if (_.isString(i.allowedRoles) && i.allowedRoles === this.currentCompany.role) {
        //   return true;
        // }
        // if (_.isArray(i.allowedRoles) && i.allowedRoles.some(i => i === this.currentCompany.role)) {
        //   return true;
        // }
        return true;
      });
    },

    headerClasses() {
      return {
        container: !this.isAuthenticated,
        "container-fluid": this.isAuthenticated
      };
    },

    oauth2Providers() {
      return oauth2Providers;
    },

    csrfToken() {
      return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    }
  },

  methods: {
    login(link) {
      this.$store.dispatch("login", {
        oauth2: link.slug
      });
    },

    logout() {
      this.$store.dispatch("logout");
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/stylesheet/_variables.scss";

.auth-bitton-form {
  align-self: stretch;

  & + & {
    margin-left: -1px;
   
  }
}
</style>