
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div(v-if="isInvite")
      .modal-title {{ $t('companies.users.invite_title') }}
      .modal-subtitle {{ $t('companies.users.invite_description') }}
    div(v-else)
      .modal-title {{ $t('companies.users.edit_title') }}
      .modal-subtitle {{ $t('companies.users.edit_description') }}

  b-form-group(
    :label="$t('admin_users.company')",
    label-for="company-input",
    label-cols="4"
    :invalid-feedback="errors.first('company_id')"
    :state="errors.has('company_id') ? false : null"
  )
    Dropdown(
      v-if="isInvite"
      v-validate="'required'",
      data-vv-name="company_id"
      :data-vv-as="$t('admin_users.company')"
      :options='companies'
      @selected='validateSelection'
      :disabled='!this.isInvite'
      placeholder='Select a company'
      :default="{id: 1485, name: 'Avara'}"
      )
    b-form-input(
      v-else
      v-model="company_name"
      :disabled="true"
    )

  b-form-group(
    :label="$t('companies.users.name')",
    label-for="name-input",
    label-cols="4"
    :invalid-feedback="errors.first('name')"
    :state="errors.has('name') ? false : null"
  )
    b-input#name-input(
      v-model="form.name"
      v-validate="'required'"
      data-vv-name="name"
      :data-vv-as="$t('companies.users.name')"
    )

  b-form-group(
    :label="$t('companies.users.email')",
    label-for="email-input",
    label-cols="4"
    :invalid-feedback="errors.first('email')"
    :state="errors.has('email') ? false : null"
  )
    b-input#email-input(
      v-model="form.email",
      v-validate="'required|email'"
      data-vv-name="email"
      :data-vv-as="$t('companies.users.email')"
      type="email"
    )

  b-form-group(
    :label="$t('companies.users.role')",
    label-for="role-input",
    label-cols="4"
    :invalid-feedback="errors.first('role')"
    :state="errors.has('role') ? false : null"
  )
    b-select#role-input(
      v-validate="'required'"
      data-vv-name="role"
      :data-vv-as="$t('companies.users.role')"
      v-model="form.role",
      :options="rolesOptions",
    )

  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
