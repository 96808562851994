<template lang="pug">
.container
  b-row
    b-col
      target-verificators(:target="this.target", :target_id="this.target_id")
    b-col
      target-approver(:target="this.target", :target_id="this.target_id")
</template>

<script>
import TargetApprover from "@/views/Approvers/approver.vue"
import TargetVerificators from "@/views/Approvers/verificators.vue"


export default {
  props: ['target', 'target_id'],

  components: {
    TargetApprover,
    TargetVerificators
  },

}
</script>

