const companies = state => state.companies;
const hasCompanies = state => !!state.companies.length;
const currentCompany = state => state.companies.find(c => c.id === state.currentCompanyId) || null;
const currentCompanyId = state => state.currentCompanyId || null;
const company = state => state.company;
const companyInProgress = state => state.inProgress;

export default {
  company,
  companies,
  companyInProgress,
  hasCompanies,
  currentCompany,
  currentCompanyId
};
