<template lang="pug">
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div
      .modal-title {{ $t('admin_users.import_title') }}
      .modal-subtitle {{ $t('admin_users.import_description') }}
  p 
    a(href="/import_users_example.xlsx", target="_blank") {{ $t('companies.file_example') }}
  b-form-file(
    v-model="file",
    :state="Boolean(file)",
    placeholder="Choose a file or drop it here...",
    drop-placeholder="Drop file here...",
    accept=".xlsx"
  )

  template(slot="modal-footer")
    b-btn(
      :disabled="!Boolean(file)",
      variant="primary",
      squared,
      @click="upload"
    ) 
      .mdi.mdi-upload
      | {{ $t('actions.upload') }}
</template>

<script>
import ApiAdminCompanyUsers from "@/api/admin_company_users.js"

export default {
  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      show: false,
      file: null,
    };
  },

  methods: {
    open() {
      this.show = true;
    },

    close() {
      this.show = false;
    },

    upload() {
      let formData = new FormData();
      formData.append("file", this.file);
      ApiAdminCompanyUsers.importUsers(formData)
        .then((resp) => {
          this.$emit("done");
          this.close();
        })
        .catch((e) => {});
    },
  },
};
</script>
