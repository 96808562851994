<template lang="pug">
  .pb-5
    .page-hero
      h3.pl-3.pb-0.pt-0 {{reseller.name}}
    .container
      b-card(no-body)
        tabs(
          :tabs="tabs"
          :selected="selected"
          @select="selectTab"
        )
        transition(name="fade" mode="out-in")
          component(:is="tabContent")
  
</template>
<script>
import { mapGetters } from "vuex";
import ApiResellers from "@/api/resellers.js";
import BasicTab from "@/views/Resellers/Tabs/basic.vue";
import PricingTab from "@/views/Resellers/Tabs/pricing.vue";
import UsersTab from "@/views/Resellers/Tabs/users.vue";
import StatisticsTab from "@/views/Resellers/Tabs/statistics.vue";
import CompaniesTab from "@/views/Resellers/Tabs/companies.vue";
import ErrorsTab from "@/views/Resellers/Tabs/errors.vue";
import GroupsTab from "@/views/Resellers/Tabs/groups.vue";

const BASIC_TAB = "basic";
const USERS_TAB = "users";
const STATISTICS_TAB = "statistics";
const PRICING_TAB = "pricing";
const ERRORS_TAB = "errors";
const COMPANIES_TAB = "companies";
const GROUPS_TAB = "groups";

export default {
  data() {
    return {
      selected: this.$route.query.tab || BASIC_TAB,
      reseller: {}
    };
  },

  watch: {
    $route(r) {
      const tab = r.query.tab;
      this.selected = tab ? tab : BASIC_TAB;
    }
  },

  computed: {
    tabs() {
      return [
        {
          id: BASIC_TAB,
          label: this.$t("resellers.tabs.basic")
        },
        {
          id: USERS_TAB,
          label: this.$t("resellers.tabs.users")
        },
        {
          id: STATISTICS_TAB,
          label: this.$t("resellers.tabs.statistics")
        },
        {
          id: PRICING_TAB,
          label: this.$t("resellers.tabs.pricing")
        },
        {
          id: ERRORS_TAB,
          label: this.$t("resellers.tabs.errors")
        },
        {
          id: COMPANIES_TAB,
          label: this.$t("resellers.tabs.companies")
        },
        {
          id: GROUPS_TAB,
          label: this.$t("resellers.tabs.groups")
        }
      ];
    },

    tabContent() {
      const tab = this.selected;

      if (tab === BASIC_TAB) return BasicTab;
      if (tab === PRICING_TAB) return PricingTab;
      if (tab === USERS_TAB) return UsersTab;

      if (tab === COMPANIES_TAB) return CompaniesTab;
      if (tab === STATISTICS_TAB) return StatisticsTab;
      if (tab === ERRORS_TAB) return ErrorsTab;
      if (tab === GROUPS_TAB) return GroupsTab;
      return null;
    }
  },

  methods: {
    selectTab(tab_id) {
      this.selected = tab_id;
      this.$router.push({
        query: {
          ...this.$route.query,
          tab: tab_id
        }
      });
    },
    fetchData() {
      ApiResellers.getReseller(this.$route.params.id).then(resp => {
        this.reseller = resp.data.reseller;
      });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
