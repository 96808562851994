import axios from "axios";

const fetchAll = (params) => {
  return axios.get("/api/v1/digia_customers", { params });
};

const restart = (params) => {
  return axios.post("/api/v1/digia_customers/restart", params);
};

const ApiDigiaCustomers = {
  fetchAll,
  restart,
};

export default ApiDigiaCustomers;
