<template lang="pug">
.ui-tabs(ref="root" :class="classes")
    ul.ui-tabs__list.ui-tabs__list-visible
        li.ui-tabs__tab(v-for="tab in visibleTabs" :key="tab.id")
            UiTabsTab(
                :badge="tab.badge"
                :badgeVariant="tab.badgeVariant"
                @click="select(tab)"
                :selected="tab.id === selected"
                :disabled="tab.disabled"
            ) {{ tab.label }}
        li.ui-tabs__tab(v-if="shouldHidden")
            b-dropdown.ui-tabs__dropdown(
                boundary="window"
                size="lg" 
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
            )
                template(#button-content)
                    UiTabsTab(className="ui-tabs__tab-other")
                        .mdi.mdi-dots-horizontal
                b-dropdown-item(
                    v-for="tab in hiddenTabs"
                    :key="tab.id"
                    @click="select(tab)"
                    href="#"
                ) {{ tab.label }}
    ul.ui-tabs__list.ui-tabs__list-hidden
        li.ui-tabs__tab(
            v-for="tab in tabs"
            :key="tab.id"
            ref="tabs"
        )
            UiTabsTab(
                :badge="tab.badge"
                :badgeVariant="tab.badgeVariant"
                :selected="tab.id === selected"
                :disabled="tab.disabled"
            ) {{ tab.label }}
        li.ui-tabs__tab(ref="other")
            UiTabsTab(className="ui-tabs__tab-other")
                .mdi.mdi-dots-horizontal
</template>

<script>
import UiTabsTab from './ui-tabs-tab.vue';

export default {
    components: {
        UiTabsTab,
    },
    emits: ['select'],
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        selected: {
            type: [Number, String],
            required: true,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        skin: {
            type: String,
            default: 'default',
        },
    },
    data() {
        return {
            observer: null,
            otherSize: 0,
            rootSize: 1000,
            tabsSizes: [],
        };
    },
    watch: {
        tabs() {
            this.$nextTick(() => {
                this.recomputeTabSizes();
            });
        },
    },
    methods: {
        select(tab) {
            if (tab.disabled) {
                return;
            }
            this.$emit('select', tab.id);
        },
        recomputeTabSizes() {
            this.rootSize = this.$refs.root?.clientWidth ?? 0;
            this.otherSize = this.$refs.other?.clientWidth ?? 0;
            this.tabsSizes = this.$refs.tabs.map((el) => el.clientWidth);
        },
    },
    computed: {
        classes() {
            return {
                ['ui-tabs--full-width']: this.fullWidth || this.shouldFullWidth,
            }
        },
        tabsWidth() {
            return this.tabsSizes.reduce((a, b) => a + b, 0);
        },
        shouldHidden() {
            return this.tabsWidth > this.rootSize;
        },
        shouldFullWidth() {
            return this.tabsWidth + 10 > this.rootSize;
        },
        fitWidth() {
            return this.rootSize - this.otherSize;
        },
        activeIndex() {
            return this.tabs.findIndex((tab) => tab.id === this.selected);
        },
        lastVisibleIndex() {
            let lastVisibleIndex = 0;
            let widthAcc = 0;
            for (let index = 0; index < this.tabsSizes.length; index++) {
                lastVisibleIndex = index;
                const newWidthAcc = widthAcc + this.tabsSizes[index];
                if (newWidthAcc > this.fitWidth) {
                    break;
                }
                widthAcc = newWidthAcc;
            }
            return lastVisibleIndex;
        },
        isActiveVisible() {
            return this.activeIndex >= 0 ? this.activeIndex < this.lastVisibleIndex : true;
        },
        visibleTabs() {
            if (!this.shouldHidden) {
                return this.tabs;
            }

            if (this.isActiveVisible) {
                return this.tabs.slice(0, this.lastVisibleIndex);
            }

            const tabs = [];

            for (let index = 0; index < this.tabsSizes.length; index++) {
                const width =
                    this.tabsSizes[this.activeIndex] +
                    this.tabsSizes.slice(0, index + 1).reduce((acc, i) => acc + i, 0);
                if (width >= this.fitWidth) {
                    break;
                }
                tabs.push(this.tabs[index]);
            }

            return [...tabs, this.tabs[this.activeIndex]];
        },
        hiddenTabs() {
            return this.tabs.filter((tab) => !this.visibleTabs.includes(tab));
        },
    },
    mounted() {
        this.observer = new ResizeObserver(() => {
            this.recomputeTabSizes();
        });
        this.observer.observe(this.$refs.root);
        this.recomputeTabSizes();
    },
    beforeUnmount() {
        if (this.$refs.root) {
            this.observer?.unobserve(this.$refs.root);
        }
    },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/_variables.scss';

.ui-tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid $bb-gray-900;

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: nowrap;
        position: relative;
    }

    &__tab {
        &-other {
            min-width: 0;
        }
    }

    &--full-width &__list-visible &__tab {
        flex-grow: 1;
        flex-shrink: 1;
    }

    &__list-hidden {
        height: 0;
        overflow: hidden;
    }

    &--light {
        border-bottom: 1px solid var(--bb-gray-900);
    }

    &__dropdown .btn {
        padding: 0;
        margin: 0;
        border: 0 solid transparent;

        &:focus {
            outline: none;
        }
    }

    &__tab-other {
        font-size: 24px;
    }
}
</style>
