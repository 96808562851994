<template lang="pug">
  .sidebar
    .sidebar-section.sidebar-header
        router-link.logotype.light(:to="{ name: 'Root' }")
    .sidebar-section.sidebar-body
      .sidebar-body-container
        .sidebar-nav.pt-3
          sidebar-nav-item(
            v-for="item in navItems" 
            :key="item.id"
            :to="item.url" 
            :icon="item.icon"
          ) {{ item.label }}
</template>

<script>
import SidebarNavItem from "./SidebarNavItem.vue";
import { mapGetters } from "vuex";

const navItems = [
  {
    id: "companies",
    url: "/companies",
    label: "menu.companies",
    icon: "domain",
    allowedRoles: ["super_admin", "reseller", "user"]
  },
  {
    id: "resellers",
    url: "/resellers",
    label: "menu.resellers",
    icon: "storefront",
    allowedRoles: ["super_admin"]
  },
  {
    id: "billing",
    url: "/billing",
    label: "menu.billing",
    icon: "cash-register",
    allowedRoles: ["super_admin", "reseller"]
  },
  {
    id: "super_admins",
    url: "/super_admins",
    label: "menu.super_admins",
    icon: "account-cog",
    allowedRoles: ["super_admin"]
  },
  {
    id: "automation",
    url: "/automation",
    label: "menu.automation",
    icon: "auto-fix",
    allowedRoles: ["super_admin", "reseller"]
  },
  {
    id: "admin_tools",
    url: "/admin_tools",
    label: "menu.admin_tools",
    icon: "wrench-outline",
    allowedRoles: ["super_admin"]
  },
  {
    id: "admin_users",
    url: "/admin_users",
    label: "menu.users",
    icon: "account",
    allowedRoles: ["super_admin"]
  }
];

export default {
  components: {
    SidebarNavItem
  },

  computed: {
    ...mapGetters(["isAuthenticated", "user"]),

    navItems() {
      return navItems
        .map(item => ({
          ...item,
          label: this.$t(item.label)
        }))
        .filter(item => {
          return !!user;
        })
        .filter(item => {
          return item.allowedRoles.includes(user.role) && !(user.role === "user" && user.companies.length === 0);
        });
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@/assets/stylesheet/_variables.scss";
@import "@/assets/stylesheet/_functions.scss";

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @include gradient-directional(rgb(54, 62, 68), rgb(29, 29, 29), 211deg);

  & .logotype {
    width: 180px;
  }
}

.sidebar-section {
  width: 100%;
}

.sidebar-header {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.sidebar-footer {
  flex-shrink: 0;
}

.sidebar-body {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;

  &-container {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>