<template lang="pug">
  .form-container
    stack
      stack-item
        b.mb-0 {{ $t('companies.approvers.min_approve_price') }}
      stack-item
        b-form-input(
          v-model="default_price_limit",
          :placeholder="$t('companies.approvers.price_in_cents')",
          :state="ifPriceValidator",
          v-b-tooltip.focus
          :title="$t('companies.approvers.default_price_tooltip')"
        )
        b-form-invalid-feedback(
          :state="ifPriceValidator"
        )
          | Price in cents
      stack-item
        b-btn(
          variant="primary"
          @click="save"
        )
         | Save
</template>

<script>
import ApiCompanyFeatures from "@/api/companyFeatures.js";

export default {
  data() {
    return {
      default_price_limit: '',
      feature_id: ''
    }
  },

  methods: {
    getPrice() {
      ApiCompanyFeatures.fetchSettings(this.$route.params.id).then(
          (resp) => {
            this.feature_id = resp.data.company_feature.id
            this.default_price_limit = resp.data.company_feature.settings.default_price_limit
          }
      )
    },

    save() {
      let params = { feature: { settings: { default_price_limit: this.default_price_limit }}}
      ApiCompanyFeatures.updateFeature(this.$route.params.id, this.feature_id, params)
    }
  },

  computed: {
    ifPriceValidator() {
      return this.default_price_limit >>> 0 === parseFloat(this.default_price_limit) || this.default_price_limit === '' || this.default_price_limit === null
    },
  },

  mounted() {
    this.getPrice();
  }
}
</script>

<style scoped>

</style>