<template lang="pug">
.py-5
  .container.text-center
    .h3 {{ $t('home.please_login') }}
    p.text-secondary {{ $t('home.please_login_description') }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user", "company"]),
    ...mapGetters("company", ["company"]),

    currentUser() {
      return this.user || "Unauthorized";
    },

    currentCompany() {
      return this.company || "Unset";
    }
  }
};
</script>