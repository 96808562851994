
.empty-state
  .empty-state__body
    slot(name="body")
      .empty-state__title(v-if="title") {{ title }}
      .empty-state__description(v-if="description") {{ description }}
      .empty-state__actions(v-if="hasActionsSlot")
        slot(name="actions")
  .empty-state__image-container
    img.empty-state__image(
      :src="image"
      :class="imageClasses"
    )
