import axios from 'axios';

const fetchFeatures = (company_id) => {
  return axios.get(`/api/v1/companies/${company_id}/features`);
};

const updateFeature = (company_id, feature_id, feature) => {
  return axios.patch(`/api/v1/companies/${company_id}/features/${feature_id}`, feature);
};

const uploadMatchFile = (company_id, form_data) => {
  return axios.post(`/api/v1/companies/${company_id}/features/upload_codes`, form_data);
};

const downloadMatchFile = (company_id) => {
  return axios({
    url:`/api/v1/companies/${company_id}/features/download_codes`,
    method: 'GET',
    responseType: 'blob'
  });
};

const uploadReplacementFile = (form_data) => {
  return axios.post(`/api/v1/replacement_codes/upload`, form_data);
};

const clearReplacementFile = (form_data) => {
  return axios.post(`/api/v1/replacement_codes/clear`, form_data);
};

const fetchSettings = (company_id) => {
  return axios.get(`/api/v1/companies/${company_id}/features/invoice_guesser_settings`);
}

// const fetchReplacementList = (company_id) => {
//   return axios.get(`/api/v1/companies/${company_id}/features`);
// };

const ApiCompanyFeatures = {
  fetchFeatures,
  updateFeature,
  uploadMatchFile,
  downloadMatchFile,
  uploadReplacementFile,
  clearReplacementFile,
  fetchSettings
};

export default ApiCompanyFeatures;
