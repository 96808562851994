<template lang="pug">
data-table(
  :embed="embed",
  :progress="progress",
  :headers="headers",
  :items="itemsList",
  :sort-by="pagination.sort_by",
  :sort-order="pagination.sort_order",
  :page="Number(pagination.page)",
  :per-page="Number(pagination.per_page)",
  :search="pagination.search",
  :per-page-options="perPageOptions",
  :total-items="totalItem",
  :total-pages="totalPages",
  :disable-pagination="disablePagination"
  @sort="onSort",
  @paginate="onPaginate",
  @search="onSearch"
)
  template(
    v-for="col in headers",
    :slot="`cel_${col.key}`",
    slot-scope="props"
  )
    slot(:name="`cel_${col.key}`", v-bind="props")
</template>

<script>
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import Pagination from "../../models/Pagination";

export default {
  props: {
    namespace: {
      type: String,
      required: true,
    },

    storePath: {
      type: String,
      default: null,
    },

    singular: {
      type: String,
      default: null,
    },

    plural: {
      type: String,
      default: null,
    },

    embed: {
      type: Boolean,
      default: false,
    },

    headers: {
      type: Array,
      required: true,
    },

    perPageOptions: {
      type: Array,
      default: null,
    },

    fetchHandler: {
      type: Function,
      default: null,
    },
    sortBy: {
      type: String,
      default: null,
    },
    sortOrder: {
      type: String,
      default: "asc",
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inProgress: false,
      pagination: new Pagination({
        search: "",
        sort_by: this.sortBy,
        sort_order: this.sortOrder || "asc",
        page: 1,
        per_page: 10,
      }),
      items: [],
      meta: {
        total_count: 0,
        total_pages: 0,
      },
    };
  },

  created() {
    const table = this.$route.query[`table_${this.namespace}`];
    if (!table) return;
    this.pagination = new Pagination(JSON.parse(table));
  },

  mounted() {
    this.fetchData();
  },

  beforeDestroy() {
    const query = { ...this.$route.query };
    delete query[`table_${this.namespace}`];
    this.$router.replace({ query });
  },

  watch: {
    $route(r) {
      const table = r.query[`table_${this.namespace}`];
      if (!table) return;
      this.pagination = new Pagination(JSON.parse(table));
      this.fetchData();
    },
  },

  computed: {
    isStored() {
      return !!this.storePath;
    },

    totalItem() {
      return this.isStored
        ? this.$store.getters[`${this.storePath}/meta`].total_count
        : this.meta.total_count;
    },

    totalPages() {
      return this.isStored
        ? this.$store.getters[`${this.storePath}/meta`].total_pages
        : this.meta.total_pages;
    },

    itemsList() {
      return this.isStored
        ? this.$store.getters[`${this.storePath}/items`]
        : this.items;
    },

    progress() {
      return this.isStored
        ? this.$store.getters[`${this.storePath}/inProgress`]
        : this.inProgress;
    },
  },

  methods: {
    fetchData() {
      this.inProgress = true;

      const { filter: filterOptions, ...paginationOption } = this.pagination;
      const fetchParams = this.filtered
        ? { ...paginationOption, ...filterOptions }
        : paginationOption;
      if (this.isStored) {
        this.$store.dispatch(`${this.storePath}/fetchList`, fetchParams);
        return;
      }
      this.fetchHandler(fetchParams)
        .then((resp) => {
          this.items = resp.items;
          this.meta.total_count = resp.meta.total_count || 0;
          this.meta.total_pages = resp.meta.total_pages || 0;
          this.inProgress = false;
        })
        .catch((e) => {
          this.inProgress = false;
        });
    },

    onSort(options) {
      this.updateOptions({
        sort_by: options.sortBy,
        sort_order: options.sortOrder,
      });
    },

    onPaginate(options) {
      this.updateOptions({
        page: options.page,
        per_page: options.perPage,
        search: options.search,
      });
    },

    onSearch(search) {
      this.updateOptions({ search });
    },

    updateOptions(options) {
      this.pagination = new Pagination({
        ...this.pagination,
        ...options,
      });
      this.$router.push({
        ...this.$router,
        query: {
          ...this.$route.query,
          [`table_${this.namespace}`]: JSON.stringify(this.pagination),
        },
      });
    },
  },
};
</script>
