
.ui-tabs(ref="root" :class="classes")
    ul.ui-tabs__list.ui-tabs__list-visible
        li.ui-tabs__tab(v-for="tab in visibleTabs" :key="tab.id")
            UiTabsTab(
                :badge="tab.badge"
                :badgeVariant="tab.badgeVariant"
                @click="select(tab)"
                :selected="tab.id === selected"
                :disabled="tab.disabled"
            ) {{ tab.label }}
        li.ui-tabs__tab(v-if="shouldHidden")
            b-dropdown.ui-tabs__dropdown(
                boundary="window"
                size="lg" 
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
            )
                template(#button-content)
                    UiTabsTab(className="ui-tabs__tab-other")
                        .mdi.mdi-dots-horizontal
                b-dropdown-item(
                    v-for="tab in hiddenTabs"
                    :key="tab.id"
                    @click="select(tab)"
                    href="#"
                ) {{ tab.label }}
    ul.ui-tabs__list.ui-tabs__list-hidden
        li.ui-tabs__tab(
            v-for="tab in tabs"
            :key="tab.id"
            ref="tabs"
        )
            UiTabsTab(
                :badge="tab.badge"
                :badgeVariant="tab.badgeVariant"
                :selected="tab.id === selected"
                :disabled="tab.disabled"
            ) {{ tab.label }}
        li.ui-tabs__tab(ref="other")
            UiTabsTab(className="ui-tabs__tab-other")
                .mdi.mdi-dots-horizontal
