import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locales from './locales';
import LocalStorage from '@/common/localstorage';

Vue.use(VueI18n);

export const FALLBACK_LANGUAGE = 'en';
export const DEFAULT_LANGUAGE = FALLBACK_LANGUAGE;
export const INITIAL_LANGUAGE = LocalStorage.get('language', DEFAULT_LANGUAGE, String);

export const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: INITIAL_LANGUAGE,
  fallbackLocale: FALLBACK_LANGUAGE,
  dateTimeFormats: locales.reduce((val, loc) => {
    if (!loc.dateTimeFormats) return val;
    val[loc.value] = loc.dateTimeFormats;
    return val;
  }, {}),
  messages: locales.reduce((val, loc) => {
    val[loc.value] = loc.localeApp;
    return val;
  }, {})
});

export const ValidatorDictionaries = locales.reduce((val, loc) => {
  val[loc.value] = loc.localeValidator;
  return val;
}, {});

export default i18n;
