
data-table(
  :embed="embed",
  :progress="progress",
  :headers="headers",
  :items="itemsList",
  :sort-by="pagination.sort_by",
  :sort-order="pagination.sort_order",
  :page="Number(pagination.page)",
  :per-page="Number(pagination.per_page)",
  :search="pagination.search",
  :per-page-options="perPageOptions",
  :total-items="totalItem",
  :total-pages="totalPages",
  :disable-pagination="disablePagination"
  @sort="onSort",
  @paginate="onPaginate",
  @search="onSearch"
)
  template(
    v-for="col in headers",
    :slot="`cel_${col.key}`",
    slot-scope="props"
  )
    slot(:name="`cel_${col.key}`", v-bind="props")
