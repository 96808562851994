<template lang="pug">
div
  .p-4(v-if="$route.query.dimension_item_id")
    DimensionItem(:dimension_item_id="$route.query.dimension_item_id")
  .p-4(v-else)
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              .h3.mb-0 {{ $t('companies.tabs.dimensions') }}
        .pt-3.px-3
          stack
            stack-item(fill)
              b-form-group(
                :label="$t('companies.dimensions.name')"
                label-for="company-id-input"
                label-cols="4"
              )
                b-form-input(
                  v-model="searchName",
                  type="search"
                  id="company-id-input"
                  @change="reloadList"
                )
        rest-table(
          embed,
          :headers="headers",
          :fetch-handler="getData",
          namespace="dimensions",
          ref="companyDimensionsTable",
        )
          template(slot="cel_not_suggest_dimension", slot-scope="{ item }")
            b-form-checkbox(
              v-model="item.not_suggest_dimension",
              switch,
              @change="changeNotSuggestDimension(item)"
            )
          template(slot="cel_data_priority",  slot-scope="{ item }")
            b-form-select(
              v-model="item.data_priority",
              :options="dataPriorityOptions",
              @change="changeDataPriority(item)"
            )
          template(slot="cel_not_suggest", slot-scope="{ item }")
            b-form-checkbox(
              v-model="item.not_suggest",
              switch,
              @change="changeNotSuggest(item)"
            )
          template(slot="cel_name_as_keyword", slot-scope="{ item }")
            b-form-checkbox(
              v-model="item.name_as_keyword",
              switch,
              @change="changeNameAsKeyword(item)"
            )
          template(slot="cel_if_account", slot-scope="{ item }")
            b-form-tags(
              size="sm",
              v-model="item.if_account",
              :placeholder="$t('companies.dimensions.accounts_prompt')",
              :invalid-tag-text="$t('companies.dimensions.invalid_account')",
              @change="changeIfAccount(item)",
              @input="changeIfAccount(item)",
              :tag-validator="ifAccountValidator"
            )
              template(v-slot:invalid-feedback)
                | You must provide at least 3 tags and no more than 8
          template(slot="cel_keywords", slot-scope="{ item }")
            b-form-tags(
              size="sm",
              v-model="item.keywords",
              :placeholder="$t('companies.dimensions.keywords_prompt')",
              @change="changeKeywords(item)",
              @input="changeKeywords(item)"
            )
          template(slot="cel_default_dimension_item", slot-scope="{ item }")
            b-form-checkbox(
              v-model="item.default",
              switch,
              @change="changeDefaultDimensionItem(item)"
            )
          template(slot="cel_row_custom_details", slot-scope="{ item }")
            b-form-select(
              v-model="item.row_custom_details",
              :options="rowCustomDetailsOptions",
              @change="changeRowCustomDetails(item)"
            )
          template(slot="cel_approver_id", slot-scope="{ item }")
            router-link(
              :to="{...$route, query: {...$route.query, dimension_item_id: item.id}}"
              label="item.name"
            )
              | Approvers
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiCompanyDimensions from "@/api/companyDimensions.js";
import DimensionItem from "@/views/DimensionItems/show.vue"

export default {
  components: {
    DimensionItem
  },

  data() {
    return {
      searchName: null,
      headers: [
        {
          key: "dimension_name",
          label: "Dimension",
          squeeze: false,
        },
        {
          key: "if_account",
          label: "Set dimension if account",
          squeeze: false,
        },
        {
          key: "not_suggest_dimension",
          label: "Don't suggest dimension",
          squeeze: false,
        },
        {
          key: "name_as_keyword",
          label: "Name as keyword",
          squeeze: false,
        },
        {
          key: "data_priority",
          label: "Data priority",
          squeeze: false,
        },
        {
          key: "name",
          label: "Dimension Item",
          squeeze: false,
        },
        {
          key: "not_suggest",
          label: "Don't suggest item",
          squeeze: false,
        },
        {
          key: "keywords",
          label: "Keywords",
          squeeze: false,
        },{
          key: "default_dimension_item",
          label: "Default",
          squeeze: false,
        },
        {
          key: "row_custom_details",
          label: "Custom Details",
          squeeze: false,
        },
        {
          key: "approver_id",
          label: "Approvers",
          squeeze: false,
        },
      ],
      dataPriorityOptions: [
        { value: "default", text: "Prioritise row based data" },
        { value: "finvoice_first", text: "Prioritise general invoice data" },
        { value: "finvoice_only", text: "Only use general invoice data" }
      ],
      rowCustomDetailsOptions: [
        { value: "", text: "Not used" },
        { value: "apartment", text: "Apartments" }
      ]
    };
  },

  methods: {
    reloadList() {
      this.$refs.companyDimensionsTable.fetchData();
    },
    getData(pagination) {
      const searchQ = this.searchName;
      return new Promise((res) => {
      return ApiCompanyDimensions.fetchDimensions(this.$route.params.id, pagination, searchQ)
        .then((resp) => {
          res({
            items: resp.data.dimensions,
            meta: resp.data.meta,
          });
        })
      })
    },
    changeNotSuggestDimension(item) {
      this.saveDimension(item.id, {
        dimension: { not_suggest_dimension: item.not_suggest_dimension },
      });
    },
    changeDefaultDimensionItem(item) {
      this.saveDimension(item.id, {
        dimension: { default: item.default },
      });
    },
    changeNotSuggest(item) {
      console.log(item.not_suggest)
      this.saveDimension(item.id, {
        dimension: { not_suggest: item.not_suggest },
      });
    },
    changeNameAsKeyword(item) {
      this.saveDimension(item.id, {
        dimension: { name_as_keyword: item.name_as_keyword },
      });
    },
    changeRowCustomDetails(item) {
      this.saveDimension(item.id, {
        dimension: { row_custom_details: item.row_custom_details },
      });
    },

    changeDataPriority(item){
      this.saveDimension(item.id, {
        dimension: { data_priority: item.data_priority },
      });
    },

    changeIfAccount(item) {
      this.saveDimension(item.id, {
        dimension: { if_account: item.if_account },
      });
    },

    changeKeywords(item) {
      this.saveDimension(item.id, { dimension: { keywords: item.keywords } });
    },

    saveDimension(id, params) {
      ApiCompanyDimensions.updateDimension(
        this.$route.params.id,
        id,
        params
      ).then((resp) => {
        this.getData();
      });
    },

    ifAccountValidator(account) {
      return (
        !isNaN(parseInt(account)) && account.length > 3 && account.length < 6
      );
    },
    goBack() {
      router.push("/companies");
    },

    onPaginate(options) {
      this.pagination = {
        page: Number(options.page),
        per_page: Number(options.perPage)
      }
    }
  },
  mounted() {
    this.getData();
  },
};
</script>
