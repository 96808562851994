<template lang="pug">
status-badge(
  :status="item.status"
  :label="$t(`automation.statuses.${item.status}`)"
  :tooltip="item.details"
)
</template>

<script>
import StatusBadge from "./status-badge.vue";

export default {
  components: {
    StatusBadge,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
