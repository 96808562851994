
div
  .p-4
    .container
      .pt-3.px-3
        stack
          stack-item(fill)
            .h3.mb-0 {{ $t('admin_tools.tabs.companies_approval') }}
          stack-item
            b-btn(
              variant="primary",
              squared,
              @click="exportApprovalCompanies()"
            )
              .mdi.mdi-download
              | {{ $t('companies.export_to_excel') }}
        stack
          stack-item()
            b-form-group(
              :label="$t('admin_users.company')"
              label-for="company-name-input"
              label-cols="3"
            )
              b-form-input(
                v-model="search_params.company_name"
                type="search"
                id="company-name-input"
                @change="reloadList(item)"
              )

      rest-table(
        namespace="companies_approval",
        :headers="headers",
        ref="companiesApprovalTable",
        :fetch-handler="getData",
        embed
      )
