<template lang="pug">
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div(v-if="isInvite")
      .modal-title {{ $t('companies.users.invite_title') }}
      .modal-subtitle {{ $t('companies.users.invite_description') }}
    div(v-else)
      .modal-title {{ $t('companies.users.edit_title') }}
      .modal-subtitle {{ $t('companies.users.edit_description') }}

  b-form-group(
    :label="$t('admin_users.company')",
    label-for="company-input",
    label-cols="4"
    :invalid-feedback="errors.first('company_id')"
    :state="errors.has('company_id') ? false : null"
  )
    Dropdown(
      v-if="isInvite"
      v-validate="'required'",
      data-vv-name="company_id"
      :data-vv-as="$t('admin_users.company')"
      :options='companies'
      @selected='validateSelection'
      :disabled='!this.isInvite'
      placeholder='Select a company'
      :default="{id: 1485, name: 'Avara'}"
      )
    b-form-input(
      v-else
      v-model="company_name"
      :disabled="true"
    )

  b-form-group(
    :label="$t('companies.users.name')",
    label-for="name-input",
    label-cols="4"
    :invalid-feedback="errors.first('name')"
    :state="errors.has('name') ? false : null"
  )
    b-input#name-input(
      v-model="form.name"
      v-validate="'required'"
      data-vv-name="name"
      :data-vv-as="$t('companies.users.name')"
    )

  b-form-group(
    :label="$t('companies.users.email')",
    label-for="email-input",
    label-cols="4"
    :invalid-feedback="errors.first('email')"
    :state="errors.has('email') ? false : null"
  )
    b-input#email-input(
      v-model="form.email",
      v-validate="'required|email'"
      data-vv-name="email"
      :data-vv-as="$t('companies.users.email')"
      type="email"
    )

  b-form-group(
    :label="$t('companies.users.role')",
    label-for="role-input",
    label-cols="4"
    :invalid-feedback="errors.first('role')"
    :state="errors.has('role') ? false : null"
  )
    b-select#role-input(
      v-validate="'required'"
      data-vv-name="role"
      :data-vv-as="$t('companies.users.role')"
      v-model="form.role",
      :options="rolesOptions",
    )

  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
</template>

<script>
import ApiCompanyUsers from "@/api/companyUsers.js";
import ApiAdminCompanyUsers from "@/api/admin_company_users.js";
import Dropdown from 'vue-simple-search-dropdown';

export default {
  components: {
    Dropdown
  },

  $_veeValidate: {
    validator: "new",
    validations: {
      company_id: "required",
    },
  },

  data() {
    return {
      show: false,
      isInvite: false,
      form: {
        company_id: null,
        name: "",
        email: "",
        role: null,
      },
      companies: [],
      rolesOptions: [
        { text: "Admin", value: "admin" },
        { text: "Reader", value: "reader" },
      ],
      company_name: ''
    };
  },

  methods: {
    open(User) {
      this.loadCompanies();
      if (User) {
        this.isInvite = false;
        this.form = {
          id: User.id,
          name: User.user_name,
          email: User.email,
          role: User.role,
          company_id: User.company_id
        };
        this.company_name = User.company_name
      } else {
        this.isInvite = true;
        this.form = {
          name: "",
          email: "",
          role: null,
          company_id: null
        };
      }
      this.show = true;
    },

    close() {
      this.show = false;
    },

    loadCompanies() {
      ApiAdminCompanyUsers.getCompanies().then(
          (resp) => {
            this.companies = resp.data;
          },
      )
    },

    save() {
      this.$validator.validateAll().then((res) => {
        if (!res) return;
        const form = this.form;
        const params = {
          name: form.name,
          email: form.email,
          role: form.role,
        };
        if (this.isInvite) {
          ApiCompanyUsers.inviteUser(form.company_id, params)
            .then((res) => {
              this.$emit("done");
              this.close();
            })
            .catch((e) => {});
        } else {
          ApiCompanyUsers.updateUser(form.company_id, form.id, params)
            .then((res) => {
              this.$emit("done");
              this.close();
            })
            .catch((e) => {});
        }
      });
    },

    validateSelection(selection) {
      this.form.company_id = selection.id;
    },
  },
};
</script>
