import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import routes from './routes';
import _isEmpty from "lodash/isEmpty";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes
});
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['isAuthenticated'];

  const isConfirmAgreementsRequired = () => {
    if (!window.user) { return false; }
    return window.user.agreement_sign_required || false;
  }

  if (to.matched.some(r => r.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        name: 'Landing',
        query: {
          reason: to.query.reason
        }
      });
      return;
    }
  }


  if(isConfirmAgreementsRequired() && to.path !== '/netvisor_contract') {
    next('/netvisor_contract');
    return;
  }

  if(to.path === '/netvisor_contract' && !isConfirmAgreementsRequired()) {
    next('/');
    return;
  }

  if (!isConfirmAgreementsRequired()) {
    if (to.name !== "NoHaveCompany" && !!window.user) {
      if (window.user.role !== 'super_admin' && _isEmpty(window.user.companies) && _isEmpty(window.user.reseller_for)) {
        next({
          name: 'NoHaveCompany'
        });
        return;
      }

      if (to.name !== "CompanyReports" && window.user.role === 'user' && window.user.companies.length === 1 && window.user.companies[0].role === 'reader') {
        next(`companies/${window.user.companies[0].id}/reports`);
        return;
      }
    }
  }
  next();
});

export default router;