<template lang="pug">
.status-badge(:class="classes")
  b-badge(
    pill
    :variant="variant"
    :id="`status-badge-${index}`"
  )
    slot {{ label || status }}
  b-tooltip(
    v-if="tooltip"
    :target="`status-badge-${index}`"
    :customClass="isLargeTooltip ? 'status-badge-tooltip-large' : ''"
  ) 
    slot(name="tooltip") 
      template(v-if="!isLargeTooltip")  {{ `Status reason: \"${tooltip}\"` }}
      template(v-else)
        p.text-left(v-for="p in tooltip") {{ p }}
</template>

<script>
let statusBadgeUniqIndex = 0;

export default {
  props: {
    status: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: null,
    },
    defaultVariant: {
      type: String,
      default: "light",
    },
    statusVariants: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      index: statusBadgeUniqIndex++,
    };
  },

  computed: {
    isLargeTooltip() {
      return Array.isArray(this.tooltip);
    },
    classes() {
      return {
        "status-badge--interactive": this.tooltip,
      };
    },
    statusVariantsWithDefaults() {
      return {
        suggested: "primary",
        failed: "danger",
        not_suggested: "warning",
        ...this.statusVariants,
      };
    },
    variant() {
      return (
        this.statusVariantsWithDefaults[this.status] ?? this.defaultVariant
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.status-badge {
  font-size: 1.25rem;

  &--interactive {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.status-badge-tooltip {
  &-large .tooltip-inner {
    max-width: 520px !important;
  }
}
</style>
