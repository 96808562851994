
div
  .p-4
    .container
      .pt-3.px-3
        stack
          stack-item(fill)
            .h3.mb-0 {{ $t('admin_tools.tabs.company_users') }}
          stack-item()
            b-btn(
              variant="primary",
              squared,
              @click="editUser(null)"
            )
              .mdi.mdi-account-plus
              | {{ $t('companies.users.invite_new_user') }}
          stack-item()
            b-btn(
              variant="primary",
              squared,
              @click="exportUsers()"
            )
              .mdi.mdi-download
              | {{ $t('companies.export_to_excel') }}
          stack-item()
            b-btn(
              variant="primary",
              squared,
              @click="importUsers()"
            )
              .mdi.mdi-briefcase-upload-outline
              | {{ $t('companies.import') }}

      .pt-3.px-3
        stack
          stack-item()
            b-form-group(
              :label="$t('admin_users.user_name')"
              label-for="company-name-input"
              label-cols="3"
            )
              b-form-input(
                v-model="search_params.user_name"
                type="search"
                id="company-name-input"
                @change="reload"
              )
          stack-item()
            b-form-group(
              :label="$t('admin_users.company')"
              label-for="company-name-input"
              label-cols="3"
            )
              b-form-input(
                v-model="search_params.company"
                type="search"
                id="company-name-input"
                @change="reload"
              )

      rest-table(
        namespace="logs",
        :headers="headers",
        ref="companyUsersTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                @click="editUser(item)"
              )
                .mdi.mdi-pencil-outline
                | {{ $t('actions.edit') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteCompanyUser(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
    edit-user-modal(ref="EditUserModal", @done="reload")
    import-users-modal(ref="ImportUsersModal", @done="reload")

