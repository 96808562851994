import axios from 'axios';
import LocalStorage from '@/common/localstorage';

const SET_CURRENT_COMPANY = (state, company_id) => {
  LocalStorage.set('current-company', company_id, Number);
  state.currentCompanyId = company_id;
  axios.defaults.headers.common['BiBook-Company-Id'] = company_id;
};

const SET_COMPANY = (state, company = {}) => {
  state.company = company;
};

const UPDATE_COMPANY = (state, company = {}) => {
  state.company = { ...state.company, ...company };
  if (company.name) {
    const companyIndex = state.companies.findIndex(c => c.id === company.id);
    if (companyIndex >= 0) {
      state.companies[companyIndex].name = company.name;
    }
  }
};

const SET_PROGRESS = (state, progress = true) => {
  state.inProgress = progress;
};

const UPDATE_COMPANY_LIST = (state, list) => {
  state.companies = list;
};

export default {
  SET_CURRENT_COMPANY,
  UPDATE_COMPANY,
  SET_COMPANY,
  SET_PROGRESS,
  UPDATE_COMPANY_LIST
};
