<template lang="pug">
  div.position-relative
    div.invoice-popover(v-if="$route.query.invoice_id")
      PurchaseInvoicePage(:id="$route.query.invoice_id")
    div(v-else)
      stack
        stack-item
          b-form-group(
            :label="$t('companies.automation.remote_key')"
            label-for="company-name-input"
            label-cols="4"
          )
            b-input(
              v-model="search_params.remote_key"
              id="invoice-key-input",
              @change="reload"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.automation.start_date')"
            label-for="start-date-input"
            label-cols="5"
          )
            b-form-input(
              v-model="search_params.start_date"
              type="date"
              id="start-date-input"
              @change="reload"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.automation.end_date')"
            label-for="end-date-input"
            label-cols="5"
          )
            b-form-input(
              v-model="search_params.end_date"
              type="date"
              id="end-date-input"
              @change="reload"
            )
      rest-table(
        namespace="vendor_invoices",
        :headers="headers",
        ref="vendorInvoicesTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_remote_key", slot-scope="{ item }")
          router-link(
            :to="{...$route, query: {...$route.query, invoice_id: item.id.toString()}}"
            label="item.name"
          )
            | {{ item.remote_key }}


</template>
<script>
import ApiCompanyVendors from "@/api/companyVendors.js";
import PurchaseInvoicePage from "@/views/PurchaseInvoice/show.vue";
import {DateTime} from "luxon";

export default {
  props: ['vendor_id'],

  components: {
    PurchaseInvoicePage
  },

  data() {
    const startDate = (typeof this.$route.query.purchaseInvoiceStartDate !== 'undefined') ? this.$route.query.purchaseInvoiceStartDate : DateTime.local().minus({year: 1}).toISODate();
    const endDate = (typeof this.$route.query.purchaseInvoiceEndDate !== 'undefined') ? this.$route.query.purchaseInvoiceEndDate : DateTime.local().toISODate();
    return {
      items: [],
      search_params: {
        remote_key: '',
        start_date: startDate,
        end_date: endDate
      },
      headers: [
        {
          key: "remote_key",
          label: "Invoice Number",
          sortable: true,
        },
        {
          key: "bill_id",
          label: "Bill ID",
          sortable: true,
        },
        {
          key: "date",
          label: "Invoice Date",
          sortable: true,
        },
        {
          key: "amount",
          label: "Invoice Amount",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "status_reason",
          label: "Status reason",
          sortable: true,
        },
        {
          key: "correct_accounts_percent",
          label: "Correct Accounts %",
          sortable: true,
        },
        {
          key: "correct_dimensions_percent",
          label: "Correct Dimensions %",
          sortable: true,
        }
      ],
    }
  },

  methods: {
    reload() {
      this.$refs.vendorInvoicesTable.fetchData();
    },
    getData(pagination) {
      this.inProgress = true;
      const search_params = this.search_params;

      return new Promise((res) => {
        ApiCompanyVendors.fetchPurchaseInvoices(this.$route.params.id, this.vendor_id, search_params, pagination)
            .then((resp) => {
              res({
                items: resp.data.purchase_invoices,
                meta: resp.data.meta,
              });
            })
            .catch((e) => {
              this.inProgress = false;
            });
      });
    },
  }
}
</script>