
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div
      .modal-title {{ $t('companies.account_keyword.create_title') }}
      .modal-subtitle {{ $t('companies.account_keyword.create_description') }}

  b-form-group(:label="$t('companies.account_keyword.number')")
    b-input(v-model="form.number" type="number")
  b-form-group(:label="$t('companies.account_keyword.keywords')")
    b-form-tags(
      size="sm",
      v-model="form.keywords",
      :placeholder="$t('companies.dimensions.keywords_prompt')"
    )
  template(slot="modal-footer")
    b-btn(variant="primary", squared, @click="save")
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
