import oauth2Providers from '@/common/login_providers';
import router from '@/router';
import store from '@/store';
import locales from '@/localization/locales';
import axios from 'axios';
import ApiUser from '@/api/user.js'

const login = ({ commit }, params) => {
  if (params.oauth2) {
    const link = oauth2Providers.find(link => link.slug === params.oauth2);
    if (!link) {
      return
    }
    console.log(link);
    fetch(link.url, {
      method: "POST"
    })
  }

  // return commit('LOGIN', user);
};

const logout = ({ commit }) => {
  delete axios.defaults.headers.common['BiBook-Company-Id'];
  window.location.href = '/users/sign_out';
  // commit('LOGOUT');
  // router.push({ name: 'Landing' });
};

const switchLanguage = ({ commit }, value) => {
  const locale = locales.find(l => l.value === value);
  if (!locale) return;
  commit('SET_LOCALE', locale);
};

export default {
  login,
  logout,
  switchLanguage
};
