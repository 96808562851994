<template lang="pug">
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div(v-if="isInvite")
      .modal-title {{ $t('companies.users.invite_title') }}
      .modal-subtitle {{ $t('companies.users.invite_description') }}
    div(v-else)
      .modal-title {{ $t('companies.users.edit_title') }}
      .modal-subtitle {{ $t('companies.users.edit_description') }}

  b-form-group(
    :label="$t('companies.users.name')",
    label-for="name-input",
    label-cols="4"
    :invalid-feedback="errors.first('name')"
    :state="errors.has('name') ? false : null"
  )
    b-input#name-input(
      v-model="form.name"
      v-validate="'required'"
      data-vv-name="name"
      :data-vv-as="$t('companies.users.name')"
    )

  b-form-group(
    :label="$t('companies.users.email')",
    label-for="email-input",
    label-cols="4"
    :invalid-feedback="errors.first('email')"
    :state="errors.has('email') ? false : null"
  )
    b-input#email-input(
      v-model="form.email",
      v-validate="'required|email'"
      data-vv-name="email"
      :data-vv-as="$t('companies.users.email')"
      type="email"
    )

  b-form-group(
    :label="$t('companies.users.role')",
    label-for="role-input",
    label-cols="4"
    :invalid-feedback="errors.first('role')"
    :state="errors.has('role') ? false : null"
  )
    b-select#role-input(
      v-validate="'required'"
      data-vv-name="role"
      :data-vv-as="$t('companies.users.role')"
      v-model="form.role",
      :options="rolesOptions",
    )

  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
</template>

<script>
import { mapGetters } from "vuex";
import ApiCompanyUsers from "@/api/companyUsers.js";
import User from "@/models/User";

export default {
  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      show: false,
      isInvite: false,
      companyId: null,
      form: {},
      rolesOptions: [
        { text: "Admin", value: "admin" },
        { text: "Reader", value: "reader" },
      ],
    };
  },

  methods: {
    open(companyId, User) {
      if (User) {
        this.isInvite = false;
        this.form = {
          id: User.id,
          name: User.name,
          email: User.email,
          role: User.role,
        };
      } else {
        this.isInvite = true;
        this.form = {
          name: "",
          email: "",
          role: null,
        };
      }
      this.companyId = companyId;
      this.show = true;
    },

    close() {
      this.show = false;
    },

    save() {
      this.$validator.validateAll().then((res) => {
        if (!res) return;
        const form = this.form;
        const params = {
          name: form.name,
          email: form.email,
          role: form.role,
        };
        const companyId = this.companyId;
        if (this.isInvite) {
          ApiCompanyUsers.inviteUser(companyId, params)
            .then((res) => {
              this.$emit("done");
              this.close();
            })
            .catch((e) => {});
        } else {
          ApiCompanyUsers.updateUser(companyId, form.id, params)
            .then((res) => {
              this.$emit("done");
              this.close();
            })
            .catch((e) => {});
        }
      });
    },
  },
};
</script>
