<template lang="pug">
.header-dropdown(:class="dropdownClasses")
  header-button(
    :subtitle-top="subtitleTop"
    @click.native="toggle($event)"
    :class="dropdownActivatorClasses"
  )
    template(slot="prepend" v-if="hasPrepend")
      slot(name="prepend")
    template(slot="subtitle" v-if="hasSubtitle")
      slot(name="subtitle") {{ subtitle }}
    .mdi.mdi-chevron-down(slot="append")
    | {{ label }}
  .header-dropdown__content(
    @click="contentClick($event)"
    v-if="state"
  )
    slot(name="dropdown")
</template>

<script>
import HeaderButton from './HeaderButton.vue';

export default {
  components: {
    HeaderButton
  },

  props: {
    label: {
      type: String,
      defalt: null
    },
    subtitle: {
      type: String,
      defalt: null
    },
    subtitleTop: {
      type: Boolean,
      defalt: false
    },
    alignment: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      state: false
    };
  },

  computed: {
    hasSubtitle() {
      return !!this.$slots.subtitle || this.subtitle;
    },

    hasPrepend() {
      return !!this.$slots.prepend;
    },

    dropdownClasses() {
      return {
        [`header-dropdown--${this.alignment}`]: !!this.alignment
      };
    },

    dropdownActivatorClasses() {
      return {
        active: this.state
      };
    }
  },

  methods: {
    show() {
      this.state = true;
    },

    hide() {
      this.state = false;
    },

    contentClick(e) {
      e.stopPropagation();
    },

    toggle(e) {
      e.stopPropagation();
      this.state = !this.state;
    }
  },

  mounted() {
    document.addEventListener('click', this.hide);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.hide);
  }
};
</script>