
b-modal(
  centered
  :title="title"
  v-model="show"
  cancel-disabled
  :hide-footer="true"
  size="lg"
)
  p(v-for="row in original_record") {{ row }}
