<template lang="pug">
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{$t('resellers.title') }}
            stack-item
              b-btn(
                variant="primary"
                squared
                @click="addReseller"
              ) 
                .mdi.mdi-account-plus-outline
                | {{ $t('actions.add') }}
        rest-table(
          namespace="resellers"
          :headers="headers"
          ref="resellersTable"
          :fetch-handler="getData"
          embed
        )
          template(slot="cel_name" slot-scope="{ item }")
            router-link(
              :to="`/resellers/${item.id}/edit`"
              label="item.name"
            )
              | {{ item.name }}
          template(slot="cel_actions" slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs"
                  variant="flat-danger"
                  squared
                  @click="deleteReseller(item)"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
  create-reseller-modal(ref="ResellerCreateModal" @done="reloadList")
</template>
<script>
import router from "@/router";
import axios from "axios";
import { mapGetters } from "vuex";
import ApiResellers from "@/api/resellers.js";
import Reseller from "@/models/Reseller";
import CreateResellerModal from "@/modals/Resellers/create.vue";

export default {
  components: {
    CreateResellerModal
  },

  data() {
    return {
      pagination: {
        sortBy: null
      },
      headers: [
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "company_id",
          label: "BusinessId",
          sortable: true
        },
        {
          key: "actions",
          label: "",
          squeeze: true
        }
      ]
    };
  },
  methods: {
    reloadList() {
      this.$refs.resellersTable.fetchData();
    },

    getData(pagination) {
      return new Promise(res => {
        ApiResellers.fetchResellers(pagination).then(resp => {
          res({
            items: resp.data.resellers,
            meta: resp.data.meta
          });
        });
      });
    },

    addReseller() {
      this.$refs.ResellerCreateModal.open(null);
    },

    deleteReseller(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "account-minus",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary"
      }).then(result => {
        if (!result) return;
        ApiResellers.deleteReseller(item.id).then(resp => {
          this.reloadList();
        });
      });
    }
  }
};
</script>
