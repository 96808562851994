export function cloneDate(date) {
  return new Date(new Date(date).getTime());
}

export function isSameDay(first, second) {
  const newFirst = cloneDate(first);
  const newSecond = cloneDate(second);
  return (
    newFirst.getFullYear() === newSecond.getFullYear() &&
    newFirst.getMonth() === newSecond.getMonth() &&
    newFirst.getDate() === newSecond.getDate()
  );
}

export function addYears(date, years) {
  const newDate = cloneDate(date);
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate;
}

export function addMonths(date, months) {
  const newDate = cloneDate(date);
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
}

export function addDays(date, days) {
  const newDate = cloneDate(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export function startOfDay(date) {
  const newDate = cloneDate(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

export function endOfDay(date) {
  const newDate = cloneDate(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
}

export function startOfWeek(date, firstDayOfWeekIndex = 0) {
  const newDate = startOfDay(cloneDate(date));
  const dayOfWeek = newDate.getDay();
  const diff =
    dayOfWeek >= firstDayOfWeekIndex
      ? dayOfWeek - firstDayOfWeekIndex
      : 6 - dayOfWeek;
  newDate.setDate(newDate.getDate() - diff);
  return newDate;
}

export function endOfWeek(date, firstDayOfWeekIndex = 0) {
  const newDate = addDays(endOfDay(startOfWeek(date, firstDayOfWeekIndex)), 6);
  return newDate;
}

export function startOfMonth(date) {
  const newDate = startOfDay(cloneDate(date));
  newDate.setDate(1);
  return newDate;
}

export function endOfMonth(date) {
  const newDate = endOfDay(cloneDate(date));
  newDate.setMonth(newDate.getMonth() + 1);
  newDate.setDate(0);
  return newDate;
}
