<template lang="pug">
  .container
    .h3.mb-0 Invoice data
    table.table-sm.table-striped.table-bordered
      tbody()
        tr(v-for="(item, field) in data" :key="field")
          td(v-if="field !== 'InvoiceLines'") {{field}}
          td(v-if="field !== 'InvoiceLines'") {{item}}
    br
    .h3.mb-0 Invoice Lines
    LineItemsTable(:line_items="this.line_items")
</template>
<script>
import LineItemsTable from "@/views/PurchaseInvoice/components/line_items.vue"
export default {
  props: ['data', 'line_items'],
  components: {
    LineItemsTable
  },
}
</script>