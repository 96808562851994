
.container
  table.table-sm.col-4
    tbody
      tr(v-for="(item, field) in data" :key="field")
        td
          router-link(
            :to="`/api/v1/purchase_invoice_attachments/${item['id']}`"
            label="item['filename']",
            target="_blank"
          )
            | {{item['filename']}}
