<template lang="pug">
.application-layout(:class="{ sidebar: $route.meta.sidebar }")
  .application-header
    app-header
  .application-content
    transition(name="fade" mode="out-in")
      router-view
  .application-footer
    .footer
      .container
        .text-center
          | <strong>Finance.Rocks</strong> made with ❤️ &nbsp;by <a href="https://renance.com/" target="_blank" rel="noopener noreferrer">Renance - Automated Financial Services Oy</a>.
  transition(name="sidebar-slide" mode="out-in")
    .app-sidebar(v-if="$route.meta.sidebar")
      app-sidebar
</template>

<script>
import { mapGetters } from "vuex";
import AppHeader from "@/components/Header/Header.vue";
import AppSidebar from "@/components/Sidebar/Sidebar.vue";

export default {
  components: {
    AppHeader,
    AppSidebar
  },

  computed: {
    ...mapGetters(["currentLanguage"])
  },

  methods: {
    checkAuthError() {
      if (this.$route.query.reason) {
        this.$dialogAlert({
          title: this.$t("user.auth_error"),
          description: `<code>${this.$route.query.reason}</code>`,
          variant: "danger",
          icon: "account-off"
        });

        const r = {
          name: this.$route.name,
          query: { ...this.$route.query }
        };

        delete r.query.reason;
        this.$router.replace(r);
      }
    }
  },

  mounted() {
    this.checkAuthError();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheet/_variables.scss";

.application-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: padding-left ease-out 230ms;

  &.sidebar {
    padding-left: $app-sidebar-size;
  }
}

.app-sidebar {
  padding: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: $app-sidebar-size;
  height: 100%;
  z-index: 1000;
}

.application-header {
  flex-shrink: 0;
}

.application-content {
  background: $bb-gray-900;
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.application-footer {
  flex-shrink: 0;
  position: relative;
}

.footer {
  background: #fafafa;
  padding: 10px 0;
  box-shadow: 0 1px 3px rgba($bb-dark, 0.08), 0 5px 10px rgba($bb-dark, 0.04);
  color: $bb-gray-600;

  & a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px dashed $bb-gray-600;

    &:hover {
      color: $primary;
      border-color: $primary;
    }
  }
}
</style>
