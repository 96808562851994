<template lang="pug">
.empty-state
  .empty-state__body
    slot(name="body")
      .empty-state__title(v-if="title") {{ title }}
      .empty-state__description(v-if="description") {{ description }}
      .empty-state__actions(v-if="hasActionsSlot")
        slot(name="actions")
  .empty-state__image-container
    img.empty-state__image(
      :src="image"
      :class="imageClasses"
    )
</template>

<script>
import EmptyImage from '@/assets/images/illustrations/hiking.svg';

export default {
  props: {
    title: {
      type: String,
      default: null
    },

    description: {
      type: String,
      default: null
    },

    image: {
      type: [Object, String],
      default: EmptyImage
    },

    flipImage: {
      type: Boolean,
      default: false
    },

    flopImage: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasTitleSlot() {
      return !!this.$slots['title'];
    },

    hasDescriptionSlot() {
      return !!this.$slots['description'];
    },

    hasActionsSlot() {
      return !!this.$slots['actions'];
    },

    imageClasses() {
      return {
        'empty-state__image--flip': this.flipImage,
        'empty-state__image--flop': this.flopImage
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/_variables.scss';

.empty-state {
  max-width: 900px;
  margin: 2rem auto;
  padding: 2rem 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &__body,
  &__image-container {
    flex-shrink: 0;
    flex-grow: 1;
    width: 50%;
    position: relative;
  }

  &__body {
    z-index: 2;

    & > * + * {
      margin-top: 1.35rem;
    }
  }

  &__title {
    font-size: 2.25rem;
    font-weight: 500;
    line-height: 1.2;
    color: $primary;
  }

  &__description {
    font-size: 1.5rem;
    color: $bb-gray-600;
  }

  &__actions {
    margin-top: 2rem;
  }

  &__image-container {
    z-index: 1;
  }

  &__image {
    display: block;
    width: 130%;
    margin-left: -30%;

    &#{&}--flip {
      transform: scale(-1, 1);
    }

    &#{&}--flop {
      transform: scale(1, -1);
    }

    &#{&}--flip#{&}--flop {
      transform: scale(-1, -1);
    }
  }
}
</style>