import axios from 'axios';

const fetchStorages = (company_id, pagination) => {
  return axios.get(`/api/v1/companies/${company_id}/storages`, { params: { ...pagination}});
};

const fetchDrive = (company_id) => {
  return axios.get(`/api/v1/companies/${company_id}/storages/drive_settings`);
}

const updateDrive = (company_id, data) => {
  return axios.patch(`/api/v1/companies/${company_id}/storages/drive`, data);
};

const deleteDrive = (company_id) => {
  return axios.delete(`/api/v1/companies/${company_id}/storages/drive`);
};

const createSFTP = (company_id, data) => {
  return axios.post(`/api/v1/companies/${company_id}/storages`, data);
}

const updateSFTP = (company_id, sftp_id, data) => {
  return axios.patch(`/api/v1/companies/${company_id}/storages/sftp/${sftp_id}`, data);
};

const deleteSFTP = (company_id, sftp_id) => {
  return axios.delete(`/api/v1/companies/${company_id}/storages/sftp/${sftp_id}`);
};

const SFTPFolderList = (company_id, path, sftp_id) => {
  return axios.get(`/api/v1/companies/${company_id}/storages/sftp_folder_list/${sftp_id}`, {
    params: {
      new_path: path
    }
  });
};

const driveFolderList = (company_id) => {
  return axios.get(`/api/v1/companies/${company_id}/storages/drive_folder_list`);
};

const ApiCompanyStorages = {
  fetchStorages,
  fetchDrive,
  createSFTP,
  updateDrive,
  deleteDrive,
  updateSFTP,
  deleteSFTP,
  SFTPFolderList,
  driveFolderList
};

export default ApiCompanyStorages;