
b-dropdown.language-switcher(
  variant="link-secondary"
  squared
)
  .language-switcher__activator(slot="button-content")
    .language-switcher__icon(:style="{ 'background-image': `url(${languageIcon})` }")
    span {{ languageAbbr }}
  b-dropdown-item(
    v-for="language in languages"
    :key="language.value"
    @click="switchLanguage(language.value)"
  ) {{ language.label }}
