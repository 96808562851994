
.pb-5
  .page-hero
    h3.pl-3.pb-0.pt-0 {{reseller.name}}
  .container
    b-card(no-body)
      tabs(
        :tabs="tabs"
        :selected="selected"
        @select="selectTab"
      )
      transition(name="fade" mode="out-in")
        component(:is="tabContent")

