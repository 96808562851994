import axios from 'axios';

const userLogin = (email, password) => {
  return axios.post('/users/sign_in', {
    user: { email: email, password: password }
  });
};

const updateUser = params => {
  return axios.patch('/users', params);
};

const finalizeIntegration = () => {
  return axios.patch('/netvisor/finalize');
};

const ApiUser = {
  userLogin,
  updateUser,
  finalizeIntegration
};

export default ApiUser;
