import axios from "axios";

const fetchAll = (params, config) => {
  return axios.get("/api/v1/digia_files", { params, ...config });
};

const download = (id, params) => {
  return axios.get(`/api/v1/digia_files/${id}/download`, { params });
};

const ApiDigiaFiles = {
  fetchAll,
  download,
};

export default ApiDigiaFiles;
