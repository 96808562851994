
div
  stack
    stack-item(fill)
      .h3.mb-0 {{ $t('companies.tabs.replacement_account_list') }}
  stack
    stack-item
      .mt-3.mb-3
      b-form-file(
        v-model="replacementFile",
        :state="Boolean(replacementFile)",
        placeholder="Choose a file or drop it here...",
        drop-placeholder="Upload match file",
        accept=".xlsx"
    )
    stack
      stack-item
      stack-item
        b-btn(
          :disabled="!Boolean(replacementFile)",
          variant="primary",
          squared,
          @click="uploadReplacementFile"
        )
          .mdi.mdi-upload
          | {{ $t('actions.upload') }}
    stack
      stack-item
        b-btn(variant="primary", squared, @click="clearReplacementFile")
          .mdi.mdi-delete
          | {{ $t('actions.delete') }}
  rest-table(
    namespace="replacementCodes",
    :embed="true",
    :progress="inProgress",
    :headers="headers",
    :items="items",
    :fetch-handler="getData",
    :sortBy="pagination.sortBy",
    :sortOrder="pagination.sortOrder",
    ref="companyReplacementtListTable"
  )
