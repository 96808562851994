
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.users') }}
        stack-item
          b-btn(
            variant="primary",
            squared,
            @click="inviteNewUser"
          )
            .mdi.mdi-account-plus
            | {{ $t('companies.users.invite_new_user') }}
      rest-table(
        namespace="company_users",
        :headers="headers",
        ref="companyUsersTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                @click="editUser(item)"
              ) 
                .mdi.mdi-pencil-outline
                | {{ $t('actions.edit') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteUser(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
    edit-user-modal(ref="EditUserModal", @done="reloadList")
