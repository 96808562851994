
div.position-relative(v-if="this.vendor !== null")
  .p-4
    .container
      stack
        stack-item
          b-button(
            class="uppercase"
            :variant="'flat-primary'"
            v-b-tooltip.focus
            @click="close()"
          )
            .mdi.mdi-backspace-outline
            | Back
        stack-item
          b-card
            .h4.mb-0 Name: {{ this.vendor.name }}
    .container
      b-tabs(content-class='mt-3' card)
        b-tab(title='Vendor')
          VendorTab(:vendor="this.vendor")
        b-tab(title='Purchase Invoices')
          PurchaseInvoicesTab(:vendor_id="this.vendor.id")
        b-tab(title='Approvers')
          ApproversTab(:target="'vendor'", :target_id="this.vendor.id")

