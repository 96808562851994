<template lang="pug">
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{ $t('companies.title') }}
        .pt-3.px-3
          stack
            stack-item(fill)
              b-form-group(
                :label="$t('companies.basic.name')"
                label-for="company-id-input"
                label-cols="4"
              )
                b-form-input(
                  v-model="search_params.company_name",
                  type="search"
                  id="company-id-input"
                  @change="reloadList"
                )
            stack-item
              b-badge(
                variant="secondary"
                v-if="search_params.company_name"
                @click="search_params.company_name = null; reloadList();"
               )
                .mdi.mdi-backspace-outline
            stack-item(fill)
              b-form-group(
                :label="$t('companies.basic.netvisor_organization_id')"
                label-for="business-id-input"
                label-cols="4"
              )
                b-form-input(
                  v-model="search_params.netvisor_organization_id",
                  type="search"
                  id="business-id-input"
                  @change="reloadList"
                )
            stack-item
              b-badge(
                variant="secondary"
                v-if="search_params.netvisor_organization_id"
                @click="search_params.netvisor_organization_id = null; reloadList();"
              )
                .mdi.mdi-backspace-outline
            stack-item
              b-btn(variant="primary", squared, @click="importCompanies") 
                .mdi.mdi-briefcase-upload-outline
                | {{ $t('companies.import') }}
            stack-item
              b-btn(variant="primary", squared, @click="addCompany") 
                .mdi.mdi-briefcase-plus-outline
                | {{ $t('actions.add') }}
        rest-table(
          namespace="companies",
          :headers="headers",
          ref="companiesTable",
          :fetch-handler="getData",
          embed
        )
          template(slot="cel_company_name", slot-scope="{ item }")
            router-link(
              :to="`/companies/${item.id}/edit`"
              label="item.company_name"
              v-if="checkRights(item)"
            )
              | {{ item.company_name }}
            span(
              v-else
            ) {{ item.company_name }}
          template(slot="cel_actions", slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-primary",
                  squared,
                  :href="`/companies/${item.id}/edit?tab=reports`"
                )
                  .mdi.mdi-file-chart
                  | {{ $t('companies.reports.title') }}
              stack-item(v-if="item.has_basware")
                b-btn(
                  size="xs",
                  variant="flat-primary",
                  squared,
                  @click="cloneBasware(item)"
                )
                  .mdi.mdi-content-copy
                  | {{ $t('companies.clone') }}
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-danger",
                  squared,
                  @click="deleteCompany(item)"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
  create-company-modal(ref="CompanyCreateModal", @done="reloadList")
  import-company-modal(ref="CompanyImportModal", @done="reloadList")
  cloneBasware-company-modal(ref="BaswareCloneCompanyModal", @done="reloadList")
</template>
<script>
import router from "@/router";
import axios from "axios";
import { mapGetters } from "vuex";
import ApiCompanies from "@/api/companies.js";
import Company from "@/models/Company";
import CreateCompanyModal from "@/modals/Companies/create.vue";
import ImportCompanyModal from "@/modals/Companies/import.vue";
import CloneBaswareCompanyModal from "@/modals/Companies/cloneBasware.vue"

export default {
  components: {
    CreateCompanyModal,
    ImportCompanyModal,
    CloneBaswareCompanyModal
  },

  data() {
    return {
      search_params: {
        company_name: null,
        netvisor_organization_id: null,
      },
      pagination: {
        sortBy: "company_name"
      },
      headers: [
        {
          key: "company_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "netvisor_organization_id",
          label: "BusinessId",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true
        },
      ],
    };
  },
  methods: {
    reloadList() {
      this.$refs.companiesTable.fetchData();
    },

    getData(pagination) {
      const search_params = this.search_params;
      return new Promise((res) => {
        ApiCompanies.fetchCompanies(search_params, pagination).then((resp) => {
          res({
            items: resp.data.companies,
            meta: resp.data.meta,
          });
        });
      });
    },

    addCompany() {
      this.$refs.CompanyCreateModal.open(null);
    },

    deleteCompany(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "briefcase-remove",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiCompanies.deleteCompany(item.id).then((resp) => {
          this.reloadList();
        });
      });
    },

    importCompanies() {
      this.$refs.CompanyImportModal.open();
    },

    cloneBasware(item) {
      this.$refs.BaswareCloneCompanyModal.open(item);
    },

    checkRights(item) {
      if (window.user.role === 'super_admin' || window.user.role === 'reseller') { return true; }
      let company = window.user.companies.find(company =>  company.id === item.id)
      return !!company && company.role === 'admin'
    },
  },
};
</script>
<style lang="scss" scoped>

.form-row {
  margin-bottom: 0px;
}

</style>