
.container
  b-tabs(content-class='mt-3')
    b-tab(:title="this.$t('companies.approvers.company_approvers_tab')")
      company-approvers
    b-tab(:title="this.$t('companies.approvers.company_default_approvers_tab')")
      target-approvers(:target="'company'", :target_id="this.$route.params.id")
    b-tab(:title="this.$t('companies.approvers.approval_settings_tab')")
      approval-settings
    b-tab(:title="this.$t('companies.approvers.approval_logs_tab')")
      approval-logs
