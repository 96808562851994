<template lang="pug">
  div
    .p-4
      .container
        .pt-3.px-3
          stack
            stack-item(fill)
              .h3.mb-0 {{ $t('admin_tools.tabs.company_users') }}
            stack-item()
              b-btn(
                variant="primary",
                squared,
                @click="editUser(null)"
              )
                .mdi.mdi-account-plus
                | {{ $t('companies.users.invite_new_user') }}
            stack-item()
              b-btn(
                variant="primary",
                squared,
                @click="exportUsers()"
              )
                .mdi.mdi-download
                | {{ $t('companies.export_to_excel') }}
            stack-item()
              b-btn(
                variant="primary",
                squared,
                @click="importUsers()"
              )
                .mdi.mdi-briefcase-upload-outline
                | {{ $t('companies.import') }}

        .pt-3.px-3
          stack
            stack-item()
              b-form-group(
                :label="$t('admin_users.user_name')"
                label-for="company-name-input"
                label-cols="3"
              )
                b-form-input(
                  v-model="search_params.user_name"
                  type="search"
                  id="company-name-input"
                  @change="reload"
                )
            stack-item()
              b-form-group(
                :label="$t('admin_users.company')"
                label-for="company-name-input"
                label-cols="3"
              )
                b-form-input(
                  v-model="search_params.company"
                  type="search"
                  id="company-name-input"
                  @change="reload"
                )

        rest-table(
          namespace="logs",
          :headers="headers",
          ref="companyUsersTable",
          :fetch-handler="getData",
          embed
        )
          template(slot="cel_actions", slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-primary",
                  squared,
                  @click="editUser(item)"
                )
                  .mdi.mdi-pencil-outline
                  | {{ $t('actions.edit') }}
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-danger",
                  squared,
                  @click="deleteCompanyUser(item)"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
      edit-user-modal(ref="EditUserModal", @done="reload")
      import-users-modal(ref="ImportUsersModal", @done="reload")

</template>

<script>
import ApiAdminCompanyUsers from "@/api/admin_company_users.js";
import EditUserModal from "@/modals/CompanyUsers/editUser.vue";
import ImportUsersModal from "@/modals/CompanyUsers/import.vue";

export default {
  components: {
    EditUserModal,
    ImportUsersModal
  },

  data() {
    return {
      search_params: {},
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "company_name",
          label: "Company",
          sortable: true,
          squeeze: true,
        },
        {
          key: "business_id",
          label: "Business ID",
          sortable: true,
          squeeze: true,
        },
        {
          key: "user_name",
          label: "Name",
          sortable: true,
          squeeze: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          squeeze: true,
        },
        {
          key: "role",
          label: "Role",
          sortable: true,
          squeeze: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ]
    }
  },

  methods: {
    reload() {
      this.$refs.companyUsersTable.fetchData();
    },

    getData(pagination) {
      const search_params = this.search_params;
      return new Promise((res) => {
        ApiAdminCompanyUsers.fetchUsers(search_params, pagination).then(
            (resp) => {
              res({
                items: resp.data.users,
                meta: resp.data.meta,
              });
            }
        );
      });
    },

    editUser(item) {
      this.$refs.EditUserModal.open(item);
    },

    importUsers() {
      this.$refs.ImportUsersModal.open();
    },

    exportUsers() {
      ApiAdminCompanyUsers.exportUsers()
          .then((resp) => {
            let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", `company_users.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((e) => {});
    },

    deleteCompanyUser(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "briefcase-remove",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiAdminCompanyUsers.deleteUser(item.id).then((resp) => {
          this.reloadList();
        });
      });
    }
  }
}
</script>