
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('resellers.tabs.groups') }}
        stack-item
          b-btn(
            variant="primary"
            squared
            @click="addCompanyGroup"
          )
            .mdi.mdi-briefcase-plus-outline
            | {{ $t('resellers.add_group') }}
      rest-table(
        namespace="reseller_company_groups"
        :headers="headers"
        ref="resellersCompanyGroupsTable"
        :fetch-handler="getData"
        embed
      )
        template(slot="cel_actions" slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs"
                variant="flat-primary"
                squared
                @click="editCompanyGroup(item)"
              ) 
                .mdi.mdi-pencil-outline
                | {{ $t('actions.edit') }}
            stack-item
              b-btn(
                size="xs"
                variant="flat-danger"
                squared
                @click="deleteCompanyGroup(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
    edit-company-group-modal(ref="EditCompanyGroupModal" @done="reloadList")
