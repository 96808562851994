
.container
  stack
    stack-item(fill)
      .h3 {{ $t('companies.approvers.target_approver') }}
    stack-item
      b-btn(
        variant="primary",
        squared,
        @click="openAssignApprover"
      )
        .mdi.mdi-account-plus
        | {{ $t('companies.approvers.assign_approver') }}
  rest-table(
    namespace="target_approvers",
    :headers="headers",
    ref="TargetApproversTable",
    :fetch-handler="getData",
    disablePagination,
    embed
  )
    template(slot="cel_actions" slot-scope="{ item }")
      b-button(
        size="xs",
        variant="flat-danger",
        squared,
        v-b-tooltip.focus,
        @click="deassign(item)"
      )
        | remove
  assign-approver-modal(ref="AssignApproverModal", @closed="reload")
