<template lang="pug">
.ui-spoiler
  transition(name="fade")
    .ui-spoiler__content(v-if="opened")
      slot
  .ui-spoiler__toggler
    slot(name="toggler")
      .ui-spoiler__link(@click="toggle")
        .mdi.mdi-chevron-up(v-if="opened")
        .mdi.mdi-chevron-down(v-else)
        | {{ this.buttonLabel }}
</template>

<script>
export default {
  props: {
    openLabel: {
      type: String,
      default: "labels.open_spoiler",
    },
    closeLabel: {
      type: String,
      default: "labels.close_spoiler",
    },
  },

  data() {
    return {
      opened: false,
    };
  },

  computed: {
    buttonLabel() {
      return this.$t(!this.opened ? this.openLabel : this.closeLabel);
    },
  },

  methods: {
    toggle() {
      this.opened = !this.opened;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/stylesheet/_variables.scss";

.ui-spoiler__link {
  display: flex;
  align-items: center;
  gap: 0.25em;
  color: $bb-gray-600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  & .mdi {
    font-size: 1.5em;
  }
}
</style>
