<template lang="pug">
div
  .mb-3
    stack
      stack-item
        stack
          stack-item
            label.m-0(label-for="search") {{ $t('labels.search') }}
          stack-item
            b-form-input(
              :value="filters.search"
              @input="updateQueryParams({search: $event || undefined})"
              :placeholder="$t('placeholders.type')"
              id="search"
            )
      stack-item
        stack
          stack-item
            label.m-0(label-for="status") {{ $t('labels.status') }}
          stack-item
            ui-select(
              :searchable="false"
              :options="statusOptions",
              :value="filters.invoice_status"
              @input="updateQueryParams({invoice_status: $event || undefined})"
              id="status"
            )
      stack-item
        stack
          stack-item
            label.m-0(label-for="status") {{ $t('labels.files') }}
          stack-item
            digia-file-select(
              multiselect
              :companyId="globalFilters.company_id"
              :value="globalFilters.files"
              @input="updateQueryParams({files: JSON.stringify($event)})"
            )
      stack-item(fill)
      stack-item
        b-btn(
          variant="primary"
          squared
          @click="callExportFailed"
        )
          .mdi.mdi-download
          | {{ $t('actions.export_failed_invoices_numbers') }}
      stack-item
        b-btn(
          variant="primary"
          squared
          @click="callRestartDialog"
        )
          .mdi.mdi-refresh
          | {{ $t('actions.restart_all_failed') }}
  rest-table(
    namespace="invoices",
    :headers="headers",
    ref="table",
    :fetch-handler="getData",
    embed
  )
    template(v-slot:cel_uploaded="{item}")
      invoice-uploaded-status(:success="item.uploaded")
    template(v-slot:cel_status="{item}")
      invoice-status-badge(:status="item.status")
    template(v-slot:cel_invoice_number="{item}")
      a(href="#" @click="showInvoiceRecord($event, item)") {{ item.invoice_number }}
    template(v-slot:cel_filename="{item}")
      router-link(:to="getFileFilterLink(item)") {{ item.filename }}
  ShowInvoiceRecordModal(ref="showInvoiceRecordModal")
</template>

<script>
import ApiDigiaSalesInvoices from "@/api/digia_sales_invoices.js";
import InvoiceUploadedStatus from "./components/invoice_uploaded_status.vue";
import InvoiceStatusBadge from "./components/invoice_status_badge.vue";
import DigiaFileSelect from "@/components/DigiaFileSelect.vue";
import { saveResponseAsFile } from "@/common/save_response_as_file.js";
import ShowInvoiceRecordModal from "@/modals/Digia/showInvoiceRecord.vue";

export default {
  components: {
    InvoiceStatusBadge,
    InvoiceUploadedStatus,
    DigiaFileSelect,
    ShowInvoiceRecordModal,
  },

  props: {
    globalFilters: {
      type: Object,
      required: true,
    },
    globalRequestFilters: {
      type: Object,
      required: true,
    },
  },

  watch: {
    requestFilters(oldValue, newValue) {
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        this.refetchTable();
      }
    },
  },

  computed: {
    filters() {
      return {
        search: this.$route.query.search ?? "",
        invoice_status: this.$route.query.invoice_status ?? null,
      };
    },

    requestFilters() {
      return {
        ...this.globalRequestFilters,
        search: this.filters.search,
        status: this.filters.invoice_status,
      };
    },

    headers() {
      return [
        {
          key: "id",
          label: this.$t("labels.id"),
          sortable: true,
          squeeze: true,
          wrap: false,
        },
        {
          key: "invoice_number",
          label: this.$t("labels.invoice_number"),
          sortable: true,
          squeeze: true,
          wrap: false,
        },
        {
          key: "filename",
          label: this.$t("labels.file_name"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("labels.date"),
          sortable: true,
        },
        {
          key: "uploaded",
          label: this.$t("labels.upload"),
          sortable: true,
          squeeze: true,
        },
        {
          key: "customer_number",
          label: this.$t("labels.customer_number"),
          sortable: true,
          wrap: false,
        },
        {
          key: "netvisor_key",
          label: this.$t("labels.netvisor_key"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("labels.status"),
          sortable: true,
        },
        {
          key: "status_reason",
          label: this.$t("labels.status_reason"),
          width: "35%",
        },
      ];
    },

    statusOptions() {
      const statuses = [
        "draft",
        "processing",
        "waiting_for_customer",
        "processed",
        "duplicate",
        "failed",
      ].map((status) => ({
        value: status,
        label: this.$t(`labels.${status}`),
      }));

      return [
        {
          value: null,
          label: this.$t("labels.any_status"),
        },
        ...statuses,
      ];
    },
  },
  methods: {
    refetchTable() {
      this.$refs.table.updateOptions({ page: 1 });
    },

    getData(pagination) {
      return new Promise((res) => {
        ApiDigiaSalesInvoices.fetchAll({
          ...pagination,
          ...this.requestFilters,
        }).then((response) => {
          res({
            items: response.data.sales_invoices,
            meta: response.data.meta,
          });
        });
      });
    },

    buildQueryParams(params) {
      return {
        ...this.$route,
        query: {
          ...this.$route.query,
          ...params,
        },
      };
    },

    updateQueryParams(params) {
      this.$router.push(this.buildQueryParams(params));
    },

    replaceQueryParams(params) {
      this.$router.replace(this.buildQueryParams(params));
    },

    clearQueryParams() {
      this.replaceQueryParams({
        search: undefined,
        invoice_status: undefined,
      });
    },

    callExportFailed() {
      ApiDigiaSalesInvoices.exportFailed({
        ...this.globalRequestFilters,
      }).then((response) => {
        saveResponseAsFile(response, "failed_invoices.xlsx");
      });
    },

    callRestartDialog() {
      this.$dialogConfirm({
        title: "Restart all failed",
        description: "Do you really want to restart all failed invoices?",
        variant: "danger",
        okLabel: this.$t("actions.restart_all_failed"),
      }).then((result) => {
        if (!result) return;
        ApiDigiaSalesInvoices.restart({
          ...this.globalRequestFilters,
        }).then(() => {
          this.refetchTable();
        });
      });
    },

    getFileFilterLink(item) {
      return {
        ...this.$route,
        query: {
          ...this.$route.query,
          files: JSON.stringify([
            { value: item.file_id, label: item.filename },
          ]),
        },
      };
    },

    showInvoiceRecord(event, item) {
      event.preventDefault();
      this.$refs.showInvoiceRecordModal.open(item);
    },
  },

  beforeDestroy() {
    this.clearQueryParams();
  },
};
</script>
