<template lang="pug">
div
  .p-4(v-if="$route.query.account_id")
    Account
  .p-4(v-else)
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.account_list') }}
        stack-item
          b-btn(variant="primary", squared, @click="addAccountKeyword")
            .mdi.mdi-lan-connect
            | {{ $t('actions.add') }}
      stack
        stack-item
          .mt-3.mb-3
          b-form-file(
            v-model="matchFile",
            :state="Boolean(matchFile)",
            placeholder="Choose a file or drop it here...",
            drop-placeholder="Upload match file",
            accept=".csv"
          )
        stack
          stack-item
          stack-item
            b-btn(variant="primary", squared, @click="downloadMatchFile")
              .mdi.mdi-download
              | {{ $t('actions.download') }}
        stack
          stack-item
            b-btn(
              :disabled="!Boolean(matchFile)",
              variant="primary",
              squared,
              @click="uploadMatchFile"
            )
              .mdi.mdi-upload
              | {{ $t('actions.upload') }}
        stack-item
          b-form-group(
            :label="$t('companies.account_keyword.number')"
            label-for="netvisor-key-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.account_number"
              type="search"
              id = "netvisor-key-input"
              @change="reloadList"
            )
      rest-table(
        namespace="accounts",
        :embed="true",
        :progress="inProgress",
        :headers="headers",
        :items="items",
        ref="companyAccountListTable",
        :fetch-handler="getData",
        :sortBy="pagination.sortBy",
        :sortOrder="pagination.sortOrder"
      )
        template(slot="cel_keywords", slot-scope="{ item }")
          b-form-tags(
            v-if="item.is_keyword",
            size="sm",
            v-model="item.keywords",
            :placeholder="$t('companies.dimensions.keywords_prompt')",
            @change="changeKeywords(item)",
            @input="changeKeywords(item)"
          )
          b-form-tags(
            v-if="!item.is_keyword",
            size="sm",
            v-model="item.keywords",
            :placeholder="$t('companies.dimensions.keywords_prompt')",
            @change="createKeywords(item)",
            @input="createKeywords(item)"
          )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs" v-if="item.is_keyword")
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteAccountKeyword(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
            stack-item(v-if="item.is_active && item.is_keyword")
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deactivate(item)"
              )
                .mdi.mdi-close-circle
                | {{ $t('actions.deactivate') }}
            stack-item(v-if="!item.is_active && item.is_keyword")
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                @click="activate(item)"
              )
                .mdi.mdi-checkbox-marked-circle
                | {{ $t('actions.activate') }}
        template(slot="cel_is_active", slot-scope="{ item }")
          b-button(
            pill
            class="uppercase"
            :variant="STATUSES[item.is_active] || 'info'"
            v-b-tooltip.focus
          )
            div(
              :class="[item.is_active ? 'mdi mdi-checkbox-marked-circle' : 'mdi mdi-close-circle']"
            )
            | {{ item.is_active }}
        template(slot="cel_in_accountlist", slot-scope="{ item }")
          b-button(
            pill
            class="uppercase"
            :variant="STATUSES[item.in_accountlist] || 'info'"
            v-b-tooltip.focus
          )
            div(
              :class="[item.in_accountlist ? 'mdi mdi-checkbox-marked-circle' : 'mdi mdi-close-circle']"
            )
            | {{ item.in_accountlist }}
        template(slot="cel_approver_id", slot-scope="{ item }")
          router-link(
            :to="{...$route, query: {...$route.query, account_id: item.id}}"
            label="item.name"
          )
            | Approvers
      replacement-account-list
    create-account-keyword-modal(ref="CreateAccountKeywordModal", @done="reloadList")
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiCompanyAccountKeyword from "@/api/companyAccountKeyword.js";
import CreateAccountKeywordModal from "@/modals/Companies/createAccountKeyword.vue";
import ApiCompanyFeatures from "@/api/companyFeatures.js";
import ReplacementAccountList from "../replacementAccountList.vue";
import ApiCompanies from "@/api/companies.js";
import ApiCompanyAccount from "@/api/companyAccount.js";
import Account from "@/views/Accounts/index.vue";

const STATUSES = {
  "true": "success",
  "false": "danger"
}

export default {
  components: {
    CreateAccountKeywordModal,
    ReplacementAccountList,
    Account
  },
  data() {
    return {
      matchFile: null,
      inProgress: false,
      pagination: {
        sortBy: 'keywords',
        sortOrder: "desc",
      },
      search_params: {
        account_number: '',
      },
      STATUSES: STATUSES,
      headers: [
        {
          key: "number",
          label: "Number",
          squeeze: true,
        },
        {
          key: "keywords",
          label: "Keywords",
          squeeze: false,
          sortable: true
        },
        {
          key: "is_active",
          label: "Active",
          squeeze: false,
        },
        {
          key: "in_accountlist",
          label: "In account list",
          squeeze: false,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
        {
          key: "approver_id",
          label: "Approver",
          squeeze: false,
        },
      ],
      items: [],
      approvers: [],
    };
  },

  methods: {
    reloadList() {
      this.$refs.companyAccountListTable.fetchData();
    },
    getData(pagination) {
      this.inProgress = true;
      return new Promise((res) => {
        ApiCompanyAccountKeyword.fetchFullAccountList(this.$route.params.id, pagination, this.search_params)
          .then((resp) => {
            this.items = resp.data.accounts;
            res({
              items: resp.data.accounts,
              meta: resp.data.meta,
            });
          })
          .catch((e) => {
            this.inProgress = false;
          });
      });
    },
    goBack() {
      router.push("/companies");
    },
    changeKeywords(item) {
      this.saveAccountKeyword(item.id, { account_keyword: { keywords: item.keywords } });
    },
    createKeywords(item) {
      this.createAccountKeyword(item.id, { account_keyword: { keywords: item.keywords, number: item.number } });
    },
    createAccountKeyword(id, params) {
      ApiCompanyAccountKeyword.createAccountKeyword(
        this.$route.params.id,
        params
      ).then((resp) => {
        this.reloadList();
      });
    },
    saveAccountKeyword(id, params) {
      ApiCompanyAccountKeyword.updateAccountKeyword(
        this.$route.params.id,
        id,
        params
      ).then((resp) => {
        this.reloadList();
      });
    },
    activate(item) {
      this.saveAccountKeyword(item.id, {'is_active': 'true'})
    },
    deactivate(item) {
      this.saveAccountKeyword(item.id, {'is_active': 'false'})
    },
    deleteAccountKeyword(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "lan-disconnect",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiCompanyAccountKeyword.deleteAccountKeyword(this.$route.params.id, item.id).then(
          (resp) => {
            this.reloadList();
          }
        );
      });
    },
    addAccountKeyword() {
      this.$refs.CreateAccountKeywordModal.open(this.$route.params.id, null);
    },

    editAccountKeyword(item) {
      this.$refs.CreateAccountKeywordModal.open(this.$route.params.id, item);
    },
    uploadMatchFile() {
      let formData = new FormData();
      formData.append("file", this.matchFile);
      ApiCompanyFeatures.uploadMatchFile(this.$route.params.id, formData)
          .then((resp) => {
            this.$emit("done");
            this.close();
          })
          .catch((e) => {});
    },
    downloadMatchFile() {
      ApiCompanyFeatures.downloadMatchFile(this.$route.params.id)
          .then((resp) => {
            let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "file.xlsx");
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((e) => {});
    },
  },
  mounted() {
    this.reloadList();
  },
};
</script>
