<template lang="pug">
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{$t('super_admins.title') }}
            stack-item
              b-btn(
                variant="primary"
                squared
                @click="addSuperAdmin"
              ) 
                .mdi.mdi-account-plus-outline
                | {{ $t('actions.add') }}
        rest-table(
          namespace="super_admins"
          :headers="headers"
          ref="superAdminsTable"
          :fetch-handler="getData"
          embed
        )
          template(slot="cel_actions" slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs"
                  variant="flat-primary"
                  squared
                  @click="editSuperAdmin(item)"
                ) 
                  .mdi.mdi-pencil-outline
                  | {{ $t('actions.edit') }}
              stack-item
                b-btn(
                  size="xs"
                  variant="flat-danger"
                  squared
                  @click="deleteSuperAdmin(item)"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
   
  super-admin-edit-modal(ref="SuperAdminEditModal" @done="reloadList")
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import ApiSuperAdmins from "@/api/super_admins.js";
import SuperAdmin from "@/models/SuperAdmin";
import SuperAdminEditModal from "@/modals/SuperAdmins/edit.vue";
export default {
  components: {
    SuperAdminEditModal
  },

  data() {
    return {
      pagination: {
        sortBy: null
      },

      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "actions",
          label: "",
          squeeze: true
        }
      ]
    };
  },
  methods: {
    reloadList() {
      this.$refs.superAdminsTable.fetchData();
    },

    getData(pagination) {
      return new Promise(res => {
        axios.get("/api/v1/super_admins", { params: pagination }).then(resp => {
          res({
            items: resp.data.super_admins,
            meta: resp.data.meta
          });
        });
      });
    },

    addSuperAdmin() {
      this.$refs.SuperAdminEditModal.open(null);
    },

    editSuperAdmin(admin) {
      this.$refs.SuperAdminEditModal.open(admin);
    },

    deleteSuperAdmin(admin) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "account-minus",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary"
      }).then(result => {
        if (!result) return;
        ApiSuperAdmins.destroy(admin.id).then(resp => {
          this.reloadList();
        });
      });
    }
  }
};
</script>
