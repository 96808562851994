
.form-container
  stack
    stack-item
      b.mb-0 {{ $t('companies.approvers.min_approve_price') }}
    stack-item
      b-form-input(
        v-model="default_price_limit",
        :placeholder="$t('companies.approvers.price_in_cents')",
        :state="ifPriceValidator",
        v-b-tooltip.focus
        :title="$t('companies.approvers.default_price_tooltip')"
      )
      b-form-invalid-feedback(
        :state="ifPriceValidator"
      )
        | Price in cents
    stack-item
      b-btn(
        variant="primary"
        @click="save"
      )
       | Save
