<template lang="pug">
.stack-item(
  :class="itemClasses"
)
  slot
</template>

<script>
export default {
  props: {
    fill: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    itemClasses() {
      const classes = {};
      classes['stack-item--fill'] = this.fill;
      return classes;
    }
  }
};
</script>
