<template lang="pug">
  stack
    b-link(
      :href="`/reports/${item.id}/job_download`"
      target="_blank"
      v-if="canDownload(this.item)",
    ) {{ this.item.name }}
    p(v-else) {{ itemName(this.item) }}
</template>

<script>
export default {
  props: {
    item: Object
  },

  methods: {
    itemName(item) {
      let itemName = '';
      const PROCESSING = this.$t('companies.reports.files_states.processing');
      const FILES_IMPORTED = this.$t('companies.reports.files_states.imported');
      const RESULT_UPLOADED = this.$t('companies.reports.files_states.uploaded_to_sftp');
      const TASK_WITHOUT_FILE = this.$t('companies.reports.files_states.no_file');


      switch (true) {
        case (item.status !== 'ok'):
          itemName = PROCESSING;
          break;
        case (item.import !== null):
          itemName = FILES_IMPORTED;
          break;
        case (item.sftp_status === 'uploaded'):
          itemName = RESULT_UPLOADED;
          break;
        case (item.file_exists):
          itemName = item.name;
          break;
        default:
          itemName = TASK_WITHOUT_FILE;
          break;
      }

      return itemName
    },

    canDownload(item) {
      return this.item.file_exists
    },
  },
}
</script>