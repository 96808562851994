
b-modal(resizable,
  v-model="show",
  cancel-disabled
  dialog-class="table-modal"
  )
  div
  .modal-title {{ $t('companies.vendors.edit_vendor_dimensions') }}
  .pt-3.px-3
    stack
      stack-item(fill)
        b-form-group(
          :label="$t('companies.dimensions.name')"
          label-for="company-id-input"
          label-cols="4"
        )
          b-form-input(
            v-model="searchName",
            type="search"
            id="company-id-input"
            @change="reloadList"
          )
  rest-table(
    embed,
    :headers="headers",
    :fetch-handler="getData",
    namespace="dimension_items",
    ref="companyDimensionsTable",
  )
    template(slot="cel_select", slot-scope="{ item }")
      b-form-checkbox(
        v-model="item.assigned_to_vendor",
        switch,
        @change="checkItem(item)"
      )
  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="close"
    )
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
