<template lang="pug">
  .container
    .h3.mb-0 Voucher
    table.table-sm.table-striped.table-bordered
      tbody()
        tr(v-for="(item, field) in data" :key="field")
          td(v-if="field !== 'VoucherLine'") {{field}}
          td(v-if="field !== 'VoucherLine'") {{item}}
    br
    .h3.mb-0 Voucher Lines
    table.table-sm.table-striped.table-bordered(v-if="data !== null")
      thead
        tr
          th(rowspan="2") #
          th(rowspan="2") LineSum
          th(rowspan="2") VatCode
          th(v-bind:colspan="dimensions_count()") Dimensions
          th(rowspan="2") VatPercent
          th(rowspan="2") Description
          th(rowspan="2") NetvisorKey
          th(rowspan="2") AccountNumber
        tr
          th(v-for="(item, i) in dimensions")
            p {{item}}
      tbody
        tr(v-for="(item, index) in [data['VoucherLine']].flat()")
          td {{index}}
          td {{item['LineSum']}}
          td {{item['VatCode']}}
          td(v-for="(dimension) in dimensions")
            p {{dimension_item(item, dimension)}}
          td {{item['VatPercent']}}
          td {{item['Description']}}
          td {{item['NetvisorKey']}}
          td {{item['AccountNumber']}}
    br
    .h3.mb-0 Suggested Lines
    LineItemsTable(:line_items="this.line_items")
</template>
<script>
import LineItemsTable from "@/views/PurchaseInvoice/components/line_items.vue"

export default {
  props: ['data', 'line_items'],
  components: {
    LineItemsTable
  },
  data() {
    return {
      dimensions: this.list_dimensions()
    }
  },

  methods: {
    dimensions_count() {
      return this.list_dimensions().length
    },

    list_dimensions() {
      var list = this.data.VoucherLine.map(
          line => Array.isArray(line.Dimension) ? line.Dimension.map(dim => dim.DimensionName) : null
      ).flat().filter(n => n)
      return [...new Set(list)]
    },

    dimension_item(record, dimension){
      if (!record?.Dimension) {
        return '';
      }

      var record_dim = record.Dimension.find(dim => dim.DimensionName == dimension)
      if (record_dim == undefined) {
        return ''
      } else {
        return record_dim.DimensionItem
      }
    }
  }
}
</script>