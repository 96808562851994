<template lang="pug">
b-badge.badge-status(pill :variant="variant") {{label}}
</template>

<script>
const InvoiceStatusVariant = {
  failed: "danger",
  processed: "info",
  processing: "success",
  customer_not_found: "info",
};

const InvoiceStatusLabelKey = {
  draft: "labels.draft",
  duplicate: "labels.duplicate",
  failed: "labels.failed",
  processed: "labels.processed",
  processing: "labels.processing",
  customer_not_found: "labels.customer_not_found",
};

export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    variant() {
      return InvoiceStatusVariant[this.status] ?? "light";
    },
    label() {
      const labelKey = InvoiceStatusLabelKey[this.status] ?? null;
      return labelKey ? this.$t(labelKey) : this.status;
    },
  },
};
</script>
