import axios from 'axios';

const fetchVendors = (search_params, pagination) => {
    return axios.get(`/api/v1/failed_vendors`, { params: { ...search_params, ...pagination }});
}

const deleteVendor = (id) => {
    return axios.delete(`/api/v1/failed_vendors/${id}`);
}

const exportToExcel = () => {
    return axios({
        url:`/api/v1/failed_vendors/export`,
        method: 'GET',
        responseType: 'blob'
    });
}

const FailedVendors = {
    fetchVendors,
    deleteVendor,
    exportToExcel
};

export default FailedVendors;