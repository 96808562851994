
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div
      .modal-title {{ $t('companies.api_keys.create_title') }}
      .modal-subtitle {{ $t('companies.api_keys.create_description') }}

  b-form-group(
    :label="$t('companies.api_keys.name')"
    :invalid-feedback="errors.first('name')"
    :state="errors.has('name') ? false : null"
  )
    b-form-input(
      v-model="api_key.name"
      v-validate="'required'"
      data-vv-name="name"
      :data-vv-as="$t('companies.api_keys.name')"
    )

  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
