<template lang="pug">
.container
  div(v-if="attachments !== null")
    AttachmentsTab(:data="attachments")
  XmlViewer(:xml="this.data")
</template>

<script>
import XmlViewer from 'vue-xml-viewer'
import AttachmentsTab from "@/views/PurchaseInvoice/tabs/attachments.vue"

export default {
  components: {
    XmlViewer,
    AttachmentsTab
  },
  props: ['data', 'attachments'],
}
</script>