import axios from 'axios';

const fetchLogs = (search_params, pagination) => {
    return axios.get(`/api/v1/action_logs`, { params: { ...search_params, ...pagination }});
};

const fetchEvents = () => {
    return axios.get(`/api/v1/action_logs/events_list`);
}

const ApiDigiaLogs = {
    fetchLogs,
    fetchEvents
};

export default ApiDigiaLogs;