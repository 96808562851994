
.header-dropdown(:class="dropdownClasses")
  header-button(
    :subtitle-top="subtitleTop"
    @click.native="toggle($event)"
    :class="dropdownActivatorClasses"
  )
    template(slot="prepend" v-if="hasPrepend")
      slot(name="prepend")
    template(slot="subtitle" v-if="hasSubtitle")
      slot(name="subtitle") {{ subtitle }}
    .mdi.mdi-chevron-down(slot="append")
    | {{ label }}
  .header-dropdown__content(
    @click="contentClick($event)"
    v-if="state"
  )
    slot(name="dropdown")
