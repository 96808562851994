<template lang="pug">
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div
      .modal-title {{ $t('companies.account_keyword.create_title') }}
      .modal-subtitle {{ $t('companies.account_keyword.create_description') }}

  b-form-group(:label="$t('companies.account_keyword.number')")
    b-input(v-model="form.number" type="number")
  b-form-group(:label="$t('companies.account_keyword.keywords')")
    b-form-tags(
      size="sm",
      v-model="form.keywords",
      :placeholder="$t('companies.dimensions.keywords_prompt')"
    )
  template(slot="modal-footer")
    b-btn(variant="primary", squared, @click="save")
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
</template>

<script>
import { mapGetters } from "vuex";
import ApiCompanyAccountKeyword from "@/api/companyAccountKeyword.js";

export default {
  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      show: false,
      companyId: null,
      form: {}
    };
  },

  methods: {
    open(companyId, Software) {
      this.companyId = companyId;
      this.show = true;
    },

    close() {
      this.show = false;
    },

    save() {
      this.$validator.validateAll().then((res) => {
        const params = this.form;
        const companyId = this.companyId;
          ApiCompanyAccountKeyword.createAccountKeyword(companyId, params)
            .then((res) => {
              this.$emit("done");
              this.close();
            })
            .catch((e) => {});
      });
    },
  },
};
</script>
