<template lang="pug">
UiSelect(
  @input="$emit('input', $event)"
  :value="value"
  :options="getData"
  :multiselect="multiselect"
  object
  :closeOnSelect="false"
)
</template>

<script>
import ApiDigiaFiles from "@/api/digia_files.js";

export default {
  props: {
    value: {
      default: null,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    companyId: {
      required: true,
    },
  },
  methods: {
    async getData(params) {
      const response = await ApiDigiaFiles.fetchAll(
        {
          company_id: this.companyId,
          filename: params.search || undefined,
          page: params.page,
          per_page: params.itemsPerPage,
        },
        {
          signal: params.abortController.signal,
        }
      );
      return {
        items: response.data.digia_files.map(({ id, filename }) => ({
          label: filename,
          value: id,
        })),
      };
    },
  },
};
</script>
