<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('admin_tools.tabs.integration_list') }}
        stack-item
          b-btn(
            variant="primary",
            squared,
            @click="exportIntegrations()"
          )
            .mdi.mdi-download
            | {{ $t('companies.export_to_excel') }}
      rest-table(
        namespace="integration_list",
        :headers="headers",
        ref="integrationListTable",
        :fetch-handler="getData",
        embed
      )
</template>
<script>

import ApiIntegrationList from "@/api/integration_list.js";

export default {
  data() {
    return {
      pagination: {
        sortBy: null,
      },
      headers: [
        {
          key: "company_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "netvisor_organization_id",
          label: "BusinessId",
          sortable: true,
        },
        {
          key: "netvisor_user_id",
          label: "Netvisor User ID",
          sortable: true,
        }
      ],
    };
  },

  methods: {
    getData(pagination) {
      return new Promise((res) => {
        ApiIntegrationList.fetchIntegrationList(pagination).then(
            (resp) => {
              res({
                items: resp.data.integration_list,
                meta: resp.data.meta,
              });
            }
        );
      });
    },
    exportIntegrations() {
      ApiIntegrationList.exportIntegrations()
        .then((resp) => {
          let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", `integrations_list.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((e) => {});
    },
  }
}
</script>