import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import LocalStorage from '@/common/localstorage';
import Company from '@/models/Company';
import RestVuex from '@/common/rest_vuex';
import User from '@/models/User';


const companies = window.companies || [];
const defaultCompanyID = companies.length ? companies[0].id : null;
let currentCompanyId = LocalStorage.get('current-company', null, Number);
if (!companies.some(c => c.id === currentCompanyId)) {
  currentCompanyId = defaultCompanyID;
}
LocalStorage.set('current-company', currentCompanyId, Number);

const state = {
  companies: companies,
  currentCompanyId,
  company: null,
  inProgress: false
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
