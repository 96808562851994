
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('resellers.tabs.errors') }}
      rest-table(
        namespace="resellerErrors"
        :headers="headers"
        ref="resellerErrorsTable"
        :fetch-handler="getData"
        embed
      )
