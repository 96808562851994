
svg.no-avatar(
  viewBox="0 0 100 100" 
  style="overflow: visible;" 
  xmlns="http://www.w3.org/2000/svg" 
  version="1.1"
)
  text.no-avatar__text(
    v-if="label"
    text-anchor="middle"  
    x="50" 
    y="64"
  ) {{ abbreviation }}
