
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{ $t('companies.title') }}
        .pt-3.px-3
          stack
            stack-item(fill)
              b-form-group(
                :label="$t('companies.basic.name')"
                label-for="company-id-input"
                label-cols="4"
              )
                b-form-input(
                  v-model="search_params.company_name",
                  type="search"
                  id="company-id-input"
                  @change="reloadList"
                )
            stack-item
              b-badge(
                variant="secondary"
                v-if="search_params.company_name"
                @click="search_params.company_name = null; reloadList();"
               )
                .mdi.mdi-backspace-outline
            stack-item(fill)
              b-form-group(
                :label="$t('companies.basic.netvisor_organization_id')"
                label-for="business-id-input"
                label-cols="4"
              )
                b-form-input(
                  v-model="search_params.netvisor_organization_id",
                  type="search"
                  id="business-id-input"
                  @change="reloadList"
                )
            stack-item
              b-badge(
                variant="secondary"
                v-if="search_params.netvisor_organization_id"
                @click="search_params.netvisor_organization_id = null; reloadList();"
              )
                .mdi.mdi-backspace-outline
            stack-item
              b-btn(variant="primary", squared, @click="importCompanies") 
                .mdi.mdi-briefcase-upload-outline
                | {{ $t('companies.import') }}
            stack-item
              b-btn(variant="primary", squared, @click="addCompany") 
                .mdi.mdi-briefcase-plus-outline
                | {{ $t('actions.add') }}
        rest-table(
          namespace="companies",
          :headers="headers",
          ref="companiesTable",
          :fetch-handler="getData",
          embed
        )
          template(slot="cel_company_name", slot-scope="{ item }")
            router-link(
              :to="`/companies/${item.id}/edit`"
              label="item.company_name"
              v-if="checkRights(item)"
            )
              | {{ item.company_name }}
            span(
              v-else
            ) {{ item.company_name }}
          template(slot="cel_actions", slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-primary",
                  squared,
                  :href="`/companies/${item.id}/edit?tab=reports`"
                )
                  .mdi.mdi-file-chart
                  | {{ $t('companies.reports.title') }}
              stack-item(v-if="item.has_basware")
                b-btn(
                  size="xs",
                  variant="flat-primary",
                  squared,
                  @click="cloneBasware(item)"
                )
                  .mdi.mdi-content-copy
                  | {{ $t('companies.clone') }}
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-danger",
                  squared,
                  @click="deleteCompany(item)"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
  create-company-modal(ref="CompanyCreateModal", @done="reloadList")
  import-company-modal(ref="CompanyImportModal", @done="reloadList")
  cloneBasware-company-modal(ref="BaswareCloneCompanyModal", @done="reloadList")
