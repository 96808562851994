<template lang="pug">
div
  .mb-3
    stack
      stack-item
        stack
          stack-item
            label.m-0(label-for="status") {{ $t('labels.status') }}
          stack-item
            ui-select(
              :searchable="false"
              :options="statusOptions",
              :value="filters.customer_status"
              @input="updateQueryParams({customer_status: $event || undefined})"
              id="status"
            )
      stack-item
        stack
          stack-item
            label.m-0(label-for="status") {{ $t('labels.files') }}
          stack-item
            digia-file-select(
              multiselect
              :companyId="globalFilters.company_id"
              :value="globalFilters.files"
              @input="updateQueryParams({files: JSON.stringify($event)})"
            )
      stack-item(fill)
      stack-item
        b-btn(
          variant="primary"
          squared
          @click="callRestartDialog"
        )
          .mdi.mdi-refresh
          | {{ $t('actions.restart_all_failed') }}
  rest-table(
    namespace="invoices",
    :headers="headers",
    ref="table",
    :fetch-handler="getData",
    embed
  )
    template(v-slot:cel_customer_number="{item}")
      a(href="#" @click="showCustomerInfo($event, item)") {{ item.customer_number }}
    template(v-slot:cel_status="{item}")
      customer-status-badge(:status="item.status")
    template(v-slot:cel_filename="{item}")
      router-link(:to="getFileFilterLink(item)") {{ item.filename }}
  ShowCustomerInfoModal(ref="showCustomerInfoModal")
</template>

<script>
import ApiDigiaCustomers from "@/api/digia_customers.js";
import CustomerStatusBadge from "./components/customer_status_badge.vue";
import DigiaFileSelect from "@/components/DigiaFileSelect.vue";
import ShowCustomerInfoModal from "@/modals/Digia/showCustomerInfo.vue";

export default {
  components: {
    CustomerStatusBadge,
    DigiaFileSelect,
    ShowCustomerInfoModal,
  },

  props: {
    globalFilters: {
      type: Object,
      required: true,
    },
    globalRequestFilters: {
      type: Object,
      required: true,
    },
  },

  watch: {
    requestFilters(oldValue, newValue) {
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        this.refetchTable();
      }
    },
  },

  computed: {
    filters() {
      return {
        customer_status: this.$route.query.customer_status ?? null,
      };
    },

    requestFilters() {
      return {
        ...this.globalRequestFilters,
        status: this.filters.customer_status,
      };
    },

    headers() {
      return [
        {
          key: "customer_number",
          label: this.$t("labels.customer_number"),
          sortable: true,
        },
        {
          key: "filename",
          label: this.$t("labels.file_name"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("labels.date"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("labels.status"),
          sortable: true,
        },
        {
          key: "status_reason",
          label: this.$t("labels.status_reason"),
          width: "35%",
        },
      ];
    },

    statusOptions() {
      return [
        {
          value: null,
          label: this.$t("labels.any_status"),
        },
        {
          value: "failed",
          label: this.$t("labels.failed"),
        },
        {
          value: "processed",
          label: this.$t("labels.processed"),
        },
        {
          value: "processing",
          label: this.$t("labels.processing"),
        },
      ];
    },
  },
  methods: {
    refetchTable() {
      this.$refs.table.updateOptions({ page: 1 });
    },

    getData(pagination) {
      return new Promise((res) => {
        ApiDigiaCustomers.fetchAll({
          ...pagination,
          ...this.requestFilters,
        }).then((response) => {
          res({
            items: response.data.customers,
            meta: response.data.meta,
          });
        });
      });
    },

    buildQueryParams(params) {
      return {
        ...this.$route,
        query: {
          ...this.$route.query,
          ...params,
        },
      };
    },

    updateQueryParams(params) {
      this.$router.push(this.buildQueryParams(params));
    },

    replaceQueryParams(params) {
      this.$router.replace(this.buildQueryParams(params));
    },

    clearQueryParams() {
      this.replaceQueryParams({
        customer_status: undefined,
      });
    },

    callRestartDialog() {
      this.$dialogConfirm({
        title: "Restart all failed",
        description: "Do you really want to restart all failed invoices?",
        variant: "danger",
        okLabel: this.$t("actions.restart_all_failed"),
      }).then((result) => {
        if (!result) return;
        ApiDigiaCustomers.restart({
          ...this.globalRequestFilters,
        }).then(() => {
          this.refetchTable();
        });
      });
    },

    getFileFilterLink(item) {
      return {
        ...this.$route,
        query: {
          ...this.$route.query,
          files: JSON.stringify([
            { value: item.file_id, label: item.filename },
          ]),
        },
      };
    },

    showCustomerInfo(event, customer) {
      this.$refs.showCustomerInfoModal.open({ customer });
    },
  },

  beforeDestroy() {
    this.clearQueryParams();
  },
};
</script>
