<template lang="pug">
  b-modal(resizable,
    v-model="show",
    cancel-disabled
    dialog-class="table-modal"
  )
    div
    .modal-title {{ $t('companies.vendors.replacement_account_list') }}
    .modal-subtitle {{ $t('companies.vendors.replacement_account_list') }}
    p
      a(href="/replacement_codes.xlsx", target="_blank") {{ $t('companies.file_example') }}
    stack
      stack-item
        .mt-3.mb-3
        b-form-file(
          v-model="replacementFile",
          :state="Boolean(replacementFile)",
          placeholder="Choose a file or drop it here...",
          drop-placeholder="Upload match file",
          accept=".xlsx"
        )
      stack
        stack-item
        stack-item
          b-btn(
            :disabled="!Boolean(replacementFile)",
            variant="primary",
            squared,
            @click="uploadReplacementFile"
          )
            .mdi.mdi-upload
            | {{ $t('actions.upload') }}
      stack
        stack-item
          b-btn(variant="primary", squared, @click="clearReplacementFile")
            .mdi.mdi-delete
            | {{ $t('actions.delete') }}
    rest-table(
      namespace="replacementCodes",
      :embed="true",
      :progress="inProgress",
      :headers="replacement_headers",
      :items="items",
      :fetch-handler="getData",
      :sortBy="pagination.sortBy",
      :sortOrder="pagination.sortOrder",
      ref="vendorReplacementtListTable"
    )
    template(slot="modal-footer")
      b-btn(
        variant="primary"
        squared
        @click="close"
      )
        .mdi.mdi-account-check-outline
        | {{ $t('actions.ok') }}
</template>

<script>
import { mapGetters } from "vuex";
import ApiCompanyAccountKeyword from "@/api/companyAccountKeyword.js";
import ApiCompanyFeatures from "@/api/companyFeatures.js";

export default {
  data() {
    return {
      show: false,
      replacementFile: null,
      inProgress: false,
      pagination: {
        sortBy: 'old_code',
        sortOrder: "asc",
      },
      replacement_headers: [
        {
          key: "old_code",
          label: "Old code",
          squeeze: false,
        },
        {
          key: "new_code",
          label: "New code",
          squeeze: false,
        },
      ],
      items: []
    };
  },

  methods: {
    reloadList() {
      this.$refs.vendorReplacementtListTable.fetchData();
    },
    open(companyId, Vendor) {
      this.companyId = companyId;
      this.vendorId = Vendor.id
      this.getData();
      this.show = true;
    },

    close() {
      this.show = false;
    },

    getData(pagination) {
      this.inProgress = true;
      return new Promise((res) => {
        ApiCompanyAccountKeyword.fetchReplacementList(this.companyId, this.vendorId, pagination)
          .then((resp) => {
            res({
              items: resp.data.replacement_codes,
              meta: resp.data.meta,
            })
          }).catch((e) => {
            this.inProgress = false;
          });
      });     
    },
    uploadReplacementFile() {
      let formData = new FormData();
      formData.append("file", this.replacementFile);
      formData.append("vendor_id", this.vendorId);
      ApiCompanyFeatures.uploadReplacementFile(formData)
          .then((resp) => {
            this.reloadList();
            this.$emit("done");
          })
          .catch((e) => {});
    },
    clearReplacementFile() {
      let formData = new FormData();
      formData.append("vendor_id", this.vendorId);
      ApiCompanyFeatures.clearReplacementFile(formData)
          .then((resp) => {
            this.reloadList();
            this.$emit("done");
          })
          .catch((e) => {});
    }
  }
}
</script>