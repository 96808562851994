
.header-void
  .header
    .header__container(
      :class="headerClasses"
    )
      .header__side
        transition(name="fade" mode="out-in")
          router-link.logotype.mr-3(v-if="!$route.meta.sidebar" :to="{ name: 'Root' }")

        language-switcher
        transition(name="fade" mode="out-in")
          template(v-if="isAuthenticated && !$route.meta.sidebar")
            .ml-3.d-flex.h-100
              router-link.header-button.header-nav-item(
                v-for="link in navItems"
                :key="`${link.id}`"
                :label="link.label"
                :to="link.url"
              ) 
                .header-button__prepend(v-if="link.icon")
                  .mdi.header-button__icon(:class="`mdi-${ link.icon }`")
                .header-button__content
                  .header-button__label {{ link.label }}

      .header__side
        template(v-if="isAuthenticated")
          header-dropdown(
            :label="user.name || `User #${user.id}`"
            :subtitle="user.email"
            alignment="right"
          )
            avatar-box(
              slot="prepend"
              :src="user.avatar"
              :label="user.name || user.email"
              size="sm"
            )
            div(slot="dropdown")
              mini-profile
        template(v-else)
          form.auth-bitton-form(
            v-for="link in oauth2Providers"
            :key="`login-link-${link.slug}`"
            :action="link.url"
            method="post"
          )
            input(type="hidden" :value="csrfToken" name="authenticity_token")
            header-button(
              :label="link.label"
              :subtitle="$t('user.login_with')"
              subtitle-top
              type="submit"
            )
              img.header-button__icon(
                slot="prepend"
                :src="link.icon"
              )
