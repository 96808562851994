import Vue from 'vue';

// Application bootstrapper component
import AppRoot from '@/components/AppRoot.vue';

// Modules
import store from '@/store';
import i18n from '@/localization';
import router from '@/router';
import '@/common/bootstrap';
import '@/common/localstorage';
import '@/common/vee_validate';
import '@/common/global_components';
import '@/common/dialogs';

// Styles
import '@/assets/stylesheet/application.scss';
import '@mdi/font/css/materialdesignicons.min.css';

export const App = new Vue({
  render: h => h(AppRoot),
  router,
  store,
  i18n,
  $validates: true
});
