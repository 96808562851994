
div
  .p-4
    .container
      stack
        stack-item()
          .h3.mb-0 {{ $t('admin_tools.tabs.machine_learning') }}
    .container
      rest-table(
        namespace="processed_companies",
        :headers="headers",
        ref="machineLearningTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                @click="startLearning(item)"
              )
                .mdi.mdi-refresh
                | {{ $t('admin_tools.relearn.start_learning') }}
