
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{$t('admin_users.title') }}
        .pt-3.px-3
          stack
            stack-item()
              b-form-group(
                :label="$t('admin_users.user_name')"
                label-for="company-name-input"
                label-cols="3"
              )
                b-form-input(
                  v-model="search_params.user_name"
                  type="search"
                  id="company-name-input"
                  @change="reloadList"
                )
            stack-item
              b-form-group(
                :label="$t('admin_users.company')"
                label-for="company-name-input"
                label-cols="3"
              )
                b-form-input(
                  v-model="search_params.company"
                  type="search"
                  id="company-name-input"
                  @change="reloadList"
                )
        .container
          .alert.alert-danger(v-if="this.error !== null") {{ this.error }}
        rest-table(
          namespace="company_users",
          :headers="headers",
          ref="adminUsersTable",
          :fetch-handler="getData",
          embed
        )
          template(slot="cel_role", slot-scope="{ item }")
            | {{ item.role && $t(`roles.${item.role}`) }}
          template(slot="cel_actions", slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-primary",
                  squared,
                  @click="editUser(item)"
                )
                  .mdi.mdi-pencil-outline
                  | {{ $t('actions.edit') }}
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-danger",
                  squared,
                  @click="deleteUser(item)"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
    edit-user-modal(ref="EditUserModal" @done="reloadList")
