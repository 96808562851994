<template lang="pug">
  b-modal(centered, v-model="show")
    template(slot="modal-title")
      .modal-title {{ $t('companies.users.edit_title') }}
      //- .modal-subtitle {{ $t('companies.users.edit_description') }}
    .alert.alert-danger(v-if="this.error !== null") {{ this.error }}

    b-form-group(
      :label="$t('companies.users.name')",
      label-for="name-input",
      label-cols="2"
      :invalid-feedback="errors.first('name')"
      :state="errors.has('name') ? false : null"
    )
      b-input#name-input(
        v-model="form.name"
        v-validate="'required'"
        data-vv-name="name"
        :data-vv-as="$t('companies.users.name')"
        :disabled="inProgress"
      )
    b-form-group(
      :label="$t('companies.users.email')",
      label-for="email-input",
      label-cols="2"
      :invalid-feedback="errors.first('email')"
      :state="errors.has('email') ? false : null"
    )
      b-input#email-input(
        v-model="form.email",
        v-validate="'required|email'"
        data-vv-name="email"
        :data-vv-as="$t('companies.users.email')"
        type="email"
        :disabled="inProgress"
      )

    b-form-group(
      :label="$t('companies.users.role')",
      label-for="role-input",
      label-cols="2"
      :invalid-feedback="errors.first('role')"
      :state="errors.has('role') ? false : null"
    )
      b-select#role-input(
        v-validate="'required'"
        data-vv-name="role"
        :data-vv-as="$t('companies.users.role')"
        v-model="form.role",
        :options="globalRolesOptions",
        :disabled="inProgress"
      )

    rest-table(
      namespace="user_companies",
      :headers="headers",
      ref="userCompaniesTable",
      :fetch-handler="getData",
      embed
    )
      template(slot="cel_role", slot-scope="{ item }")
        b-form-select(
          v-model="item.role",
          :options="rolesOptions",
          @change="updateCompanyUser(item)"
        )
      template(slot="cel_actions", slot-scope="{ item }")
        b-btn(
          size="xs",
          variant="flat-danger",
          squared,
          @click="deleteCompanyUser(item)"
        )
          .mdi.mdi-trash-can-outline
          | {{ $t('actions.delete') }}

    template(slot="modal-footer")
      b-btn(variant="primary", squared, @click="save" :disabled="inProgress")
        .mdi.mdi-account-check-outline
        | {{ $t('actions.save') }}

</template>

<script>
import ApiAdminUsers from "@/api/admin_user.js";

export default {
  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      inProgress: false,
      show: false,
      form: {},
      user_id: null,
      error: null,
      rolesOptions: [
        { text: "Admin", value: "admin" },
        { text: "Reader", value: "reader" },
        { text: "Guest", value: "guest" },
      ],
      globalRolesOptions: [
        { text: "Super admin", value: "super_admin" },
        { text: "Reseller", value: "reseller" },
        { text: "User", value: "user" },
      ],
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "company_name",
          label: "Company",
          sortable: true,
        },
        {
          key: "role",
          label: "role",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    };
  },

  methods: {
    open(user) {
      this.user_id = user.id;
      this.form = {
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role,
      };
      this.getData();
      this.error = null;
      this.show = true;
    },

    save() {
      this.inProgress = true;
      this.$validator.validateAll().then((res) => {
        if (!res) {
          this.inProgress = false;
          return;
        }
        const form = this.form;
        const params = {
          name: form.name,
          email: form.email,
          role: form.role,
        };
        ApiAdminUsers.updateUser(form.id, params)
          .then((resp) => {
            this.inProgress = false;
            this.$emit("done");
            this.error = resp.data.error || null;
            if (this.error == null) {
              this.show = false;
            }
          })
          .catch(() => {
            this.inProgress = false;
          });
      });
    },

    reloadList() {
      this.$refs.userCompaniesTable.fetchData();
    },

    getData(pagination) {
      return new Promise((res) => {
        ApiAdminUsers.userCompanies(this.user_id, pagination).then((resp) => {
          res({
            items: resp.data.users_companies,
            meta: resp.data.meta,
          });
        });
      });
    },

    updateCompanyUser(item) {
      ApiAdminUsers.updateCompanyUser(this.user_id, {
        id: item.id,
        role: item.role,
      })
        .then((res) => {
          this.reloadList();
          this.error = res.data.error || null;
        })
        .catch((e) => {});
    },

    deleteCompanyUser(item) {
      ApiAdminUsers.deleteCompanyUser(this.user_id, item.id)
        .then((res) => {
          this.reloadList();
          this.error = res.data.error || null;
        })
        .catch((e) => {
          this.error = e;
        });
    },
  },
};
</script>
