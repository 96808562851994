import axios from "axios";

const fetchAll = (params) => {
  return axios.get("/api/v1/digia_sales_invoices", { params });
};

const restart = (params) => {
  return axios.post("/api/v1/digia_sales_invoices/restart", params);
};

const exportFailed = (params) => {
  return axios.get("/api/v1/digia_sales_invoices/export_failed", {
    params,
    responseType: "blob",
  });
};

const ApiDigiaSaleInvoices = {
  fetchAll,
  restart,
  exportFailed,
};

export default ApiDigiaSaleInvoices;
