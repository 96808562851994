<template lang="pug">
div
  .p-4
    .container
      stack.mb-1
        stack-item
        stack-item(fill)
          .h3.mb-0 {{ $t('resellers.tabs.companies') }}
        stack-item
          b-btn(
            variant="primary"
            squared
            @click="exportToExcel"
          )
            .mdi.mdi-download
            | {{ $t('resellers.export_to_excel') }}
      stack
        stack-item(fill)
          b-form-group(
            :label="$t('automation.name')"
            label-for="company-name-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.q"
              type="search"
              id="company-name-input"
              @change="reload"
            )
        stack-item
          b-badge(
            variant="secondary"
            v-if="search_params.q"
            @click="search_params.q = null; reload();"
            )
            .mdi.mdi-backspace-outline
        stack-item(fill)
          b-form-group(
            :label="$t('companies.basic.netvisor_organization_id')"
            label-for="business-id-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.netvisor_organization_id",
              type="search"
              id="business-id-input"
              @change="reload"
            )
        stack-item
          b-badge(
            variant="secondary"
            v-if="search_params.netvisor_organization_id"
            @click="search_params.netvisor_organization_id = null; reloadList();"
          )
            .mdi.mdi-backspace-outline
      rest-table(
        namespace="resellerCompanies"
        :headers="headers"
        ref="resellerCompaniesTable"
        :fetch-handler="getData"
        embed
      )
        template(slot="cel_company_name", slot-scope="{ item }")
          router-link(
            :to="`/companies/${item.id}/edit`"
            label="item.company_name"
          )
            | {{ item.company_name }}

</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiResellers from "@/api/resellers.js";

export default {
  data() {
    return {
      search_params: {
        q: null,
      },
      pagination: {
        sortBy: null
      },
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true
        },
        {
          key: "company_name",
          label: "Name",
          sortable: true
        },
        {
          key: "netvisor_organization_id",
          label: "BusinessId",
          sortable: true
        },
        {
          key: "invoice_automation",
          label: "Invoice Automation",
          sortable: false,
        },
        {
          key: "set_dimensions",
          label: "Set Dimensions",
          sortable: false,
        },
      ]
    };
  },

  methods: {
    reload() {
      this.$refs.resellerCompaniesTable.fetchData();
    },
    getData(pagination) {
      const search_params = this.search_params;
      return new Promise(res => {
        ApiResellers.fetchResellerCompanies(this.$route.params.id, search_params, pagination).then(
          resp => {
            res({
              items: resp.data.reseller_companies,
              meta: resp.data.meta
            });
          }
        );
      });
    },
    goBack() {
      router.push("/resellers");
    },
    exportToExcel() {
      ApiResellers.exportToExcel(this.$route.params.id)
          .then((resp) => {
            let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", `reseller_companies.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((e) => {});
    }
  }
};
</script>
