
transition(name="fade")
  .overlay-loader(
    v-if="show"
    :class="{ fixed: fixed }"
  )
    .overlay-loader__waves
      span(
        v-for="i in 2"
        :style="{ 'animation-delay': `${initialDelay + (i - 1) * nextDelay}ms` }"
      )
    .overlay-loader__content
      .overlay-loader__logotype
        .logotype.centred
      .overlay-loader__description(v-if="label") {{ label }}
