<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.statistics') }}
      data-table(
        :embed="true",
        :progress="inProgress",
        :headers="headers",
        :items="items",
        ref="companyStatistics",
        :disablePagination="true"
      )
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiCompanies from "@/api/companies.js";

export default {
  data() {
    return {
      inProgress: true,
      pagination: {
        sortBy: null,
      },
      headers: [
        {
          key: "method",
          label: "Method",
          squeeze: false,
        },
        {
          key: "total",
          label: "Suggestions",
          squeeze: false,
        },
        {
          key: "true",
          label: "True",
          squeeze: false,
        },
        {
          key: "false",
          label: "False",
          squeeze: false,
        },
        {
          key: "rate",
          label: "Rate, %",
          squeeze: false,
        },
      ],
      items: [],
    };
  },

  methods: {
    getData() {
      this.inProgress = true;
      ApiCompanies.fetchStastistics(this.$route.params.id)
        .then((resp) => {
          this.items = resp.data.statistics;
          this.inProgress = false;
        })
        .catch((e) => {
          this.inProgress = false;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
