import axios from 'axios';

const fetchIntegrationList = (pagination) => {
    return axios.get(`/api/v1/integration_list`, { params: { ...pagination }});
};

const exportIntegrations = () => {
    return axios({
        url:`/api/v1/integration_list/export`,
        method: 'GET',
        responseType: 'blob'
    });
}

const ApiIntegrationList = {
    fetchIntegrationList,
    exportIntegrations
};

export default ApiIntegrationList;