<template lang="pug">
  .container
    stack
      stack-item
        b-button(
          class="uppercase"
          :variant="'flat-primary'"
          v-b-tooltip.focus
          @click="close()"
        )
          .mdi.mdi-backspace-outline
          | Back
      stack-item
        h3
          | {{ `${this.dimension_item.dimension_name} | ${this.dimension_item.name}` }}
    Approvers(:target="'cost_center'", :target_id="this.$route.query.dimension_item_id")

</template>

<script>
import Approvers from "@/views/Approvers/index.vue";
import ApiCompanyDimensions from "@/api/companyDimensions.js";

export default {
  components: {
    Approvers
  },

  props: ['dimension_item_id'],

  data() {
    return {
      dimension_item: {name: ''},
    }
  },

  watch: {
    dimension_item_id: {
      immediate: true,
      handler (_v, _ov) {
        this.fetchDimensionItem()
      }
    }
  },

  methods: {
    close() {
      const {vendor_id, invoice_id, dimension_item_id, ...rest} = this.$route.query;

      this.$router.push({...this.$route, query: rest})
    },

    fetchDimensionItem() {
      ApiCompanyDimensions.fetchDimension(this.$route.params.id, this.$route.query.dimension_item_id)
        .then(response => {
          this.dimension_item = response.data.dimension;
          console.log(response.data.dimension)
        })
        .catch(error => {
        });
    }
  }
}
</script>