
.application-layout(:class="{ sidebar: $route.meta.sidebar }")
  .application-header
    app-header
  .application-content
    transition(name="fade" mode="out-in")
      router-view
  .application-footer
    .footer
      .container
        .text-center
          | <strong>Finance.Rocks</strong> made with ❤️ &nbsp;by <a href="https://renance.com/" target="_blank" rel="noopener noreferrer">Renance - Automated Financial Services Oy</a>.
  transition(name="sidebar-slide" mode="out-in")
    .app-sidebar(v-if="$route.meta.sidebar")
      app-sidebar
