import axios from 'axios';
import ApiResellers from "@/api/resellers.js";

const fetchCompanies = (query_params, pagination) => {
    return axios.get('/api/v1/machine_learning', { params: { ...query_params, ...pagination }});
};

const startLearning = (company_id) => {
    return axios.post(`/api/v1/machine_learning/${company_id}/learn`)
};

const ApiMachineLearning = {
    fetchCompanies,
    startLearning
};

export default ApiMachineLearning;