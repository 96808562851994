<template lang="pug">
b-modal(
  centered
  :title="title"
  v-model="show"
  cancel-disabled
  :hide-footer="true"
  size="lg"
)
  p(v-for="row in original_record") {{ row }}
</template>

<script>
import XmlViewer from "vue-xml-viewer";

export default {
  components: {
    XmlViewer,
  },

  data() {
    return {
      show: false,
      inProgress: false,
      invoice_number: "",
      original_record: "",
    };
  },

  computed: {
    title() {
      return this.$t("digia.show_invoice_record_modal.title", {
        invoice_number: this.invoice_number,
      });
    },
  },

  methods: {
    open({ original_record, invoice_number }) {
      this.invoice_number = invoice_number;
      this.original_record = original_record;
      this.show = true;
    },

    close() {
      this.show = false;
    },
  },
};
</script>
