<template lang="pug">
  b-modal(resizable,
    v-model="show",
    cancel-disabled
    dialog-class="table-modal"
    )
    div
    .modal-title {{ $t('companies.vendors.edit_vendor_dimensions') }}
    .pt-3.px-3
      stack
        stack-item(fill)
          b-form-group(
            :label="$t('companies.dimensions.name')"
            label-for="company-id-input"
            label-cols="4"
          )
            b-form-input(
              v-model="searchName",
              type="search"
              id="company-id-input"
              @change="reloadList"
            )
    rest-table(
      embed,
      :headers="headers",
      :fetch-handler="getData",
      namespace="dimension_items",
      ref="companyDimensionsTable",
    )
      template(slot="cel_select", slot-scope="{ item }")
        b-form-checkbox(
          v-model="item.assigned_to_vendor",
          switch,
          @change="checkItem(item)"
        )
    template(slot="modal-footer")
      b-btn(
        variant="primary"
        squared
        @click="close"
      )
        .mdi.mdi-account-check-outline
        | {{ $t('actions.save') }}
</template>

<script>
import ApiVendorDimensionItems from "@/api/vendorDimensionItems.js";

export default {
  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      show: false,
      companyId: null,
      vendorId: null,
      searchName: null,
      form: {},
      pagination: {
        sortBy: null,
        sortOrder: null
      },
      headers: [
        {
          key: "dimension_name",
          label: "Dimension",
          squeeze: false,
        },
        {
          key: "name",
          label: "Dimension Item",
          squeeze: false,
        },
        {
          key: 'select',
          label: "",
          squeeze: false,
        }
      ],
    }
  },

  methods: {
    open(companyId, vendorId, selectedItems) {
      this.companyId = companyId;
      this.vendorId = vendorId;
      this.selectedItems = selectedItems;
      this.getData();
      this.show = true;
    },

    close() {
      this.$emit("done");
      this.show = false;
    },

    reloadList() {
      this.$refs.companyDimensionsTable.fetchData();
    },

    getData(pagination) {
      const searchQ = this.searchName;

      return new Promise((res) => {
        return ApiVendorDimensionItems.vendorDimensionItems(this.companyId, this.vendorId, searchQ, pagination)
          .then((resp) => {
            res({
              items: resp.data.dimension_items,
              meta: resp.data.meta,
            });
          })
      })
    },

    checkItem(item) {
      if(item.assigned_to_vendor) {
        ApiVendorDimensionItems.assignDimensionItem(this.companyId, this.vendorId, item.id).then(
            (resp) => {
            }
        )
      } else {
        ApiVendorDimensionItems.deassignDimensionItem(this.companyId, this.vendorId, item.id).then(
            (resp) => {
            }
        )
      }

    },
  }

}
</script>