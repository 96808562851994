<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('admin_tools.tabs.logs') }}
      stack
        stack-item(fill)
          b-form-group(
            :label="$t('admin_tools.digia_logs.event_type')"
            label-for="model-input"
            label-cols="4"
          )
            b-form-select(
              v-model="search_params.event_type",
              :options="eventsOptions",
              @change="reloadList(item)"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('admin_tools.digia_logs.event')"
            label-for="model-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.event"
              type="search"
              id = "model-input"
              @change="reloadList"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('admin_tools.digia_logs.company')"
            label-for="model-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.company_name"
              type="search"
              id = "model-input"
              @change="reloadList"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.automation.start_date')"
            label-for="start-date-input"
            label-cols="5"
          )
            b-form-input(
              v-model="search_params.start_date"
              type="date"
              id="start-date-input"
              @change="reloadList"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.automation.end_date')"
            label-for="end-date-input"
            label-cols="5"
          )
            b-form-input(
              v-model="search_params.end_date"
              type="date"
              id="end-date-input"
              @change="reloadList"
            )

      rest-table(
        namespace="logs",
        :headers="headers",
        ref="logsTable",
        :fetch-handler="getData",
        embed
      )
</template>
<script>

// import router from "@/router";
// import axios from "axios";
import ApiActionLogs from "@/api/digia_logs.js";
import { DateTime } from "luxon"

export default {
  data() {
    const monthBefore = DateTime.local().minus({ month: 1 }).toISODate();
    const today = DateTime.local().plus({ day: 1 }).toISODate();

    return {
      events: [],
      search_params: {
        event_type: null,
        record_id: null,
        company_name: null,
        start_date: monthBefore,
        end_date: today
      },
      pagination: {
        sortBy: null,
      },
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "created_at",
          label: "Event time",
          sirtable: true,
          squeeze: false,
        },
        {
          key: "company",
          label: "Company",
          sortable: true,
        },
        {
          key: "subject",
          label: "Subject",
          sortable: true,
        },
        {
          key: "event",
          label: "Event",
          sortable: true,
        },
        {
          key: "body",
          label: "Body",
          sortable: true,
        },
        {
          key: "event_type",
          label: "Type"
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    };
  },

  methods: {
    reloadList() {
      this.$refs.logsTable.fetchData();
    },

    getData(pagination) {
      const search_params = this.search_params;
      return new Promise((res) => {
        ApiActionLogs.fetchLogs(search_params, pagination).then(
            (resp) => {
              res({
                items: resp.data.logs,
                meta: resp.data.meta,
              });
            }
        );

        ApiActionLogs.fetchEvents()
            .then((resp) => {
              this.events = resp.data.events;
            });
      });
    },
  },

  computed: {
    eventsOptions() {
      let first_element = { text: 'All', value: null };
      let options =  this.events.map((i) => {
        return { text: i[0], value: i[1] };
      });
      options.unshift(first_element)
      return options
    },
  }
}
</script>