
div
  .p-4
    .container
      stack
        stack-item()
          .h3.mb-0 {{ $t('admin_tools.tabs.relearn') }}
        stack-item
          .p#relearn-status
    .container
      stack
        stack-item
          b-form-group(
            :label="$t('admin_tools.relearn.company')"
            label-for="model-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.company"
              type="search"
              id = "model-input"
              @change="reloadList"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.automation.start_date')"
            label-for="start-date-input"
            label-cols="5"
          )
            b-form-input(
              v-model="search_params.start_date"
              type="date"
              id="start-date-input"
              @change="reloadList"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.automation.end_date')"
            label-for="end-date-input"
            label-cols="5"
          )
            b-form-input(
              v-model="search_params.end_date"
              type="date"
              id="end-date-input"
              @change="reloadList"
            )
        stack-item

      rest-table(
        namespace="processed_companies",
        :headers="headers",
        ref="companiesTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_start_date", slot-scope="{ item }")
          b-form-input(
            type="date",
            v-model="item.start_date",
            @input="setEdit(item)",
          )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                :disabled="canBeLearned(item)",
                @click="startLearning(item)"
              )
                .mdi.mdi-refresh
                | {{ $t('admin_tools.relearn.start_learning') }}
      b-button.float-button(
        v-if="edited_items.length > 0"
        variant="primary",
        @click="saveAll"
      )
        .mdi.mdi-content-save-all
        | Save changes
