import Vue from "vue";
import VeeValidate from "vee-validate";
import store from "@/store";
import { i18n, ValidatorDictionaries } from "@/localization";

const scopePrintCodesDuplicateRule = {
  validate(value, { list }) {
    const count = list.reduce((acc, item) => {
      if (value === item.key) {
        return acc + 1;
      }
      return acc;
    }, 0);
    const valid = count <= 1;

    return {
      valid,
      data: valid ? undefined : { message: "Key duplicate" },
    };
  },
};

VeeValidate.Validator.extend(
  "scope_print_codes_duplicate",
  scopePrintCodesDuplicateRule
);

Vue.use(VeeValidate, {
  i18nRootKey: "validations",
  i18n,
  inject: false,
  dictionary: ValidatorDictionaries,
  locale: store.getters.language,
});
