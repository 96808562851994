
.ui-spoiler
  transition(name="fade")
    .ui-spoiler__content(v-if="opened")
      slot
  .ui-spoiler__toggler
    slot(name="toggler")
      .ui-spoiler__link(@click="toggle")
        .mdi.mdi-chevron-up(v-if="opened")
        .mdi.mdi-chevron-down(v-else)
        | {{ this.buttonLabel }}
