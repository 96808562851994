
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{$t('super_admins.title') }}
            stack-item
              b-btn(
                variant="primary"
                squared
                @click="addSuperAdmin"
              ) 
                .mdi.mdi-account-plus-outline
                | {{ $t('actions.add') }}
        rest-table(
          namespace="super_admins"
          :headers="headers"
          ref="superAdminsTable"
          :fetch-handler="getData"
          embed
        )
          template(slot="cel_actions" slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs"
                  variant="flat-primary"
                  squared
                  @click="editSuperAdmin(item)"
                ) 
                  .mdi.mdi-pencil-outline
                  | {{ $t('actions.edit') }}
              stack-item
                b-btn(
                  size="xs"
                  variant="flat-danger"
                  squared
                  @click="deleteSuperAdmin(item)"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
   
  super-admin-edit-modal(ref="SuperAdminEditModal" @done="reloadList")
