import axios from 'axios';

const fetchReports = (company_id, pagination) => {
  return axios.get(`/api/v1/companies/${company_id}/reports`, { params: { ...pagination}});
};

const fetchScheduledReports = (company_id, pagination) => {
  return axios.get(`/api/v1/companies/${company_id}/reports/scheduled_reports`, { params: { ...pagination}});
};

const fetchScheduledReportTypes = (company_id, pagination) => {
  return axios.get(`/api/v1/companies/${company_id}/reports/scheduled_report_types`, { params: { ...pagination}});
};

const createReport = (company_id, report) => {
  return axios.post(`/api/v1/companies/${company_id}/reports`, report);
};

const deleteReport = (company_id, report_id) => {
  return axios.delete(`/api/v1/companies/${company_id}/reports/${report_id}`);
};

const fetchReportTypes = (company_id) => {
  return axios.get(`/api/v1/companies/${company_id}/reports/report_types`);
};

const showScopeReport = (company_id) => {
  return axios.get(`/api/v1/companies/${company_id}/reports/show_scope_reports`);
};

const downloadReport = (company_id, report_id) => {
  return axios.get(`/api/v1/companies/${company_id}/reports/${report_id}/download`);
};

const restartReport = (company_id, report_id) => {
  return axios.get(`/api/v1/companies/${company_id}/reports/${report_id}/restart`);
};

const updateReport = (company_id, report_id, params) => {
  return axios.patch(`/api/v1/companies/${company_id}/reports/${report_id}`, params);
};

const ApiCompanyReports = {
  fetchReports,
  createReport,
  deleteReport,
  fetchReportTypes,
  downloadReport,
  restartReport,
  updateReport,
  fetchScheduledReports,
  fetchScheduledReportTypes,
  showScopeReport
};

export default ApiCompanyReports;
