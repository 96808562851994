<template lang="pug">
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{$t('automation.title') }}
        .pt-3.px-3
          stack
            stack-item(fill)
              b-form-group(
                :label="$t('automation.name')"
                label-for="company-name-input"
                label-cols="4"
              )
                b-form-input(
                  v-model="search_params.q"
                  type="search"
                  id="company-name-input"
                  @change="reload"
                )
            stack-item
              b-badge(
                variant="secondary"
                v-if="search_params.q"
                @click="search_params.q = null; reload();"
              )
                .mdi.mdi-backspace-outline
            stack-item(fill)
              b-form-group(
                :label="$t('automation.start_date')"
                label-for="start-date-input"
                label-cols="5"
              )
                b-form-input(
                  v-model="search_params.start_date"
                  type="date"
                  value-as-date
                  id="start-date-input"
                  @change="reload"
                )
            stack-item(fill)
              b-form-group(
                :label="$t('automation.end_date')"
                label-for="end-date-input"
                label-cols="5"
              )
                b-form-input(
                  v-model="search_params.end_date"
                  type="date"
                  id="end-date-input"
                  @change="reload"
                )
          stack
            stack-item(fill)
              b-form-group(
                :label="$t('automation.approved')"
                label-for="approved-input"
                label-cols="3"
              )
                b-form-checkbox(
                  v-model="search_params.approved"
                  switch
                  size="lg"
                  id="approved-input"
                  @change="reload"
                )
            stack-item(fill)
              b-form-group(
                :label="$t('automation.wrong_dimensions')"
                label-for="wrong_dimensions-input"
                label-cols="6"
              )
                b-form-checkbox(
                  v-model="search_params.wrong_dimensions"
                  switch
                  size="lg"
                  id="wrong_dimensions-input"
                  @change="reload"
                )
            stack-item(fill)
              b-form-group(
                :label="$t('automation.wrong_accounts')"
                label-for="wrong_accounts-input"
                label-cols="6"
              )
                b-form-checkbox(
                  v-model="search_params.wrong_accounts"
                  switch
                  size="lg"
                  id="wrong_accounts-input"
                  @change="reload"
                )
        .container.table-limit
          rest-table(
            namespace="automation_list"
            :headers="headers"
            ref="automationTable"
            :fetch-handler="getData"
            embed
          )
            template(slot="cel_company_name", slot-scope="{ item }")
              router-link(
                :to="`/companies/${item.id}/edit?tab=automation`"
                label="item.company_name"
                v-if="item.company_name !== 'Total'"
              ) 
                | {{ item.company_name }}
              span(
                v-else
              ) {{ item.company_name }}

</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiAutomation from "@/api/automation.js";
import { DateTime } from "luxon"

export default {
  data() {
    const monthBefore = DateTime.local().minus({month: 1}).toISODate()
    const today = DateTime.local().toISODate();
    return {
      search_params: {
        start_date: monthBefore,
        end_date: today,
        q: null,
        approved: true,
        wrong_dimensions: false,
        wrong_accounts: false
      },
      pagination: {
        sortBy: "company_name"
      },
      headers: [
        {
          key: "company_name",
          label: "Name",
          sortable: true,
          squeeze: true
        },
        {
          key: "netvisor_organization_id",
          label: "BusinessId",
          sortable: true,
          squeeze: true
        },
        {
          key: "invoices_count",
          label: "Invoices",
          sortable: true,
          squeeze: true
        },
        {
          key: "suggested_invoices_count",
          label: "Suggested Invoices",
          sortable: true,
          squeeze: true
        },
        {
          key: "correct_invoices_perc",
          label: "Correct Invoices %",
          sortable: true,
          squeeze: true
        },
        {
          key: "suggested_correct_invoices_perc",
          label: "Suggested Correct Invoices %",
          sortable: true,
          squeeze: true
        },
        {
          key: "lines_count",
          label: "Invoice Lines",
          sortable: true,
          squeeze: true
        },
        {
          key: "suggested_lines_count",
          label: "Suggested Lines",
          sortable: true,
          squeeze: true
        },
        {
          key: "correct_lines_perc",
          label: "Correct Lines %",
          sortable: true,
          squeeze: true
        },
        {
          key: "suggested_correct_lines_perc",
          label: "Suggested Correct Lines %",
          sortable: true,
          squeeze: true
        },
        {
          key: "dimension_correct",
          label: "Dimension Correct Lines",
          sortable: true,
          squeeze: true
        },

        {
          key: "dimension_correct_perc",
          label: "Dimension Correct Lines %",
          sortable: true,
          squeeze: true
        },
        {
          key: "dimension_lines_suggested",
          label: "Dimension Lines Suggested",
          sortable: true,
          squeeze: true
        }
      ]
    };
  },
  methods: {
    reload() {
      this.$refs.automationTable.fetchData();
    },
    getData(pagination) {
      const search_params = this.search_params;
      return new Promise(res => {
        ApiAutomation.FetchList(search_params, pagination).then(resp => {
          res({
            items: [...resp.data.totals, ...resp.data.companies],
            meta: resp.data.meta
          });
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>

.form-group {
  margin-bottom: 0px;
}
</style>
