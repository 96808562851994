import axios from 'axios';

const fetchAccountList = (company_id) => {
  return axios.get(`/api/v1/companies/${company_id}/account_keywords`);
};

const fetchFullAccountList = (company_id, pagination, query) => {
  return axios.get(`/api/v1/companies/${company_id}/account_keywords/full_list`, { params: { ...query, ...pagination }});
};

const fetchReplacementList = (company_id = '', vendor_id = '', pagination = {}) => {
  return axios.get(`/api/v1/replacement_codes?company_id=${company_id}&vendor_id=${vendor_id}`, { params: pagination });
};

const updateAccountKeyword = (company_id, account_keyword_id, account_keyword) => {
  return axios.patch(`/api/v1/companies/${company_id}/account_keywords/${account_keyword_id}`, account_keyword);
};

const deleteAccountKeyword = (company_id, software_id) => {
  return axios.delete(`/api/v1/companies/${company_id}/account_keywords/${software_id}`);
};

const createAccountKeyword = (company_id, account_keyword) => {
  return axios.post(`/api/v1/companies/${company_id}/account_keywords`, account_keyword);
};

const ApiCompanyAccountKeyword = {
  fetchAccountList,
  fetchFullAccountList,
  updateAccountKeyword,
  deleteAccountKeyword,
  createAccountKeyword,
  fetchReplacementList
};

export default ApiCompanyAccountKeyword;