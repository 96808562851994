
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3.mb-5
          stack
            stack-item(fill)
              h1.title {{$t('landing.title') }}
              h2.subtitle {{$t('landing.subtitle') }}
        .container
          b-container.mb-5
            b-row
              b-col(sm="4")
                strong {{$t('landing.first_row.first_col.title') }}
                p {{$t('landing.first_row.first_col.content') }}
              b-col(sm="4")
                strong {{$t('landing.first_row.second_col.title') }}
                p {{$t('landing.first_row.second_col.content') }}
              b-col(sm="4")
                strong {{$t('landing.first_row.third_col.title') }}
                p {{$t('landing.first_row.third_col.content') }}
          b-container
            b-row
              b-col(sm="12")
                strong {{$t('landing.second_row.title') }}
                p {{$t('landing.second_row.subtitle') }}
            b-row
              b-col(sm="6")
                strong {{$t('landing.first_row.first_col.title') }}
                ul
                  li General Ledger
                  li Accounts List
                  li Accounting Period
                  li Customers List
                  li Dimensions List
                  li Products List
                  li Sales Invoices List
                  li Sales Payments List
                  li Sales Personnel List
              b-col(sm="6")
                strong {{$t('landing.first_row.second_col.title') }}
                ul
                  li General Ledger with Sales Invoice & Purchase Invoice details
                  li Customers List With Details
                  li Products List With Details
                  li Sales Invoices List With Details


