import _ from 'lodash';

class User {
  constructor(p = {}) {
    this.id = _.get(p, 'id', null);
    this.email = _.get(p, 'email', null);
    this.name = _.get(p, 'name', '');
    this.avatar = _.get(p, 'avatar', null);
    this.role = _.get(p, 'role', null);

  }
}

export default User;
