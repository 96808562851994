import axios from 'axios';

const FetchLogs = (company_id, query_params, pagination) => {
    return axios.get(`/api/v1/companies/${company_id}/approval_logs`, { params: { ...query_params, ...pagination }});
}

const ApiApprovalLogs = {
    FetchLogs
};

export default ApiApprovalLogs