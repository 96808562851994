<template lang="pug">
.stack(
  :class="containerClasses"
)
  slot
</template>

<script>
const SPACING_XS = "xs";
const SPACING_SM = "sm";
const SPACING_MD = "md";
const SPACING_LG = "lg";
const SPACING_XL = "xl";

export default {
  props: {
    spacing: {
      type: String,
      default: "md",
    },

    wrap: {
      type: Boolean,
      default: false,
    },

    vertical: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sizes: [SPACING_XS, SPACING_SM, SPACING_MD, SPACING_LG, SPACING_XL],
    };
  },

  computed: {
    currentSpacing() {
      return this.sizes.indexOf(this.spacing) >= 0 ? this.spacing : SPACING_MD;
    },

    containerClasses() {
      const classes = {};
      classes[`stack-${this.currentSpacing}`] = true;
      classes["flex-wrap"] = this.wrap;
      classes["flex-column"] = this.vertical;
      return classes;
    },
  },
};
</script>

<style lang="scss">
$xs: 5px;
$sm: 10px;
$md: 15px;
$lg: 20px;
$xl: 30px;

$spacing: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
);

.stack {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  @each $name, $value in $spacing {
    &.stack-#{$name} {
      margin-top: -$value;
      margin-left: -$value;

      & > .stack-item {
        padding-top: $value;
        padding-left: $value;
      }
    }
  }

  & > .stack-item {
    min-width: 0;
  }
}

.stack-item {
  flex-grow: 0;
  flex-shrink: 0;
}

.stack-item.stack-item--fill {
  flex-grow: 1;
  flex-shrink: 0;
}
</style>
