<template lang="pug">
  div
    table.table-sm
      tbody()
        tr(v-for="(item, field) in this.vendor" :key="field")
          td.font-weight-bold {{humanize(field)}}
          td {{item}}

</template>
<script>
export default {
  props: ['vendor'],

  methods: {
    humanize(str) {
      return str
          .replace(/^[\s_]+|[\s_]+$/g, '')
          .replace(/[_\s]+/g, ' ')
          .replace(/^[a-z]/, function(m) { return m.toUpperCase(); });
    }
  }
}

</script>
