<template lang="pug">
  router-link.sidebar-nav-item(:to="to")
    .sidebar-nav-item-icon
      .mdi(:class="`mdi-${ icon }`")
    .sidebar-nav-item-label
      slot
</template>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      required: true
    },
    icon: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheet/_variables.scss";
@import "@/assets/stylesheet/_functions.scss";

.sidebar-nav-item {
  color: $bb-warm-gray-700;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  transition: all 0.15s ease-in-out;

  &.router-link-active {
    color: $bb-warm-gray-900;
    background: rgba($black, 0.1);

    & .sidebar-nav-item-icon {
      color: $primary;
    }
  }

  &:hover {
    color: $white;
    text-decoration: none;
    background: rgba($white, 0.1);
  }
}

.sidebar-nav-item-icon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  line-height: 24px;
  font-size: 24px;
  color: $bb-warm-gray-600;
  transition: all 0.15s ease-in-out;
}

.sidebar-nav-item-label {
  font-weight: 300;
}
</style>