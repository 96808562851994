<template lang="pug">
UiSelect(
  @input="$emit('input', $event)"
  :value="value"
  :options="getCompanies"
  object
)
</template>

<script>
import ApiCompanies from "@/api/companies.js";

export default {
  props: {
    value: {
      default: null,
    },
  },
  methods: {
    async getCompanies(params) {
      const response = await ApiCompanies.fetchCompanies(
        {
          company_name: params.search,
          page: params.page,
          per_page: params.itemsPerPage,
        },
        undefined,
        {
          signal: params.abortController.signal,
        }
      );
      return {
        items: response?.data?.companies?.map(({ id, company_name }) => ({
          label: company_name,
          value: id,
        })),
      };
    },
  },
};
</script>
