// import RestClient from '@/common/rest_api';
import axios from "axios";

const processedCompanies = (search_params, pagination) => {
    return axios.get(`/api/v1/processed_companies`, { params: { ...search_params, ...pagination }});
}

const startLearning = (company_id) => {
    return axios.post(`/api/v1/processed_companies/${company_id}/relearn`);
}

const checkRelearnQueue = () => {
    return axios.get(`/api/v1/processed_companies/check_relearn_queue`)
}

const setStartDate = (company_id, date) => {
    return axios.post(`/api/v1/processed_companies/${company_id}/set_start_date`, { start_date: date });
}

const ApiAdminTools = {
    processedCompanies,
    startLearning,
    checkRelearnQueue,
    setStartDate
};

export default ApiAdminTools;
