
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('resellers.tabs.pricing') }}
      div.form-container.mt-5
        b-form-group(
          :label="$t('resellers.line_price')"
          label-for="line-price-input"
          label-cols="4"
        )
          b-form-input(
            v-model="reseller.line_price"
            type="number"
            id="line-price-input"
          )

        b-form-group(
          :label="$t('resellers.invoice_price')"
          label-for="invoice-price-input"
          label-cols="4"
        )
          b-form-input(
            v-model="reseller.invoice_price"
            type="number"
            id="invoice-price-input"
          )

        b-form-group(
          :label="$t('resellers.every_invoice_price')"
          label-for="every-invoice-price-input"
          label-cols="4"
        )
          b-form-input(
            v-model="reseller.every_invoice_price"
            type="number"
            id="every-invoice-price-input"
          )
        stack
          stack-item(fill)
          stack-item
            b-btn(
              variant="primary"
              squared
              @click="save"
            )
              .mdi.mdi-account-check-outline
              | {{ $t('actions.save') }}
