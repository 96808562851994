<template lang="pug">
transition(name="fade")
  .overlay-loader(
    v-if="show"
    :class="{ fixed: fixed }"
  )
    .overlay-loader__waves
      span(
        v-for="i in 2"
        :style="{ 'animation-delay': `${initialDelay + (i - 1) * nextDelay}ms` }"
      )
    .overlay-loader__content
      .overlay-loader__logotype
        .logotype.centred
      .overlay-loader__description(v-if="label") {{ label }}
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },

    fixed: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      initialDelay: 500,
      nextDelay: 500
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/_variables.scss';

.overlay-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($bb-gray-900, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;

  &.fixed {
    position: fixed;
  }

  &__waves {
    position: absolute;
    top: 50%;
    left: 50%;

    & span {
      position: absolute;
      top: -50vmin;
      left: -50vmin;
      width: 100vmin;
      height: 100vmin;
      background: $bb-gray-600;
      border-radius: 50%;
      opacity: 0;
      animation: pulse 1.5s ease-out infinite;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__logotype {
    width: 120px;

    & > * {
      width: 100%;
    }
  }

  &__description {
    margin-top: 30px;
    color: $bb-gray-600;
  }
}
</style>