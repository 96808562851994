<template lang="pug">
b-modal(
  centered
  v-model="show"
)
  template(slot="modal-title")
    div
      .modal-title {{ $t('resellers.edit') }}
      .modal-subtitle {{ $t('resellers.edit_description') }}
  b-form-group(
    :label="$t('resellers.name')"
    label-for="name-input"
    label-cols="4"
    :invalid-feedback="errors.first('name')"
    :state="errors.has('name') ? false : null"
  )
    b-input(
      v-model="form.name"
      id="name-input"
      v-validate="'required'"
      data-vv-name="name"
      :data-vv-as="$t('resellers.name')"
    )
  b-form-group(
    :label="$t('resellers.email')"
    label-for="email-input"
    label-cols="4"
    :invalid-feedback="errors.first('email')"
    :state="errors.has('email') ? false : null"
  )
    b-input(
      v-model="form.email"
      type="email"
      id="email-input"
      v-validate="'required|email'"
      data-vv-name="email"
      :data-vv-as="$t('resellers.email')"
    )
  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )  
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
</template>

<script>
import { mapGetters } from 'vuex';
import Reseller from '@/models/Reseller';
import ApiResellers from '@/api/resellers';

export default {
  $_veeValidate: {
    validator: 'new'
  },

  data() {
    return {
      show: false,
      isInvite: false,
      resellerId: null,
      form: {}
    };
  },

  methods: {
    open(resellerId, resellerUser) {
      if (resellerUser) {
        this.isInvite = false;
        this.form = {
          id: resellerUser.id,
          name: resellerUser.name,
          email: resellerUser.email
        }
      } else {
        this.isInvite = true;
        this.form = {
          name: '',
          email: ''
        }
      }
      this.resellerId = resellerId;
      this.show = true;
    },

    close() {
      this.show = false;
    },

    save() {
      this.$validator.validateAll().then(res => {
        if (!res) return;
        const form = this.form;
        const params = {
          name: form.name,
          email: form.email
        }
        const resellerId = this.resellerId;
        if (this.isInvite) {
          ApiResellers.inviteResellerUser(resellerId, params)
            .then(res => {
              this.$emit('done');
              this.close();
            })
            .catch(e => {
            });
        } else {
          ApiResellers.updateResellerUser(resellerId, form.id, params)
            .then(res => {
              this.$emit('done');
              this.close();
            })
            .catch(e => {
            });
        }
      });
    }
  }
};
</script>