
div
  //.container
  //  price-limit-form
  .container
    stack
      stack-item(fill)
        .h3.mb-0 {{ $t('companies.approvers.approval_flow') }}
      stack-item
        b-btn(
          variant="primary",
          squared,
          @click="exportApprovalSettings()"
        )
          .mdi.mdi-download
          | {{ $t('companies.export_to_excel') }}
    .pt-3.px-3
    rest-table(
      :disable-pagination="true"
      namespace="approval_flow",
      :headers="headers",
      ref="approvalFlowTable",
      :fetch-handler="getData",
      embed
    )
      template(slot="cel_name", slot-scope="{ item }")
        stack(spacing="xs")
          stack-item
            | {{ APPROVAL_FLOWS[item.name] }}
      template(slot="cel_actions", slot-scope="{ item }")
        stack(spacing="xs")
          stack-item
            b-btn(
              size="xs",
              variant="primary",
              squared,
              @click="upApprovalFlow(item)"
            )
              .mdi.mdi-arrow-up
              | Up
          stack-item
            b-btn(
              size="xs",
              variant="primary",
              squared,
              @click="downApprovalFlow(item)"
            )
              .mdi.mdi-arrow-down
              | Down
      template(slot="cel_is_active", slot-scope="{ item }")
        b-form-checkbox(
          v-model="item.is_active",
          switch,
          @change="changeEnabled(item)"
        )

