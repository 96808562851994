import axios from 'axios';

const fetchSoftwares = (company_id) => {
  return axios.get(`/api/v1/companies/${company_id}/softwares`);
};

const getSoftware = (company_id, software_id) => {
  return axios.get(`/api/v1/companies/${company_id}/softwares/${software_id}`);
};

const createSoftware = (company_id, software) => {
  return axios.post(`/api/v1/companies/${company_id}/softwares`, software);
};

const updateSoftware = (company_id, software_id, software) => {
  return axios.patch(`/api/v1/companies/${company_id}/softwares/${software_id}`, software);
};

const deleteSoftware = (company_id, software_id) => {
  return axios.delete(`/api/v1/companies/${company_id}/softwares/${software_id}`);
};

const fetchErrors = (company_id, software_id) => {
  return axios.get(`/api/v1/companies/${company_id}/softwares/${software_id}/errors`);
}; 

const getProcountorLink = (company_id) => {
  return axios.get(`/api/v1/companies/${company_id}/softwares/procountor_link`);
};

const ApiCompanySoftwares = {
  fetchSoftwares,
  getSoftware,
  createSoftware,
  updateSoftware,
  deleteSoftware,
  fetchErrors,
  getProcountorLink
};

export default ApiCompanySoftwares;
