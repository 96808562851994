
b-modal(
  centered
  v-model="show"
)
  template(slot="modal-title")
    div
      .modal-title {{ $t('resellers.edit') }}
      .modal-subtitle {{ $t('resellers.edit_description') }}
  b-form-group(
    :label="$t('resellers.name')"
    label-for="name-input"
    label-cols="4"
    :invalid-feedback="errors.first('name')"
    :state="errors.has('name') ? false : null"
  )
    b-input(
      v-model="form.name"
      id="name-input"
      v-validate="'required'"
      data-vv-name="name"
      :data-vv-as="$t('resellers.name')"
    )

  b-form-group(
    :label="$t('resellers.business_id')"
    label-for="business-id-input"
    label-cols="4"
    :invalid-feedback="errors.first('company_id')"
    :state="errors.has('company_id') ? false : null"
  )
    b-input(
      v-model="form.company_id"
      id="business-id-input"
      v-validate="'required'"
      data-vv-name="company_id"
      :data-vv-as="$t('resellers.business_id')"
    )

  b-form-group(
    :label="$t('resellers.einvoice_address')"
    label-for="einvoice-address-input"
    label-cols="4"
    :invalid-feedback="errors.first('einvoice_address')"
    :state="errors.has('einvoice_address') ? false : null"
  )
    b-input(
      v-model="form.einvoice_address"
      id="einvoice-address-input"
      v-validate="'required'"
      data-vv-name="einvoice_address"
      :data-vv-as="$t('resellers.einvoice_address')"
    )

  b-form-group(
    :label="$t('resellers.einvoice_operator_address')"
    label-for="einvoice-operator-address-input"
    label-cols="4"
    :invalid-feedback="errors.first('einvoice_operator_address')"
    :state="errors.has('einvoice_operator_address') ? false : null"
  )
    b-input(
      v-model="form.einvoice_operator_address"
      id="einvoice-operator-address-input"
      v-validate="'required'"
      data-vv-name="einvoice_operator_address"
      :data-vv-as="$t('resellers.einvoice_operator_address')"
    )

  b-form-group(
    :label="$t('resellers.domain')"
    label-for="domain-input"
    label-cols="4"
  )
    b-input(
      v-model="form.domain"
      id="domain-input"

    )

  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
