
.avatar-box(
  :class="avatarClasses"
)
  img(
    v-if="src"
    :src="src"
  )
  no-avatar(
    v-else
    :label="label"
  )
