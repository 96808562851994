
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.softwares') }}
        stack-item
          b-btn(variant="primary", squared, @click="addSoftware")
            .mdi.mdi-lan-connect
            | {{ $t('actions.add') }}
        stack-item
          b-btn(
            v-if="basWareUploadButton"
            variant="primary",
            squared,
            @click="uploadArchive"
            )
            .mdi.mdi-briefcase-upload-outline
            | {{ $t('companies.softwares.upload_learning_data') }}
        stack-item
          b-btn(
            v-if="basWareUploadButton"
            variant="primary",
            squared,
            @click="forceLearning"
            )
            .mdi.mdi-school-outline
            | {{ $t('companies.softwares.force_learning') }}
      rest-table(
        namespace="company_softwares",
        :headers="headers",
        ref="companySoftwaresTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="showErrors(item)",
                v-if="item.has_errors"
              )
                .mdi.mdi-alert-box-outline
                | {{ $t('companies.softwares.show_errors') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                @click="editSoftware(item)"
              ) 
                .mdi.mdi-pencil-outline
                | {{ $t('actions.edit') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteSoftware(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
      stack(v-if="errors.length > 0")
        stack-item(fill)
        stack-item
          b-btn(
            size="xs",
            variant="flat-primary",
            squared,
            @click="hideErrors"
          )
            .mdi.mdi-eye-off-outline
            | {{ $t('companies.softwares.hide_errors') }}
      data-table(
        :embed="true",
        :progress="inProgress",
        :headers="errorsHeaders",
        :items="errors",
        ref="softwareErrorsTable",
        :disablePagination="true",
        v-if="errors.length > 0"
      )
    edit-software-modal(ref="EditSoftwareModal", @done="reloadList")
    upload-learning-archive-modal(ref="UploadLearningArchiveModal")
  storages(v-if="storages_show")
  company-api-keys
