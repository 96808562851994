import axios from 'axios';

const fetchLogs = (search_params, pagination) => {
    return axios.get(`/api/v1/logs`, { params: { ...search_params, ...pagination }});
};

const ApiLogs = {
    fetchLogs
};

export default ApiLogs;