// Flags
import FlagEN from '@/assets/images/flags/united-kingdom.svg';
import FlagFI from '@/assets/images/flags/finland.svg';

// Application dictionaries
import JSONLocaleEN from '@/localization/locales/en.json';
import JSONLocaleFI from '@/localization/locales/fi.json';

// Validator dictionaries
import ValidatorLocaleEN from 'vee-validate/dist/locale/en';
import ValidatorLocaleFI from 'vee-validate/dist/locale/fi';

const locales = [
  {
    label: 'English',
    value: 'en',
    img: FlagEN,
    localeApp: JSONLocaleEN,
    localeValidator: ValidatorLocaleEN,
    dateTimeFormats: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
  {
    label: 'Finnish',
    value: 'fi',
    img: FlagFI,
    localeApp: JSONLocaleFI,
    localeValidator: ValidatorLocaleFI,
    dateTimeFormats: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
];

export default locales;
