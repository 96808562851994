
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{$t('billing.title') }}
        .container.table-limit
          rest-table(
            namespace="billing"
            :headers="headers"
            ref="billingTable"
            :fetch-handler="getData"
            embed
          )
            template(slot="cel_name", slot-scope="{ item }")
              router-link(
                :to="`/billing/${item.id}/show`"
                label="item.name"
              )
                | {{ item.name }}
