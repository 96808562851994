import axios from 'axios';

const fetchVouchers = (company_id, query_params, pagination) => {
    return axios.get(`/api/v1/companies/${company_id}/scope_vouchers`, { params: { ...query_params, ...pagination }});
};

const fetchStates = (company_id) => {
    return axios.get(`/api/v1/companies/${company_id}/scope_vouchers/states`);
};

const restartVouchers = (company_id) => {
    return axios.get(`/api/v1/companies/${company_id}/scope_vouchers/restart`);
};

const deleteFailedInvoices = (company_id) => {
    return axios.get(`/api/v1/companies/${company_id}/scope_vouchers/delete_failed`);
};

const deleteProcessedInvoices = (company_id) => {
    return axios.get(`/api/v1/companies/${company_id}/scope_vouchers/delete_processed`);
};

const deleteVoucher = (company_id, voucher_id) => {
    return axios.delete(`/api/v1/companies/${company_id}/scope_vouchers/${voucher_id}`)
}

const ApiScopeVouchers = {
    fetchVouchers,
    fetchStates,
    restartVouchers,
    deleteFailedInvoices,
    deleteProcessedInvoices,
    deleteVoucher
}

export default ApiScopeVouchers;