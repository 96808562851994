
.ui-select(ref="select")
  .ui-select-input(@click="onInputClick" :class="inputClasses")
    .ui-select-input__value-container
      .ui-select-input__placeholder(v-if="!hasSelected") 
        | {{ $t(multiselect ? multiselectPlaceholder : placeholder) }}
      template(v-else)
        .ui-select-input__selected-tags(v-if="multiselect")
          .ui-select-input__selected-tag(v-for="option in valueObject")
            UiTag(@close="toggleOption(option)") {{ getOptionLabel(option) }}
        .ui-select-input__selected-value(v-else) {{ getOptionLabel(valueObject) }}
    .ui-select-input__actions-container
      //- .ui-select-input__action.ui-select-input__action-clear
      .ui-select-input__action(v-if="serverOptions.loading")
        b-spinner.ui-select-input__spinner(variant="primary" small)
      .ui-select-input__action(v-else)
        .ui-select-input__arrow
  .ui-select-dropdown(v-if="opened")
    .ui-select-dropdown__search(v-if="searchable")
      input.ui-select-dropdown__search-input(
        :value="search"
        @input="inputSearch"
        type="text"
        autofocus
        :placeholder="$t(searchPlaceholder)"
      )
    .ui-select-dropdown__list-selected-tags(v-if="hasSelected && multiselect")
      .ui-select-dropdown__list-selected-tag(v-for="option in valueObject")
        UiTag(@close="toggleOption(option)") {{ getOptionLabel(option) }}
    .ui-select-dropdown__list-container
      .ui-select-dropdown__list-option(
        v-for="item in extendedOptionsList"
        @click="toggleOption(item.option)"
        :class="getOptionClasses(item)"
      )
        .ui-select-dropdown__list-option-checkbox(v-if="multiselect")
          b-checkbox(:checked="item.selected")
        .ui-select-dropdown__list-option-label {{ getOptionLabel(item.option) }}
        .ui-select-dropdown__list-option-checked(v-if="!multiselect && item.selected")
          .mdi.mdi-check
