import axios from 'axios';

const uploadFile = (company_id, file) => {
    return axios.post(`/api/v1/companies/${company_id}/basware_files`, file, { headers: { 'Content-Type': 'multipart/form-data'} })
}

const forceLearning = (company_id) => {
    return axios.get(`/api/v1/companies/${company_id}/basware_files/force_learning`)
}

const ApiBaswareFiles = {
    uploadFile,
    forceLearning
};

export default ApiBaswareFiles;