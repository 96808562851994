
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{$t('resellers.title') }}
            stack-item
              b-btn(
                variant="primary"
                squared
                @click="addReseller"
              ) 
                .mdi.mdi-account-plus-outline
                | {{ $t('actions.add') }}
        rest-table(
          namespace="resellers"
          :headers="headers"
          ref="resellersTable"
          :fetch-handler="getData"
          embed
        )
          template(slot="cel_name" slot-scope="{ item }")
            router-link(
              :to="`/resellers/${item.id}/edit`"
              label="item.name"
            )
              | {{ item.name }}
          template(slot="cel_actions" slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs"
                  variant="flat-danger"
                  squared
                  @click="deleteReseller(item)"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
  create-reseller-modal(ref="ResellerCreateModal" @done="reloadList")
