<template lang="pug">
  b-modal(centered, v-model="show")
    template(slot="modal-title")
      div(v-if="isCreate")
        .modal-title {{ $t('companies.softwares.create_title') }}
        .modal-subtitle {{ $t('companies.softwares.create_description') }}
      div(v-else)
        .modal-title {{ $t('companies.softwares.edit_title') }}
        .modal-subtitle {{ $t('companies.softwares.edit_description') }}

    b-form-group(:label="$t('companies.softwares.software')")
      b-select(v-model="form.name", :options="softwaresOptions")

    template
      div(v-if="form.name == 'netvisor'")
        b-form-group(
          :label="$t('companies.softwares.netvisor_user_id')",
          :invalid-feedback="errors.first('netvisor_user_id')",
          :state="errors.has('netvisor_user_id') ? false : null"
        )
          b-input(
            v-model="form.options.netvisor_user_id",
            v-validate="'required'",
            data-vv-name="netvisor_user_id",
            :data-vv-as="$t('companies.softwares.netvisor_user_id')"
          )

        b-form-group(:label="$t('companies.softwares.netvisor_user_secret')")
          b-input(v-model="form.options.netvisor_user_secret")

    b-form-group(
      :label="$t('companies.softwares.fortnox_auth_code')",
      v-if="form.name == 'fortnox'"
    )
      b-input(v-model="form.options.fortnox_auth_code")
    b-link(
      v-if="form.name == 'procountor' && procountorLink",
      :href="procountorLink"
    ) {{ $t('companies.softwares.procountor_link') }}

    b-form-group(
      :label="$t('companies.softwares.maventa_user_api_key')",
      v-if="form.name == 'maventa'",
      :invalid-feedback="errors.first('maventa_user_api_key')",
      :state="errors.has('maventa_user_api_key') ? false : null"
    )
      b-input(
        v-model="form.options.user_api_key"
        v-validate="'required'",
        data-vv-name="maventa_user_api_key",
        :data-vv-as="$t('companies.softwares.maventa_user_api_key')"
        )

    b-form-group(
      :label="$t('companies.softwares.maventa_company_uuid')",
      v-if="form.name == 'maventa'",
      :invalid-feedback="errors.first('maventa_company_uuid')",
      :state="errors.has('maventa_company_uuid') ? false : null"
    )
      b-input(
        v-model="form.options.company_uuid"
        v-validate="'required'",
        data-vv-name="maventa_company_uuid",
        :data-vv-as="$t('companies.softwares.maventa_company_uuid')"
        )
    template(v-if="form.name == 'netsuite'")
      b-form-group(
        :label="$t('companies.softwares.netsuite_consumer_key')",
        :invalid-feedback="errors.first('netsuite_consumer_key')",
        :state="errors.has('netsuite_consumer_key') ? false : null"
      )
        b-input(
          v-model="form.options.netsuite_consumer_key",
          v-validate="'required'",
          data-vv-name="netsuite_consumer_key",
          :data-vv-as="$t('companies.softwares.netsuite_consumer_key')"
        )

      b-form-group(
        :label="$t('companies.softwares.netsuite_consumer_secret')",
        :invalid-feedback="errors.first('netsuite_consumer_secret')",
        :state="errors.has('netsuite_consumer_secret') ? false : null"
      )
        b-input(
          v-model="form.options.netsuite_consumer_secret",
          v-validate="'required'",
          data-vv-name="netsuite_consumer_secret",
          :data-vv-as="$t('companies.softwares.netsuite_consumer_secret')"
        )

      b-form-group(
        :label="$t('companies.softwares.netsuite_access_token')",
        :invalid-feedback="errors.first('netsuite_access_token')",
        :state="errors.has('netsuite_access_token') ? false : null"
      )
        b-input(
          v-model="form.options.netsuite_access_token",
          v-validate="'required'",
          data-vv-name="netsuite_access_token",
          :data-vv-as="$t('companies.softwares.netsuite_access_token')"
        )

      b-form-group(
        :label="$t('companies.softwares.netsuite_access_secret')",
        :invalid-feedback="errors.first('netsuite_access_secret')",
        :state="errors.has('netsuite_access_secret') ? false : null"
      )
        b-input(
          v-model="form.options.netsuite_access_secret",
          v-validate="'required'",
          data-vv-name="netsuite_access_secret",
          :data-vv-as="$t('companies.softwares.netsuite_access_secret')"
        )

      b-form-group(
        :label="$t('companies.softwares.netsuite_realm')",
        :invalid-feedback="errors.first('netsuite_realm')",
        :state="errors.has('netsuite_realm') ? false : null"
      )
        b-input(
          v-model="form.options.netsuite_realm",
          v-validate="'required'",
          data-vv-name="netsuite_realm",
          :data-vv-as="$t('companies.softwares.netsuite_realm')"
        )

      b-form-group(
        :label="$t('companies.softwares.netsuite_script_id')",
        :invalid-feedback="errors.first('netsuite_script_id')",
        :state="errors.has('netsuite_script_id') ? false : null"
      )
        b-input(
          v-model="form.options.netsuite_script_id",
          v-validate="'required'",
          data-vv-name="netsuite_script_id",
          :data-vv-as="$t('companies.softwares.netsuite_script_id')"
        )

      b-form-group(
        :label="$t('companies.softwares.netsuite_subsidiary_id')",
        :invalid-feedback="errors.first('netsuite_subsidiary_id')",
        :state="errors.has('netsuite_subsidiary_id') ? false : null"
      )
        b-input(
          v-model="form.options.netsuite_subsidiary_id",
          v-validate="'required'",
          data-vv-name="netsuite_subsidiary_id",
          :data-vv-as="$t('companies.softwares.netsuite_subsidiary_id')"
        )

    template(v-if="form.name == 'basware'")
      b-form-group(
        :label="$t('companies.softwares.basware_company_id')",
        :invalid-feedback="errors.first('basware_company_id')",
        :state="errors.has('basware_company_id') ? false : null"
      )
        b-input(
          v-model="form.options.basware_company_id",
          v-validate="'required'",
          data-vv-name="basware_company_id",
          :data-vv-as="$t('companies.softwares.basware_company_id')"
        )
      b-form-group(
        :label="$t('companies.softwares.basware_vendors_folder')",
      )
      stack
        stack-item(fill)
          b-form-group(
            :label="$t('companies.softwares.storages')",
            :invalid-feedback="errors.first('basware_vendors_storage_id')",
            label-for="vendors-storage-id",
            label-cols="3",
            :state="errors.has('basware_vendors_storage_id') ? false : null"
          )
            b-select#vendors-storage-id(
              :value="form.options.basware_vendors_storage_id",
              v-validate="'required'",
              data-vv-name="basware_vendors_storage_id",
              :options="storageOptions",
              :disabled="loading",
              @input="clearFolder($event, 'basware_vendors_folder', 'basware_vendors_storage_id')"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.softwares.folder')",
            :invalid-feedback="errors.first('basware_vendors_folder')",
            label-for="vendors-folder",
            label-cols="3",
            :state="errors.has('basware_vendors_folder') ? false : null"
          )
            b-select#vendors-folder(
              v-model="form.options.basware_vendors_folder",
              v-validate="'required'",
              data-vv-name="basware_vendors_folder",
              :options="getFoldersList(form.options.basware_vendors_storage_id)",
              :disabled="loading"
            )
      b-form-group(
        :label="$t('companies.softwares.basware_dimensions_folder')",
      )
      stack
        stack-item(fill)
          b-form-group(
            :label="$t('companies.softwares.storages')",
            :invalid-feedback="errors.first('basware_dimensions_storage_id')",
            label-for="dimensions-storage-id",
            label-cols="3",
            :options="getFoldersList(form.options.basware_dimensions_storage_id)",
            :state="errors.has('basware_dimensions_storage_id') ? false : null"
          )
            b-select#dimensions-storage-id(
              :value="form.options.basware_dimensions_storage_id",
              v-validate="'required'",
              data-vv-name="basware_dimensions_storage_id",
              :options="storageOptions",
              :disabled="loading",
              @input="clearFolder($event, 'basware_dimensions_folder', 'basware_dimensions_storage_id')"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.softwares.folder')",
            :invalid-feedback="errors.first('basware_dimensions_folder')",
            label-for="dimensions-folder",
            label-cols="3",
            :state="errors.has('basware_dimensions_folder') ? false : null"
          )
            b-select#dimensions-folder(
              v-model="form.options.basware_dimensions_folder",
              v-validate="'required'",
              data-vv-name="basware_dimensions_folder",
              :options="getFoldersList(form.options.basware_dimensions_storage_id)",
              :disabled="loading"
            )
      b-form-group(
        :label="$t('companies.softwares.basware_invoices_folder')",
      )
      stack
        stack-item(fill)
          b-form-group(
            :label="$t('companies.softwares.storages')",
            :invalid-feedback="errors.first('basware_invoices_storage_id')",
            :state="errors.has('basware_invoices_storage_id') ? false : null"
            label-for="invoices-storage-id",
            label-cols="3"
          )
            b-select#invoices-storage-id(
              :value="form.options.basware_invoices_storage_id",
              v-validate="'required'",
              data-vv-name="basware_invoices_storage_id",
              :options="storageOptions",
              :disabled="loading",
              @input="clearFolder($event, 'basware_invoices_folder', 'basware_invoices_storage_id')"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.softwares.folder')",
            :invalid-feedback="errors.first('basware_invoices_folder')",
            :state="errors.has('basware_invoices_folder') ? false : null"
            label-for="invoices-folder",
            label-cols="3"
          )
            b-select#dimensions-folder(
              v-model="form.options.basware_invoices_folder",
              v-validate="'required'",
              data-vv-name="basware_invoices_folder",
              :options="getFoldersList(form.options.basware_invoices_storage_id)",
              :disabled="loading"
            )
      b-form-group(
        :label="$t('companies.softwares.basware_vouchers_folder')",
      )
      stack
        stack-item(fill)
          b-form-group(
            :label="$t('companies.softwares.storages')",
            :invalid-feedback="errors.first('basware_vouchers_storage_id')",
            :state="errors.has('basware_vouchers_storage_id') ? false : null",
            label-for="vouchers-storage-id",
            label-cols="3"
          )
            b-select#vouchers-storage-id(
              :value="form.options.basware_vouchers_storage_id",
              v-validate="'required'",
              data-vv-name="basware_vouchers_storage_id",
              :options="storageOptions",
              :disabled="loading",
              @input="clearFolder($event, 'basware_vouchers_folder', 'basware_vouchers_storage_id')"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.softwares.folder')",
            :invalid-feedback="errors.first('basware_vouchers_folder')",
            :state="errors.has('basware_vouchers_folder') ? false : null",
            label-for="vouchers-folder",
            label-cols="3"
          )
            b-select#dimensions-folder(
              v-model="form.options.basware_vouchers_folder",
              v-validate="'required'",
              data-vv-name="basware_vouchers_folder",
              :options="getFoldersList(form.options.basware_vouchers_storage_id)",
              :disabled="loading"
            )
      b-form-group(
        :label="$t('companies.softwares.basware_learning_folder')",
      )
      stack
        stack-item(fill)
          b-form-group(
            :label="$t('companies.softwares.storages')",
            :invalid-feedback="errors.first('basware_learning_storage_id')",
            :state="errors.has('basware_learning_storage_id') ? false : null",
            label-for="learning-storage-id",
            label-cols="3"
          )
            b-select#learning-storage-id(
              :value="form.options.basware_learning_storage_id",
              v-validate="'required'",
              data-vv-name="basware_learning_storage_id",
              :options="storageOptions",
              :disabled="loading",
              @input="clearFolder($event, 'basware_learning_folder', 'basware_learning_storage_id')"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.softwares.folder')",
            :invalid-feedback="errors.first('basware_learning_folder')",
            :state="errors.has('basware_learning_folder') ? false : null",
            label-for="learning-folder",
            label-cols="3"
          )
            b-select#dimensions-folder(
              v-model="form.options.basware_learning_folder",
              v-validate="'required'",
              data-vv-name="basware_learning_folder",
              :options="getFoldersList(form.options.basware_learning_storage_id)",
              :disabled="loading"
            )
      b-form-group(
        :label="$t('companies.softwares.basware_suggested_invoices_folder')",
      )
      stack
        stack-item(fill)
          b-form-group(
            :label="$t('companies.softwares.storages')",
            :invalid-feedback="errors.first('basware_suggested_invoices_storage_id')",
            :state="errors.has('basware_suggested_invoices_storage_id') ? false : null",
            label-for="suggested_invoices-storage-id",
            label-cols="3"
          )
            b-select#suggested_invoices-storage-id(
              :value="form.options.basware_suggested_invoices_storage_id",
              v-validate="'required'",
              data-vv-name="basware_suggested_invoices_storage_id",
              :options="storageOptions",
              :disabled="loading",
              @input="clearFolder($event, 'basware_suggested_invoices_folder', 'basware_suggested_invoices_storage_id')"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.softwares.folder')",
            :invalid-feedback="errors.first('basware_suggested_invoices_folder')",
            :state="errors.has('basware_suggested_invoices_folder') ? false : null",
            label-for="suggested_invoices-folder",
            label-cols="3"
          )
            b-select#dimensions-folder(
              v-model="form.options.basware_suggested_invoices_folder",
              v-validate="'required'",
              data-vv-name="basware_suggested_invoices_folder",
              :options="getFoldersList(form.options.basware_suggested_invoices_storage_id)",
              :disabled="loading"
            )
    template(slot="modal-footer")
      b-btn(variant="primary", squared, @click="save")
        .mdi.mdi-account-check-outline
        | {{ $t('actions.save') }}
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
import ApiCompanySoftwares from "@/api/companySoftwares.js";
import ApiCompanyStorages from "@/api/companyStorages.js";

export default {
  $_veeValidate: {
    validator: "new",
  },

  components: {
    ValidationProvider,
  },

  data() {
    return {
      show: false,
      isCreate: false,
      companyId: null,
      procountorLink: null,
      form: {},
      storageOptions: [],
      storagePath: {},
      foldersList: {},
      loading: false,
      softwaresOptions: [
        { text: "Netvisor",   value: "netvisor" },
        { text: "Procountor", value: "procountor" },
        { text: "FortNox",    value: "fortnox" },
        { text: "NetSuite", value: "netsuite" },
        { text: "BasWare", value: "basware" },
        { text: "Maventa",    value: "maventa" },
      ],
    };
  },

  methods: {
    open(companyId, Software) {
      if (Software) {
        this.isCreate = false;
        this.form = {
          id: Software.id,
          name: (Software.name || "").toLowerCase(),
          options: Software.options,
        };
      } else {
        this.isCreate = true;
        this.form = {
          name: "netvisor",
          options: {},
        };
      }
      this.companyId = companyId;
      this.show = true;
      this.getProcountorLink();
      this.getSftpConnections(); //TODO - only for basware
    },

    close() {
      this.show = false;
    },

    getProcountorLink() {
      if (this.procountorLink) {
        return;
      }
      ApiCompanySoftwares.getProcountorLink(this.companyId).then((resp) => {
        this.procountorLink = resp.data.link;
      });
    },

    clearFolder(event, folder, field) {
      this.form = {
        ...this.form, options: {
          ...this.form.options, [folder]: null, [field]: event
        }
      }
    },

    save() {
      this.$validator.validateAll().then((res) => {
        if (!res) return;
        const form = this.form;
        const params = {
          software: {
            name: form.name,
            options: form.options
          }
        };
        const companyId = this.companyId;
        if (this.isCreate) {
          ApiCompanySoftwares.createSoftware(companyId, params)
              .then((res) => {
                this.$emit("done");
                this.close();
              })
              .catch((e) => {});
        } else {
          ApiCompanySoftwares.updateSoftware(companyId, form.id, params)
              .then((res) => {
                this.$emit("done");
                this.close();
              })
              .catch((e) => {});
        }
      });
    },

    getFoldersList(sftp_id) {
      if(sftp_id === undefined){
        return []
      } else {
        return this.foldersList[sftp_id]
      }
    },

    getSftpConnections() {
      this.loading = true;

      ApiCompanyStorages.fetchStorages(this.$route.params.id).then(
          (resp) => {
            this.storageOptions = resp.data.sftp.map(function(connection){
              return  { text: connection.name, value: connection.id }
            })

            const folders = resp.data.sftp.map(element => {
              this.storagePath[element.id] = element.remote_path
              return ApiCompanyStorages.SFTPFolderList(this.$route.params.id, element.remote_path, element.id).then(
                  (resp_folders) => {
                    this.foldersList = { ...this.foldersList,  [element.id]: resp_folders.data.folders }
                    this.foldersList[element.id].unshift('/')
                  }
              );
            });
            Promise.allSettled(folders).then(() =>{
              this.loading = false;
            })
          });
    }
  },
};
</script>