<template lang="pug">
div
  .pb-5
    .company-hero 
      .container
        stack.align-items-start
          stack-item(fill)
            .h1.mb-3 {{ companyName}}
            div
              stack(spacing="xl")
                stack-item
                  .company-info
                    span {{ $t('company.business_id') }}:
                    | {{ companyBusinessId }}
      .container(v-if="!signed && !failed")
        b-card.whiteboard(v-if="!inProgress")
          stack.align-items-center
            stack-item(fill)
              .mt4.text-container
                p Thanks for choosing finance.rocks.
                p Please accept our terms of service so we can set up your environment.
                p After you press Integrate, go and get yourself a hot cup of coffee. 
                p When your back, you will have more info in your email (can take a few minutes to get you started) :)
                a(href='/terms_of_service') Terms of service
          stack.align-items-center
            stack-item(fill)
              b-form-group
                b-form-checkbox-group(
                  v-model="confirmed"
                )
                  b-form-checkbox.text-container {{ $t('netvisor_integration.confirm') }}
            stack-item(fill)
              b-btn(
                squared
                variant="primary"
                @click="confirmAgreement"
                :disabled="confirmed.length == 0"
              ) {{ $t('netvisor_integration.submit') }}
        b-card.whiteboard(v-else)
          stack.align-items-center
            stack-item(fill)
              b-spinner(variant="primary" type="grow")
      .container(v-else-if="signed")
        b-card.whiteboard
          stack.align-items-center
            stack-item(fill)
              .mt4.text-container
                p Terms accepted. Please wait we setup your environment.
            stack-item(fill)
              b-btn(
                squared
                variant="primary"
                @click="continueUsage"
              ) {{ $t('actions.continue') }}

      .container(v-else-if="failed")
        b-card.whiteboard
          stack.align-items-center
            stack-item(fill)
              .mt4.text-container
                p {{ $t('netvisor_integration.error_occurred') }}: {{errorMessage}}

</template>
<script>
import { mapGetters } from 'vuex';
import ApiUser from '@/api/user.js'

export default {
  data() {
    return {
      inProgress: false,
      signed: false,
      failed: false,
      errorMessage: '',
      confirmed: [],
      companyName: '',
      companyBusinessId: ''
    }
  },

  computed: {
    ...mapGetters(['user'])
  },

  mounted() {
    this.companyName = user.current_integration_attempt.company_name;
    this.companyBusinessId = user.current_integration_attempt.company_business_id;
  },

  methods: {
    continueUsage() {
      window.location.href = '/';
    },
    confirmAgreement() {
      this.inProgress = true;
      this.$store.dispatch('confirmAgreement');
      ApiUser.finalizeIntegration().then(resp => {
        if (resp.data.success) {
          this.signed = true;
          this.inProgress = false;
        } else {
          this.failed = true;
          this.errorMessage = resp.data.message;
          this.inProgress = false;
        }
      }).catch(err => {
          this.failed = true;
          this.errorMessage = err.response.data.error;
          this.inProgress = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/_variables.scss';

$hero-padding: 40px;
$hero-escape: 80px;

.company-hero {
  @include gradient-directional($bb-deep-green, $bb-grass-green, -165deg);
  padding: $hero-padding 0 $hero-padding + $hero-escape;
  margin-bottom: -$hero-escape;
  color: $white;
}

.company-info {
  & > span {
    display: inline-block;
    margin-right: 5px;
    color: rgba($white, 0.5);
  }
}
.text-container {
  color: $black;
}

.whiteboard {
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 24px;
}
</style>
