<template lang="pug">
div.position-relative.dx-viewport
  div.invoice-popover(v-if="$route.query.vendor_id")
    ShowVendor(:id="$route.query.vendor_id")
  .p-4(v-else)
    .container#vendors-container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.vendors') }}

      .pt-3.px-3
        stack
          stack-item(fill)
            b-form-group(
              :label="$t('companies.vendors.name')"
              label-for="name-input"
              label-cols="4"
            )
              b-form-input(
                v-model="search_params.name"
                type="search"
                id = "name-input"
                @change="reload"
              )
          stack-item(fill)
            b-form-group(
              :label="$t('companies.automation.start_date')"
              label-for="start-date-input"
              label-cols="5"
            )
              b-form-input(
                v-model="search_params.start_date"
                type="date"
                id="start-date-input"
                @change="reload"
              )
          stack-item(fill)
            b-form-group(
              :label="$t('companies.automation.end_date')"
              label-for="end-date-input"
              label-cols="5"
            )
              b-form-input(
                v-model="search_params.end_date"
                type="date"
                id="end-date-input"
                @change="reload"
              )
          stack-item(fill)
            b-form-checkbox(
              v-model="combineAll",
              id="combine-all-switch"
              switch,
              @change="changeCombineAll(combineAll, 'combine_lines')"
            )
              | {{ $t('companies.vendors.combine_all') }}
          stack-item(fill)
            b-form-checkbox(
              v-model="combineAllAccounts",
              id="combine-all-accounts-switch"
              switch,
              @change="changeCombineAll(combineAllAccounts, 'combine_lines_by_account')"
            )
              | {{ $t('companies.vendors.combine_all_accounts') }}
      rest-table(
        namespace="company_vendors",
        :headers="headers",
        ref="companyVendorsTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_name", slot-scope="{ item }")
          router-link(
            :to="{...$route, query: {...$route.query, vendor_id: item.id.toString()}}"
            label="item.name"
          )
            | {{ item.name }}
        template(slot="cel_specified_vat_code", slot-scope="{ item }")
          b-form-select(
            v-model="item.specified_vat_code",
            :options="vatOptions",
            @change="changeVatCode(item)"
          )
        template(slot="cel_combine_lines", slot-scope="{ item }")
          b-form-checkbox(
            v-model="item.combine_lines",
            switch,
            @change="changeCombine(item)"
          )
        template(slot="cel_combine_lines_by_account", slot-scope="{ item }")
          b-form-checkbox(
            v-model="item.combine_lines_by_account",
            switch,
            @change="changeCombineAccount(item)"
          )
        template(slot="cel_suggest_voucher_date", slot-scope="{ item }")
          b-form-checkbox(
            v-model="item.suggest_voucher_date",
            switch,
            @change="changeSuggestVoucherDate(item)"
          )
        template(slot="cel_is_accounted", slot-scope="{ item }")
          b-form-select(
            v-model="item.is_accounted",
            :options="accountedOptions",
            @change="changeAccounted(item)"
          )
        template(slot="cel_default_account", slot-scope="{ item }")
          b-form-input(
            type="number"
            v-model="item.default_account",
            @input="setEdit(item)",
            @change="changeDefaultAccountNumber(item)"
          )
        template(slot="cel_vendor_dimensions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-info",
                squared,
                @click="dimensionsModalOpen(item)"
              )
                | {{ $t('companies.vendors.vendor_dimensions') }}
        template(slot="cel_replacement_codes", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-info",
                squared,
                @click="replacementModalOpen(item)"
              )
                | {{ $t('companies.vendors.replacement_account_list') }}
      b-button.float-button(
        v-if="edited_items.length > 0"
        variant="primary",
        @click="saveAll"
      )
        .mdi.mdi-content-save-all
        | Save vendors
  edit-vendor-dimensions-modal(ref="EditVendorDimensionsModal", @done="reload")
  edit-replacement-account-list-modal(ref="EditReplacementModal", @done="reload")
</template>

<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiCompanyVendors from "@/api/companyVendors.js";
import ApiCompanies from "@/api/companies.js";
import { DateTime } from "luxon"
import EditVendorDimensionsModal from "@/modals/Companies/editVendorDimensions.vue";
import ShowVendor from "@/views/Vendor/show.vue";
import EditReplacementAccountListModal from "@/modals/Companies/editReplacementAccountList.vue";
import ApiCompanyDimensions from "@/api/companyDimensions.js";

export default {
  components: {
    EditVendorDimensionsModal,
    ShowVendor,
    EditReplacementAccountListModal
  },

  data() {
    const startDate = (typeof this.$route.query.purchaseInvoiceStartDate !== 'undefined') ? this.$route.query.purchaseInvoiceStartDate : DateTime.local().minus({year: 1}).toISODate();
    const end_date = (typeof this.$route.query.purchaseInvoiceEndDate !== 'undefined') ? this.$route.query.purchaseInvoiceEndDate : DateTime.local().toISODate();
    return {
      inProgress: false,
      search_params: {
        vendor_name: null,
        start_date: startDate,
        end_date: end_date
      },
      pagination: {
        sortBy: null,
        sortOrder: null
      },
      headers: [
        {
          key: "id",
          label: "#",
          squeeze: true,
        },
        {
          key: "name",
          label: "Name",
          squeeze: false,
          sortable: true,
        },
        {
          key: "business_id",
          label: "Business ID",
          squeeze: false,
        },
        {
          key: "account_number",
          label: "Account Number",
          squeeze: false,
          sortable: false,
        },
        {
          key: "combine_lines",
          label: "Combine lines",
          squeeze: false,
          sortable: true,
        },
        {
          key: "combine_lines_by_account",
          label: "Combine lines by account number",
          squeeze: false,
          sortable: true,
        },
        {
          key: "suggest_voucher_date",
          label: "Suggest voucher date",
          squeeze: false,
          sortable: true,
        },
        {
          key: "default_account",
          label: "Default Account number",
          squeeze: false,
          sortable: true,
        },
        {
          key: "specified_vat_code",
          label: "VAT code",
          squeeze: false,
          sortable: true,
        },
        {
          key: "is_accounted",
          label: "Automate accounting",
          squeeze: false,
          sortable: true,
        },
        {
          key: "invoices_amount",
          label: "Invoice Amount",
          squeeze: false,
          sortable: true,
        },
        {
          key: "suggested_correct_accounts",
          label: "Suggested correct Account %",
          squeeze: false,
        },
        {
          key: "suggested_correct_dimensions",
          label: "Suggested correct Dimensions %",
          squeeze: false,
        },
        {
          key: "vendor_dimensions",
          label: "Vendor Dimensions",
          squeeze: false,
        },
        {
          key: "replacement_codes",
          label: "Replacement codes",
          squeeze: false,
        }
      ],
      items: [],
      edited_items: [],
      combineAll: false,
      combineAllAccounts: false,
      accountedOptions: [
        { value: "company_setting", text: "Company setting" },
        { value: "suggest_automate", text: "Suggest and automate" },
        { value: "only_suggest", text: "Only suggest" },
        { value: "donot_suggest", text: "Don't suggest" },
      ],
      vatOptions: [],
    };
  },

  methods: {
    reload() {
      this.$refs.companyVendorsTable.fetchData();

      this.$router.push({
        query: {
          ...this.$route.query,
          purchaseInvoiceStartDate: document.querySelector('#start-date-input').value,
          purchaseInvoiceEndDate: document.querySelector('#end-date-input').value,
        }
      });
    },
    getData(pagination) {
      this.inProgress = true;
      const search_params = this.search_params;
      ApiCompanyVendors.mostCommonVatCodes(this.$route.params.id).then(
          (resp) => {
            this.vatOptions = resp.data.codes;
            this.vatOptions.unshift({text: '', value: ''});
          }
      );
      return new Promise((res) => {
        ApiCompanyVendors.fetchVendors(this.$route.params.id, search_params, pagination)
            .then((resp) => {
              this.items = resp.data.vendors;
              res({
                items: resp.data.vendors,
                meta: resp.data.meta,
              });
            })
            .catch((e) => {
              this.inProgress = false;
            });
      });
    },
    changeCombine(item) {
      ApiCompanyVendors.updateVendor(this.$route.params.id, item.id, {
        vendor: { combine_lines: item.combine_lines },
      }).then((resp) => {
        item = resp.data.vendor;
      });
    },
    changeCombineAccount(item) {
      ApiCompanyVendors.updateVendor(this.$route.params.id, item.id, {
        vendor: { combine_lines_by_account: item.combine_lines_by_account },
      }).then((resp) => {
        item = resp.data.vendor;
      });
    },
    changeAccounted(item) {
      ApiCompanyVendors.updateVendor(this.$route.params.id, item.id, {
        vendor: { is_accounted: item.is_accounted },
      }).then((resp) => {});
    },
    changeVatCode(item) {
      ApiCompanyVendors.updateVendor(this.$route.params.id, item.id, {
        vendor: { specified_vat_code: item.specified_vat_code },
      }).then((resp) => {
        item.is_edited = false
      });
    },
    changeSuggestVoucherDate(item){
      ApiCompanyVendors.updateVendor(this.$route.params.id, item.id, {
        vendor: { suggest_voucher_date: item.suggest_voucher_date },
      }).then((resp) => {
        item.is_edited = false
      });
    },
    changeDefaultAccountNumber(item){
      ApiCompanyVendors.updateVendor(this.$route.params.id, item.id, {
        vendor: { default_account: item.default_account },
      }).then((resp) => {
        item.is_edited = false
      });
    },
    changeApprover(item){
      ApiCompanyVendors.updateVendor(this.$route.params.id, item.id, {
        vendor: { approver_id: item.approver_id },
      }).then((resp) => {
        item.is_edited = false
      });
    },
    save(item){
      item.is_edited = false;
      return new Promise((res) => {
        ApiCompanyVendors.updateVendor(this.$route.params.id, item.id, {
          vendor: {
            default_account: item.default_account,
            specified_vat_code: item.specified_vat_code,
            is_accounted: item.is_accounted
          },
        }).then((resp) => {
          item.is_edited = false;
        });
      })
    },

    saveAll() {
      this.items.forEach((item) => {
        if(item.is_edited) {
          this.save(item)
        }
      })
      this.edited_items = []
    },

    changeCombineAll(combine, field) {

      this.$dialogConfirm({
        title: this.$t("companies.vendors.combine_all"),
        description: this.$t("companies.vendors.description"),
        variant: "flat-secondary",
        icon: "pencil",
        okLabel: this.$t("actions.ok"),
        okVariant: "primary",
        okIcon: "pencil",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) {
          this.combineAll = false
          return;
        }

        let request;

        let state = { field: field, value: combine };

        request = ApiCompanyVendors.switchCombineLines(this.$route.params.id, state);

        request.then(() => this.reload());
      });
    },
    goBack() {
      router.push("/companies");
    },
    dimensionsModalOpen(item) {
      this.$refs.EditVendorDimensionsModal.open(this.$route.params.id, item);
    },
    replacementModalOpen(item) {
      this.$refs.EditReplacementModal.open(this.$route.params.id, item);
    },
    setEdit(item) {
      item.is_edited = true
      this.edited_items.push(item.id)
    }
  },
  mounted() {
    this.getData();
  },
};
</script>
<style>
.float-button {
  position:  fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9000;
}
</style>