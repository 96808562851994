<template lang="pug">
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{$t('billing.title') }}
        .container.table-limit
          rest-table(
            namespace="billing"
            :headers="headers"
            ref="billingTable"
            :fetch-handler="getData"
            embed
          )
            template(slot="cel_name", slot-scope="{ item }")
              router-link(
                :to="`/billing/${item.id}/show`"
                label="item.name"
              )
                | {{ item.name }}
</template>
<script>
import router from "@/router";
import axios from "axios";
import { mapGetters } from "vuex";
import ApiBilling from "@/api/billing.js";

export default {
  data() {
    return {
      pagination: {
        sortBy: null
      },
      headers: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          squeeze: false
        },
        {
          key: "company_id",
          label: "Business ID",
          sortable: true,
          squeeze: false
        },
      ]
    };
  },
  methods: {
    reload() {
      this.$refs.billingTable.fetchData();
    },
    getData(pagination) {
      return new Promise(res => {
        ApiBilling.FetchList(pagination).then(resp => {
          res({
            items: resp.data.resellers,
            meta: resp.data.meta
          });
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>

.form-group {
  margin-bottom: 0px;
}
</style>
