
div.position-relative
  div.invoice-popover(v-if="$route.query.invoice_id")
    PurchaseInvoicePage(:id="$route.query.invoice_id")
  div(v-else)
    stack
      stack-item
        b-form-group(
          :label="$t('companies.automation.remote_key')"
          label-for="company-name-input"
          label-cols="4"
        )
          b-input(
            v-model="search_params.remote_key"
            id="invoice-key-input",
            @change="reload"
          )
      stack-item(fill)
        b-form-group(
          :label="$t('companies.automation.start_date')"
          label-for="start-date-input"
          label-cols="5"
        )
          b-form-input(
            v-model="search_params.start_date"
            type="date"
            id="start-date-input"
            @change="reload"
          )
      stack-item(fill)
        b-form-group(
          :label="$t('companies.automation.end_date')"
          label-for="end-date-input"
          label-cols="5"
        )
          b-form-input(
            v-model="search_params.end_date"
            type="date"
            id="end-date-input"
            @change="reload"
          )
    rest-table(
      namespace="vendor_invoices",
      :headers="headers",
      ref="vendorInvoicesTable",
      :fetch-handler="getData",
      embed
    )
      template(slot="cel_remote_key", slot-scope="{ item }")
        router-link(
          :to="{...$route, query: {...$route.query, invoice_id: item.id.toString()}}"
          label="item.name"
        )
          | {{ item.remote_key }}


