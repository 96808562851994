<template lang="pug">
  b-modal(resizable,
    v-model="show",
    dialog-class="table-modal"
  )
    template(slot="modal-title")
      .modal-title {{ $t('companies.approvers.add_title') }}
      .modal-subtitle {{ $t('companies.approvers.add_description') }}
    stack
      stack-item
        b-form-group(
          :label="$t('companies.approvers.name')"
          label-for="name-input"
          label-cols="4"
        )
          b-form-input(
            v-model="search_params.name"
            type="search"
            id = "name-input"
            @change="reload"
          )
    rest-table(
      namespace="assign_approvers",
      :headers="headers",
      ref="AssignApproversTable",
      :fetch-handler="getData",
      embed
    )
      template(slot="cel_actions", slot-scope="{ item }")
        b-btn(
          variant="primary"
          squared
          v-if="!item.is_assigned"
          @click="assign(item)"
        )
          .mdi.mdi-plus-box-outline
          | {{ $t('companies.approvers.assign') }}
        b-btn(
          variant="flat-danger",
          squared,
          v-if="item.is_assigned",
          @click="deassign(item)"
        )
          .mdi.mdi-minus-box-outline
          | {{ $t('companies.approvers.deassign') }}
    template(slot="modal-footer")
      b-btn(
        variant="primary"
        squared
        @click="close"
      )
        | {{ $t('actions.ok') }}
</template>

<script>

import ApiApprovers from "@/api/approvers.js";

export default {
  data() {
    return {
      show: false,
      search_params: { name: '' },
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "approver_name",
          label: "Name",
          sortable: true,
          squeeze: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          squeeze: true,
        },
        {
          key: "price_limit",
          label: "Price limit",
          sortable: true,
          squeeze: true,
        },
        {
          key: "is_assigned",
          label: "Assigned?",
          sortable: true,
          squeeze: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    }
  },

  watch: {
    show(newVal) {
      if (!newVal) {
        this.$emit('closed')
      }
    }
  },

  methods: {
    reload() {
      this.$refs.AssignApproversTable.fetchData();
    },

    open(target_name, target_id, role) {
      this.target_name = target_name;
      this.target_id = target_id;
      this.search_params = { name: '', target_id: target_id, target: target_name }

      this.role = role;
      this.is_approver = false;
      this.is_verificator = false;

      this.getData();
      this.show = true;
    },

    close() {
      this.show = false
    },

    assign(item) {
      this.inProgress = true;
      return new Promise((res) => {
        ApiApprovers.AssignApprover(this.$route.params.id, item.id, this.target_name, this.target_id, this.role).then(
            (resp) => {
              this.reload();
            });
      })
    },

    deassign(item) {
      this.inProgress = true;
      return new Promise((res) => {
        ApiApprovers.DeassignApprover(this.$route.params.id, item.id, this.target_name, this.target_id, this.role).then(
            (resp) => {
              this.reload();
            });
      })
    },

    getData(pagination) {
      this.inProgress = true;
      const search_params = this.search_params;
      if (this.role == 'approver') {
        search_params['is_approver'] = true
      } else if (this.role == 'verificator') {
        search_params['is_verificator'] = true
      }
      return new Promise((res) => {
        ApiApprovers.FetchApproversList(this.$route.params.id, search_params, pagination).then(
            (resp) => {
              res({
                items: resp.data.approvers,
                meta: resp.data.meta,
              });
            }
        ).catch((e) => {
          this.inProgress = false;
        });
      });

    }
  }
}
</script>