<template lang="pug">
.avatar-box(
  :class="avatarClasses"
)
  img(
    v-if="src"
    :src="src"
  )
  no-avatar(
    v-else
    :label="label"
  )
</template>

<script>
import NoAvatar from './NoAvatar.vue';

export default {
  name: 'AvatarBox',

  components: {
    NoAvatar
  },

  props: {
    src: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    bordered: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    avatarClasses() {
      return {
        [`avatar-box-${this.size}`]: !!this.size,
        'avatar-box--bordered': this.bordered
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheet/_variables.scss';

@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

.avatar-box {
  display: block;
  width: 100%;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--bordered {
    border: 3px solid #fff;
    box-shadow: rgba($bb-dark, 0.08) 0px 1px 3px, rgba($bb-dark, 0.04) 0px 5px 10px;
  }

  @each $key, $value, $index in $avatar-sizes {
    &-#{$key} {
      width: $value;
    }

    &-#{$key}#{&}--bordered {
      $pow: pow(1.012, strip-units($value)) * 1px;

      @if ($pow < 2) {
        $pow: 2px;
      }

      border: $pow solid #fff;
    }
  }
}
</style>