
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('admin_tools.tabs.company_logs') }}
      stack
        stack-item(fill)
          b-form-group(
            :label="$t('admin_tools.logs.model')"
            label-for="model-input"
            label-cols="4"
          )
            b-form-select(
              v-model="search_params.model",
              :options="modelsOptions",
              @change="reloadList(item)"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('admin_tools.logs.record_id')"
            label-for="model-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.record_id"
              type="search"
              id = "model-input"
              @change="reloadList"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.automation.start_date')"
            label-for="start-date-input"
            label-cols="5"
          )
            b-form-input(
              v-model="search_params.start_date"
              type="date"
              id="start-date-input"
              @change="reloadList"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.automation.end_date')"
            label-for="end-date-input"
            label-cols="5"
          )
            b-form-input(
              v-model="search_params.end_date"
              type="date"
              id="end-date-input"
              @change="reloadList"
            )

      rest-table(
        namespace="logs",
        :headers="headers",
        ref="logsTable",
        :fetch-handler="getData",
        embed
      )
