
div
  .card-body
    stack
      stack-item(fill)
        .h3.mb-0 {{ $t("admin_tools.tabs.digia") }}
      stack-item 
        stack(spacing="xl")
          stack-item
            strong {{ $t("labels.filter_by") }}
          stack-item
            stack
              stack-item
                label.m-0(label-for="start-date-input") {{ $t('labels.company') }}
              stack-item
                company-select(v-model="selectedCompany")
          stack-item
            stack
              stack-item
                label.m-0(label-for="start-date-input") {{ $t('companies.automation.start_date') }}
              stack-item
                b-form-input(
                  :value="filters.date_start"
                  @input="updateQueryParams({date_start: $event || undefined})"
                  type="date"
                  id="start-date-input"
                )
          stack-item
            stack
              stack-item
                label.m-0(label-for="end-date-input") {{ $t('companies.automation.end_date') }}
              stack-item
                b-form-input(
                  :value="filters.date_end"
                  @input="updateQueryParams({date_end: $event || undefined})"
                  type="date"
                  id="end-date-input"
                )
  b-tabs(v-if="filters.company_id" card :value="digiaTab" @input="onTabChange" lazy)
    b-tab(v-for="tab in tabs" :key="tab.id" :title="tab.label") 
      component(
        :is="tab.component"
        :globalFilters="filters"
        :globalRequestFilters="requestFilters"
      )
