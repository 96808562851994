
b-modal(
  centered
  v-model="show"
)
  template(slot="modal-title")
    div
      .modal-title {{ $t('resellers.add_group') }}

  b-form-group(
    :label="$t('resellers.name')"
  )     
    b-input(
      v-model="form.name"
    )
    b-form-group(label="Choose companies:")
      b-form-checkbox-group(
        id="checkbox-company" 
        name="company_ids" 
        v-model="selected"
        switches
        stacked
      )
        b-form-checkbox(:value="company.id" :key="company.id" v-for="company in resellerCompanies")
          | {{ company.company_name }}{{ company.company_group_name != null ? ' (group ' + company.company_group_name + ')' : '' }}

  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )  
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
