<template lang="pug">
  div
    //.container
    //  price-limit-form
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.approvers.approval_flow') }}
        stack-item
          b-btn(
            variant="primary",
            squared,
            @click="exportApprovalSettings()"
          )
            .mdi.mdi-download
            | {{ $t('companies.export_to_excel') }}
      .pt-3.px-3
      rest-table(
        :disable-pagination="true"
        namespace="approval_flow",
        :headers="headers",
        ref="approvalFlowTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_name", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              | {{ APPROVAL_FLOWS[item.name] }}
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="primary",
                squared,
                @click="upApprovalFlow(item)"
              )
                .mdi.mdi-arrow-up
                | Up
            stack-item
              b-btn(
                size="xs",
                variant="primary",
                squared,
                @click="downApprovalFlow(item)"
              )
                .mdi.mdi-arrow-down
                | Down
        template(slot="cel_is_active", slot-scope="{ item }")
          b-form-checkbox(
            v-model="item.is_active",
            switch,
            @change="changeEnabled(item)"
          )

</template>

<script>
import ApiApprovers from "@/api/approvers.js";
import PriceLimitForm from "@/views/Approvers/default_price_form.vue";
import ApiAdminCompanyUsers from "@/api/admin_company_users.js";

const APPROVAL_FLOWS = {
  'vendor': 'Default approver by vendor',
  'account': 'Default approver by account',
  'price': 'Default approver by price',
  'company': 'Default approver by company',
  'cost_center': 'Default approver by cost center'
}

export default {
  components: {
    PriceLimitForm
  },

  data() {
    return {
      APPROVAL_FLOWS: APPROVAL_FLOWS,
      inProgress: false,
      active_flow_list: [],
      list_headers: [
        {
          key: "name",
          label: "Name",
          squeeze: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
      headers: [
        {
          key: "sort",
          label: "#",
          squeeze: true,
        },
        {
          key: "name",
          label: "Active Flow",
          squeeze: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
        {
          key: "is_active",
          label: "",
          squeeze: true,
        }
      ],
    };
  },

  methods: {
    createApprovalFlow(item) {
      ApiApprovers.createApprovalFlow(this.$route.params.id, { approval_flow: { name: item.name }}).then(
          (resp) => {
            this.reload();
          }
      );
    },
    deleteApprovalFlow(item) {
      ApiApprovers.deleteApprovalFlow(this.$route.params.id, item.id).then(
          (resp) => {
            this.reload();
          });
    },

    changeEnabled(item){
      if(item.is_active) {
        this.createApprovalFlow(item)
      } else {
        this.deleteApprovalFlow(item)
      }
    },

    reload() {
      this.$refs.approvalFlowTable.fetchData();
      // this.$refs.approvalFlowListTable.fetchData();
    },
    downApprovalFlow(item) {
      this.inProgress = true;
      return new Promise((res) => {
            ApiApprovers.downApprovalFlow(this.$route.params.id, item.id).then(
                (resp) => {
                  this.reload();
                }
            );
          }
      )},
    upApprovalFlow(item) {
      this.inProgress = true;
      return new Promise((res) => {
            ApiApprovers.upApprovalFlow(this.$route.params.id, item.id).then(
                (resp) => {
                  this.reload();
                }
            );
          }
      )},
    getList() {
      return new Promise((res) => {
            res({
              items: this.approvalFlowList()
            })
          }
      )},

    exportApprovalSettings() {
      ApiApprovers.exportApprovalSettings(this.$route.params.id)
          .then((resp) => {
            let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", `approval_settings.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((e) => {});
    },

    getData(pagination) {
      this.inProgress = true;
      return new Promise((res) => {
        ApiApprovers.fetchApprovalFlows(this.$route.params.id)
            .then((resp) => {
              this.active_flow_list = resp.data.approval_flows.map (x => x.name)
              // this.$refs.approvalFlowListTable.fetchData();

              let list = this.approvalFlowList()
              let activeFlows = resp.data.approval_flows
              // debugger
              activeFlows = activeFlows.map((flow) => ({"id": flow.id, "name": flow.name, "displayName": APPROVAL_FLOWS[flow.name], is_active: true }))
              res({
                items: activeFlows.concat(list)
              });
            })
            .catch((e) => {
              this.inProgress = false;
            });
      });
    },

    approvalFlowList () {
      const all_approval_flows = Object.keys(APPROVAL_FLOWS)
      const to_remove = new Set(this.active_flow_list)
      let non_active_approval_flow = all_approval_flows.filter( x => !to_remove.has(x) );
      let list = non_active_approval_flow.map((key) => ({"name": key, "displayName": APPROVAL_FLOWS[key], is_active: false }) )
      return list
    },
  },
  mounted() {
    this.getData();
  },
}
</script>
