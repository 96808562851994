
div.position-relative(v-if="this.purchase_invoice !== null")
  .p-4
    .container
      stack
        stack-item
          b-button(
            class="uppercase"
            :variant="'flat-primary'"
            v-b-tooltip.focus
            @click="close()"
          )
            .mdi.mdi-backspace-outline
            | Back
        stack-item
          b-card
            .h4.mb-0 Netvisor key: {{ this.purchase_invoice.remote_key }}
        stack-item
          b-card
            .h4.mb-0 {{ this.purchase_invoice.status }}
        stack-item
          b-card
            .h4.mb-0 {{ this.purchase_invoice.status_reason }}
    br
    .container
      b-tabs(content-class='mt-3' card)
        b-tab(title='Data' active='')
          DataTab(:data="this.purchase_invoice.data", :line_items="this.purchase_invoice.invoice_lines")
        b-tab(title='Voucher' v-if="this.purchase_invoice.voucher_hash !== null")
          VoucherTab(:data="this.purchase_invoice.voucher_hash", :line_items="this.purchase_invoice.invoice_lines")
        b-tab(title='Finvoice' v-if="this.purchase_invoice.finvoice !== null")
          XMLTab(:data="this.purchase_invoice.finvoice", :attachments="this.purchase_invoice.finvoice_attachment")
        b-tab(title='Netvisor document' v-if="this.purchase_invoice.original_document !== null")
          XMLTab(:data="this.purchase_invoice.original_document")
        b-tab(title='History' v-if="this.purchase_invoice.history !== null")
          HistoryTab(:data="this.purchase_invoice.history")
        b-tab(title='Attachments' v-if="this.purchase_invoice.attachments !== null && this.purchase_invoice.attachments.length !== 0")
          AttachmentsTab(:data="this.purchase_invoice.attachments")

