
div
  .p-4(v-if="$route.query.dimension_item_id")
    DimensionItem(:dimension_item_id="$route.query.dimension_item_id")
  .p-4(v-else)
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              .h3.mb-0 {{ $t('companies.tabs.dimensions') }}
        .pt-3.px-3
          stack
            stack-item(fill)
              b-form-group(
                :label="$t('companies.dimensions.name')"
                label-for="company-id-input"
                label-cols="4"
              )
                b-form-input(
                  v-model="searchName",
                  type="search"
                  id="company-id-input"
                  @change="reloadList"
                )
        rest-table(
          embed,
          :headers="headers",
          :fetch-handler="getData",
          namespace="dimensions",
          ref="companyDimensionsTable",
        )
          template(slot="cel_not_suggest_dimension", slot-scope="{ item }")
            b-form-checkbox(
              v-model="item.not_suggest_dimension",
              switch,
              @change="changeNotSuggestDimension(item)"
            )
          template(slot="cel_data_priority",  slot-scope="{ item }")
            b-form-select(
              v-model="item.data_priority",
              :options="dataPriorityOptions",
              @change="changeDataPriority(item)"
            )
          template(slot="cel_not_suggest", slot-scope="{ item }")
            b-form-checkbox(
              v-model="item.not_suggest",
              switch,
              @change="changeNotSuggest(item)"
            )
          template(slot="cel_name_as_keyword", slot-scope="{ item }")
            b-form-checkbox(
              v-model="item.name_as_keyword",
              switch,
              @change="changeNameAsKeyword(item)"
            )
          template(slot="cel_if_account", slot-scope="{ item }")
            b-form-tags(
              size="sm",
              v-model="item.if_account",
              :placeholder="$t('companies.dimensions.accounts_prompt')",
              :invalid-tag-text="$t('companies.dimensions.invalid_account')",
              @change="changeIfAccount(item)",
              @input="changeIfAccount(item)",
              :tag-validator="ifAccountValidator"
            )
              template(v-slot:invalid-feedback)
                | You must provide at least 3 tags and no more than 8
          template(slot="cel_keywords", slot-scope="{ item }")
            b-form-tags(
              size="sm",
              v-model="item.keywords",
              :placeholder="$t('companies.dimensions.keywords_prompt')",
              @change="changeKeywords(item)",
              @input="changeKeywords(item)"
            )
          template(slot="cel_default_dimension_item", slot-scope="{ item }")
            b-form-checkbox(
              v-model="item.default",
              switch,
              @change="changeDefaultDimensionItem(item)"
            )
          template(slot="cel_row_custom_details", slot-scope="{ item }")
            b-form-select(
              v-model="item.row_custom_details",
              :options="rowCustomDetailsOptions",
              @change="changeRowCustomDetails(item)"
            )
          template(slot="cel_approver_id", slot-scope="{ item }")
            router-link(
              :to="{...$route, query: {...$route.query, dimension_item_id: item.id}}"
              label="item.name"
            )
              | Approvers
