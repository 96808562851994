
div
  .p-4
    .container
      stack
        stack-item(fill)
          h3.title {{ $t('companies.api_keys.title') }}
        stack-item
          b-btn(variant="primary", squared, @click="addApiKey") 
            .mdi.mdi-file-plus-outline
            | {{ $t('companies.api_keys.create_title') }}
      rest-table(
        namespace="api_keys",
        :headers="headers",
        ref="ApiKeysTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_active", slot-scope="{ item }")
          b-btn(
            size="xs",
            :variant="`flat-${item.active == true ? 'success' : 'danger'}`"
          )
            | {{ item.active ? 'Active' : 'Disabled' }}
        template(slot="cel_name", slot-scope="{ item }")
          b-link(
            :href="`/ApiKeys/${item.id}/job_download`",
            target="_blank",
            v-if="item.status == 'ok'"
          ) {{ item.name }}
          p(v-else) {{ item.name }}
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteApiKey(item)",
                v-if="item.status != 'started'"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-warning",
                squared,
                v-if="(item.status == 'error') & ['twin_treasury_file', 'netvisor_opus_voucher', 'netvisor_importer_payment'].includes(item.ApiKey_type)"
              )
                .mdi.mdi-file-edit
                | {{ $t('actions.edit') }}
  create-api-key(ref="CreateApiKey", @done="reloadList")
