import axios from 'axios';

const deassignDimensionItem = (company_id, vendor_id, dimension_item_id) => {
   return axios.delete(`/api/v1/companies/${company_id}/vendor_dimension_items/${dimension_item_id}/${vendor_id}`)
};

const assignDimensionItem = (company_id, vendor_id, dimension_item_id) => {
    return axios.post(`/api/v1/companies/${company_id}/vendor_dimension_items/${dimension_item_id}/${vendor_id}`)

};

const fetchDimensionItems = (company_id, vendor_id, pagination) => {
    return axios.get(`/api/v1/companies/${company_id}/vendor_dimension_items/${vendor_id}`,
        { params: { ...pagination }})
};

const vendorDimensionItems = (company_id, vendor_id, searchQ, pagination) => {
    return axios.get(`/api/v1/companies/${company_id}/vendor_dimension_items/${vendor_id}/dimension_items`,
        { params: { ...pagination, ...searchQ }})
}

const ApiVendorDimensionItems = {
    deassignDimensionItem,
    assignDimensionItem,
    fetchDimensionItems,
    vendorDimensionItems
}

export default ApiVendorDimensionItems;