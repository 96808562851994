<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.softwares') }}
        stack-item
          b-btn(variant="primary", squared, @click="addSoftware")
            .mdi.mdi-lan-connect
            | {{ $t('actions.add') }}
        stack-item
          b-btn(
            v-if="basWareUploadButton"
            variant="primary",
            squared,
            @click="uploadArchive"
            )
            .mdi.mdi-briefcase-upload-outline
            | {{ $t('companies.softwares.upload_learning_data') }}
        stack-item
          b-btn(
            v-if="basWareUploadButton"
            variant="primary",
            squared,
            @click="forceLearning"
            )
            .mdi.mdi-school-outline
            | {{ $t('companies.softwares.force_learning') }}
      rest-table(
        namespace="company_softwares",
        :headers="headers",
        ref="companySoftwaresTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="showErrors(item)",
                v-if="item.has_errors"
              )
                .mdi.mdi-alert-box-outline
                | {{ $t('companies.softwares.show_errors') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                @click="editSoftware(item)"
              ) 
                .mdi.mdi-pencil-outline
                | {{ $t('actions.edit') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteSoftware(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
      stack(v-if="errors.length > 0")
        stack-item(fill)
        stack-item
          b-btn(
            size="xs",
            variant="flat-primary",
            squared,
            @click="hideErrors"
          )
            .mdi.mdi-eye-off-outline
            | {{ $t('companies.softwares.hide_errors') }}
      data-table(
        :embed="true",
        :progress="inProgress",
        :headers="errorsHeaders",
        :items="errors",
        ref="softwareErrorsTable",
        :disablePagination="true",
        v-if="errors.length > 0"
      )
    edit-software-modal(ref="EditSoftwareModal", @done="reloadList")
    upload-learning-archive-modal(ref="UploadLearningArchiveModal")
  storages(v-if="storages_show")
  company-api-keys
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiCompanySoftwares from "@/api/companySoftwares.js";
import EditSoftwareModal from "@/modals/Companies/editSoftware.vue";
import UploadLearningArchiveModal from "@/modals/Companies/uploadLearingArchive.vue";
import ApiBaswareFiles from "@/api/basware_files.js";
import Storages from "@/views/Companies/Tabs/storages.vue";
import CompanyApiKeys from "@/views/Companies/apikeys.vue";

export default {
  components: {
    EditSoftwareModal,
    UploadLearningArchiveModal,
    Storages,
    CompanyApiKeys
  },
  data() {
    return {
      inProgress: false,
      pagination: {
        sortBy: null,
      },
      basWareUploadButton: false,
      errors: [],
      headers: [
        {
          key: "name",
          label: "Name",
          squeeze: false,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
      errorsHeaders: [
        {
          key: "error",
          label: "Error",
          squeeze: false,
        },
        {
          key: "created_at",
          label: "Time",
          squeeze: true,
        },
      ],
    };
  },
  computed: {
    storages_show() {
      return window.user.role !== 'user';
    }
  },
  methods: {
    reloadList() {
      this.$refs.companySoftwaresTable.fetchData();
    },

    getData(pagination) {
      return new Promise((res) => {
        ApiCompanySoftwares.fetchSoftwares(this.$route.params.id).then(
          (resp) => {
            res({
              items: resp.data.softwares,
              meta: resp.data.meta,
            });
            this.basWareUploadButton = resp.data.softwares.map((s) => {return s.name}).includes('Basware')
          }
        );
      });
    },

    addSoftware() {
      this.$refs.EditSoftwareModal.open(this.$route.params.id, null);
    },

    uploadArchive() {
      this.$refs.UploadLearningArchiveModal.open(this.$route.params.id);
    },

    forceLearning(){
      this.$dialogConfirm({
        title: this.$t("companies.softwares.force_learning"),
        description:  this.$t("actions.basware_learning_description"),
        variant: "success",
        icon: "head-cog-outline",
        okLabel: this.$t("actions.ok"),
        okVariant: "success",
        okIcon: "check-bold",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiBaswareFiles.forceLearning(this.$route.params.id).then(
            (resp) => {
              this.reloadList();
            }
        );
      });
    },

    editSoftware(item) {
      this.$refs.EditSoftwareModal.open(this.$route.params.id, item);
    },

    deleteSoftware(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "lan-disconnect",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiCompanySoftwares.deleteSoftware(this.$route.params.id, item.id).then(
          (resp) => {
            this.reloadList();
          }
        );
      });
    },

    showErrors(item) {
      this.inProgress = true;
      ApiCompanySoftwares.fetchErrors(this.$route.params.id, item.id)
        .then(resp => {
          this.errors = resp.data.errors;
          this.inProgress = false;
        })
        .catch(e => {
          this.inProgress = false;
        });
    },

    hideErrors() {
      this.errors = [];
    },

    goBack() {
      router.push("/companies");
    },
  },
};
</script>

