// import moment from 'moment';
import LocalStorage from '@/common/localstorage';
import i18n from '@/localization';
import axios from 'axios';

const LOGIN = (state, user) => {
  state.user = user;
};

const LOGOUT = state => {
  state.user = null;
  state.companies = [];
  state.currentCompanyId = null;
};

const SET_LOCALE = (state, locale) => {
  const value = locale.value;
  LocalStorage.set('language', value);
  i18n.locale = value;
  state.language = value;
  axios.defaults.headers.common['FinanceRocks-Locale'] = value;
};

const UPDATE_USER = (state, data) => {
  state.user = {
    ...state.user,
    ...data
  }
};

export default {
  LOGIN,
  LOGOUT,
  SET_LOCALE,
  UPDATE_USER
};
