
.container
  stack
    stack-item
      b-button(
        class="uppercase"
        :variant="'flat-primary'"
        v-b-tooltip.focus
        @click="close()"
      )
        .mdi.mdi-backspace-outline
        | Back
    stack-item
      h3
        | {{ `${this.dimension_item.dimension_name} | ${this.dimension_item.name}` }}
  Approvers(:target="'cost_center'", :target_id="this.$route.query.dimension_item_id")

