<template lang="pug">
  div
    .p-4
      .container
        stack
          stack-item(fill)
            .h3.mb-0 {{ $t('admin_tools.tabs.failed_vendors') }}
        stack
          stack-item()
            b-form-group(
              :label="$t('admin_tools.failed_vendors.name')"
              label-for="model-input"
              label-cols="4"
            )
              b-form-input(
                v-model="search_params.name"
                type="search"
                id = "model-input"
                @change="reload"
              )
          stack-item()
            b-form-group(
              :label="$t('admin_tools.failed_vendors.company_name')"
              label-for="model-input"
              label-cols="4"
            )
              b-form-input(
                v-model="search_params.company_name"
                type="search"
                id = "model-input"
                @change="reload"
              )
          stack-item
            b-btn(
              variant="primary"
              squared
              @click="exportToExcel"
            )
              .mdi.mdi-download
              | {{ $t('resellers.export_to_excel') }}
        rest-table(
          namespace="logs",
          :headers="headers",
          ref="failedVendorsTable",
          :fetch-handler="getData",
          embed
        )
          template(slot="cel_actions" slot-scope="{ item }")
            b-button(
              size="xs",
              variant="flat-danger",
              squared,
              v-b-tooltip.focus,
              @click="deleteVendor(item)"
            )
              .mdi.mdi-trash-can-outline
              | {{ $t("actions.delete") }}
</template>

<script>
import ApiFailedVendors from "@/api/failed_vendors.js";
export default {
  data() {
    return {
      search_params: {
        company_name: "",
        name: ""
      },
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "company_name",
          label: "Company",
          sortable: true,
          squeeze: true,
        },
        {
          key: "name",
          label: "Vendor",
          sortable: true,
          squeeze: true,
        },
        {
          key: "actions",
          label: "#",
          sortable: false,
          squeeze: true,
        },
      ]
    }
  },

  methods: {
    reload() {
      this.$refs.failedVendorsTable.fetchData();
    },

    getData(pagination) {
      const search_params = this.search_params;
      return new Promise((res) => {
        ApiFailedVendors.fetchVendors(pagination, search_params).then(
            (resp) => {
              res({
                items: resp.data.vendors,
                meta: resp.data.meta,
              });
            });
      });
    },

    deleteVendor(item) {
      return new Promise((res) => {
        ApiFailedVendors.deleteVendor(item.id).then(
            (resp) => {
              this.reload();
            });
      });
    },

    exportToExcel() {
      ApiFailedVendors.exportToExcel()
          .then((resp) => {
            let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", `failed_vendors.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((e) => {});
    }
  }
}
</script>

<style scoped>

</style>