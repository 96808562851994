
div
  .p-4(v-if="$route.query.account_id")
    Account
  .p-4(v-else)
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.account_list') }}
        stack-item
          b-btn(variant="primary", squared, @click="addAccountKeyword")
            .mdi.mdi-lan-connect
            | {{ $t('actions.add') }}
      stack
        stack-item
          .mt-3.mb-3
          b-form-file(
            v-model="matchFile",
            :state="Boolean(matchFile)",
            placeholder="Choose a file or drop it here...",
            drop-placeholder="Upload match file",
            accept=".csv"
          )
        stack
          stack-item
          stack-item
            b-btn(variant="primary", squared, @click="downloadMatchFile")
              .mdi.mdi-download
              | {{ $t('actions.download') }}
        stack
          stack-item
            b-btn(
              :disabled="!Boolean(matchFile)",
              variant="primary",
              squared,
              @click="uploadMatchFile"
            )
              .mdi.mdi-upload
              | {{ $t('actions.upload') }}
        stack-item
          b-form-group(
            :label="$t('companies.account_keyword.number')"
            label-for="netvisor-key-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.account_number"
              type="search"
              id = "netvisor-key-input"
              @change="reloadList"
            )
      rest-table(
        namespace="accounts",
        :embed="true",
        :progress="inProgress",
        :headers="headers",
        :items="items",
        ref="companyAccountListTable",
        :fetch-handler="getData",
        :sortBy="pagination.sortBy",
        :sortOrder="pagination.sortOrder"
      )
        template(slot="cel_keywords", slot-scope="{ item }")
          b-form-tags(
            v-if="item.is_keyword",
            size="sm",
            v-model="item.keywords",
            :placeholder="$t('companies.dimensions.keywords_prompt')",
            @change="changeKeywords(item)",
            @input="changeKeywords(item)"
          )
          b-form-tags(
            v-if="!item.is_keyword",
            size="sm",
            v-model="item.keywords",
            :placeholder="$t('companies.dimensions.keywords_prompt')",
            @change="createKeywords(item)",
            @input="createKeywords(item)"
          )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs" v-if="item.is_keyword")
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteAccountKeyword(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
            stack-item(v-if="item.is_active && item.is_keyword")
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deactivate(item)"
              )
                .mdi.mdi-close-circle
                | {{ $t('actions.deactivate') }}
            stack-item(v-if="!item.is_active && item.is_keyword")
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                @click="activate(item)"
              )
                .mdi.mdi-checkbox-marked-circle
                | {{ $t('actions.activate') }}
        template(slot="cel_is_active", slot-scope="{ item }")
          b-button(
            pill
            class="uppercase"
            :variant="STATUSES[item.is_active] || 'info'"
            v-b-tooltip.focus
          )
            div(
              :class="[item.is_active ? 'mdi mdi-checkbox-marked-circle' : 'mdi mdi-close-circle']"
            )
            | {{ item.is_active }}
        template(slot="cel_in_accountlist", slot-scope="{ item }")
          b-button(
            pill
            class="uppercase"
            :variant="STATUSES[item.in_accountlist] || 'info'"
            v-b-tooltip.focus
          )
            div(
              :class="[item.in_accountlist ? 'mdi mdi-checkbox-marked-circle' : 'mdi mdi-close-circle']"
            )
            | {{ item.in_accountlist }}
        template(slot="cel_approver_id", slot-scope="{ item }")
          router-link(
            :to="{...$route, query: {...$route.query, account_id: item.id}}"
            label="item.name"
          )
            | Approvers
      replacement-account-list
    create-account-keyword-modal(ref="CreateAccountKeywordModal", @done="reloadList")
