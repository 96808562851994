
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          h3.title {{$t('admin_tools.title') }}
        tabs(
          :tabs="tabs"
          :selected="activeTab"
          @select="selectTab"
        )
        transition(name="fade" mode="out-in")
          component(:is="tabContent")
