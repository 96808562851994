
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div
      .modal-title {{ $t('companies.softwares.upload_learning_data') }}
      .modal-subtitle {{ $t('companies.softwares.upload_learning_data_description') }}
  p
  b-form-file(
    v-model="file",
    :state="Boolean(file)",
    placeholder="Choose a file or drop it here...",
    drop-placeholder="Drop file here...",
    accept=".zip"
  )

  template(slot="modal-footer")
    b-btn(
      :disabled="!Boolean(file)",
      variant="primary",
      squared,
      @click="upload"
    )
      .mdi.mdi-upload
      | {{ $t('actions.upload') }}
