<template lang="pug">
  table.table-sm.table-striped.table-bordered.w-100(v-if="Array.isArray(line_items)")
    thead
      tr
        th(rowspan="2") LineSum
        th(rowspan="2") VatCode
        th(rowspan="2") VatPercent
        th(rowspan="2") Description
        th(v-bind:colspan="dimensions_count()") Dimensions
        th(rowspan="2") Account
        th(rowspan="2") Suggestions
      tr
        th(v-for="(item, i) in dimensions")
          p {{item}}
    tbody
      tr(v-for="(item, index) in line_items")
        td {{item['line_sum']}}
        td {{item['suggestion'] !== null ? line_vat_code(item['suggestion']['vat_code']) : ''}}
        td {{item['data'] !== null ? item['data']['VatPercent'] : ''}}
        td 
          div
            p 
              b Netvisor: 
              span {{item['description']}}
          div(v-if="(item['article_name'])")
            p 
              b Finvoice: 
              span {{ item['article_name'] }}
          div(v-if="(item['finvoice_comment'])")
            p 
              b Finvoice comment: 
              span {{ item['finvoice_comment'] }}
        td(v-for="(dimension) in dimensions" v-bind:id="dimensions_correct(item, dimension)" width="20%")
          p {{dimension_item(item, dimension)}}
          UiSpoiler(
            :openLabel="$t('labels.show_suggestion')"
            :closeLabel="$t('labels.hide_suggestion')"
          )
            p.text-muted(v-html="suggestion_description(item['suggestion'])")
        td(v-bind:id="account_correct(item)") {{item['suggestion'] !== null ? item['suggestion']['account_number'] : ''}}
        td
          table
            tr(v-for="(result, i) in item['suggest_results']" v-bind:id="result['result']")
              td {{result['method']}}
              td {{result['result']}}
              td {{result['description']}}
              td {{result['account_number']}}
</template>

<script>
export default {
  props: ["line_items"],

  computed: {
    dimensions() {
      return this.list_dimensions();
    },
  },

  methods: {
    dimensions_count() {
      return (this.dimensions && this.dimensions.length) || 1;
    },

    list_dimensions() {
      let list = this.line_items
        .map((line) =>
          this.line_dimensions(line).map((dim) => dim.DimensionName)
        )
        .flat()
        .filter((n) => n);
      return list.length === 0 ? [""] : [...new Set(list)];
    },

    dimension_item(record, dimension) {
      if (record.suggestion === null) {
        return "";
      }

      let record_dim = this.line_dimensions(record).find(
        (dim) => dim.DimensionName == dimension
      );

      if (!record_dim || typeof record_dim.DimensionItem === "undefined") {
        return "";
      } else {
        return record_dim.DimensionItem;
      }
    },

    suggestion_description(suggestion) {
      let description = "";
      let dimension_keys = [];
      if (!suggestion.dimensions || suggestion.dimensions.length == 0) {
        if (suggestion.dimensions_method !== undefined) {
          dimension_keys = Object.keys(suggestion.dimensions_method);
        }
      } else {
        dimension_keys = suggestion.dimensions.map((dim) => dim.DimensionName);
      }

      for (const dimension of dimension_keys) {
        for (const item of suggestion.dimensions_method[dimension]) {
          description += `${item.method}: ${item.description};<br>`;
        }
      }
      return description;
    },

    line_dimensions(line) {
      let line_dimensions = [];
      if (
        line.suggestion &&
        (line.suggestion.dimensions !== undefined ||
          line.suggestion.dimensions_to_push !== undefined)
      ) {
        line_dimensions =
          line.suggestion.dimensions || line.suggestion.dimensions_to_push;
      }
      return line_dimensions;
    },

    line_vat_code(vat_code) {
      if (Array.isArray(vat_code)) {
        return vat_code.join(", ");
      } else if (vat_code === undefined) {
        return "";
      } else {
        return String(vat_code).split(":").join(", ");
      }
    },

    dimensions_correct(item, dimension) {
      if (item.dimensions_correct) {
        return "";
      } else if (item.dimensions_partially_correct) {
        return "partially_suggestion";
      } else {
        return this.dimension_item(item, dimension) !== ""
          ? "wrong_suggestion"
          : "";
      }
    },

    account_correct(item) {
      if (item.account_correct) {
        return "";
      } else {
        return item.suggestion !== null ? "wrong_suggestion" : "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table-striped {
  tbody tr#success {
    background-color: #2fcba0;
  }
  tbody td#wrong_suggestion {
    background-color: #dc8e35;
  }
  tbody td#partially_suggestion {
    background-color: #c9da2b;
  }
}
</style>
