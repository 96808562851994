<template lang="pug">
b-dropdown.language-switcher(
  variant="link-secondary"
  squared
)
  .language-switcher__activator(slot="button-content")
    .language-switcher__icon(:style="{ 'background-image': `url(${languageIcon})` }")
    span {{ languageAbbr }}
  b-dropdown-item(
    v-for="language in languages"
    :key="language.value"
    @click="switchLanguage(language.value)"
  ) {{ language.label }}
</template>

<script>
import LocaleMixin from '@/mixins/locale';

export default {
  mixins: [LocaleMixin],

  computed: {
    languageAbbr() {
      const locale = this.currentLanguage;
      return locale ? locale.value : '...';
    },

    languageIcon() {
      const locale = this.currentLanguage;
      return locale ? locale.img : '...';
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/_variables.scss';
</style>