<template lang="pug">
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div
      .modal-title {{ $t('companies.api_keys.create_title') }}
      .modal-subtitle {{ $t('companies.api_keys.create_description') }}

  b-form-group(
    :label="$t('companies.api_keys.name')"
    :invalid-feedback="errors.first('name')"
    :state="errors.has('name') ? false : null"
  )
    b-form-input(
      v-model="api_key.name"
      v-validate="'required'"
      data-vv-name="name"
      :data-vv-as="$t('companies.api_keys.name')"
    )

  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
</template>

<script>
import ApiCompanyApis from "@/api/companyApiKeys.js";

export default {
  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      show: false,
      companyId: null,
      api_key: {
        name: null,
      }
    };
  },

  methods: {
    open(companyId) {
      this.companyId = companyId;
      this.show = true;
    },

    close() {
      this.show = false;
    },

    save() {
      this.$validator.validateAll().then((res) => {
        if (!res) return;
      });
      let params = {
        api_key: {
          ...this.api_key
        },
      };
      ApiCompanyApis.createApiKey(this.companyId, params).then((res) => {
            this.$emit("done");
            this.close();
        })
        .catch((e) => {
        });
    },
  },
};
</script>
