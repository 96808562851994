<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('resellers.tabs.statistics') }}

      rest-table(
        namespace="resellerStatistics"
        :headers="headers"
        ref="resellerStatisticsTable"
        :fetch-handler="getData"
        embed
      )
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiResellers from "@/api/resellers.js";

export default {
  data() {
    return {
      headers: [
        {
          key: "method",
          label: "Method",
          sortable: false
        },
        {
          key: "total",
          label: "Suggestions",
          sortable: false
        },
        {
          key: "true",
          label: "True",
          sortable: false
        },
        {
          key: "false",
          label: "False",
          sortable: false
        },
        {
          key: "rate",
          label: "Rate",
          sortable: false
        }
      ]
    };
  },

  methods: {
    getData() {
      return new Promise(res => {
        ApiResellers.fetchResellerStatistics(this.$route.params.id).then(
          resp => {
            res({
              items: resp.data.reseller_statistics,
              meta: resp.data.meta
            });
          }
        );
      });
    },
    goBack() {
      router.push("/resellers");
    }
  }
};
</script>
