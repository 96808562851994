import Vue from 'vue';
import _ from 'lodash';
import { BModal, props as modalProps } from 'bootstrap-vue/src/components/modal/modal';
import { getComponentConfig } from 'bootstrap-vue/src/utils/config';

import DialogAlert from '@/components/Dialogs/Alert.vue';
import DialogConfirm from '@/components/Dialogs/Confirm.vue';

const defaultResolver = evt => {};

const BASE_PROPS = [
  'id',
  ..._.keys(_.omit(modalProps, ['busy', 'lazy', 'noStacking', `static`, 'visible']))
];

const filterOptions = options => {
  return BASE_PROPS.reduce((memo, key) => {
    if (!_.isUndefined(options[key])) {
      memo[key] = options[key];
    }
    return memo;
  }, {});
};

const DialogModal = Vue.extend({
  name: 'DialogModal',
  extends: BModal,
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  },
  mounted() {
    const handleDestroy = () => {
      const self = this;
      this.$nextTick(() => {
        setTimeout(() => self.$destroy(), 0);
      });
    };
    this.$parent.$once('hook:destroyed', handleDestroy);
    this.$once('hidden', handleDestroy);
    if (this.$router && this.$route) {
      this.$once('hook:beforeDestroy', this.$watch('$router', handleDestroy));
    }
    this.show();
  }
});

const createDialogModal = (context, component, props, resolver = defaultResolver) => {
  const dialogModal = new DialogModal({
    parent: context,
    propsData: {
      ...filterOptions(getComponentConfig('BModal') || {}),
      ...props,
      hideHeader: true,
      hideFooter: true,
      lazy: false,
      busy: false,
      visible: false,
      noStacking: false,
      noEnforceFocus: false,
      bodyClass: 'p-0',
      centered: true,
      size: null,
      modalClass: 'dialog-wrapper'
    }
  });

  dialogModal.$slots.default = context.$createElement(component, {
    props,
    on: {
      ok: v => dialogModal.hide('ok', v),
      cancel: v => dialogModal.hide('cancel', v)
    }
  });

  return new Promise((resolve, reject) => {
    let resolved = false;
    dialogModal.$once('hook:destroyed', () => {
      if (!resolved) {
        reject(new Error('DialogModal destroyed before resolve'));
      }
    });
    dialogModal.$on('hide', evt => {
      // console.log(evt);
      if (!evt.defaultPrevented) {
        const result = resolver(evt);
        if (!evt.defaultPrevented) {
          resolved = true;
          resolve(result);
        }
      }
    });
    const div = document.createElement('div');
    document.body.appendChild(div);
    dialogModal.$mount(div);
  });
};

Vue.prototype.$dialogAlert = function(props) {
  return createDialogModal(this, DialogAlert, props, evt => true);
};

Vue.prototype.$dialogConfirm = function(props) {
  return createDialogModal(this, DialogConfirm, props, evt => evt.trigger === 'ok');
};
