
dialog-template(
  :title="title"
  :description="description"
  :variant="variant"
  :icon="icon"
)
  stack.justify-content-center(slot="actions" spacing="sm" wrap)
    stack-item
      b-btn.dialog-action-btn(
        block
        @click="$emit('ok')"
        squared
        :variant="okVariant || `flat-${variant}`"
      ) 
        .mdi(v-if="okIcon" :class="`mdi-${okIcon}`")
        | {{ okLabel }}
