
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div(v-if="addNew")
      .modal-title {{ $t('companies.approvers.add_title') }}
      .modal-subtitle {{ $t('companies.approvers.add_description') }}
    div(v-else)
      .modal-title {{ $t('companies.approvers.edit_title') }}
      .modal-subtitle {{ $t('companies.approvers.edit_description') }}
  b-form-group(
    :label="$t('companies.users.name')",
    label-for="name-input",
    label-cols="4"
    :invalid-feedback="errors.first('name')"
    :state="errors.has('name') ? false : null"
  )
    b-input#name-input(
      v-model="form.approver_name"
      v-validate="'required'"
      data-vv-name="name"
      :data-vv-as="$t('companies.users.name')"
    )

  b-form-group(
    :label="$t('companies.users.email')",
    label-for="email-input",
    label-cols="4"
    :invalid-feedback="errors.first('email')"
    :state="errors.has('email') ? false : null"
  )
    b-input#email-input(
      v-model="form.email",
      v-validate="'required|email'"
      data-vv-name="email"
      :data-vv-as="$t('companies.users.email')"
      type="email"
    )
  b-form-group(
    :label="$t('companies.approvers.price_limit')",
    label-for="price_limit-input",
    label-cols="4",
    v-if="form.is_approver"
  )
    b-input#email-input(
      v-model="form.price_limit",
    )
  b-form-group(
    :label="$t('companies.approvers.is_approver')"
    label-cols="4"
  )
    b-form-checkbox(v-model="form.is_approver", switch)
  b-form-group(
    :label="$t('companies.approvers.is_verificator')"
    label-cols="4"
  )
    b-form-checkbox(v-model="form.is_verificator", switch)
  template(slot="modal-footer")
    b-btn(
      variant="primary"
      squared
      @click="save"
    )
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
