
b-modal(
  centered
  :title="title"
  v-model="show"
  cancel-disabled
  :hide-footer="true"
  size="lg"
)
  b-table(:items="userData" :fields="headers" striped small thead-class="d-none")
