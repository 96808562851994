<template lang="pug">
.dialog.dialog-alert(:class="dialogClasses")
  .dialog-icon(v-if="icon")
    .mdi(:class="`mdi-${icon}`")
  .dialog-title(v-html="title")
  .dialog-description(v-html="description")
  .dialog-actions
    slot(name="actions")
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },

    description: {
      type: String,
      default: null
    },

    variant: {
      type: String,
      default: 'primary'
    },

    icon: {
      type: String,
      default: 'check'
    }
  },

  computed: {
    dialogClasses() {
      return {
        [`dialog--${this.variant}`]: true
      };
    }
  }
};
</script>