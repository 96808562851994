
div.position-relative.dx-viewport
  div.invoice-popover(v-if="$route.query.vendor_id")
    ShowVendor(:id="$route.query.vendor_id")
  .p-4(v-else)
    .container#vendors-container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.vendors') }}

      .pt-3.px-3
        stack
          stack-item(fill)
            b-form-group(
              :label="$t('companies.vendors.name')"
              label-for="name-input"
              label-cols="4"
            )
              b-form-input(
                v-model="search_params.name"
                type="search"
                id = "name-input"
                @change="reload"
              )
          stack-item(fill)
            b-form-group(
              :label="$t('companies.automation.start_date')"
              label-for="start-date-input"
              label-cols="5"
            )
              b-form-input(
                v-model="search_params.start_date"
                type="date"
                id="start-date-input"
                @change="reload"
              )
          stack-item(fill)
            b-form-group(
              :label="$t('companies.automation.end_date')"
              label-for="end-date-input"
              label-cols="5"
            )
              b-form-input(
                v-model="search_params.end_date"
                type="date"
                id="end-date-input"
                @change="reload"
              )
          stack-item(fill)
            b-form-checkbox(
              v-model="combineAll",
              id="combine-all-switch"
              switch,
              @change="changeCombineAll(combineAll, 'combine_lines')"
            )
              | {{ $t('companies.vendors.combine_all') }}
          stack-item(fill)
            b-form-checkbox(
              v-model="combineAllAccounts",
              id="combine-all-accounts-switch"
              switch,
              @change="changeCombineAll(combineAllAccounts, 'combine_lines_by_account')"
            )
              | {{ $t('companies.vendors.combine_all_accounts') }}
      rest-table(
        namespace="company_vendors",
        :headers="headers",
        ref="companyVendorsTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_name", slot-scope="{ item }")
          router-link(
            :to="{...$route, query: {...$route.query, vendor_id: item.id.toString()}}"
            label="item.name"
          )
            | {{ item.name }}
        template(slot="cel_specified_vat_code", slot-scope="{ item }")
          b-form-select(
            v-model="item.specified_vat_code",
            :options="vatOptions",
            @change="changeVatCode(item)"
          )
        template(slot="cel_combine_lines", slot-scope="{ item }")
          b-form-checkbox(
            v-model="item.combine_lines",
            switch,
            @change="changeCombine(item)"
          )
        template(slot="cel_combine_lines_by_account", slot-scope="{ item }")
          b-form-checkbox(
            v-model="item.combine_lines_by_account",
            switch,
            @change="changeCombineAccount(item)"
          )
        template(slot="cel_suggest_voucher_date", slot-scope="{ item }")
          b-form-checkbox(
            v-model="item.suggest_voucher_date",
            switch,
            @change="changeSuggestVoucherDate(item)"
          )
        template(slot="cel_is_accounted", slot-scope="{ item }")
          b-form-select(
            v-model="item.is_accounted",
            :options="accountedOptions",
            @change="changeAccounted(item)"
          )
        template(slot="cel_default_account", slot-scope="{ item }")
          b-form-input(
            type="number"
            v-model="item.default_account",
            @input="setEdit(item)",
            @change="changeDefaultAccountNumber(item)"
          )
        template(slot="cel_vendor_dimensions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-info",
                squared,
                @click="dimensionsModalOpen(item)"
              )
                | {{ $t('companies.vendors.vendor_dimensions') }}
        template(slot="cel_replacement_codes", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-info",
                squared,
                @click="replacementModalOpen(item)"
              )
                | {{ $t('companies.vendors.replacement_account_list') }}
      b-button.float-button(
        v-if="edited_items.length > 0"
        variant="primary",
        @click="saveAll"
      )
        .mdi.mdi-content-save-all
        | Save vendors
  edit-vendor-dimensions-modal(ref="EditVendorDimensionsModal", @done="reload")
  edit-replacement-account-list-modal(ref="EditReplacementModal", @done="reload")
