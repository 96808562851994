
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.statistics') }}
      data-table(
        :embed="true",
        :progress="inProgress",
        :headers="headers",
        :items="items",
        ref="companyStatistics",
        :disablePagination="true"
      )
