<template lang="pug">
.py-5
  .container
    b-card.whiteboard
      stack.align-items-center
        stack-item
          .mt4.text-container.text-center
            .h3 Kiitos, että aktivoit finance.rocks in Netvisor Storessa!
          .mt4.text-container.text-left
            ol.instructions Vielä muutama askel finance.rocks in käyttöönottamiseksi:
              li Kirjaudu sisään käyttäen sähköpostiosoitettasi. Tämä on sama sähköpostiosoite, jota käytät työsähköpostissasi / Netvisorissa.
              li Hyväksy palvelun käyttöehdot.
              li Tunnuksesi ovat käyttövalmiit noin 10 minuutin kuluttua.
            .contacts
              p Ystävällisin terveisin,
              p finance.rocks puolesta
              p Fredrik Teir
              p +358440161288
            ul.question Siltä varalta, että rekisteröitymisessä ilmenee ongelmia, olemme listanneet alle muutamia yleisimmin kysyttyjä kysymyksiä:
              li Millä sähköpostiosoitteella kirjaudun?
                ul.question
                  li.question_item Kirjautuminen tapahtuu samalla sähköpostiosoitteella, jolla tunnuksesi on luotu Netvisoriin. Löydät tämän kirjautumalla Netvisoriin ja valitsemalla nimesi kohdalta Omat perusasetukset.
                  li.question_item Kirjaudu käyttäen oman sähköpostisi palveluntarjoajaa (Tuetut palveluntarjoajat Google ja Microsoft).
              li Sähköpostiosoite on virheellinen
                ul.question
                  li.question_item Mikäli Netvisorissa oleva sähköpostiosoite on virheellinen, pääset korjaamaan sen Netvisorissa valitsemalla oman nimesi takaa Omat perusasetukset. Aloita tämän jälkeen rekisteröityminen uudelleen.
                  li.question_item Rekisteröitymisprosessi nollaantuu itsestään tunnin kuluessa, mikäli kirjautuminen ei onnistu.
              li Salasana on virheellinen
                ul.question
                  li.question_item finance.rocks ei säilytä käyttäjätunnuksiasi, olethan yhteydessä omaan IT-tukeesi resetoidaksesi sähköpostitilisi salasanan.
              li Tuleeko minun käyttää Google vai Microsoft kirjautumista?
                ul.question
                  li.question_item Kirjautumistapa riippuu käyttämästäsi palveluntarjoajasta.
              li Rekisteröinti kestää liian kauan?
                ul.question
                  li.question_item Palvelun ruuhkautuessa, tunnusten luomisessa voi kulua 15 minuuttia.

</template>

<script>
import eventBus from "@/eventBus";

export default {
  mounted() {
    this.$store.dispatch('switchLanguage', 'fi');
    eventBus.$on('LocaleChanged', this.changeLocale);
  },

  beforeDestroy() {
    eventBus.$off('LocaleChanged', this.changeLocale);
  },

  methods: {
    changeLocale(locale) {
      if (locale != 'fi') {
        this.$router.replace('/sign-up-via-netvisor/' + locale);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .instructions {
    list-style-position: inside;
  }

  .contacts {
    margin-left: 40px;
  }

  .question {
    list-style-position: inside;
    list-style-type: disc;
  }

  .whiteboard {
    word-wrap: break-word !important;
  }
</style>
