import axios from 'axios';

const fetchApiKeys = (company_id, pagination) => {
  console.log('fetch api keys')
  return axios.get(`/api/v1/companies/${company_id}/api_keys`, { params: { ...pagination}});
};

const getApiKey = (company_id, api_id) => {
  return axios.get(`/api/v1/companies/${company_id}/api_keys/${api_id}`);
};

const createApiKey = (company_id, params) => {
  return axios.post(`/api/v1/companies/${company_id}/api_keys`, params);
};

const deleteApiKey = (company_id, api_id) => {
  return axios.delete(`/api/v1/companies/${company_id}/api_keys/${api_id}`);
};

const ApiCompanyApis = {
  fetchApiKeys,
  getApiKey,
  createApiKey,
  deleteApiKey,
};

export default ApiCompanyApis;
