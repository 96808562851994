<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          h3.title {{ $t('companies.api_keys.title') }}
        stack-item
          b-btn(variant="primary", squared, @click="addApiKey") 
            .mdi.mdi-file-plus-outline
            | {{ $t('companies.api_keys.create_title') }}
      rest-table(
        namespace="api_keys",
        :headers="headers",
        ref="ApiKeysTable",
        :fetch-handler="getData",
        embed
      )
        template(slot="cel_active", slot-scope="{ item }")
          b-btn(
            size="xs",
            :variant="`flat-${item.active == true ? 'success' : 'danger'}`"
          )
            | {{ item.active ? 'Active' : 'Disabled' }}
        template(slot="cel_name", slot-scope="{ item }")
          b-link(
            :href="`/ApiKeys/${item.id}/job_download`",
            target="_blank",
            v-if="item.status == 'ok'"
          ) {{ item.name }}
          p(v-else) {{ item.name }}
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteApiKey(item)",
                v-if="item.status != 'started'"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-warning",
                squared,
                v-if="(item.status == 'error') & ['twin_treasury_file', 'netvisor_opus_voucher', 'netvisor_importer_payment'].includes(item.ApiKey_type)"
              )
                .mdi.mdi-file-edit
                | {{ $t('actions.edit') }}
  create-api-key(ref="CreateApiKey", @done="reloadList")
</template>
<script>
import ApiCompanies from "@/api/companies.js";
import CreateApiKey from "@/modals/Companies/createApiKey.vue";
import ApiCompanyApiKeys from "@/api/companyApiKeys.js";

export default {
  components: {
    CreateApiKey,
  },

  data() {
    return {
      company: {},
      pagination: {
        sortBy: null,
      },
      headers: [
        {
          key: "name",
          label: "Name",
          sortable: false,
        },
        {
          key: "api_key",
          label: "Api Key",
          sortable: false,
        },
        {
          key: "api_secret",
          label: "Api Secret",
          sortable: false,
        },
        {
          key: "created_by",
          label: "Created By",
          sortable: false,
        },
        {
          key: "active",
          label: "Active",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Created at",
          sortable: false,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    };
  },
  methods: {
    reloadList() {
      this.$refs.ApiKeysTable.fetchData();
    },

    getData(pagination) {
      return new Promise((res) => {
        ApiCompanyApiKeys.fetchApiKeys(this.$route.params.id, pagination).then(
          (resp) => {
            res({
              items: resp.data.api_keys,
              meta: resp.data.meta,
            });
          }
        );
      });
    },

    addApiKey() {
      this.$refs.CreateApiKey.open(this.$route.params.id, null);
    },

    deleteApiKey(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "briefcase-remove",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiCompanyApiKeys.deleteApiKey(this.$route.params.id, item.id).then(
          (resp) => {
            this.reloadList();
          }
        );
      });
    },
    fetchData() {
      ApiCompanies.getCompany(this.$route.params.id).then((resp) => {
        this.company = resp.data.company;
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
