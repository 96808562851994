
div
  .mb-3
    stack
      stack-item
        stack
          stack-item
            label.m-0(label-for="search") {{ $t('labels.search') }}
          stack-item
            b-form-input(
              :value="filters.search"
              @input="updateQueryParams({search: $event || undefined})"
              :placeholder="$t('placeholders.type')"
              id="search"
            )
      stack-item
        stack
          stack-item
            label.m-0(label-for="status") {{ $t('labels.status') }}
          stack-item
            ui-select(
              :searchable="false"
              :options="statusOptions",
              :value="filters.file_status"
              @input="updateQueryParams({file_status: $event || undefined})"
              id="status"
            )
      stack-item(fill)
      stack-item
        b-btn(
          variant="primary"
          squared
          @click="callRestartDialog"
        )
          .mdi.mdi-refresh
          | {{ $t('actions.restart_all_failed') }}
  rest-table(
    ref="table"
    namespace="files"
    :headers="headers"
    :fetch-handler="getData"
  )
    template(v-slot:cel_status="{item}")
      file-status-badge(:status="item.status")
    template(v-slot:cel_invoices_count="{item}")
      router-link(:to="getInvoicesLink(item)") {{ item.invoices_count }}
    template(v-slot:cel_customers_count="{item}")
      router-link(:to="getCustomerLink(item)") {{ item.customers_count }}
