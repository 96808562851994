<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.storages') }}
      .form-container.mt-5
        stack
          stack-item(fill)
            .h5.mb-3 {{ $t('companies.storages.drive.title') }}
        b-link(:href="googleDriveLink", v-if="!drive.enabled") {{ $t('companies.storages.drive.connect') }}
        stack(v-if="drive.enabled")
          stack-item(fill)
            b-input(v-model="driveSelectedFolderName", :readonly="true")
          stack-item
            b-btn(
              :disabled="drive.root_folder_id === driveSelectedFolder",
              variant="primary",
              squared,
              @click="saveDriveFolder"
            )
              .mdi.mdi-folder-outline
              | {{ $t('actions.save') }}
        b-form-select.mt-3(
          v-model="driveSelectedFolder",
          v-if="drive.enabled",
          :disabled="driveFiles.length == 0",
          :options="driveFiles",
          :select-size="driveFiles.length"
        )
      stack-item
        b-btn(variant="primary", squared, @click="addSftp")
          .mdi.mdi-lan-connect
          | {{ $t('actions.add') }}
      rest-table(
        namespace="company_storages",
        :headers="headers",
        ref="companyStoragesTable",
        :fetch-handler="getStorages",
        embed
      )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="showErrors(item)",
                v-if="item.has_errors"
              )
                .mdi.mdi-alert-box-outline
                | {{ $t('companies.softwares.show_errors') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                @click="editSFTP(item)"
              )
                .mdi.mdi-pencil-outline
                | {{ $t('actions.edit') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteSFTP(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
        template(slot="cel_default" slot-scope="{ item }")
            b-button(
              pill
              class="uppercase"
              :variant="STORAGES[item.default] || 'false'"
              v-b-tooltip.focus
            )
              | {{ item.default == true ? $t('companies.storages.sftp.reports') : "" }}
    edit-sftp-modal(ref="EditSftpModal", @done="reloadList")
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiCompanyStorages from "@/api/companyStorages.js";
import EditSftpModal from "@/modals/Companies/updateSftp.vue";
import ApiCompanySoftwares from "@/api/companySoftwares.js";

const STORAGES = {
  "true": "success",
  "false": ""
}

export default {
  components: {
    EditSftpModal
  },

  data() {
    return {
      STORAGES: STORAGES,
      inProgress: false,
      pagination: {
        sortBy: null,
      },
      drive: {},
      driveSelectedFolder: null,
      driveFiles: [],
      headers: [
        {
          key: "id",
          label: "ID",
          squeeze: true,
        },
        {
          key: "name",
          label: "Name",
          squeeze: false,
        },
        {
          key: "host",
          label: "Host",
          squeeze: false,
        },
        {
          key: "port",
          label: "Port",
          squeeze: false,
        },
        {
          key: "remote_path",
          label: "Folder",
          squeeze: false,
        },
        {
          key: "default",
          label: "Reports",
          squeeze: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    };
  },

  watch: {
    sftpSelectedFolder: function (value) {
      this.changeSelectedSFTPFolder(value);
    },
  },

  computed: {
    googleDriveLink() {
      return `/auth/${this.$route.params.id}`;
    },

    driveSelectedFolderName() {
      if (!this.driveSelectedFolder) {
        return "";
      }
      if (!this.driveFiles) {
        return "";
      }
      return this.driveFiles.find((a) => a.value === this.driveSelectedFolder)
          .text;
    },
  },

  methods: {
    reloadList() {
      this.$refs.companyStoragesTable.fetchData();
    },

    getStorages(pagination) {
      return new Promise((res) => {
        ApiCompanyStorages.fetchStorages(this.$route.params.id, pagination).then(
            (resp) => {
              res({
                items: resp.data.sftp,
                meta: resp.data.meta,
              });
            });
      });
    },

    addSftp() {
      this.$refs.EditSftpModal.open(this.$route.params.id, null);
    },

    editSFTP(item) {
      this.$refs.EditSftpModal.open(this.$route.params.id, item);
    },

    deleteSFTP(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "lan-disconnect",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiCompanyStorages.deleteSFTP(this.$route.params.id, item.id).then(
            (resp) => {
              this.reloadList();
            });
      });
    },

    //Google drive stuff
    getDrive() {
      return new Promise((res) => {
        ApiCompanyStorages.fetchDrive(this.$route.params.id).then(
            (resp) => {
              this.drive = resp.data.drive;
            });
      });
    },
  },

  mounted() {
    this.getDrive();
  },
};
</script>
