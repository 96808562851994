<template lang="pug">
li.ui-tabs-tab(:class="classes" @click="$emit('click', $event)")
    .ui-tabs-tab__body
        .ui-tabs-tab__label
            slot
</template>

<script>
export default {

    emits: ['select'],
    props: {
        badge: {
            type: [String, Number],
        },
        selected: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        className: {
            type: [String, Array, Object]
        }
    },
    computed: {
        classes() {
            return [
                this.className, 
                {
                    'ui-tabs-tab--selected': this.selected,
                    'ui-tabs-tab--disabled': this.disabled,
                }
            ];
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/stylesheet/_variables.scss';

.ui-tabs-tab {
    padding: 0 15px;
    cursor: pointer;
    white-space: nowrap;

    &__body {
        flex-shrink: 0;
        height: 50px;
        min-width: 50px;
        text-align: center;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        margin-bottom: -1px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $bb-gray-600;

        &.disabled {
            pointer-events: none;
            opacity: 0.3;
        }
    }

    &:hover &__body {
        color: $bb-gray-100;
        border-bottom-color: $bb-gray-800;
    }

    &--selected:hover &__body,
    &--selected &__body {
        color: $primary;
        border-bottom-color: $primary;
    }

    &--disabled {
        pointer-events: none;
        opacity: 0.3;
    }
}
</style>
