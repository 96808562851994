<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('resellers.tabs.errors') }}
      rest-table(
        namespace="resellerErrors"
        :headers="headers"
        ref="resellerErrorsTable"
        :fetch-handler="getData"
        embed
      )
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import ApiResellers from "@/api/resellers.js";

export default {
  data() {
    return {
      headers: [
        {
          key: "company",
          label: "Company",
          sortable: false
        },
        {
          key: "software",
          label: "Software",
          sortable: false
        },
        {
          key: "error",
          label: "Error",
          sortable: false
        },
        {
          key: "created",
          label: "#",
          sortable: false
        }
      ]
    };
  },

  methods: {
    getData() {
      return new Promise(res => {
        ApiResellers.fetchResellerErrors(this.$route.params.id).then(resp => {
          res({
            items: resp.data.reseller_errors,
            meta: resp.data.meta
          });
        });
      });
    },
    goBack() {
      router.push("/resellers");
    }
  }
};
</script>

