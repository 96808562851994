
div
  .mb-3
    stack
      stack-item
        stack
          stack-item
            label.m-0(label-for="search") {{ $t('labels.search') }}
          stack-item
            b-form-input(
              :value="filters.search"
              @input="updateQueryParams({search: $event || undefined})"
              :placeholder="$t('placeholders.type')"
              id="search"
            )
      stack-item
        stack
          stack-item
            label.m-0(label-for="status") {{ $t('labels.status') }}
          stack-item
            ui-select(
              :searchable="false"
              :options="statusOptions",
              :value="filters.invoice_status"
              @input="updateQueryParams({invoice_status: $event || undefined})"
              id="status"
            )
      stack-item
        stack
          stack-item
            label.m-0(label-for="status") {{ $t('labels.files') }}
          stack-item
            digia-file-select(
              multiselect
              :companyId="globalFilters.company_id"
              :value="globalFilters.files"
              @input="updateQueryParams({files: JSON.stringify($event)})"
            )
      stack-item(fill)
      stack-item
        b-btn(
          variant="primary"
          squared
          @click="callExportFailed"
        )
          .mdi.mdi-download
          | {{ $t('actions.export_failed_invoices_numbers') }}
      stack-item
        b-btn(
          variant="primary"
          squared
          @click="callRestartDialog"
        )
          .mdi.mdi-refresh
          | {{ $t('actions.restart_all_failed') }}
  rest-table(
    namespace="invoices",
    :headers="headers",
    ref="table",
    :fetch-handler="getData",
    embed
  )
    template(v-slot:cel_uploaded="{item}")
      invoice-uploaded-status(:success="item.uploaded")
    template(v-slot:cel_status="{item}")
      invoice-status-badge(:status="item.status")
    template(v-slot:cel_invoice_number="{item}")
      a(href="#" @click="showInvoiceRecord($event, item)") {{ item.invoice_number }}
    template(v-slot:cel_filename="{item}")
      router-link(:to="getFileFilterLink(item)") {{ item.filename }}
  ShowInvoiceRecordModal(ref="showInvoiceRecordModal")
