<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.basic') }}
      div.form-container.mt-5
        b-form-group(
          :label="$t('companies.basic.name')"
          label-for="company-name-input"
          label-cols="4"
        )
          b-input(
            v-model="company.company_name"
            id="company-name-input"
          )

        b-form-group(
          :label="$t('companies.basic.netvisor_organization_id')"
          label-for="business-id-input"
          label-cols="4"
        )
          b-input(
            v-model="company.netvisor_organization_id"
            id="business-id-input"
          )
        b-form-group(
          :label="$t('companies.basic.email')"
          label-for="email-input"
          label-cols="4"
          :invalid-feedback="errors.first('email')"
          :state="errors.has('email') ? false : null"
        )
          b-input#email-input(
            v-model="company.email"
            v-validate="'email'"
            data-vv-name="email"
            :data-vv-as="$t('companies.basic.email')"
          )

        b-form-group(
          :label="$t('companies.basic.reseller')"
          label-for="reseller-input"
          label-cols="4"
        )
          v-select(
            v-model="company.reseller_id"
            id="reseller-input"
            :options="resellers"
            :reduce="reseller => reseller.value"
            label="text"
            :clearable="false"
          )
        stack
          stack-item
            b-btn(variant="primary", squared, @click="downloadServiceAgreementFile")
              .mdi.mdi-download
              | {{ $t('actions.download_service_agreement') }}
          stack-item(fill)
          stack-item
            b-btn(
              variant="primary"
              squared
              @click="save"
            )
              .mdi.mdi-account-check-outline
              | {{ $t('actions.save') }}

</template>
<script>
import router from '@/router';
import _isEmpty from "lodash/isEmpty"
import { mapGetters } from 'vuex';
import Company from "@/models/Company";
import ApiCompanies from "@/api/companies.js";
import ApiResellers from "@/api/resellers.js";

export default {
  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      company: {},
      resellers: []
    }
  },

  methods: {
    getData() {
      ApiResellers.fetchResellers({sortBy: 'name', per_page: 9999}).then(resp => {
        this.resellers = resp.data.resellers.map(res => { return { value: res.id, text: res.name } });
        ApiCompanies.getCompany(this.$route.params.id)
          .then(resp => {
          this.company = resp.data.company;
          }).catch(e => {
          if (!_isEmpty(e.response) && !!e.response.status && e.response.status === 403) {
            this.$router.push({name: "Companies"});
          }
        });
      });
    },

    save() {
      ApiCompanies.updateCompany(this.company.id, this.company).then(resp => {
        this.company = resp.data.company;
      });
    },
    goBack() {
      router.push("/companies")
    },
    downloadServiceAgreementFile() {
      ApiCompanies.downloadServiceAgreementFile(this.$route.params.id)
        .then((resp) => {
          let fileURL = window.URL.createObjectURL(new Blob([resp.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "maintenance_contract.pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((e) => {});
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

