
b-modal(
  centered
  :title="title"
  v-model="show"
  cancel-disabled
  dialog-class="table-modal"
  :hide-footer="true"
)
  stack
    stack-item
      .container
        XmlViewer(:xml="this.file_content")
