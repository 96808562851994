import GoogleIconSource from '@/assets/images/google-icon.svg'
import AzureIconSource from '@/assets/images/microsoft-icon.svg'

const oauth2Providers = [
  {
    slug: 'google',
    label: 'Google',
    url: '/users/auth/google_oauth2',
    icon: GoogleIconSource
  },
  {
    slug: 'azure',
    label: 'Microsoft',
    url: '/users/auth/azure_oauth2',
    icon: AzureIconSource
  }
];

export default oauth2Providers;
