<template lang="pug">
b-modal(centered, v-model="show")
  template(slot="modal-title")
    div
      .modal-title {{ $t('super_admins.edit') }}
      .modal-subtitle {{ $t('super_admins.edit_description') }}

  b-form-group(
    :label="$t('super_admins.name')"
    label-for="name-input"
    label-cols="4"
    :invalid-feedback="errors.first('name')"
    :state="errors.has('name') ? false : null"
  ) 
    b-input#name-input(
      v-model="form.name"
      v-validate="'required'"
      data-vv-name="name"
      :data-vv-as="$t('super_admins.name')"
    )

  b-form-group(
    :label="$t('super_admins.email')"
    label-for="email-input"
    label-cols="4"
    :invalid-feedback="errors.first('email')"
    :state="errors.has('email') ? false : null"
  )
    b-input#email-input(
      v-model="form.email",
      type="email",
      v-validate="'required|email'"
      data-vv-name="email"
      :data-vv-as="$t('super_admins.email')"
    )

  template(slot="modal-footer")
    b-btn(variant="primary", squared, @click="save") 
      .mdi.mdi-account-check-outline
      | {{ $t('actions.save') }}
</template>

<script>
import { mapGetters } from "vuex";
import SuperAdmin from "../../models/SuperAdmin";
import ApiSuperAdmins from "@/api/super_admins.js";

export default {
  $_veeValidate: {
    validator: "new",
  },

  data() {
    return {
      show: false,
      form: new SuperAdmin(),
    };
  },

  methods: {
    open(admin) {
      this.form = new SuperAdmin(admin);
      this.show = true;
    },

    close() {
      this.show = false;
    },

    save() {
      this.$validator.validateAll().then((res) => {
        if (!res) return;
        const form = this.form;
        ApiSuperAdmins.save({ name: form.name, email: form.email })
          .then((res) => {
            this.$emit("done");
            this.close();
          })
          .catch((e) => {});
      });
    },
  },
};
</script>
