
button.header-button(
  @click="click"
)
  .header-button__prepend(v-if="hasPrepend")
    slot(name="prepend")
  .header-button__content
    .header-button__subtitle(v-if="hasSubtitle && subtitleTop")
      slot(name="subtitle") {{ subtitle }}
    .header-button__label
      slot {{ label }}
    .header-button__subtitle(v-if="hasSubtitle && !subtitleTop")
      slot(name="subtitle") {{ subtitle }}
  .header-button__append(v-if="hasAppend")
    slot(name="append")
