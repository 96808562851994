
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.storages') }}
      .form-container.mt-5
        stack
          stack-item(fill)
            .h5.mb-3 {{ $t('companies.storages.drive.title') }}
        b-link(:href="googleDriveLink", v-if="!drive.enabled") {{ $t('companies.storages.drive.connect') }}
        stack(v-if="drive.enabled")
          stack-item(fill)
            b-input(v-model="driveSelectedFolderName", :readonly="true")
          stack-item
            b-btn(
              :disabled="drive.root_folder_id === driveSelectedFolder",
              variant="primary",
              squared,
              @click="saveDriveFolder"
            )
              .mdi.mdi-folder-outline
              | {{ $t('actions.save') }}
        b-form-select.mt-3(
          v-model="driveSelectedFolder",
          v-if="drive.enabled",
          :disabled="driveFiles.length == 0",
          :options="driveFiles",
          :select-size="driveFiles.length"
        )
      stack-item
        b-btn(variant="primary", squared, @click="addSftp")
          .mdi.mdi-lan-connect
          | {{ $t('actions.add') }}
      rest-table(
        namespace="company_storages",
        :headers="headers",
        ref="companyStoragesTable",
        :fetch-handler="getStorages",
        embed
      )
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="showErrors(item)",
                v-if="item.has_errors"
              )
                .mdi.mdi-alert-box-outline
                | {{ $t('companies.softwares.show_errors') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-primary",
                squared,
                @click="editSFTP(item)"
              )
                .mdi.mdi-pencil-outline
                | {{ $t('actions.edit') }}
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteSFTP(item)"
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
        template(slot="cel_default" slot-scope="{ item }")
            b-button(
              pill
              class="uppercase"
              :variant="STORAGES[item.default] || 'false'"
              v-b-tooltip.focus
            )
              | {{ item.default == true ? $t('companies.storages.sftp.reports') : "" }}
    edit-sftp-modal(ref="EditSftpModal", @done="reloadList")
