import _ from 'lodash';

class Reseller {
  constructor(p = {}) {
    this.id = _.get(p, 'id', null);
    this.name = _.get(p, 'name', '');
    this.company_id = _.get(p, 'company_id', '');
    this.einvoice_address = _.get(p, 'einvoice_address', '');
    this.einvoice_operator_address = _.get(p, 'einvoice_operator_address', '');
    this.rtype = _.get(p, 'rtype', '');
    this.automate_accounting = _.get(p, 'automate_accounting', '');
    this.disabled_reports = _.get(p, 'disabled_reports', '');
    this.default_dimensions = _.get(p, 'default_dimensions', '');
    this.line_price = _.get(p, 'line_price', '');
    this.invoice_price = _.get(p, 'invoice_price', '');
    this.every_invoice_price = _.get(p, 'every_invoice_price', '');
  }
}

export default Reseller;
