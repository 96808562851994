
.py-5
  .container
    b-card.whiteboard
      stack.align-items-center
        stack-item
          .mt4.text-container.text-center
            .h3 Thank you for activating finance.rocks via the Netvisor store!
          .mt4.text-container.text-left
            ol.instructions Please follow these exact instructions to set finance.rocks  up for you:
              li Log in using your email address. This is the same login you use for your work emails / in Netvisor.
              li Accept the terms of service.
              li Wait about 10 minutes while we set you up.
            .contacts
              p Best regards,
              p Fredrik Teir on behalf of finance.rocks 
              p +358440161288
            ul.question Having issues, here are some common questions:
              li What is my email?
                ul.question
                  li.question_item This is the email address you have set-up in Netvisor. To find this, go to Netvisor, and select user settings behind your name.
                  li.question_item Log in using your email provider (Google or Microsoft supported).
              li Email is not correct
                ul.question
                  li.question_item No problem, the process will reset itself within one hour if you do now successfully log in.
                  li.question_item Change your email by going to user settings in Netvisor (behind your name) and initiate the signup process again! :)
              li Password is not correct
                ul.question
                  li.question_item finance.rocks does not store your credentials, please contact your own IT support to reset your password to your email account.
              li Should I use google or Microsoft login?
                ul.question
                  li.question_item Depends on what email provider you use, choose google or microsoft.
              li Signup taking too long?
                ul.question
                  li.question_item It can take up to 15 minutes to set you up if there is a lot of traffic.
                  li.question_item If the signup process takes longer than 1 hour, the process will automatically be aborted. Please signup again or contact our support.
