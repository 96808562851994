import locales from '@/localization/locales';

const isAuthenticated = state => !!state.user;
const user = state => state.user;
const language = state => state.language;
const currentLanguage = state => locales.find(l => l.value === state.language) || null;

export default {
  isAuthenticated,
  user,
  language,
  currentLanguage
};
