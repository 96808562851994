<template lang="pug">
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('admin_tools.tabs.company_logs') }}
      stack
        stack-item(fill)
          b-form-group(
            :label="$t('admin_tools.logs.model')"
            label-for="model-input"
            label-cols="4"
          )
            b-form-select(
              v-model="search_params.model",
              :options="modelsOptions",
              @change="reloadList(item)"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('admin_tools.logs.record_id')"
            label-for="model-input"
            label-cols="4"
          )
            b-form-input(
              v-model="search_params.record_id"
              type="search"
              id = "model-input"
              @change="reloadList"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.automation.start_date')"
            label-for="start-date-input"
            label-cols="5"
          )
            b-form-input(
              v-model="search_params.start_date"
              type="date"
              id="start-date-input"
              @change="reloadList"
            )
        stack-item(fill)
          b-form-group(
            :label="$t('companies.automation.end_date')"
            label-for="end-date-input"
            label-cols="5"
          )
            b-form-input(
              v-model="search_params.end_date"
              type="date"
              id="end-date-input"
              @change="reloadList"
            )

      rest-table(
        namespace="logs",
        :headers="headers",
        ref="logsTable",
        :fetch-handler="getData",
        embed
      )
</template>
<script>

// import router from "@/router";
// import axios from "axios";
import ApiLogs from "@/api/logs.js";
import { DateTime } from "luxon"

export default {
  data() {
    const monthBefore = DateTime.local().minus({month: 1}).toISODate();
    const today = DateTime.local().toISODate();

    return {
      modelsOptions: [
        { value: null, text: "All" },
        { value: "Company", text: "Company" },
        { value: "CompanyFeature", text: "CompanyFeature" }
      ],
      search_params: {
        model: null,
        record_id: null,
        start_date: monthBefore,
        end_date: today
      },
      pagination: {
        sortBy: null,
      },
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "username",
          label: "Username",
          sortable: true,
        },
        {
          key: "model",
          label: "Model",
          sortable: true,
        },
        {
          key: "record_id",
          label: "Record Id",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Date",
          sortable: true,
        },
        {
          key: "record_changes",
          label: "Record Changes",
          sortable: true,
        },
        {
          key: "comment",
          label: "Comment",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    };
  },

  methods: {
    reloadList() {
      this.$refs.logsTable.fetchData();
    },

    getData(pagination) {
      const search_params = this.search_params;
      return new Promise((res) => {
        ApiLogs.fetchLogs(search_params, pagination).then(
            (resp) => {
              res({
                items: resp.data.logs,
                meta: resp.data.meta,
              });
            }
        );
      });
    },
  }
}
</script>