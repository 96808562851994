<template lang="pug">
b-modal(
  centered
  :title="title"
  v-model="show"
  cancel-disabled
  dialog-class="table-modal"
  :hide-footer="true"
)
  stack
    stack-item
      .container
        XmlViewer(:xml="this.file_content")
</template>

<script>
import ApiScopeSalesInvoices from "@/api/scopeSalesInvoices.js";
import XmlViewer from "vue-xml-viewer";

export default {
  components: {
    XmlViewer,
  },

  data() {
    return {
      show: false,
      title: this.$t("companies.sales_invoices.title"),
      inProgress: false,
      file_content: "",
    };
  },

  methods: {
    open(invoiceId) {
      this.show = true;
      this.inProgress = true;
      ApiScopeSalesInvoices.fetchInvoice(this.$route.params.id, invoiceId).then(
        (resp) => {
          this.file_content = resp.data.scope_sales_invoice.original_record;
          console.log(this.file_content);
          this.title =
            this.$t("companies.sales_invoices.title") +
            " " +
            resp.data.scope_sales_invoice.invoice_number;
          this.inProgress = false;
        }
      );
    },

    close() {
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
// .container {
//   word-wrap: break-word;
// }
</style>
