import axios from 'axios';

const FetchList = (pagination) => {
  return axios.get('/api/v1/billing', { params: {...pagination }});
}

const FetchCompanies = (reseller_id, params, pagination) => {
  return axios.get(`/api/v1/billing/${reseller_id}`, { params: { ...params, ...pagination }});
}


const ApiBilling = {
  FetchList,
  FetchCompanies
}

export default ApiBilling;