<template lang="pug">
div
  .pb-5
    .page-hero
    .container
      b-card(no-body)
        .pt-3.px-3
          stack
            stack-item(fill)
              h3.title {{$t('admin_users.title') }}
        .pt-3.px-3
          stack
            stack-item()
              b-form-group(
                :label="$t('admin_users.user_name')"
                label-for="company-name-input"
                label-cols="3"
              )
                b-form-input(
                  v-model="search_params.user_name"
                  type="search"
                  id="company-name-input"
                  @change="reloadList"
                )
            stack-item
              b-form-group(
                :label="$t('admin_users.company')"
                label-for="company-name-input"
                label-cols="3"
              )
                b-form-input(
                  v-model="search_params.company"
                  type="search"
                  id="company-name-input"
                  @change="reloadList"
                )
        .container
          .alert.alert-danger(v-if="this.error !== null") {{ this.error }}
        rest-table(
          namespace="company_users",
          :headers="headers",
          ref="adminUsersTable",
          :fetch-handler="getData",
          embed
        )
          template(slot="cel_role", slot-scope="{ item }")
            | {{ item.role && $t(`roles.${item.role}`) }}
          template(slot="cel_actions", slot-scope="{ item }")
            stack(spacing="xs")
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-primary",
                  squared,
                  @click="editUser(item)"
                )
                  .mdi.mdi-pencil-outline
                  | {{ $t('actions.edit') }}
              stack-item
                b-btn(
                  size="xs",
                  variant="flat-danger",
                  squared,
                  @click="deleteUser(item)"
                )
                  .mdi.mdi-trash-can-outline
                  | {{ $t('actions.delete') }}
    edit-user-modal(ref="EditUserModal" @done="reloadList")
</template>
<script>
import ApiAdminUsers from "@/api/admin_user.js";
import EditUserModal from "@/modals/AdminUsers/editUser.vue";

export default {
  components: {
    EditUserModal,
  },

  data() {
    return {
      error: null,
      error_delay: 2,
      pagination: {
        sortBy: null,
      },
      search_params: {
        user_name: "",
        company: "",
      },
      headers: [
        {
          key: "id",
          label: "#",
          sortable: true,
          squeeze: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "email",
          label: "email",
          sortable: true,
        },
        {
          key: "role",
          label: "role",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          squeeze: true,
        },
      ],
    };
  },

  watch: {},

  computed: {},

  methods: {
    reloadList() {
      this.$refs.adminUsersTable.fetchData();
    },

    getData(pagination) {
      const search_params = this.search_params;
      return new Promise((res) => {
        ApiAdminUsers.fetchUsers(search_params, pagination).then((resp) => {
          this.error_delay -= 1;
          if (this.error_delay <= 0) {
            this.error = null;
          }
          res({
            items: resp.data.users,
            meta: resp.data.meta,
          });
        });
      });
    },

    deleteUser(item) {
      this.$dialogConfirm({
        title: this.$t("actions.remove"),
        description: this.$t("actions.confirm_remove"),
        variant: "danger",
        icon: "account-minus",
        okLabel: this.$t("actions.remove"),
        okVariant: "danger",
        okIcon: "trash-can-outline",
        cancelVariant: "flat-secondary",
      }).then((result) => {
        if (!result) return;
        ApiAdminUsers.deleteUser(item.id).then((resp) => {
          this.reloadList();
          this.error = resp.data.error || null;
          if (this.error !== null) {
            this.error_delay = 2;
          }
        });
      });
    },

    editUser(item) {
      this.$refs.EditUserModal.open(item);
    },
  },
};
</script>
